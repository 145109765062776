import React, { useContext, useState } from 'react';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useGetOrgInvites, usePostProfile } from '../../../api';
import { Loader } from '../../index';
import { ProfileOrgInviteList } from './ProfileOrgInviteList';
import { OrganizationsDropdown } from './OrganizationsDropdown';
import { Check, Edit, Restore } from '@mui/icons-material';
import { AuthContext } from '../../../providers';

export const Profile = () => {
  const { profile } = useContext(AuthContext);
  const {
    data: orgInvitesData,
    isLoading: orgInvitesLoading,
    error: orgInvitesError,
  } = useGetOrgInvites({ enabled: !!profile });
  const { mutate: profileMutation } = usePostProfile();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editName, setEditName] = useState<string>('');

  const handleEdit = (open: boolean) => {
    if (open) {
      setEditName(profile?.name || '');
      setIsEditing(true);
    } else {
      setEditName('');
      setIsEditing(false);
    }
  };

  const handleSave = () => {
    if (profile) {
      profileMutation(
        {
          ...profile,
          name: editName,
        },
        { onSuccess: () => handleEdit(false) },
      );
    }
  };

  if (!profile) {
    return <Loader />;
  } else {
    return (
      <Box m={2}>
        <ProfileOrgInviteList
          orgInvites={orgInvitesData}
          orgInvitesAvailable={!orgInvitesLoading && !orgInvitesError}
        />
        <Box m={2}>
          <Card>
            <CardContent>
              {isEditing ? (
                <TextField
                  fullWidth
                  variant="outlined"
                  id="editName"
                  value={editName}
                  label="Name"
                  placeholder="Name"
                  onChange={(event) => setEditName(event.target.value)}
                />
              ) : (
                <Typography variant={'h5'}>{profile?.name}</Typography>
              )}
              <Typography variant="h6">{profile?.email}</Typography>
              <Typography variant="body1" color="text.secondary">
                Organization: {profile?.organizationContext?.name || 'None'}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Roles:{' '}
                {profile?.authorities
                  ?.map((role) => role.authority)
                  .join(', ') || 'None'}
              </Typography>
            </CardContent>
            <CardActions>
              {isEditing ? (
                <>
                  <IconButton onClick={handleSave}>
                    <Check />
                  </IconButton>
                  <IconButton onClick={() => handleEdit(false)}>
                    <Restore />
                  </IconButton>
                </>
              ) : (
                <IconButton onClick={() => handleEdit(true)}>
                  <Edit />
                </IconButton>
              )}
            </CardActions>
          </Card>
        </Box>
        <Box m={2}>
          <OrganizationsDropdown organization={profile?.organizationContext} />
        </Box>
      </Box>
    );
  }
};
