import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipe } from '../../../types';

const fetchRecipesByTag = async (tagId: number): Promise<TypeRecipe[]> => {
  const res = await axios.get(`api/recipe/tag/${tagId}`);
  return res.data;
};

export const PrefetchRecipeByTag = (tagId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['recipe/tag', tagId],
    () => fetchRecipesByTag(tagId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetRecipesByTag = (
  tagId: number,
  options?: UseQueryOptions<TypeRecipe[], AxiosError, TypeRecipe[], any>,
) => {
  return useQuery(
    ['recipe/tag', tagId],
    () => fetchRecipesByTag(tagId),
    options,
  );
};
