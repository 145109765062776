import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTimeSpanDTO } from '../../../types';

export const usePostFindAppointments = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (timespanDTO: TypeTimeSpanDTO) =>
      axios.post(`api/appointment/dates`, timespanDTO).then((res) => res.data),
    {
      onMutate: () => {
        queryClient.cancelQueries('appointment/dates');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('appointment/dates');
        queryClient.invalidateQueries('availability');
      },
    },
  );
};
