import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { TypeCartDTO, TypeMembership } from '../../../types';
import { useGetMemberships, usePostStripeCreateSession } from '../../../api';
import { AuthContext, ProjectContext } from '../../../providers';
import { ShoppingCartCheckout } from '@mui/icons-material';
import { MembershipElement } from './MembershipElement';

export const Store = () => {
  const { webUrl } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  // const { stripePromise } = useContext(ProjectContext);
  const [cart, setCart] = useState<TypeCartDTO>({
    memberships: [],
    successURL: `${webUrl}/account`,
    cancelURL: `${webUrl}/account`,
  });
  const [email, setEmail] = useState('');
  const { data: memberships } = useGetMemberships();
  const { mutate: postCreateSession } = usePostStripeCreateSession();
  // const { data: clientSecret, refetch: getClientSecret } = useGetClientSecret({
  //   refetchOnWindowFocus: false,
  //   enabled: false,
  // });

  const existsInCart = (membership: TypeMembership) =>
    !!cart.memberships.find((product) => product.id === membership.id);
  const addSubscription = (membership: TypeMembership) => {
    if (existsInCart(membership)) return;
    setCart({
      ...cart,
      memberships: [...cart.memberships, membership],
    });
  };

  const removeSubscription = (membership: TypeMembership) => {
    if (!existsInCart(membership)) return;
    setCart({
      ...cart,
      memberships: cart.memberships.filter(
        (product) => product.id !== membership.id,
      ),
    });
  };

  const createSession = () => {
    postCreateSession(cart, {
      onSuccess: (data) => {
        window.location.href = data.url;
      },
    });
  };

  const checkoutButtonText = `Checkout ($${cart.memberships.reduce(
    (total, product) => total + (product.price || 0),
    0,
  )})`;

  console.log(cart);

  return (
    <Box m={2}>
      <Container>
        <Box mt={2}>
          <Typography variant="h4">Memberships</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                {!profile?.email && (
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    value={email}
                    label="Email"
                    placeholder="Email (for account creation)"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                )}
                {memberships?.map((membership) => (
                  <MembershipElement
                    key={membership.id}
                    membership={membership}
                    addToCart={() => addSubscription(membership)}
                    removeFromCart={() => removeSubscription(membership)}
                    inCart={existsInCart(membership)}
                  />
                ))}
              </CardContent>
            </Card>
          </Grid>
          {/*<Grid item xs={12}>*/}
          {/*  <Button onClick={addSubscription}>Add Subscription</Button>*/}
          {/*</Grid>*/}
          <Grid item xs={12}>
            <Button
              variant={'contained'}
              onClick={createSession}
              disabled={!cart.memberships.length}
              endIcon={<ShoppingCartCheckout />}
            >
              {checkoutButtonText}
            </Button>
          </Grid>
          <Grid item xs={12}>
            {/*{clientSecret?.secret && (*/}
            {/*  <Elements*/}
            {/*    stripe={stripePromise}*/}
            {/*    options={{ clientSecret: clientSecret?.secret }}*/}
            {/*  >*/}
            {/*    <CheckoutForm />*/}
            {/*  </Elements>*/}
            {/*)}*/}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
