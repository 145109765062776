import React, { useContext } from 'react';
import { DialogContext } from '../../../providers';
import { DeleteDialog } from './DeleteDialog';

type DeleteLeagueFieldDialogProps = {
  deleteTitle: string;
  contentText: string;
  deleteFn: () => void;
};

export const DeleteLeagueFieldDialog = ({
  deleteTitle,
  contentText,
  deleteFn,
}: DeleteLeagueFieldDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteLeagueFieldDialog } = dialogs;

  return (
    <DeleteDialog
      deleteTitle={deleteTitle}
      contentText={contentText}
      deleteFn={deleteFn}
      closeFn={() => closeDialog('deleteLeagueFieldDialog')}
      open={deleteLeagueFieldDialog}
    />
  );
};
