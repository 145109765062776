import React, { useContext } from 'react';
import {
  AppointmentCalendarContext,
  AppointmentContext,
} from '../../../../providers';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

export const SelectClient = () => {
  const { clientProfiles } = useContext(AppointmentCalendarContext);
  const {
    selectedAppointmentClientProfile,
    setSelectedAppointmentClientProfile,
  } = useContext(AppointmentContext);

  return (
    <Autocomplete
      options={clientProfiles}
      getOptionLabel={(option) => `${option.name} (${option.email})` || ''}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      value={selectedAppointmentClientProfile}
      fullWidth
      onChange={(_event, newValue) =>
        setSelectedAppointmentClientProfile(newValue)
      }
      renderInput={(params) => (
        <TextField {...params} label="Client" placeholder="Client" />
      )}
    />
  );
};
