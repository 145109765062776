import React, { useEffect } from 'react';
import { TypeQuestionnaireAnswer } from '../../../types';
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { KeyboardArrowDown, Save } from '@mui/icons-material';
import { genericSwap } from '../Utils/genericSwap';
import produce from 'immer';
import { usePostQuestionnaireAnswer } from '../../../api';

type QuestionnaireAnswerListProps = {
  questionnaireAnswerList?: TypeQuestionnaireAnswer[];
  setQuestionnaireAnswerList: (
    _newQuestionnaireAnswerList: TypeQuestionnaireAnswer[],
  ) => void;
  questionnaireQuestionId?: number;
  refetchQuestion: () => void;
};

export const QuestionnaireAnswerList = ({
  questionnaireAnswerList,
  setQuestionnaireAnswerList,
  questionnaireQuestionId,
  refetchQuestion,
}: QuestionnaireAnswerListProps) => {
  const answerMutation = usePostQuestionnaireAnswer();
  const [newAnswerText, setNewAnswerText] = React.useState('');
  const [newAnswerPosition, setNewAnswerPosition] = React.useState(0);

  const handleSetNewAnswerText = (event) => {
    setNewAnswerText(event.target.value);
  };
  const handleMoveAnswer = (index: number, up: boolean) => {
    setQuestionnaireAnswerList(
      produce(questionnaireAnswerList!, (draft) => {
        genericSwap<TypeQuestionnaireAnswer>(draft, index, up);
      }),
    );
  };

  const handleNewQuestionnaireAnswer = () => {
    answerMutation.mutate(
      {
        answerText: newAnswerText,
        position: newAnswerPosition,
        questionnaireQuestion: questionnaireQuestionId,
      },
      {
        onSuccess: () => {
          refetchQuestion();
          setNewAnswerText('');
        },
      },
    );
  };

  useEffect(() => {
    if (questionnaireAnswerList === undefined) return;
    setNewAnswerPosition(questionnaireAnswerList.length + 1);
  }, [questionnaireAnswerList]);

  return (
    <TableContainer>
      <Table style={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Answer</TableCell>
            <TableCell>Display Order</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionnaireAnswerList?.map((questionnaireAnswer, index) => (
            <TableRow key={questionnaireAnswer.id}>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/answer/${questionnaireAnswer.id}`}
                  underline="none"
                >
                  {questionnaireAnswer.answerText}
                </Link>
              </TableCell>
              <TableCell>{questionnaireAnswer.position}</TableCell>
              <TableCell>
                <IconButton
                  color="primary"
                  disabled={
                    questionnaireAnswer.position ===
                    questionnaireAnswerList?.length
                  }
                  onClick={() => handleMoveAnswer(index, false)}
                >
                  <KeyboardArrowDown />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={questionnaireAnswer.position === 1}
                  onClick={() => handleMoveAnswer(index, true)}
                >
                  <KeyboardArrowUpIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {questionnaireAnswerList?.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No Answers Yet</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <TextField
                fullWidth
                variant="outlined"
                id="newAnswerText"
                value={newAnswerText}
                label="New Answer Text"
                placeholder="Enter Answer Here"
                onChange={handleSetNewAnswerText}
              ></TextField>
            </TableCell>
            <TableCell>{newAnswerPosition}</TableCell>
            <TableCell>
              <IconButton
                color="primary"
                disabled={newAnswerText === ''}
                onClick={handleNewQuestionnaireAnswer}
              >
                <Save />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
