import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeFeatureFlag } from '../../../types';

export const usePostFeatureFlag = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (featureFlag: TypeFeatureFlag) =>
      axios.post(`api/feature-flag`, featureFlag).then((res) => res.data),
    {
      onMutate: (featureFlag: TypeFeatureFlag) => {
        queryClient.cancelQueries('feature-flag');

        const oldQuestionnaireQuestion = queryClient.getQueryData([
          'feature-flag',
          featureFlag.id,
        ]);

        queryClient.setQueryData(['feature-flag', featureFlag.id], featureFlag);

        return () =>
          queryClient.setQueryData(
            ['feature-flag', featureFlag.id],
            oldQuestionnaireQuestion,
          );
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('feature-flag');
        // queryClient.invalidateQueries(['featureFlag', variables.id]).then(_ => {})
      },
    },
  );
};
