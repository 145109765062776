import axios from 'axios';
import { auth } from '../../../firebaseapp';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export function jwtInterceptor() {
  console.log('setup interceptor');

  axios.interceptors.request.use(firebaseAuthInterceptor);
}

export async function firebaseAuthInterceptor(config) {
  // console.log(config)
  const user = auth.currentUser;

  if (user) {
    try {
      // Force refresh is false
      config.headers['Authorization'] = await user.getIdToken(false);
      // config.headers.common["Authorization"] = "Bearer " + token;
      // config.headers.token = token;
    } catch (error) {
      console.log('Error obtaining auth token in interceptor, ', error);
    }
  }

  return config;
}
