export type TypeRoleOptions =
  | 'ADMIN'
  | 'ANONYMOUS'
  | 'CLIENT'
  | 'COACH'
  | 'CONCIERGE_CLIENT'
  | 'COORDINATOR'
  | 'EMPLOYEE'
  | 'MEMBER'
  | 'OWNER'
  | 'PARENT'
  | 'PLAYER'
  | 'SUBSCRIBER'
  | 'SYSADMIN'
  | 'THERAPIST'
  | 'USER';

export const roleOptions: TypeRoleOptions[] = [
  'ANONYMOUS',
  'USER',
  'SUBSCRIBER',
  'PLAYER',
  'PARENT',
  'MEMBER',
  'CLIENT',
  'CONCIERGE_CLIENT',
  'EMPLOYEE',
  'THERAPIST',
  'COACH',
  'COORDINATOR',
  'ADMIN',
  'SYSADMIN',
  'OWNER',
];
