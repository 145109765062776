import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMeasurement } from '../../../types';

const fetchMeasurement = async (
  measurementId: number,
): Promise<TypeMeasurement> => {
  const res = await axios.get(`api/measurement/${measurementId}`);
  return res.data;
};

export const PrefetchMeasurement = (measurementId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['measurement', measurementId],
    () => fetchMeasurement(measurementId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetMeasurement = (
  measurementId: number,
  options?: UseQueryOptions<TypeMeasurement, AxiosError, TypeMeasurement, any>,
) => {
  return useQuery(
    ['measurement', measurementId],
    () => fetchMeasurement(measurementId),
    options,
  );
};
