import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  useGetSendgridActivity,
  usePostAllClientInviteEmails,
  usePostProfile,
} from '../../../api';
import { TypeProfile, TypeRole } from '../../../types';
import Autocomplete from '@mui/material/Autocomplete';
import { flexCenterCenter } from '../../../data/styles';
import { AppointmentCalendarContext } from '../../../providers';
import { format } from 'date-fns';

export const Clients = () => {
  const { clientProfiles } = useContext(AppointmentCalendarContext);

  const [editClient, setEditClient] = useState<TypeProfile | null>(null);
  const [clientName, setClientName] = useState<string>('');
  const [clientEmail, setClientEmail] = useState<string>('');
  const [clientPhone, setClientPhone] = useState<string>('');
  const [clientRoles, setClientRoles] = useState<TypeRole[]>([
    { authority: 'CLIENT' },
  ]);
  const { data: emailEvents } = useGetSendgridActivity(editClient || {}, {
    enabled: !!editClient,
  });

  const { mutate: profileMutation } = usePostProfile();
  const { mutate: sendAllLeagueClientInvitesMutation } =
    usePostAllClientInviteEmails();

  const disableCreateClient = clientName === '' || clientEmail === '';
  const createClient = () => {
    if (disableCreateClient) return;
    profileMutation(
      {
        id: editClient?.id,
        name: clientName,
        email: clientEmail,
        phoneNumber: clientPhone,
        authorities: clientRoles,
      },
      {
        onSuccess: () => handleEdit(null),
      },
    );
  };

  const handleEdit = (client: TypeProfile | null = null) => {
    console.log(client);
    setEditClient(client);
    setClientName(client?.name || '');
    setClientEmail(client?.email || '');
    setClientPhone(client?.phoneNumber || '');
    setClientRoles(client?.authorities || [{ authority: 'CLIENT' }]);
  };

  const getClientOptionLabel = (option: string | TypeProfile) =>
    typeof option === 'string' ? option : `${option.name}`;

  const toggleConcierge = () => {
    if (!isConciergeClient()) {
      setClientRoles([...clientRoles!, { authority: 'CONCIERGE_CLIENT' }]);
    } else {
      setClientRoles(
        clientRoles?.filter(
          (authority) => authority.authority !== 'CONCIERGE_CLIENT',
        ),
      );
    }
  };

  const isConciergeClient = () =>
    clientRoles.some((authority) => authority.authority === 'CONCIERGE_CLIENT');

  const sendAllClientInvites = () => sendAllLeagueClientInvitesMutation();

  const timestampFormatted = (timestamp: number) =>
    format(timestamp, 'MM/dd/yyyy hh:mm a');

  return (
    <Box mt={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h4'}>
              Search and Add Clients ({clientProfiles.length})
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert
              severity="warning"
              action={<Button onClick={sendAllClientInvites}>Send</Button>}
            >
              Click the button on the right to send invitations to all new
              clients.
            </Alert>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type={'text'}
              id="clientName"
              value={clientName}
              label="Client Name"
              placeholder="Client Name"
              onChange={(event) => setClientName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type={'email'}
              id="clientEmail"
              value={clientEmail}
              label="Client Email"
              placeholder="Client Email"
              onChange={(event) => setClientEmail(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              variant="outlined"
              type={'phone'}
              id="clientPhone"
              value={clientPhone}
              label="Client Phone"
              placeholder="Client Phone"
              onChange={(event) => setClientPhone(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={flexCenterCenter}>
            <Chip
              clickable
              sx={{ marginRight: 1 }}
              label={'Concierge'}
              color={isConciergeClient() ? 'primary' : 'default'}
              onClick={() => toggleConcierge()}
            />
            <Button
              variant="contained"
              disabled={disableCreateClient || !!editClient}
              onClick={createClient}
            >
              Add Client
            </Button>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Autocomplete
              options={clientProfiles}
              getOptionLabel={getClientOptionLabel}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              value={editClient}
              fullWidth
              onChange={(_event, newValue) => handleEdit(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Edit Client"
                  placeholder="Edit Client"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={3} sx={flexCenterCenter}>
            <Button
              variant="contained"
              disabled={disableCreateClient || editClient === null}
              onClick={createClient}
              sx={{ marginRight: 1 }}
            >
              Update Client
            </Button>
            <Button
              variant="outlined"
              color={'secondary'}
              disabled={disableCreateClient}
              onClick={() => handleEdit(null)}
            >
              Clear
            </Button>
          </Grid>
          {emailEvents?.map((event, index) => (
            <Grid item xs={12} key={`email-event-${index}`}>
              <Typography variant={'body1'}>
                {timestampFormatted(event.timestamp!! * 1000)} - {event.event}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
