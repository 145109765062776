import React from 'react';
import { Box, Grid } from '@mui/material';
import { TeamList } from '../Team';
import { DivisionSelector } from './DivisionSelector';

export const LeagueTeams = () => {
  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DivisionSelector />
        </Grid>
      </Grid>
      <TeamList />
    </Box>
  );
};
