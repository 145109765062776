import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeAppointment } from '../../../types';

export const usePostAppointment = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (appointment: TypeAppointment) =>
      axios.post(`api/appointment`, appointment).then((res) => res.data),
    {
      onMutate: () => {
        queryClient.cancelQueries('appointment');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('appointment');
        queryClient.invalidateQueries('availability');
      },
    },
  );
};
