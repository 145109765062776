import React, { createContext, useEffect, useRef, useState } from 'react';
import { format, startOfMinute } from 'date-fns';

export type TimeContextType = {
  currentTime: Date;
  currentMinute: Date;
  formattedTime: string | null;
};

export const TimeContext = createContext<TimeContextType>({
  currentTime: new Date(),
  currentMinute: new Date(),
  formattedTime: null,
});

type TimeProviderProps = {
  children: React.ReactNode;
};

export const TimeProvider = ({ children }: TimeProviderProps) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [currentMinute, setCurrentMinute] = useState(new Date());
  const intervalRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const updateCurrentTime = () => {
    const now = new Date();
    setCurrentTime(now);
  };

  useEffect(() => {
    const tick = () => {
      updateCurrentTime();
      const now = new Date();
      const millisecondsUntilNextSecond = 1000 - now.getMilliseconds();
      intervalRef.current = setTimeout(tick, millisecondsUntilNextSecond);
    };
    tick();

    return () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const minute = startOfMinute(currentTime);
    if (minute > currentMinute || currentMinute.getMilliseconds() > 0) {
      setCurrentMinute(minute);
    }
  }, [currentTime]);

  const formattedTime = format(currentTime, 'yyyy-MM-dd HH:mm:ss');

  return (
    <TimeContext.Provider value={{ currentTime, currentMinute, formattedTime }}>
      {children}
    </TimeContext.Provider>
  );
};
