import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTag } from '../../../types';

const fetchTag = async (tagId: number): Promise<TypeTag> => {
  const res = await axios.get(`api/tag/${tagId}`);
  return res.data;
};

export const PrefetchTag = (tagId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['tag', tagId], () => fetchTag(tagId), {
    staleTime: 5000,
  });
};

export const useGetTag = (
  tagId: number,
  options?: UseQueryOptions<TypeTag, AxiosError, TypeTag, any>,
) => {
  return useQuery(['tag', tagId], () => fetchTag(tagId), options);
};
