import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeCourse } from '../../../types';

const fetchCourses = async (): Promise<TypeCourse[]> => {
  const res = await axios.get('api/course');
  return res.data;
};

export const PrefetchCourse = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['course'], fetchCourses, {
    staleTime: 5000,
  });
};

export const useGetCourses = (
  options?: UseQueryOptions<TypeCourse[], AxiosError, TypeCourse[], any>,
) => {
  return useQuery('course', fetchCourses, options);
};
