import React, { useContext, useMemo } from 'react';
import { Alert, Button, Card, CardContent, Grid } from '@mui/material';
import {
  AuthContext,
  PlannerContext,
  ProjectContext,
} from '../../../providers';
import { format, parseISO } from 'date-fns';
import { getFilterIcon } from './getFilterIcon';
import { stableSort } from '../../_common';
import { HourglassTop, PlayArrow, Schedule } from '@mui/icons-material';
import { usePostScheduledJobEntry } from '../../../api/_common/ScheduledJobEntry';
import { formatInTimeZone } from 'date-fns-tz';

export const WorkflowHistory = () => {
  const { setPerformingAction } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { activeWorkflow, handleRefreshActiveWorkflow } =
    useContext(PlannerContext);
  const { mutate: startJob } = usePostScheduledJobEntry();
  const anyHistory = useMemo(
    () =>
      !!activeWorkflow?.events?.length ||
      !!activeWorkflow?.pendingTasks?.length ||
      !!activeWorkflow?.activeTasks?.length,
    [activeWorkflow],
  );
  const sortedEvents = useMemo(
    () =>
      stableSort(activeWorkflow?.events ?? [], {
        property: 'startAt',
        isDescending: true,
      }),
    [activeWorkflow],
  );
  const sortedPendingTasks = useMemo(
    () =>
      stableSort(activeWorkflow?.pendingTasks ?? [], {
        property: 'startAt',
        isDescending: false,
      }),
    [activeWorkflow],
  );
  const sortedActiveTasks = useMemo(
    () =>
      stableSort(activeWorkflow?.activeTasks ?? [], {
        property: 'startAt',
        isDescending: false,
      }),
    [activeWorkflow],
  );
  const sortedEventsSize = sortedEvents.length;
  return (
    <>
      {activeWorkflow?.workflowRunStartAt && (
        <Card sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert severity={'success'} icon={<HourglassTop />}>
                  <strong>Workflow started at: </strong>
                  {formatInTimeZone(
                    activeWorkflow.workflowRunStartAt,
                    profile?.timeZone!!,
                    'MMM do, yyyy - hh:mmaaa',
                  )}
                </Alert>
              </Grid>
              {sortedActiveTasks?.map((step) => (
                <Grid item xs={12} key={step.taskId}>
                  <Alert severity="success">
                    <strong>Task:</strong> {step.taskName}
                    <br />
                    <strong>Started at: </strong>
                    {step.startAt
                      ? format(
                          parseISO(step.startAt),
                          'MMM do, yyyy - hh:mmaaa',
                        )
                      : 'N/A'}
                  </Alert>
                </Grid>
              ))}
              {sortedPendingTasks?.map((step) => (
                <Grid item xs={12} key={step.scheduledJobEntryId}>
                  <Alert
                    severity="success"
                    icon={<Schedule />}
                    action={
                      <Button
                        onClick={() => {
                          setPerformingAction(true);
                          startJob(step.scheduledJobEntryId!!, {
                            onSuccess: () => {
                              handleRefreshActiveWorkflow();
                            },
                            onSettled: () => {
                              setPerformingAction(false);
                            },
                          });
                        }}
                        variant={'text'}
                        color={'success'}
                        endIcon={<PlayArrow />}
                      >
                        Start Now
                      </Button>
                    }
                  >
                    <strong>Pending:</strong> {step.workflowTaskName}
                    <br />
                    <strong>Starts at: </strong>
                    {step.startAt
                      ? format(
                          parseISO(step.startAt),
                          'MMM do, yyyy - hh:mmaaa',
                        )
                      : 'N/A'}
                  </Alert>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      )}
      {sortedEvents.map((event, index) => (
        <Card key={index} sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Alert
                  severity="success"
                  icon={getFilterIcon(sortedEventsSize - (index + 1))}
                >
                  <strong>Run {sortedEventsSize - index}</strong>
                  <br />
                  <strong>Started at: </strong>
                  {event.startAt
                    ? format(parseISO(event.startAt), 'MMM do, yyyy - hh:mmaaa')
                    : 'N/A'}
                  <br />
                  <strong>Finished at: </strong>
                  {event.endAt
                    ? format(parseISO(event.endAt), 'MMM do, yyyy - hh:mmaaa')
                    : 'N/A'}
                </Alert>
              </Grid>
              {event.taskList.map((task, taskIndex) => (
                <Grid item xs={12} key={taskIndex}>
                  <Alert severity="success">
                    <strong>Task:</strong> {task.taskName}
                    <br />
                    <strong>Completed at: </strong>
                    {task.completedAt
                      ? format(
                          parseISO(task.completedAt),
                          'MMM do, yyyy - hh:mmaaa',
                        )
                      : 'N/A'}
                  </Alert>
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      ))}
      {!anyHistory && (
        <Grid item xs={12}>
          <Alert severity={'info'} sx={{ width: '100%', mb: 2 }}>
            No history available
          </Alert>
        </Grid>
      )}
    </>
  );
};
