import React, { createContext, useContext } from 'react';
import useSystemTheme from 'react-use-system-theme';
import { Theme, ThemeProvider } from '@mui/material';
import camelCase from 'camelcase';

import { createTheme } from '@mui/material/styles';

import {
  amber,
  blue,
  blueGrey as blueGray,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  grey as gray,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';
import { AuthContext } from './AuthProvider';

type ColorShade = {
  id: string;
  name: string;
  import: Record<string, string>;
};

type ColorsObject = Record<string, ColorShade>;

let colors: ColorsObject = {
  red: {
    id: 'red',
    name: 'Red',
    import: red,
  },

  pink: {
    id: 'pink',
    name: 'Pink',
    import: pink,
  },

  purple: {
    id: 'purple',
    name: 'Purple',
    import: purple,
  },

  deepPurple: {
    id: 'deep-purple',
    name: 'Deep Purple',
    import: deepPurple,
  },

  indigo: {
    id: 'indigo',
    name: 'Indigo',
    import: indigo,
  },

  blue: {
    id: 'blue',
    name: 'Blue',
    import: blue,
  },

  lightBlue: {
    id: 'light-blue',
    name: 'Light Blue',
    import: lightBlue,
  },

  cyan: {
    id: 'cyan',
    name: 'Cyan',
    import: cyan,
  },

  teal: {
    id: 'teal',
    name: 'Teal',
    import: teal,
  },

  green: {
    id: 'green',
    name: 'Green',
    import: green,
  },

  lightGreen: {
    id: 'light-green',
    name: 'Light Green',
    import: lightGreen,
  },

  lime: {
    id: 'lime',
    name: 'Lime',
    import: lime,
  },

  yellow: {
    id: 'yellow',
    name: 'Yellow',
    import: yellow,
  },

  amber: {
    id: 'amber',
    name: 'Amber',
    import: amber,
  },

  orange: {
    id: 'orange',
    name: 'Orange',
    import: orange,
  },

  deepOrange: {
    id: 'deep-orange',
    name: 'Deep Orange',
    import: deepOrange,
  },

  brown: {
    id: 'brown',
    name: 'Brown',
    import: brown,
  },

  gray: {
    id: 'gray',
    name: 'Gray',
    import: gray,
  },

  blueGray: {
    id: 'blue-gray',
    name: 'Blue Gray',
    import: blueGray,
  },
};

export type AppearanceContextType = {
  colors: ColorsObject;
  defaultPrimaryColor: ColorShade | null;
  defaultSecondaryColor: ColorShade | null;
  defaultTheme: Theme;
  selectedTheme: string;
};

const AppearanceContext = createContext<AppearanceContextType>({
  colors: {},
  defaultPrimaryColor: null,
  defaultSecondaryColor: null,
  defaultTheme: createTheme(),
  selectedTheme: 'LIGHT',
});

type AppearanceProviderProps = {
  children: React.ReactNode;
};

const AppearanceProvider = ({ children }: AppearanceProviderProps) => {
  const { profile } = useContext(AuthContext);
  const systemTheme = useSystemTheme('light');
  const getColor = (colorId?: string) => {
    if (!colorId) {
      return null;
    }

    colorId = camelCase(colorId);

    return colors[colorId];
  };

  const defaultPrimaryColor = getColor(
    process.env.REACT_APP_THEME_PRIMARY_COLOR,
  );
  const defaultSecondaryColor = getColor(
    process.env.REACT_APP_THEME_SECONDARY_COLOR,
  );
  const lightTheme = profile?.appearance === 'LIGHT' ? 'light' : undefined;
  const darkTheme = profile?.appearance === 'DARK' ? 'dark' : undefined;

  const selectedTheme = lightTheme || darkTheme || systemTheme;

  const defaultTheme = createTheme({
    palette: {
      primary: defaultPrimaryColor?.import,
      secondary: defaultSecondaryColor?.import,
      mode: selectedTheme,
      text: {
        primary: selectedTheme === 'light' ? grey[900] : grey[50],
        secondary: selectedTheme === 'light' ? grey[700] : grey[300],
      },
      background: {
        default: selectedTheme === 'light' ? grey[50] : grey[900],
        paper: selectedTheme === 'light' ? grey[100] : grey[800],
      },
    },
  });

  return (
    <AppearanceContext.Provider
      value={{
        colors,
        defaultPrimaryColor,
        defaultSecondaryColor,
        defaultTheme,
        selectedTheme,
      }}
    >
      <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
    </AppearanceContext.Provider>
  );
};

export { AppearanceContext, AppearanceProvider };
