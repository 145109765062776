import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeProfile, TypeSendgridEvent } from '../../../types';

const fetchSendgridActivity = async (
  profile: TypeProfile,
): Promise<TypeSendgridEvent[]> => {
  const res = await axios.get(`api/sendgrid/activity/profile/${profile.id}`);
  return res.data;
};

export const PrefetchSendgridActivity = (profile: TypeProfile) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['sendgrid/activity'],
    () => fetchSendgridActivity(profile),
    {
      staleTime: 5000,
    },
  );
};

export const useGetSendgridActivity = (
  profile: TypeProfile,
  options?: UseQueryOptions<
    TypeSendgridEvent[],
    AxiosError,
    TypeSendgridEvent[],
    any
  >,
) => {
  return useQuery(
    ['sendgrid/activity'],
    () => fetchSendgridActivity(profile),
    options,
  );
};
