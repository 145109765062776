import React, { useContext, useState } from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  useDeleteAnnouncement,
  useGetAnnouncements,
  useGetAnnouncementsByLeague,
} from '../../../api';
import { AuthContext, DialogContext } from '../../../providers';
import { TypeAnnouncement, TypeLeague, TypeRoleOptions } from '../../../types';
import { DeleteForever } from '@mui/icons-material';
import { DeleteAnnouncementDialog } from '../Dialogs';
import { Announcement } from './Announcement';
import { formatDateToStringTZ } from '../Utils/formatDateToStringTZ';

export type AnnouncementsProps = {
  league?: TypeLeague;
  selectableRoles?: TypeRoleOptions[];
};
export const Announcements = ({
  league,
  selectableRoles,
}: AnnouncementsProps) => {
  const { data: announcementsData, isLoading: announcementsLoading } =
    useGetAnnouncements({
      enabled: !league?.id,
    });
  const {
    data: leagueAnnouncementsData,
    isLoading: leagueAnnouncementsLoading,
  } = useGetAnnouncementsByLeague(league?.id ?? 0, {
    enabled: !!league?.id,
  });
  const announcements =
    (league ? leagueAnnouncementsData : announcementsData) ?? [];

  const { openDialog, closeDialog } = useContext(DialogContext);
  const { profile } = useContext(AuthContext);
  const { mutate: deleteAnnouncement } = useDeleteAnnouncement();
  const [announcementToDelete, setAnnouncementToDelete] =
    useState<TypeAnnouncement>();
  const [editAnnouncement, setEditAnnouncement] =
    useState<TypeAnnouncement | null>(null);

  const handleOpenAnnouncementDialog = (announcement: TypeAnnouncement) => {
    setEditAnnouncement(announcement);
    openDialog('announcementDialog');
  };

  const handleDeleteAnnouncementDialog = (announcement?: TypeAnnouncement) => {
    if (announcement) {
      setAnnouncementToDelete(announcement);
      openDialog('deleteAnnouncementDialog');
    } else {
      setAnnouncementToDelete(undefined);
      closeDialog('deleteAnnouncementDialog');
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            onClick={() =>
              handleOpenAnnouncementDialog({
                league,
              })
            }
          >
            Create Announcement
          </Button>
        </Grid>
      </Grid>
      <TableContainer
        hidden={announcementsLoading || leagueAnnouncementsLoading}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Announcement</TableCell>
              <TableCell>Start</TableCell>
              <TableCell>End</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {announcements?.map((announcement) => (
              <TableRow key={announcement.id}>
                <TableCell>
                  <Button
                    onClick={() => handleOpenAnnouncementDialog(announcement)}
                  >
                    {announcement.announcementTitle}
                  </Button>
                </TableCell>
                <TableCell>
                  {formatDateToStringTZ(
                    announcement.startAt,
                    profile?.timeZone,
                    'dateTimeOrdinal',
                    'No Start Date',
                  )}
                </TableCell>
                <TableCell>
                  {formatDateToStringTZ(
                    announcement.endAt,
                    profile?.timeZone,
                    'dateTimeOrdinal',
                    'No End Date',
                  )}
                </TableCell>
                <TableCell>
                  {announcement.roles?.map((r) => r.authority).join(',') ??
                    'N/A'}
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteAnnouncementDialog(announcement)}
                  >
                    <DeleteForever />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {announcementsData?.length === 0 && (
              <TableRow>
                <TableCell colSpan={3}>No Announcements Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {announcementToDelete ? (
        <DeleteAnnouncementDialog
          announcementTitle={announcementToDelete.announcementTitle!!}
          deleteFn={() =>
            deleteAnnouncement(announcementToDelete, {
              onSuccess: () => handleDeleteAnnouncementDialog(),
            })
          }
        />
      ) : null}
      <Announcement
        announcement={editAnnouncement}
        setAnnouncement={setEditAnnouncement}
        selectableRoles={selectableRoles}
      />
    </>
  );
};
