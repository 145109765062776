import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTagEntityMap } from '../../../types';

const fetchWorkflowTagEntityMap = async (): Promise<TypeTagEntityMap> => {
  const res = await axios.get('api/workflow/mapped');
  return res.data;
};

export const PrefetchWorkflowTagEntityMap = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['workflow/mapped'],
    fetchWorkflowTagEntityMap,
    {
      staleTime: 5000,
    },
  );
};

export const useGetWorkflowTagEntityMap = (
  options?: UseQueryOptions<
    TypeTagEntityMap,
    AxiosError,
    TypeTagEntityMap,
    any
  >,
) => {
  return useQuery('workflow/mapped', fetchWorkflowTagEntityMap, options);
};
