import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { TypeMeetingRecap } from '../../../types';
import { useParams } from 'react-router-dom';
import { useGetMeetingRecap, usePostMeetingRecap } from '../../../api';

export const MeetingRecap = () => {
  const { meetingRecapId } = useParams();
  const [meetingRecap, setMeetingRecap] = useState<TypeMeetingRecap>({});
  useGetMeetingRecap(Number(meetingRecapId), {
    onSuccess: (data) => setMeetingRecap(data),
    refetchOnWindowFocus: false,
  });
  const meetingRecapMutation = usePostMeetingRecap();

  const updateMeetingRecap = () => {
    meetingRecapMutation.mutate(meetingRecap);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setMeetingRecap(
      Object.assign({}, meetingRecap, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={10}
              variant="outlined"
              id="meetingRecapNotes"
              value={meetingRecap?.meetingNotes || ''}
              label="Enter Meeting Recap Notes"
              placeholder="Meeting Recap Notes"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateMeetingRecap}>
              Update Meeting Recap
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
