import React, { useContext } from 'react';
import { Chip, Grid, Link, Typography } from '@mui/material';
import { RecipeContext } from '../../../providers';
import { Link as RouterLink } from 'react-router-dom';

export const ViewRecipeHeader = () => {
  const { recipe } = useContext(RecipeContext);

  return (
    <>
      <Grid item xs={12}>
        <Typography variant={'h5'}>{recipe?.recipeTitle}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="text.secondary">
          {recipe?.tags?.map((tag) => (
            <Link
              key={`tag-${tag.id}`}
              component={RouterLink}
              to={`/recipe-tag/${tag.id}`}
            >
              <Chip clickable sx={{ marginRight: 1 }} label={tag.name} />
            </Link>
          ))}
        </Typography>
      </Grid>
    </>
  );
};
