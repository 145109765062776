import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTeam } from '../../../types';

export const usePostTeam = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (team: TypeTeam) => axios.post(`api/team`, team).then((res) => res.data),
    {
      onMutate: (_team: TypeTeam) => {
        queryClient.cancelQueries('team');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('team');
        queryClient.invalidateQueries('team/division');
        // queryClient.invalidateQueries(['team', variables.id]).then(_ => {})
      },
    },
  );
};
