import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeCoach } from '../../../types';

export const usePostCoach = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (coach: TypeCoach) =>
      axios.post(`api/coach`, coach).then((res) => res.data),
    {
      onMutate: (coach: TypeCoach) => {
        queryClient.cancelQueries('coach');

        const oldCoach = queryClient.getQueryData(['coach', coach.id]);

        queryClient.setQueryData(['coach', coach.id], coach);

        return { oldCoach };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('coach');
        queryClient.invalidateQueries('coach/league');
      },
    },
  );
};
