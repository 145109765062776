import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeAnnouncement } from '../../../types';

const fetchAnnouncementsByLeague = async (
  leagueId: number,
): Promise<TypeAnnouncement[]> => {
  const res = await axios.get(`api/announcement/league/${leagueId}`);
  return res.data;
};

export const PrefetchAnnouncementsByLeague = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['announcement/league', leagueId],
    () => fetchAnnouncementsByLeague(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetAnnouncementsByLeague = (
  leagueId: number,
  options?: UseQueryOptions<
    TypeAnnouncement[],
    AxiosError,
    TypeAnnouncement[],
    any
  >,
) => {
  return useQuery(
    ['announcement/league', leagueId],
    () => fetchAnnouncementsByLeague(leagueId),
    options,
  );
};
