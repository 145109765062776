import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeProfile } from '../../../types';

export const usePostProfile = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (profile: TypeProfile) =>
      axios.post(`api/profile`, profile).then((res) => res.data),
    {
      onMutate: (_profile: TypeProfile) => {
        queryClient.cancelQueries('profile');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('profile/all');
      },
    },
  );
};
