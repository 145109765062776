import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeQuestionnaireAnswer } from '../../../types';

const fetchQuestionnaireAnswer = async (
  questionnaireAnswerId: number,
): Promise<TypeQuestionnaireAnswer> => {
  const res = await axios.get(
    `api/questionnaire-answer/${questionnaireAnswerId}`,
  );
  return res.data;
};

export const PrefetchQuestionnaireAnswer = (questionnaireAnswerId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['questionnaire-answer', questionnaireAnswerId],
    () => fetchQuestionnaireAnswer(questionnaireAnswerId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetQuestionnaireAnswer = (
  questionnaireAnswerId: number,
  options?: UseQueryOptions<
    TypeQuestionnaireAnswer,
    AxiosError,
    TypeQuestionnaireAnswer,
    any
  >,
) => {
  return useQuery(
    ['questionnaire-answer', questionnaireAnswerId],
    () => fetchQuestionnaireAnswer(questionnaireAnswerId),
    options,
  );
};
