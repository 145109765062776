import React, { useContext, useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TypeLeagueField } from '../../../types';
import { Check, DeleteForever, Edit, Restore } from '@mui/icons-material';
import { useDeleteLeagueField, usePostLeagueField } from '../../../api';
import { DialogContext, LeagueContext } from '../../../providers';
import { DeleteLeagueFieldDialog } from '../../_common';

export const LeagueFieldList = ({
  leagueFields,
  leagueFieldsAvailable,
}: {
  leagueFields: TypeLeagueField[];
  leagueFieldsAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { league } = useContext(LeagueContext);
  const [editLeagueField, setEditLeagueField] = useState<string>('');
  const [activeLeagueField, setActiveLeagueField] = useState<TypeLeagueField>();
  const [leagueFieldToDelete, setLeagueFieldToDelete] =
    useState<TypeLeagueField>();
  const { mutate: updateLeagueField } = usePostLeagueField();
  const { mutate: deleteLeagueField } = useDeleteLeagueField();
  const handleEdit = (leagueField: TypeLeagueField | null = null) => {
    if (leagueField) {
      setEditLeagueField(leagueField?.name || '');
      setActiveLeagueField(leagueField);
    } else {
      setEditLeagueField('');
      setActiveLeagueField(undefined);
    }
  };

  const handleSave = () => {
    if (activeLeagueField) {
      updateLeagueField(
        {
          id: activeLeagueField.id,
          name: editLeagueField,
          league: activeLeagueField.league,
        },
        {
          onSuccess: () => handleEdit(),
        },
      );
    }
  };

  const handleDeleteLeagueFieldDialog = (leagueField?: TypeLeagueField) => {
    if (leagueField) {
      setLeagueFieldToDelete(leagueField);
      openDialog('deleteLeagueFieldDialog');
    } else {
      setLeagueFieldToDelete(undefined);
      closeDialog('deleteLeagueFieldDialog');
    }
  };

  if (!leagueFieldsAvailable) {
    return <></>;
  }
  return (
    <>
      <TableContainer hidden={!leagueFieldsAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leagueFields?.map((leagueField) => (
              <TableRow key={leagueField.id}>
                <TableCell>
                  {editLeagueField &&
                  activeLeagueField?.id === leagueField?.id ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={`week-${leagueField.id}`}
                      value={editLeagueField}
                      label="Field"
                      type={'text'}
                      onChange={(event) =>
                        setEditLeagueField(event.target.value)
                      }
                    />
                  ) : (
                    leagueField?.name
                  )}
                </TableCell>
                <TableCell>
                  {editLeagueField &&
                  activeLeagueField?.id === leagueField?.id ? (
                    <>
                      <IconButton onClick={handleSave}>
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => handleEdit()}>
                        <Restore />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEdit(leagueField)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleDeleteLeagueFieldDialog(leagueField)
                        }
                        disabled={league?.matchesGenerated}
                      >
                        <DeleteForever />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {leagueFields?.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No Fields Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {leagueFieldToDelete && (
        <DeleteLeagueFieldDialog
          deleteTitle={leagueFieldToDelete?.name!!}
          contentText={`This will delete ${leagueFieldToDelete?.name}, its practice slots, and time slots.`}
          deleteFn={() =>
            deleteLeagueField(leagueFieldToDelete, {
              onSuccess: () => handleDeleteLeagueFieldDialog(),
            })
          }
        />
      )}
    </>
  );
};
