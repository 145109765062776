import React, { useState } from 'react';
import {
  TypeQuestionnaireAnswer,
  TypeQuestionnaireQuestion,
} from '../../../types';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useGetQuestionnaireQuestion,
  usePostQuestionnaireAnswerList,
  usePostQuestionnaireQuestion,
} from '../../../api';
import { QuestionnaireAnswerList } from './QuestionnaireAnswerList';

export const QuestionnaireQuestion = () => {
  const { questionId } = useParams();
  const [question, setQuestionnaireQuestion] =
    useState<TypeQuestionnaireQuestion>({});
  const { refetch: refetchQuestion } = useGetQuestionnaireQuestion(
    Number(questionId),
    {
      onSuccess: (data) => setQuestionnaireQuestion(data),
      refetchOnWindowFocus: false,
    },
  );
  const questionMutation = usePostQuestionnaireQuestion();
  const answerListMutation = usePostQuestionnaireAnswerList();

  const updateQuestionnaireQuestion = () => {
    questionMutation.mutate(question);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setQuestionnaireQuestion(
      Object.assign({}, question, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  const handleSetQuestionnaireAnswerList = (
    newQuestionnaireAnswerList: TypeQuestionnaireAnswer[],
  ) => {
    answerListMutation.mutate(newQuestionnaireAnswerList, {
      onSuccess: () => {
        refetchQuestion();
      },
    });
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="questionText"
              value={question.questionText || ''}
              label="Enter Question Text"
              placeholder="Question Text"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateQuestionnaireQuestion}>
              Update Question
            </Button>
          </Grid>
          <Grid item xs={12}>
            <QuestionnaireAnswerList
              questionnaireAnswerList={question.questionnaireAnswerList}
              setQuestionnaireAnswerList={handleSetQuestionnaireAnswerList}
              questionnaireQuestionId={question.id}
              refetchQuestion={refetchQuestion}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
