import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTimeSlot } from '../../../types';

const fetchTimeSlots = async (divisionId: number): Promise<TypeTimeSlot[]> => {
  const res = await axios.get(`api/time-slot/division/${divisionId}`);
  return res.data;
};

export const PrefetchTimeSlot = (divisionId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['time-slot/division', divisionId],
    () => fetchTimeSlots(divisionId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetTimeSlots = (
  divisionId: number,
  options?: UseQueryOptions<TypeTimeSlot[], AxiosError, TypeTimeSlot[], any>,
) => {
  return useQuery(
    ['time-slot/division', divisionId],
    () => fetchTimeSlots(divisionId),
    options,
  );
};
