import { TypeEditWorkflowField, TypeWorkflow } from '../../../types';
import {
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import React, { useContext } from 'react';
import { HourglassTop, Start } from '@mui/icons-material';
import { ProjectContext } from '../../../providers';
import { usePostStartWorkflow } from '../../../api/coach/Workflow/usePostStartWorkflow';
import { DateView } from '../../_common';

type ViewWorkflowProps = {
  sortBy: TypeEditWorkflowField;
  showChips: boolean;
  workflow: TypeWorkflow;
  onSetEditWorkflow: (
    _workflow: TypeWorkflow,
    _workflowFocusField: TypeEditWorkflowField | null,
  ) => void;
  onSave: (_workflow: TypeWorkflow) => void;
};

export const ViewWorkflowCard = ({
  sortBy,
  showChips,
  workflow,
  onSetEditWorkflow,
}: ViewWorkflowProps) => {
  const { performingAction, setPerformingAction, setSnackbar } =
    useContext(ProjectContext);
  const { mutate: startWorkflow } = usePostStartWorkflow();
  const chipsView = () => {
    if (sortBy === 'updatedAt') {
      return <DateView title={'Updated At'} date={workflow.updatedAt} />;
    }
    if (sortBy === 'workflowRunStartAt') {
      return workflow.workflowRunStartAt ? (
        <Chip label={'Running'} color={'primary'} />
      ) : (
        <Chip label={'Not Running'} color={'secondary'} />
      );
    }
    if (workflow.tags) {
      return workflow.tags.map((tag) => (
        <Box key={tag.id} sx={{ marginRight: 1 }}>
          <Chip label={tag.name} />
        </Box>
      ));
    }
    return null;
  };

  const onStartWorkflow = (workflow: TypeWorkflow) => {
    setPerformingAction(true);
    startWorkflow(workflow?.id!!, {
      onSuccess: () => {
        setSnackbar('Workflow started');
      },
      onSettled: () => setPerformingAction(false),
    });
  };
  return (
    <Paper elevation={3} sx={{ width: '100%', marginBottom: 1 }}>
      <ListItem
        sx={{
          height: '40px',
          pb: 0,
          pt: 0,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <ListItemText
            onClick={() => onSetEditWorkflow(workflow, 'name')}
            primary={workflow.name}
            sx={{
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflowX: 'clip',
              margin: '0 auto',
              display: 'inline-grid',
              maxWidth: '100%',
            }}
          ></ListItemText>
          {showChips && chipsView()}
          <IconButton
            onClick={() => onStartWorkflow(workflow)}
            disabled={performingAction}
            edge={'end'}
          >
            {workflow.workflowRunStartAt ? <HourglassTop /> : <Start />}
          </IconButton>
        </Box>
      </ListItem>
    </Paper>
  );
};
