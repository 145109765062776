import React, { useContext, useState } from 'react';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {
  TypeDivision,
  TypePracticeDivisionGroup,
  TypePracticeSlot,
  TypePracticeSlotDay,
  TypeTeam,
} from '../../../types';
import {
  AuthContext,
  DialogContext,
  LeagueContext,
  ProjectContext,
} from '../../../providers';
import { usePostPracticeSlot, usePostPracticeSlotDay } from '../../../api';
import Autocomplete from '@mui/material/Autocomplete';
import { ClaimPracticeSlotDayDialog } from '../../_common';
import { Add, Edit, Warning } from '@mui/icons-material';
import { formatTimeToStringTZ } from '../../_common/Utils/formatTimeToStringTZ';

type PracticeSlotListProps = {
  practiceSlots: TypePracticeSlot[];
  teams: TypeTeam[];
  practiceDivisionGroup?: TypePracticeDivisionGroup;
};
export const PracticeSlotList = ({
  practiceSlots,
  teams,
}: PracticeSlotListProps) => {
  const { setSnackbar } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { days, divisions } = useContext(LeagueContext);
  const { profile } = useContext(AuthContext);
  const { mutate: postPracticeSlot } = usePostPracticeSlot();
  const { mutate: postPracticeSlotDay } = usePostPracticeSlotDay();
  const [claimPracticeSlot, setClaimPracticeSlot] =
    useState<TypePracticeSlot>();
  const [claimPracticeSlotDay, setClaimPracticeSlotDay] =
    useState<TypePracticeSlotDay>();

  const handleClaimPracticeSlot = (
    practiceSlot?: TypePracticeSlot,
    practiceSlotDay?: TypePracticeSlotDay,
  ) => {
    if (practiceSlot && practiceSlotDay) {
      setClaimPracticeSlot(practiceSlot);
      setClaimPracticeSlotDay(practiceSlotDay);
      openDialog('claimPracticeSlotDayDialog');
    } else {
      setClaimPracticeSlot(undefined);
      setClaimPracticeSlotDay(undefined);
      closeDialog('claimPracticeSlotDayDialog');
    }
  };

  const setPracticeSlotDayTargetDivision = (
    practiceSlot: TypePracticeSlot,
    division: TypeDivision | null,
  ) => {
    practiceSlot.targetDivision = division || undefined;
    postPracticeSlot(practiceSlot, {
      onSuccess: () => setSnackbar('Practice Slot Updated'),
    });
  };

  const savePracticeSlotDay = (
    practiceSlotDay: TypePracticeSlotDay,
    team: TypeTeam | null,
    disabled: boolean,
  ) => {
    practiceSlotDay.team = team || undefined;
    practiceSlotDay.disabled = disabled;
    postPracticeSlotDay(practiceSlotDay, {
      onSuccess: () => {
        setSnackbar('Practice Slot Day Updated');
        handleClaimPracticeSlot();
      },
      onError: () => {
        setSnackbar('Practice Slot Day Failed to Update', 'error');
        handleClaimPracticeSlot();
      },
    });
  };

  if (!practiceSlots) {
    return <></>;
  }

  const renderDivision = (practiceSlot: TypePracticeSlot) => {
    if (divisions) {
      return (
        <Autocomplete
          value={practiceSlot?.targetDivision || null}
          id="targetDivision"
          options={divisions || []}
          getOptionLabel={(option) => option.name || ''}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(_event, newValue) =>
            setPracticeSlotDayTargetDivision(practiceSlot, newValue)
          }
          renderInput={(params) => (
            <TextField {...params} label="Division" placeholder="Division" />
          )}
        />
      );
    } else {
      return <></>;
    }
  };

  const renderPracticeSlotDay = (
    practiceSlot: TypePracticeSlot,
    practiceSlotDay: TypePracticeSlotDay,
  ) => {
    const teamDetails = practiceSlotDay?.disabled
      ? 'Disabled'
      : practiceSlotDay?.team
      ? practiceSlotDay?.team?.headCoach
        ? `${practiceSlotDay?.team?.headCoach?.name} - ${practiceSlotDay?.team?.division?.name} - ${practiceSlotDay?.team?.name}`
        : `Needs Coach - ${practiceSlotDay?.team?.division?.name} - ${practiceSlotDay?.team?.name}`
      : 'Add Team';

    return (
      <Button
        variant={practiceSlotDay?.team ? 'contained' : 'outlined'}
        color={practiceSlotDay?.disabled ? 'warning' : 'primary'}
        onClick={() => handleClaimPracticeSlot(practiceSlot, practiceSlotDay)}
        startIcon={practiceSlotDay?.disabled ? <Warning /> : null}
        endIcon={
          practiceSlotDay?.team || practiceSlotDay?.disabled ? (
            <Edit />
          ) : (
            <Add />
          )
        }
        sx={{ whiteSpace: 'nowrap' }}
      >
        {teamDetails}
      </Button>
    );
  };

  const renderPracticeSlot = (
    practiceSlot: TypePracticeSlot,
    index: number,
  ) => {
    return (
      <TableRow key={index}>
        <TableCell>{practiceSlot.leagueField?.name}</TableCell>
        <TableCell>
          {formatTimeToStringTZ(
            practiceSlot?.leagueTime?.time,
            profile?.timeZone,
          )}
        </TableCell>
        <TableCell sx={{ minWidth: '200px' }}>
          {renderDivision(practiceSlot)}
        </TableCell>
        {practiceSlot?.practiceSlotDayList?.map((practiceSlotDay, index) => (
          <TableCell key={index}>
            {renderPracticeSlotDay(practiceSlot, practiceSlotDay)}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <div style={{ overflowX: 'auto' }}>
          <Table style={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Field</TableCell>
                <TableCell>Time</TableCell>
                <TableCell>Division</TableCell>
                {days
                  ?.filter((day) => day.enabled)
                  .map((day, index) => (
                    <TableCell key={`day-${index}`}>{day.day}</TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {practiceSlots?.map((practiceSlot, index) =>
                renderPracticeSlot(practiceSlot, index),
              )}
              {Object.values(practiceSlots).length === 0 && (
                <TableRow>
                  <TableCell colSpan={7}>No Practice Slots Yet</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
      {teams && claimPracticeSlot && claimPracticeSlotDay && (
        <ClaimPracticeSlotDayDialog
          practiceSlot={claimPracticeSlot}
          practiceSlotDay={claimPracticeSlotDay}
          teams={teams}
          savePracticeSlotDay={savePracticeSlotDay}
        />
      )}
    </>
  );
};
