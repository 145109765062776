import React, { useContext, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { LeagueList } from './LeagueList';
import { useGetLeagues, usePostLeague } from '../../../api';
import { AuthContext } from '../../../providers';

export const Leagues = () => {
  const { profile } = useContext(AuthContext);
  const [leagueName, setLeagueName] = useState<string>('');
  const { ...queryInfo } = useGetLeagues({ enabled: !!profile });
  const leagueMutation = usePostLeague();

  const createLeague = () => {
    if (leagueName === '') return;
    leagueMutation.mutate({
      name: leagueName,
    });
    setLeagueName('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLeagueName(event.target.value);
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="leagueName"
              value={leagueName}
              label="Search or Add League"
              placeholder="Leagues Title"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="contained"
              disabled={leagueName === ''}
              onClick={createLeague}
            >
              Create League
            </Button>
          </Grid>
        </Grid>
        <LeagueList
          leagues={queryInfo.data}
          leaguesAvailable={!queryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
