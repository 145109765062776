import React, { useContext } from 'react';
import { BaseDialog } from './BaseDialog';
import { AuthContext, DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

export const SignOutDialog = () => {
  const { signOut } = useContext(AuthContext);
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { signOutDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: 'Sign out?',
    contentText:
      'While signed out you are unable to manage your profile and conduct other activities that require you to be signed in.',
    dismissiveAction: (
      <Button color="primary" onClick={() => closeDialog('signOutDialog')}>
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={signOut}
      >
        Sign Out
      </Button>
    ),
  };

  return <BaseDialog dialogProps={{ open: signOutDialog }} {...props} />;
};
