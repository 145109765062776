import React, { useContext } from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeOrder } from '../../../types';
import { DeleteForever } from '@mui/icons-material';
import { useDeleteOrder } from '../../../api';
import { DialogContext } from '../../../providers';
import { DeleteOrderDialog } from '../../_common';

export const OrderList = ({
  orders,
  ordersAvailable,
  flatList = false,
}: {
  orders: TypeOrder[];
  ordersAvailable: boolean;
  flatList?: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { mutate: deleteOrder } = useDeleteOrder();
  const [orderToDelete, setOrderToDelete] = React.useState<TypeOrder>();

  const handleDeleteOrderDialog = (order?: TypeOrder) => {
    if (order) {
      setOrderToDelete(order);
      openDialog('deleteOrderDialog');
    } else {
      setOrderToDelete(undefined);
      closeDialog('deleteOrderDialog');
    }
  };

  return (
    <>
      <TableContainer hidden={!ordersAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Order</TableCell>
              {!flatList && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.map((order) => (
              <TableRow key={order.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/order/${order.id}`}
                    underline="none"
                  >
                    {order.name}
                  </Link>
                </TableCell>
                {!flatList && (
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteOrderDialog(order)}
                    >
                      <DeleteForever />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {(!orders || orders.length === 0) && (
              <TableRow>
                <TableCell colSpan={4}>No Orders Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {orderToDelete && (
        <DeleteOrderDialog
          order={orderToDelete}
          deleteFn={() =>
            deleteOrder(orderToDelete, {
              onSuccess: () => handleDeleteOrderDialog(),
            })
          }
        />
      )}
    </>
  );
};
