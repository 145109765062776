import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeMeasurement } from '../../../types';

export const usePostMeasurement = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (measurement: TypeMeasurement) =>
      axios.post(`api/measurement`, measurement).then((res) => res.data),
    {
      onMutate: (_measurement: TypeMeasurement) => {
        queryClient.cancelQueries('measurement');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('measurement');
        // queryClient.invalidateQueries(['measurement', variables.id]).then(_ => {})
      },
    },
  );
};
