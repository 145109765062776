import React, { useContext } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeOrgInvite } from '../../../types';
import { Send, SendOutlined } from '@mui/icons-material';
import { usePostOrgInviteEmail } from '../../../api';
import { ProjectContext } from '../../../providers';

export const OrgInviteList = ({
  orgInvites,
  orgInvitesAvailable,
}: {
  orgInvites: TypeOrgInvite[] | undefined;
  orgInvitesAvailable: boolean;
}) => {
  const { performingAction } = useContext(ProjectContext);
  const { mutate: sendOrgInviteEmailMutation } = usePostOrgInviteEmail();
  const sendOrgInviteEmail = (inviteId: number) =>
    sendOrgInviteEmailMutation(inviteId);
  return (
    <TableContainer hidden={!orgInvitesAvailable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>Organization</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orgInvites?.map((orgInvite) => (
            <TableRow key={orgInvite.id}>
              <TableCell>{orgInvite.email}</TableCell>
              <TableCell>
                {orgInvite.authorities
                  ?.map((role) => role.authority)
                  .join(', ')}
              </TableCell>
              <TableCell>{orgInvite.organization?.name}</TableCell>
              <TableCell>
                {orgInvite.accepted ? 'Accepted' : 'Pending'}
              </TableCell>
              <TableCell>
                <Button
                  variant={'outlined'}
                  startIcon={orgInvite.emailSent ? <SendOutlined /> : <Send />}
                  disabled={performingAction}
                  onClick={() =>
                    orgInvite.id && sendOrgInviteEmail(orgInvite.id)
                  }
                >
                  Send Invite
                </Button>
              </TableCell>
            </TableRow>
          ))}
          {orgInvites?.length === 0 && (
            <TableRow>
              <TableCell colSpan={5}>No Org Invites Yet</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
