import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeCartDTO } from '../../../types';

export const usePostStripeCreateSession = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (cartDTO: TypeCartDTO) =>
      axios.post(`api/public/create-session`, cartDTO).then((res) => res.data),
    {
      onMutate: (_cartDTO: TypeCartDTO) => {
        queryClient.cancelQueries('public/create-session');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('public/create-session');
      },
    },
  );
};
