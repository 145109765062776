import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLeagueDate } from '../../../types';

const fetchLeagueDates = async (
  leagueId: number,
): Promise<TypeLeagueDate[]> => {
  const res = await axios.get(`api/league-date/league/${leagueId}`);
  return res.data;
};

export const PrefetchLeagueDate = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['league-date/league', leagueId],
    () => fetchLeagueDates(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLeagueDates = (
  leagueId: number,
  options?: UseQueryOptions<
    TypeLeagueDate[],
    AxiosError,
    TypeLeagueDate[],
    any
  >,
) => {
  return useQuery(
    ['league-date/league', leagueId],
    () => fetchLeagueDates(leagueId),
    options,
  );
};
