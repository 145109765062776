import React from 'react';
import { createRoot } from 'react-dom/client';
import 'typeface-roboto';
import './index.css';
import { App } from './components';
import { jwtInterceptor } from './components/_common/Axios/axiosInterceptor';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

jwtInterceptor();
const root = createRoot(document.getElementById('root'));
root.render(<App />);
// serviceWorker.register();
