import React, { useContext } from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeImage } from '../../../types';
import { ProjectContext } from '../../../providers';
import { CloudinaryPhoto } from '../Cloudinary';

export const ImageList = ({
  images,
  imagesAvailable,
}: {
  images?: TypeImage[];
  imagesAvailable: boolean;
}) => {
  const { cld } = useContext(ProjectContext);

  return (
    <TableContainer hidden={!imagesAvailable}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Thumbnail</TableCell>
            <TableCell>URL</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Order</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {images?.map((image) => {
            const cloudinaryImageUrl = cld?.image(image.imageUrl).toURL() || '';
            return (
              <TableRow key={image.id}>
                <TableCell>
                  <CloudinaryPhoto
                    publicId={image.imageUrl}
                    width={100}
                    height={100}
                    format={image.format}
                    isThumbnail
                  />
                </TableCell>
                <TableCell>
                  <Link
                    href={cloudinaryImageUrl}
                    underline="none"
                    target="_blank"
                  >
                    {image.imageUrl}
                  </Link>
                </TableCell>
                <TableCell>{image.imageCategory?.name}</TableCell>
                <TableCell>{image.position}</TableCell>
              </TableRow>
            );
          })}
          {images?.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No Images Yet</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
