import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRole } from '../../../types';

const fetchRoles = async (): Promise<TypeRole[]> => {
  const res = await axios.get('api/role');
  return res.data;
};

export const PrefetchRole = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['role'], fetchRoles, {
    staleTime: 5000,
  });
};

export const useGetRoles = (
  options?: UseQueryOptions<TypeRole[], AxiosError, TypeRole[], any>,
) => {
  return useQuery('role', fetchRoles, options);
};
