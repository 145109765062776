import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMatch } from '../../../types';

const fetchMatches = async (weekId: number): Promise<TypeMatch[]> => {
  const res = await axios.get(`api/match/week/${weekId}`);
  return res.data;
};

export const PrefetchMatch = (weekId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['match/week', weekId],
    () => fetchMatches(weekId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetMatchesByWeek = (
  weekId: number,
  options?: UseQueryOptions<TypeMatch[], AxiosError, TypeMatch[], any>,
) => {
  return useQuery(['match/week', weekId], () => fetchMatches(weekId), options);
};
