import React from 'react';
import { TimePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';

interface TimePickerWithZoneProps {
  value: string | null; // Expects format like "09:00-05:00"
  onChange: (_newTime: string | null) => void;
  userTimeZone: string; // e.g. "America/New_York"
  label?: string;
}

export const TimePickerWithZone = ({
  value,
  onChange,
  userTimeZone,
  label = 'Select Time',
}: TimePickerWithZoneProps) => (
  <TimePicker
    sx={{ width: '100%' }}
    label={label}
    value={value ? parseISO('2024-01-01T' + value) : null}
    onChange={(newValue) => {
      onChange(newValue ? format(newValue, 'HH:mmXXX') : null);
    }}
    views={['hours', 'minutes']}
    format="h:mm a"
    ampm
    slotProps={{
      textField: {
        helperText: `Times are shown in ${userTimeZone}`,
      },
    }}
  />
);
