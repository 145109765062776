import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeProfile } from '../../../types';

const fetchAllProfiles = async (): Promise<TypeProfile[]> => {
  const res = await axios.get('api/profile/all');
  return res.data;
};

export const PrefetchProfile = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['profile/all'], fetchAllProfiles, {
    staleTime: 5000,
  });
};

export const useGetAllProfiles = (
  options?: UseQueryOptions<TypeProfile[], AxiosError, TypeProfile[], any>,
) => {
  return useQuery('profile/all', fetchAllProfiles, options);
};
