import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { TypeMembership } from '../../../types';

export type MembershipProps = {
  membership: TypeMembership;
  addToCart?: () => void;
  removeFromCart?: () => void;
  inCart?: boolean;
};

export const MembershipElement = ({
  membership,
  addToCart,
  removeFromCart,
  inCart,
}: MembershipProps) => {
  return (
    <Grid item xs={12}>
      <Typography variant="h6">{membership.name}</Typography>
      <Typography variant="body1">{membership.description}</Typography>
      {addToCart && (
        <Button
          variant="contained"
          onClick={addToCart}
          disabled={inCart}
          sx={{ marginRight: 2 }}
        >
          Add
        </Button>
      )}
      {removeFromCart && (
        <Button
          variant="contained"
          color={'secondary'}
          onClick={removeFromCart}
          disabled={!inCart}
        >
          Remove
        </Button>
      )}
    </Grid>
  );
};
