import React, { useContext, useState } from 'react';
import {
  Alert,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TypeCoach, TypeTeam } from '../../../types';
import { Check, DeleteForever, Edit, Restore } from '@mui/icons-material';
import { useDeleteTeam, useGetTeams, usePostTeam } from '../../../api';
import Autocomplete from '@mui/material/Autocomplete';
import {
  DialogContext,
  LeagueContext,
  ProjectContext,
} from '../../../providers';
import { DeleteTeamDialog } from '../../_common';

export const TeamList = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { selectedDivision } = useContext(LeagueContext);
  const { coaches, isLeagueReady, league } = useContext(LeagueContext);

  const { data: teamsData, isLoading: teamsLoading } = useGetTeams(
    selectedDivision?.id!!,
    { enabled: isLeagueReady },
  );
  const { mutate: updateTeam } = usePostTeam();
  const { mutate: deleteTeam } = useDeleteTeam();

  const [addTeamName, setAddTeamName] = useState<string>('');
  const [editTeamName, setEditTeamName] = useState<string>('');
  const [editTeamColor, setEditTeamColor] = useState<string>('');
  const [editHeadCoach, setEditHeadCoach] = useState<TypeCoach | undefined>();
  const [editAssistantCoaches, setEditAssistantCoaches] =
    useState<TypeCoach[]>();
  const [editOtherCoaches, setEditOtherCoaches] = useState<TypeCoach[]>();
  const [activeTeam, setActiveTeam] = useState<TypeTeam>();
  const [teamToDelete, setTeamToDelete] = useState<TypeTeam>();

  const handleEdit = (team: TypeTeam) => {
    setEditTeamName(team?.name || '');
    setEditTeamColor(team?.color || '');
    setEditHeadCoach(team?.headCoach);
    setEditAssistantCoaches(team?.assistantCoaches || []);
    setEditOtherCoaches(team?.otherCoaches || []);
    setActiveTeam(team);
  };

  const resetEditTeam = (error = false) => {
    setEditTeamName('');
    setEditTeamColor('');
    setEditHeadCoach(undefined);
    setEditAssistantCoaches([]);
    setEditOtherCoaches([]);
    setActiveTeam(undefined);
    if (error) {
      setSnackbar('Error saving team data', 'error');
    } else {
      setSnackbar('Saved team data');
    }
  };

  const handleSave = () => {
    if (activeTeam) {
      updateTeam(
        {
          id: activeTeam.id,
          name: editTeamName,
          color: editTeamColor,
          division: activeTeam.division,
          headCoach: editHeadCoach,
          assistantCoaches: editAssistantCoaches,
          otherCoaches: editOtherCoaches,
        },
        {
          onSuccess: () => resetEditTeam(),
          onError: () => resetEditTeam(true),
        },
      );
    }
  };

  const handleDeleteTeamDialog = (team?: TypeTeam) => {
    if (team) {
      setTeamToDelete(team);
      openDialog('deleteTeamDialog');
    } else {
      setTeamToDelete(undefined);
      closeDialog('deleteTeamDialog');
    }
  };

  const createTeam = () => {
    if (addTeamName === '') return;
    updateTeam({
      name: addTeamName,
      division: selectedDivision!!,
    });
    setAddTeamName('');
  };

  if (!teamsData || teamsLoading || !isLeagueReady) {
    return <></>;
  }

  return (
    <>
      {teamsData && teamsData?.length % 2 === 1 && (
        <Alert severity={'warning'}>
          There are an odd number of teams in this division.
        </Alert>
      )}
      <TableContainer>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Color</TableCell>
              <TableCell>Head Coach</TableCell>
              <TableCell>Assistant Coaches</TableCell>
              <TableCell>Other Coaches</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamsData?.map((team) => (
              <TableRow key={team.id}>
                <TableCell>
                  {editTeamName && activeTeam?.id === team?.id ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={`name-${team.id}`}
                      value={editTeamName}
                      label="Team Name"
                      type={'text'}
                      onChange={(event) => setEditTeamName(event.target.value)}
                    />
                  ) : (
                    team?.name
                  )}
                </TableCell>
                <TableCell>
                  {editTeamName && activeTeam?.id === team?.id ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={`name-${team.id}`}
                      value={editTeamColor}
                      label="Team Color"
                      type={'text'}
                      onChange={(event) => setEditTeamColor(event.target.value)}
                    />
                  ) : (
                    team?.color
                  )}
                </TableCell>
                <TableCell>
                  {editTeamName && activeTeam?.id === team?.id ? (
                    <Autocomplete
                      value={editHeadCoach}
                      id="editHeadCoach"
                      options={coaches}
                      getOptionLabel={(option) => option.name || ''}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      onChange={(_event, newValue) =>
                        setEditHeadCoach(newValue || undefined)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Head Coach"
                          placeholder="Head Coach"
                        />
                      )}
                    />
                  ) : (
                    team?.headCoach?.name
                  )}
                </TableCell>
                <TableCell>
                  {editTeamName && activeTeam?.id === team?.id ? (
                    <Autocomplete
                      multiple
                      value={editAssistantCoaches}
                      id="editAssistantCoaches"
                      options={coaches}
                      getOptionLabel={(option) => option.name || ''}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      onChange={(_event, newValue) =>
                        setEditAssistantCoaches(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Coaches"
                          placeholder="Coaches"
                        />
                      )}
                    />
                  ) : (
                    team?.assistantCoaches
                      ?.map((coach) => coach.name)
                      .join(', ')
                  )}
                </TableCell>
                <TableCell>
                  {editTeamName && activeTeam?.id === team?.id ? (
                    <Autocomplete
                      multiple
                      value={editOtherCoaches}
                      id="editOtherCoaches"
                      options={coaches}
                      getOptionLabel={(option) => option.name || ''}
                      filterSelectedOptions
                      isOptionEqualToValue={(option, value) =>
                        option.id === value?.id
                      }
                      onChange={(_event, newValue) =>
                        setEditOtherCoaches(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Coaches"
                          placeholder="Coaches"
                        />
                      )}
                    />
                  ) : (
                    team?.otherCoaches?.map((coach) => coach.name).join(', ')
                  )}
                </TableCell>
                <TableCell>
                  {editTeamName && activeTeam?.id === team?.id ? (
                    <>
                      <IconButton onClick={handleSave}>
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => resetEditTeam()}>
                        <Restore />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEdit(team)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteTeamDialog(team)}
                        disabled={league?.matchesGenerated}
                      >
                        <DeleteForever />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={5}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="teamName"
                  value={addTeamName}
                  label="Add Team"
                  placeholder="Teams Title"
                  onChange={(event) => setAddTeamName(event.target.value)}
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="outlined"
                  disabled={addTeamName === ''}
                  onClick={createTeam}
                >
                  Create Team
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {teamToDelete && (
        <DeleteTeamDialog
          deleteTitle={teamToDelete?.name!!}
          contentText={`This will delete ${teamToDelete?.name}, its practices, and affect division time slots.`}
          deleteFn={() =>
            deleteTeam(teamToDelete, {
              onSuccess: () => handleDeleteTeamDialog(),
            })
          }
        />
      )}
    </>
  );
};
