import React from 'react';

import GoogleIcon from '@mui/icons-material/Google';
import { TypeAuthProvider } from '../types';

export const authProviders: TypeAuthProvider[] = [
  {
    id: 'google.com',
    color: '#ea4335',
    icon: <GoogleIcon />,
    name: 'Google',
  },
];
