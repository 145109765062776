import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeDivision } from '../../../types';

export const useDeleteDivision = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (division: TypeDivision) => axios.delete(`api/division/${division.id}`),
    {
      onMutate: (division: TypeDivision) => {
        queryClient.cancelQueries('division');

        const previousDivision = queryClient.getQueryData([
          'division',
          division.id,
        ]);

        queryClient.setQueryData(['division', division.id], division);

        return { previousDivision };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('division');
        queryClient.invalidateQueries('division/league');
      },
    },
  );
};
