import {
  Box,
  Card,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TagsHeader } from './TagsHeader';
import { PlannerFilterAndSort } from './PlannerFilterAndSort';
import React, { useContext } from 'react';
import { PlannerContext } from '../../../providers';

export const TagsAndFilters = () => {
  const { showFilters, setShowFilters, selectedTags, handleSelectTag } =
    useContext(PlannerContext);
  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <Box>
            <Typography variant={'h6'}>
              Tags & Filters
              <IconButton onClick={() => setShowFilters(!showFilters)}>
                {showFilters ? <ExpandMore /> : <ChevronRight />}
              </IconButton>
            </Typography>
          </Box>
          <Collapse in={showFilters} timeout="auto" unmountOnExit>
            <Grid container spacing={2}>
              <TagsHeader
                selectedTags={selectedTags}
                onSelectTag={handleSelectTag}
              />
              <PlannerFilterAndSort />
            </Grid>
          </Collapse>
        </CardContent>
      </Card>
    </Box>
  );
};
