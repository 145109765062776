import React, { useContext } from 'react';
import { AppointmentContext } from '../../../../providers';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { FormattedCurrency } from '../../../_common';

export const SelectCatalogItem = () => {
  const { catalogItems, setSelectedCatalogItem, setShowCatalogItems } =
    useContext(AppointmentContext);

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Select Package</Typography>
      </Grid>
      {catalogItems.map((catalogItem, index) => {
        return (
          <Grid item xs={12} key={`catalog-item-${index}`}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormattedCurrency
                    title={catalogItem?.name}
                    amount={
                      catalogItem?.defaultSquareCatalogItemVariation
                        .unitAmountDecimal
                    }
                  />
                  <Button
                    color="primary"
                    onClick={() => {
                      setSelectedCatalogItem(catalogItem);
                      setShowCatalogItems(false);
                    }}
                  >
                    Select
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};
