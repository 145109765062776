import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeagueDate } from '../../../types';

export const usePostLeagueDate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (match: TypeLeagueDate) =>
      axios.post(`api/league-date`, match).then((res) => res.data),
    {
      onMutate: (_match: TypeLeagueDate) => {
        queryClient.cancelQueries('league-date');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-date');
        queryClient.invalidateQueries('league-date/league');
      },
    },
  );
};
