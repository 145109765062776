import React from 'react';
import { Button } from '@mui/material';

interface ToggleChipsProps {
  isActive: boolean;
  onToggle: (_value: boolean) => void;
  label: string;
  activeIcon?: React.ReactNode;
  inactiveIcon?: React.ReactNode;
  sx?: object;
}

export const ToggleChips: React.FC<ToggleChipsProps> = ({
  isActive,
  onToggle,
  label,
  sx,
  activeIcon,
  inactiveIcon,
}) => {
  return (
    <Button
      sx={{ mr: 1, mb: 1, borderRadius: 20, textTransform: 'none', ...sx }}
      size={'small'}
      variant={isActive ? 'contained' : 'outlined'}
      onClick={() => onToggle(!isActive)}
      endIcon={isActive ? activeIcon : inactiveIcon}
    >
      {label}
    </Button>
  );
};
