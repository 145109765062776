import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeDiscussionTopic } from '../../../types';

const fetchDiscussionTopics = async (): Promise<TypeDiscussionTopic[]> => {
  const res = await axios.get('api/discussion-topic');
  return res.data;
};

export const PrefetchDiscussionTopic = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['discussion-topic'],
    fetchDiscussionTopics,
    {
      staleTime: 5000,
    },
  );
};

export const useGetDiscussionTopics = (
  options?: UseQueryOptions<
    TypeDiscussionTopic[],
    AxiosError,
    TypeDiscussionTopic[],
    any
  >,
) => {
  return useQuery('discussion-topic', fetchDiscussionTopics, options);
};
