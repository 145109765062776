import React from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeBodyGroup } from '../../../types';

export const BodyGroupList = ({
  bodyGroups,
  bodyGroupsAvailable,
}: {
  bodyGroups?: TypeBodyGroup[];
  bodyGroupsAvailable: boolean;
}) => (
  <TableContainer hidden={!bodyGroupsAvailable}>
    <Table style={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>Body Group</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bodyGroups?.map((bodyGroup) => (
          <TableRow key={bodyGroup.id}>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/body-group/${bodyGroup.id}`}
                underline="none"
              >
                {bodyGroup.bodyGroupName}
              </Link>
            </TableCell>
          </TableRow>
        ))}
        {bodyGroups?.length === 0 && (
          <TableRow>
            <TableCell>No Body Groups Yet</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
