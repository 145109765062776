import React, { useContext } from 'react';
import { TypeProfile } from '../../../../types';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { AppointmentContext } from '../../../../providers';

type AppointmentDetailsProps = {
  profile?: TypeProfile;
};

export const EditAppointmentDetails = ({
  profile,
}: AppointmentDetailsProps) => {
  const {
    setSelectedAppointmentClientProfile,
    editAppointment,
    createdAppointment,
    appointmentHeadlineDetails,
    resetAppointmentScheduler,
  } = useContext(AppointmentContext);

  const resetWithProfile = () => {
    if (!profile) {
      setSelectedAppointmentClientProfile(null);
    }
    resetAppointmentScheduler();
  };

  if (!editAppointment || createdAppointment) {
    return <></>;
  }

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Rescheduling Appointment</Typography>
            <Typography variant={'body1'}>
              {appointmentHeadlineDetails(editAppointment)}
            </Typography>
          </CardContent>
          <CardActions>
            <Button color="primary" onClick={resetWithProfile}>
              Cancel
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
