import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeagueWeekReschedule } from '../../../types';

export const useCancelLeagueWeekReschedule = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (leagueWeekReschedule: TypeLeagueWeekReschedule) =>
      axios.delete(`api/league-week-reschedule/${leagueWeekReschedule.id}`),
    {
      onMutate: (leagueWeekReschedule: TypeLeagueWeekReschedule) => {
        queryClient.cancelQueries('league-week-reschedule');

        const previousLeagueWeekReschedule = queryClient.getQueryData([
          'league-week-reschedule',
          leagueWeekReschedule.id,
        ]);

        queryClient.setQueryData(
          ['league-week-reschedule', leagueWeekReschedule.id],
          leagueWeekReschedule,
        );

        return { previousLeagueWeekReschedule };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-week-reschedule');
        queryClient.invalidateQueries('league-week-reschedule/league-week');
      },
    },
  );
};
