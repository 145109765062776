import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTag } from '../../../types';

const fetchTagsByType = async (type: string): Promise<TypeTag[]> => {
  const res = await axios.get(`api/tag/type/${type}`);
  return res.data;
};

export const PrefetchTagsByType = (type: string) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['tag/type', type],
    () => fetchTagsByType(type),
    {
      staleTime: 5000,
    },
  );
};

export const useGetTagsByType = (
  type: string,
  options?: UseQueryOptions<TypeTag[], AxiosError, TypeTag[], any>,
) => {
  return useQuery(['tag/type', type], () => fetchTagsByType(type), options);
};
