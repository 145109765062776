import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostOrganizationContext = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (organizationId: number) =>
      axios
        .post(`api/organization/context/${organizationId}`, {})
        .then((res) => res.data),
    {
      onMutate: (organizationId: number) => {
        return () =>
          queryClient.setQueryData('organization/context', organizationId);
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
        queryClient.invalidateQueries('organization');
        queryClient.invalidateQueries('organization/all');
      },
    },
  );
};
