import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeQuestionnaireAnswer } from '../../../types';

export const usePostQuestionnaireAnswer = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (questionnaireAnswer: TypeQuestionnaireAnswer) =>
      axios
        .post(`api/questionnaire-answer`, questionnaireAnswer)
        .then((res) => res.data),
    {
      onMutate: (_questionnaireAnswer: TypeQuestionnaireAnswer) => {
        queryClient.cancelQueries('questionnaire-answer');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('questionnaire-answer');
        // queryClient.invalidateQueries(['questionnaire-answer', variables.id]).then(_ => {})
      },
    },
  );
};
