import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeagueField } from '../../../types';

export const useDeleteLeagueField = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (leagueField: TypeLeagueField) =>
      axios.delete(`api/league-field/${leagueField.id}`),
    {
      onMutate: (leagueField: TypeLeagueField) => {
        queryClient.cancelQueries('league-field');

        const previousLeagueField = queryClient.getQueryData([
          'league-field',
          leagueField.id,
        ]);

        queryClient.setQueryData(['league-field', leagueField.id], leagueField);

        return { previousLeagueField };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-field');
        queryClient.invalidateQueries('league-field/league');
      },
    },
  );
};
