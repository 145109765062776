import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeMeasurementConversion } from '../../../types';

export const usePostMeasurementConversion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (measurementConversion: TypeMeasurementConversion) =>
      axios
        .post(`api/measurement-conversion`, measurementConversion)
        .then((res) => res.data),
    {
      onMutate: (_measurement: TypeMeasurementConversion) => {
        queryClient.cancelQueries('measurement-conversion');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('measurement-conversion');
        // queryClient.invalidateQueries(['measurement', variables.id]).then(_ => {})
      },
    },
  );
};
