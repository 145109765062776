import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import { TypeRecipeEvent } from '../../../types';
import { formatInTimeZone } from 'date-fns-tz';
import { AuthContext, ChefContext } from '../../../providers';
import { useContext } from 'react';

type RecipeEventListProps = {
  recipeEvents?: TypeRecipeEvent[];
};

export const RecipeEventList = ({ recipeEvents }: RecipeEventListProps) => {
  const { profile } = useContext(AuthContext);
  const { allRecipeEvents } = useContext(ChefContext);

  const showRecipeNames = useMemo(() => !recipeEvents, [allRecipeEvents]);

  const eventList = useMemo(
    () => recipeEvents ?? allRecipeEvents,
    [recipeEvents, allRecipeEvents],
  );

  return (
    <Grid container spacing={2}>
      {eventList.map((recipeEvent, index) => (
        <Grid item xs={12} key={`recipe-event-${index}`}>
          {showRecipeNames && `${recipeEvent.recipe?.recipeTitle} - `}
          Made on{' '}
          {recipeEvent.madeAt
            ? formatInTimeZone(
                recipeEvent.madeAt,
                profile?.timeZone!!,
                `MMM do, yyyy - hh:mmaaa`,
              )
            : ''}
        </Grid>
      ))}
    </Grid>
  );
};
