import { TypeProjectConfig } from '../../types/_common/ProjectConfig';
import { CoachHome, Planner, TaskTags } from '../../components';
import { TypeAppRoute } from '../../types';
import { commonAppRoutes, commonSysAdminAppRoutes } from './CommonRouteConfig';

const coachAdminRoutes = (): TypeAppRoute[] => [
  {
    name: 'Home',
    path: '/',
    component: CoachHome,
    showInDrawer: true,
  },
  {
    name: 'Planner',
    path: '/planner',
    component: Planner,
    showInDrawer: true,
  },
  {
    name: 'Project Tags',
    path: '/task-tags',
    component: TaskTags,
    showInDrawer: true,
  },
];

export const CoachRouteConfig = (): TypeProjectConfig => ({
  cldCloudName: 'hkgss7uwm',
  cldUploadPreset: 'tvlwmfw3',
  roleProjectRoutes: {
    ADMIN: {
      homeRoute: '/planner',
      appRoutes: [...coachAdminRoutes(), ...commonAppRoutes()],
    },
    SYSADMIN: {
      homeRoute: '/planner',
      appRoutes: [
        ...coachAdminRoutes(),
        ...commonSysAdminAppRoutes(),
        ...commonAppRoutes(),
      ],
    },
  },
});
