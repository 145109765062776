import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import {
  useGetAllOrganizations,
  useGetAllProfiles,
  usePostOrganization,
} from '../../../api';
import { OrganizationList } from './OrganizationList';
import { CreateSysAdmin } from './CreateSysAdmin';
import { Loader } from '../Loader';
import { ProfileList } from './ProfileList';
import { TypeProfile } from '../../../types';

export const Organizations = () => {
  const {
    data: organizationsData,
    isLoading: organizationsLoading,
    error: organizationsError,
  } = useGetAllOrganizations();
  const {
    data: allProfilesData,
    isLoading: allProfilesLoading,
    error: allProfilesError,
  } = useGetAllProfiles();
  const { mutate: organizationMutation } = usePostOrganization();
  const [name, setName] = React.useState<string>('');
  const [sysAdminProfiles, setSysAdminProfiles] = useState<TypeProfile[]>([]);

  useEffect(() => {
    if (allProfilesData === undefined) return;
    const sysAdminProfiles = allProfilesData.filter((profile) =>
      profile.authorities?.some(
        (authority) => authority.authority === 'SYSADMIN',
      ),
    );
    setSysAdminProfiles(sysAdminProfiles);
  }, [allProfilesData]);

  const createOrganizations = () => {
    if (name === '') return;
    organizationMutation({
      name: name,
    });
    setName('');
  };

  if (
    organizationsLoading ||
    organizationsError ||
    allProfilesLoading ||
    allProfilesError
  ) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      <Box mb={2}>
        <CreateSysAdmin />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            value={name}
            label="Create Organization"
            placeholder="Name"
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            disabled={name === ''}
            onClick={createOrganizations}
          >
            Create Organization
          </Button>
        </Grid>
      </Grid>
      <Box m={2}>
        <Typography variant="h6">Organizations</Typography>
      </Box>
      <OrganizationList
        organizations={organizationsData}
        organizationsAvailable={!organizationsLoading && !organizationsError}
      />
      <Box m={2}>
        <Typography variant="h6">System Admins</Typography>
      </Box>
      <ProfileList
        profiles={sysAdminProfiles}
        profilesAvailable={!allProfilesLoading && !allProfilesError}
      />
    </Box>
  );
};
