import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TypeAppointment, TypeProfile } from '../../types';
import { useGetAllProfiles, usePostFindAppointments } from '../../api';
import { DatesSetArg, EventInput } from '@fullcalendar/core';
import { AuthContext } from '../AuthProvider';
import { endOfMonth, startOfMonth } from 'date-fns';

type AppointmentCalendarContextType = {
  clientProfiles: TypeProfile[];
  therapistProfiles: TypeProfile[];
  events: EventInput[];
  handleDates: (_dates: DatesSetArg) => void;
  findAppointments: () => void;
  refetchProfiles: () => void;
};

export const AppointmentCalendarContext =
  createContext<AppointmentCalendarContextType>({
    clientProfiles: [],
    therapistProfiles: [],
    events: [],
    handleDates: (_dates: DatesSetArg) => {},
    findAppointments: () => {},
    refetchProfiles: () => {},
  });

export type AppointmentProviderProps = {
  children: React.ReactNode;
};

export const AppointmentCalendarProvider = ({
  children,
}: AppointmentProviderProps) => {
  const { hasRole } = useContext(AuthContext);
  const { data: allProfilesData, refetch: refetchProfiles } =
    useGetAllProfiles();
  const { mutate: postFindAppointments } = usePostFindAppointments();

  const [clientProfiles, setClientProfiles] = useState<TypeProfile[]>([]);
  const [therapistProfiles, setTherapistProfiles] = useState<TypeProfile[]>([]);
  const [appointments, setAppointments] = useState<TypeAppointment[]>([]);
  const [rangeStart, setRangeStart] = useState<Date>(startOfMonth(new Date()));
  const [rangeEnd, setRangeEnd] = useState<Date>(endOfMonth(new Date()));

  const findAppointments = () =>
    postFindAppointments(
      {
        startAt: rangeStart,
        endAt: rangeEnd,
      },
      {
        onSuccess: (data) => setAppointments(data),
      },
    );

  const handleDates = (dates: DatesSetArg) => {
    setRangeStart(dates.start);
    setRangeEnd(dates.end);
  };

  useEffect(() => {
    if (!hasRole(['SYSADMIN', 'THERAPIST'])) return;
    findAppointments();
  }, [rangeStart, rangeEnd]);

  useEffect(() => {
    if (allProfilesData === undefined) return;
    const clientProfiles = allProfilesData.filter((profile) => {
      return profile.authorities?.some(
        (authority) => authority.authority === 'CLIENT',
      );
    });
    setClientProfiles(clientProfiles);
    const therapistProfiles = allProfilesData.filter((profile) => {
      return profile.authorities?.some(
        (authority) => authority.authority === 'THERAPIST',
      );
    });
    setTherapistProfiles(therapistProfiles);
  }, [allProfilesData]);

  const events: EventInput[] = useMemo(() => {
    return appointments?.map((appointment) => {
      const event: EventInput = {
        id: appointment.id!!.toString(),
        title: appointment.profile?.name || '',
        start: appointment.startAt,
        end: appointment.endAt,
        allDay: false,
        textColor: 'red',
        source: null,
        startStr: '',
        endStr: '',
        groupId: '',
        url: '',
        display: '',
        startEditable: false,
        durationEditable: false,
        constraint: undefined,
        overlap: false,
        allow: undefined,
        backgroundColor:
          appointment.appointmentCalendar?.id === 1 ? 'red' : 'blue',
        borderColor: appointment.appointmentCalendar?.id === 1 ? 'red' : 'blue',
        // classNames: [],
      };
      return event;
    });
  }, [appointments]);

  return (
    <AppointmentCalendarContext.Provider
      value={{
        clientProfiles,
        therapistProfiles,
        events,
        handleDates,
        findAppointments,
        refetchProfiles,
      }}
    >
      {children}
    </AppointmentCalendarContext.Provider>
  );
};
