import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  useGetStripeProducts,
  usePostStripeProduct,
  usePostStripeProductRehydrate,
} from '../../../../api';
import { Link as RouterLink } from 'react-router-dom';
import { Refresh } from '@mui/icons-material';
import Autocomplete from '@mui/material/Autocomplete';
import { ProjectContext } from '../../../../providers';

export const StripeProducts = () => {
  const { setSnackbar, performingAction, setPerformingAction } =
    useContext(ProjectContext);
  const [addProductName, setAddProductName] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('service');
  const { mutate: postStripeProductsRehydrate } =
    usePostStripeProductRehydrate();
  const { mutate: postStripeProduct } = usePostStripeProduct();
  const { data: stripeProductsData } = useGetStripeProducts();

  const handleRehydrateStripeProducts = () => {
    setPerformingAction(true);
    postStripeProductsRehydrate(undefined, {
      onSuccess: () => setSnackbar('Stripe Products Rehydrated'),
      onError: () => setSnackbar('Failed to get Stripe Products'),
      onSettled: () => setPerformingAction(false),
    });
  };

  const disableCreateStripeProduct = !addProductName || !selectedType;

  const createStripeProduct = () => {
    if (!disableCreateStripeProduct) {
      postStripeProduct({
        name: addProductName,
        type: selectedType,
      });
    }
  };

  if (!stripeProductsData) {
    return <></>;
  }

  return (
    <Box m={2}>
      <Container>
        <Box mt={2}>
          <Typography variant="h4">Stripe Products</Typography>
        </Box>
        <Box mt={2}>
          <Button
            variant={'contained'}
            endIcon={<Refresh />}
            disabled={performingAction}
            onClick={handleRehydrateStripeProducts}
          >
            Sync Stripe Products
          </Button>
        </Box>
        <TableContainer>
          <Table style={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Prices</TableCell>
                <TableCell>Default Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stripeProductsData?.map((sp) => (
                <TableRow key={sp.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/stripe-product/${sp.id}`}
                      underline="none"
                    >
                      {sp.name}
                    </Link>
                  </TableCell>
                  <TableCell>{sp.type}</TableCell>
                  <TableCell>{sp.stripePriceList?.length} Price(s)</TableCell>
                  <TableCell>
                    {sp.defaultPrice
                      ? `$${sp.defaultPrice?.unitAmountDecimal?.toFixed(2)}`
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="addProductName"
                    value={addProductName}
                    label="Product Name"
                    placeholder="Product Name"
                    onChange={(event) => setAddProductName(event.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <Autocomplete
                    value={selectedType}
                    id="selectedType"
                    options={['service', 'good']}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    disableClearable
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(_event, newValue) => setSelectedType(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Type"
                        placeholder="Select Type"
                      />
                    )}
                  />
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    disabled={disableCreateStripeProduct}
                    onClick={createStripeProduct}
                  >
                    Create Stripe Product
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};
