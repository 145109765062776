import React, { useContext } from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeMaterial } from '../../../types';
import { DialogContext } from '../../../providers';
import { DeleteForever } from '@mui/icons-material';
import { DeleteMaterialDialog } from '../../_common/Dialogs';
import { useDeleteMaterial, useGetMaterial } from '../../../api';

export const MaterialList = ({
  materials,
  materialsAvailable,
}: {
  materials?: TypeMaterial[];
  materialsAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { mutate: deleteMaterial } = useDeleteMaterial();
  const [materialToDelete, setMaterialToDelete] =
    React.useState<TypeMaterial>();
  const { data: refetchMaterial } = useGetMaterial(materialToDelete?.id!!, {
    enabled: !!materialToDelete,
  });

  const handleDeleteMaterialDialog = (material?: TypeMaterial) => {
    if (material) {
      setMaterialToDelete(material);
      openDialog('deleteMaterialDialog');
    } else {
      setMaterialToDelete(undefined);
      closeDialog('deleteMaterialDialog');
    }
  };

  return (
    <>
      <TableContainer hidden={!materialsAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Material</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {materials?.map((material) => (
              <TableRow key={material.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/material/${material.id}`}
                    underline="none"
                  >
                    {material.materialName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteMaterialDialog(material)}
                  >
                    <DeleteForever />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {materials?.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No Materials Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {refetchMaterial && (
        <DeleteMaterialDialog
          materialName={refetchMaterial.materialName!!}
          usages={refetchMaterial.usages || 0}
          deleteFn={() =>
            deleteMaterial(refetchMaterial, {
              onSuccess: () => handleDeleteMaterialDialog(),
            })
          }
        />
      )}
    </>
  );
};
