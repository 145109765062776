import { TypeProjectConfig } from '../../types/_common/ProjectConfig';
import {
  Announcements,
  ChefHome,
  DiscussionTopic,
  DiscussionTopics,
  Material,
  Materials,
  Measurement,
  Measurements,
  MeetingRecap,
  MeetingRecaps,
  Preparation,
  Preparations,
  Recipe,
  RecipeEvents,
  Recipes,
  RecipeTag,
  RecipeTags,
} from '../../components';
import { TypeAppRoute } from '../../types';
import { commonAppRoutes, commonSysAdminAppRoutes } from './CommonRouteConfig';

const chefAdminRoutes = (): TypeAppRoute[] => [
  {
    name: 'Practice Makes Chef',
    path: '/',
    component: ChefHome,
    showInDrawer: true,
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: ChefHome,
    showInDrawer: true,
  },
  {
    name: 'Announcements',
    path: '/announcements',
    component: Announcements,
    showInDrawer: true,
  },
  {
    name: 'Discussion Topics',
    path: '/discussion-topics',
    component: DiscussionTopics,
    showInDrawer: true,
  },
  {
    name: 'Discussion Topic',
    path: '/discussion-topic/:discussionTopicId',
    component: DiscussionTopic,
  },
  {
    name: 'Materials',
    path: '/materials',
    component: Materials,
    showInDrawer: true,
  },
  {
    name: 'Material',
    path: '/material/:materialId',
    component: Material,
  },
  {
    name: 'Measurements',
    path: '/measurements',
    component: Measurements,
    showInDrawer: true,
  },
  {
    name: 'Measurement',
    path: '/measurement/:measurementId',
    component: Measurement,
  },
  {
    name: 'Meeting Recaps',
    path: '/meeting-recaps',
    component: MeetingRecaps,
    showInDrawer: true,
  },
  {
    name: 'Meeting Recap',
    path: '/meeting-recap/:meetingRecapId',
    component: MeetingRecap,
  },
  {
    name: 'Preparations',
    path: '/preparations',
    component: Preparations,
    showInDrawer: true,
  },
  {
    name: 'Preparation',
    path: '/preparation/:preparationId',
    component: Preparation,
  },
  {
    name: 'Recipes',
    path: '/recipes',
    component: Recipes,
    showInDrawer: true,
  },
  {
    name: 'Recipe',
    path: '/recipe/:recipeId',
    component: Recipe,
  },
  {
    name: 'Recipe Events',
    path: '/recipe-events',
    component: RecipeEvents,
    showInDrawer: true,
  },
  {
    name: 'Recipe Tags',
    path: '/recipe-tags',
    component: RecipeTags,
    showInDrawer: true,
  },
  {
    name: 'Recipe Tag',
    path: '/recipe-tag/:tagId',
    component: RecipeTag,
  },
];

export const ChefRouteConfig = (): TypeProjectConfig => ({
  cldCloudName: 'hpgzbe5ye',
  cldUploadPreset: 'lkt5kgew',
  roleProjectRoutes: {
    ADMIN: {
      homeRoute: '/recipes',
      appRoutes: [...chefAdminRoutes(), ...commonAppRoutes()],
    },
    SYSADMIN: {
      homeRoute: '/recipes',
      appRoutes: [
        ...chefAdminRoutes(),
        ...commonSysAdminAppRoutes(),
        ...commonAppRoutes(),
      ],
    },
  },
});
