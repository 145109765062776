import React, { useContext } from 'react';
import {
  Button,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeRecipe } from '../../../types';
import { DeleteForever } from '@mui/icons-material';
import { useDeleteRecipe } from '../../../api/chef/Recipe/useDeleteRecipe';
import { DialogContext } from '../../../providers';
import { DeleteRecipeDialog } from '../../_common';

export const RecipeList = ({
  recipes,
  recipesAvailable,
  flatList = false,
}: {
  recipes: TypeRecipe[];
  recipesAvailable: boolean;
  flatList?: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { mutate: deleteRecipe } = useDeleteRecipe();
  const [recipeToDelete, setRecipeToDelete] = React.useState<TypeRecipe>();

  const handleDeleteRecipeDialog = (recipe?: TypeRecipe) => {
    if (recipe) {
      setRecipeToDelete(recipe);
      openDialog('deleteRecipeDialog');
    } else {
      setRecipeToDelete(undefined);
      closeDialog('deleteRecipeDialog');
    }
  };

  return (
    <>
      <TableContainer hidden={!recipesAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Recipe</TableCell>
              {!flatList && <TableCell>Recipe Tags</TableCell>}
              <TableCell>YouTube Link</TableCell>
              {!flatList && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {recipes?.map((recipe) => (
              <TableRow key={recipe.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/recipe/${recipe.id}`}
                    underline="none"
                  >
                    {recipe.recipeTitle}
                  </Link>
                </TableCell>
                {!flatList && (
                  <TableCell>
                    {recipe.tags?.map((tag) => (
                      <Link
                        key={`tag-${tag.id}`}
                        component={RouterLink}
                        to={`/recipe-tag/${tag.id}`}
                        underline="none"
                      >
                        <Chip
                          clickable
                          sx={{ marginRight: 1 }}
                          label={tag.name}
                        />
                      </Link>
                    ))}
                  </TableCell>
                )}
                <TableCell>
                  {recipe.youtubeEmbedCode && (
                    <Link
                      component={RouterLink}
                      to={`https://www.youtube.com/watch?v=${recipe.youtubeEmbedCode}`}
                      target="_blank"
                      underline="none"
                    >
                      {`youtube.com/watch?v=${recipe.youtubeEmbedCode}`}
                    </Link>
                  )}
                </TableCell>
                {!flatList && (
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteRecipeDialog(recipe)}
                    >
                      <DeleteForever />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {(!recipes || recipes.length === 0) && (
              <TableRow>
                <TableCell colSpan={4}>No Recipes Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {recipeToDelete && (
        <DeleteRecipeDialog
          recipeName={recipeToDelete?.recipeTitle!!}
          deleteFn={() =>
            deleteRecipe(recipeToDelete, {
              onSuccess: () => handleDeleteRecipeDialog(),
            })
          }
        />
      )}
    </>
  );
};
