import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import {
  useGetAppointmentCalendars,
  useGetGoogleCalendarOAuthLink,
  usePostAppointmentCalendar,
} from '../../../api';
import { Button, Grid, Link, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { TypeAppointmentCalendar, TypeProfile } from '../../../types';
import { TimePicker } from '@mui/x-date-pickers';
import { Restore, Save } from '@mui/icons-material';
import {
  AppointmentCalendarContext,
  AuthContext,
  ProjectContext,
} from '../../../providers';
import { format, parseISO } from 'date-fns';

export const Calendars = () => {
  const { profile } = useContext(AuthContext);
  const { setSnackbar, setPerformingAction } = useContext(ProjectContext);
  const { therapistProfiles } = useContext(AppointmentCalendarContext);
  const { data: oauthLink } = useGetGoogleCalendarOAuthLink();
  const { data: appointmentCalendars } = useGetAppointmentCalendars();
  const [selectedCalendar, setSelectedCalendar] =
    useState<TypeAppointmentCalendar | null>(null);
  const [selectedTherapist, setSelectedTherapist] =
    useState<TypeProfile | null>(null);
  const [minDaysOut, setMinDaysOut] = useState<number>(0);
  const [minHoursOut, setMinHoursOut] = useState<number>(0);
  const [maxDaysOut, setMaxDaysOut] = useState<number>(0);
  const [maxHoursOut, setMaxHoursOut] = useState<number>(0);
  const [businessStart, setBusinessStart] = useState<Date | null>(null);
  const [businessEnd, setBusinessEnd] = useState<Date | null>(null);
  const [maxWorkingHours, setMaxWorkingHours] = useState<number>(0);

  const { mutate: postCalendar } = usePostAppointmentCalendar();
  const resetCalendar = () => {
    if (!selectedCalendar) {
      return;
    }
    handleEdit(selectedCalendar);
    setSnackbar('Calendar Reset');
  };
  const isCalendarValid =
    !!businessStart &&
    !!businessEnd &&
    maxWorkingHours > 0 &&
    minDaysOut >= 0 &&
    minHoursOut >= 0 &&
    maxDaysOut >= 0 &&
    maxHoursOut >= 0 &&
    maxWorkingHours >= 0 &&
    minDaysOut * 24 + minHoursOut <= maxDaysOut * 24 + maxHoursOut &&
    businessStart < businessEnd;

  const updateCalendar = () => {
    if (!isCalendarValid) {
      return;
    }
    setPerformingAction(true);
    const businessStartString = format(businessStart, 'hh:mm A');
    const businessEndString = format(businessEnd, 'hh:mm A');
    postCalendar(
      {
        id: selectedCalendar?.id,
        minDaysOut,
        minHoursOut,
        maxDaysOut,
        maxHoursOut,
        businessStart: businessStartString,
        businessEnd: businessEndString,
        maxWorkingHours,
        profile: selectedTherapist || undefined,
      },
      {
        onSuccess: () => {
          handleEdit(null);
          setSnackbar('Calendar Saved');
        },
        onError: () => setSnackbar('Error updating calendar'),
        onSettled: () => setPerformingAction(false),
      },
    );
  };
  const convertTimeToNewDate = (
    time: string,
    setValue: Dispatch<SetStateAction<Date | null>>,
  ) => {
    setValue(parseISO(time));
  };

  const handleEdit = (calendar: TypeAppointmentCalendar | null = null) => {
    if (!calendar) {
      setSelectedCalendar(null);
      setSelectedTherapist(null);
      setMinDaysOut(0);
      setMinHoursOut(0);
      setMaxDaysOut(0);
      setMaxHoursOut(0);
      setBusinessStart(null);
      setBusinessEnd(null);
      setMaxWorkingHours(0);
    } else {
      setSelectedCalendar(calendar);
      setSelectedTherapist(calendar.profile || null);
      setMinDaysOut(calendar.minDaysOut || 0);
      setMinHoursOut(calendar.minHoursOut || 0);
      setMaxDaysOut(calendar.maxDaysOut || 0);
      setMaxHoursOut(calendar.maxHoursOut || 0);
      convertTimeToNewDate(calendar.businessStart!!, setBusinessStart);
      convertTimeToNewDate(calendar.businessEnd!!, setBusinessEnd);
      setMaxWorkingHours(calendar.maxWorkingHours || 0);
    }
  };

  if (!oauthLink) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Autocomplete
          value={selectedCalendar}
          id="selectedCalendar"
          options={appointmentCalendars || []}
          getOptionLabel={(option) =>
            `${option.id} - ${option.profile?.name}` || ''
          }
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(_event, newValue) => handleEdit(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Calendar" placeholder="Calendar" />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          value={selectedTherapist}
          id="selectedTherapist"
          options={therapistProfiles || []}
          getOptionLabel={(option) => `${option.name}` || ''}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(_event, newValue) => setSelectedTherapist(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Therapist" placeholder="Therapist" />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Google Calendar Status:{' '}
          {selectedCalendar?.googleCalendar ? 'Linked' : 'Not Linked'}
        </Typography>
        {!selectedCalendar?.googleCalendar &&
          selectedCalendar?.profile?.id === profile?.id && (
            <Link href={oauthLink.url!!} underline="none" target="_blank">
              Link Google Calendar
            </Link>
          )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          id={`minDaysOut`}
          value={minDaysOut}
          label="Min Days Out"
          type={'number'}
          onChange={(event) => setMinDaysOut(Number(event.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          id={`minHoursOut`}
          value={minHoursOut}
          label="Min Hours Out"
          type={'number'}
          onChange={(event) => setMinHoursOut(Number(event.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          id={`maxDaysOut`}
          value={maxDaysOut}
          label="Max Days Out"
          type={'number'}
          onChange={(event) => setMaxDaysOut(Number(event.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          variant="outlined"
          id={`maxHoursOut`}
          value={maxHoursOut}
          label="Max Hours Out"
          type={'number'}
          onChange={(event) => setMaxHoursOut(Number(event.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <TimePicker
          label="Business Start"
          value={businessStart}
          onChange={setBusinessStart}
        />
      </Grid>
      <Grid item xs={6}>
        <TimePicker
          label="Business End"
          value={businessEnd}
          onChange={setBusinessEnd}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          id={`maxWorkingHours`}
          value={maxWorkingHours}
          label="Max Working Hours"
          type={'number'}
          onChange={(event) => setMaxWorkingHours(Number(event.target.value))}
        />
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          onClick={updateCalendar}
          startIcon={<Save />}
          disabled={!isCalendarValid}
          sx={{ marginRight: 2 }}
        >
          Save Calendar
        </Button>
        <Button
          variant="outlined"
          onClick={resetCalendar}
          startIcon={<Restore />}
        >
          Reset Calendar
        </Button>
      </Grid>
    </Grid>
  );
};
