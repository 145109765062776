import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeImage } from '../../../types';

const fetchImagesByCategory = async (
  category: string,
): Promise<TypeImage[]> => {
  const res = await axios.get(`api/public/images/${category}`);
  return res.data;
};

export const PrefetchImagesByCategory = (category: string) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['public/images', category],
    () => fetchImagesByCategory(category),
    {
      staleTime: 5000,
    },
  );
};

export const useGetImagesByCategory = (
  category: string,
  options?: UseQueryOptions<TypeImage[], AxiosError, TypeImage[], any>,
) => {
  return useQuery(
    ['public/images', category],
    () => fetchImagesByCategory(category),
    options,
  );
};
