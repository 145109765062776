import React, { Fragment, useContext, useState } from 'react';
import { DialogContext, ProjectContext } from '../../../providers';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import {
  TypePracticeAvailabilityDay,
  TypePracticeSlotDay,
} from '../../../types';
import Autocomplete from '@mui/material/Autocomplete';
import { SportsCoachContext } from '../../../providers';

type SwapPracticeSlotDayProps = {
  practiceSlotDay?: TypePracticeSlotDay;
  swapPracticeSlotDay: (
    _practiceAvailabilityDay: TypePracticeAvailabilityDay | null,
  ) => void;
  outerHandleCloseDialog: () => void;
};

export const SwapPracticeSlotDayDialog = ({
  practiceSlotDay,
  swapPracticeSlotDay,
  outerHandleCloseDialog,
}: SwapPracticeSlotDayProps) => {
  const { dialogs } = useContext(DialogContext);
  const { swapPracticeSlotDayDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);
  const { practiceSlotAvailability, hasPracticeAtSameDayTime } =
    useContext(SportsCoachContext);

  const [selectedPracticeAvailabilityDay, setSelectedPracticeAvailabilityDay] =
    useState<TypePracticeAvailabilityDay | null>(null);

  const handleCompleteFn = () => {
    setSelectedPracticeAvailabilityDay(null);
    swapPracticeSlotDay(selectedPracticeAvailabilityDay);
  };

  const handleCloseDialog = () => {
    setSelectedPracticeAvailabilityDay(null);
    outerHandleCloseDialog();
  };

  const dayTimeField = `${practiceSlotDay?.leagueDay?.day} ${practiceSlotDay?.leagueTime?.time} on ${practiceSlotDay?.leagueField?.name}`;

  const swapOptions = practiceSlotAvailability
    .flatMap(
      (practiceAvailability) =>
        practiceAvailability.practiceAvailabilityDayList,
    )
    .filter(
      (practiceAvailabilityDay) =>
        !practiceAvailabilityDay.isFull &&
        !hasPracticeAtSameDayTime(practiceAvailabilityDay),
    );

  if (!practiceSlotDay) return <></>;

  return (
    <Dialog open={swapPracticeSlotDayDialog}>
      <DialogTitle>Select Swap for {dayTimeField}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              value={selectedPracticeAvailabilityDay}
              id="selectedPracticeAvailabilityDay"
              options={swapOptions || []}
              getOptionLabel={(option) =>
                `${option?.leagueDay?.day} - ${option?.leagueTime?.time}`
              }
              filterSelectedOptions
              isOptionEqualToValue={(option, value) =>
                `${option?.leagueDay?.day} - ${option?.leagueTime?.time}` ===
                `${value?.leagueDay?.day} - ${value?.leagueTime?.time}`
              }
              onChange={(_event, newValue) =>
                setSelectedPracticeAvailabilityDay(newValue)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Practice"
                  placeholder="Select Practice"
                />
              )}
              sx={{ marginTop: 2 }}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={handleCloseDialog}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={performingAction || !selectedPracticeAvailabilityDay}
          variant="contained"
          onClick={handleCompleteFn}
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};
