import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeSquareCatalogItem } from '../../../types';

const fetchAllSquareCatalogItems = async (): Promise<
  TypeSquareCatalogItem[]
> => {
  const res = await axios.get('api/square-catalog-item');
  return res.data;
};

export const PrefetchSquareCatalogItem = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['square-catalog-item'],
    fetchAllSquareCatalogItems,
    {
      staleTime: 5000,
    },
  );
};

export const useGetAllSquareCatalogItems = (
  options?: UseQueryOptions<
    TypeSquareCatalogItem[],
    AxiosError,
    TypeSquareCatalogItem[],
    any
  >,
) => {
  return useQuery('square-catalog-item', fetchAllSquareCatalogItems, options);
};
