import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostAllLeagueCoachInviteEmails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (leagueId: number) =>
      axios
        .post(`api/league-email/invite-coaches/league/${leagueId}`, {})
        .then((res) => res.data),
    {
      onMutate: (_leagueId: number) => {
        queryClient.cancelQueries('league-email/invite-coaches/league');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-email/invite-coaches/league');
        // queryClient.invalidateQueries(['EmailSent', variables.id]).then(_ => {})
      },
    },
  );
};
