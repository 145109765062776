import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTimeSlot } from '../../../types';

export const useDeleteTimeSlot = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (timeSlot: TypeTimeSlot) => axios.delete(`api/time-slot/${timeSlot.id}`),
    {
      onMutate: (timeSlot: TypeTimeSlot) => {
        queryClient.cancelQueries('time-slot');

        const previousTimeSlot = queryClient.getQueryData([
          'time-slot',
          timeSlot.id,
        ]);

        queryClient.setQueryData(['time-slot', timeSlot.id], timeSlot);

        return { previousTimeSlot };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('time-slot');
        queryClient.invalidateQueries('time-slot/division');
      },
    },
  );
};
