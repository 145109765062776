import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeMaterial } from '../../../types';

export const usePostMaterial = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (material: TypeMaterial) =>
      axios.post(`api/material`, material).then((res) => res.data),
    {
      onMutate: (_material: TypeMaterial) => {
        queryClient.cancelQueries('material');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('material');
        // queryClient.invalidateQueries(['material', variables.id]).then(_ => {})
      },
    },
  );
};
