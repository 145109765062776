import React, { useContext } from 'react';

import { Alert, Button } from '@mui/material';
import { DialogContext, ProjectContext } from '../../../providers';
import { Replay, Warning } from '@mui/icons-material';
import { BaseDialog } from './BaseDialog';

type WorkflowDialogProps = {
  title?: string;
  isWarning?: boolean;
  saveFn: () => void;
  resetFn: () => void;
  children: React.ReactNode;
};

export const WorkflowDialog = ({
  title,
  isWarning,
  saveFn,
  resetFn,
  children,
}: WorkflowDialogProps) => {
  const { performingAction } = useContext(ProjectContext);
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { workflowDialog } = dialogs;

  return (
    <BaseDialog
      title={title}
      dialogProps={{ open: workflowDialog, fullScreen: true }}
      handleClose={() => closeDialog('workflowDialog')}
      alert={
        isWarning ? (
          <Alert severity={'warning'} sx={{ width: '100%', mb: 2 }}>
            Updating a running workflow may have unintended consequences
          </Alert>
        ) : undefined
      }
      dismissiveAction={
        <Button
          color="primary"
          variant="outlined"
          onClick={() => closeDialog('workflowDialog')}
        >
          Close
        </Button>
      }
      secondaryAction={
        <Button
          color="primary"
          variant="outlined"
          onClick={resetFn}
          endIcon={<Replay />}
        >
          Reset
        </Button>
      }
      confirmingAction={
        <Button
          color={isWarning ? 'warning' : 'primary'}
          startIcon={isWarning ? <Warning /> : null}
          disabled={performingAction}
          variant="contained"
          onClick={saveFn}
        >
          Save & Close
        </Button>
      }
    >
      {children}
    </BaseDialog>
  );
};
