import React, { useContext, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { BodyGroupList } from './BodyGroupList';
import { useGetBodyGroups, usePostBodyGroup } from '../../../api';
import { AuthContext } from '../../../providers';

export const BodyGroups = () => {
  const { profile } = useContext(AuthContext);
  const [bodyGroupName, setBodyGroupName] = useState<string>('');
  const { ...queryInfo } = useGetBodyGroups({ enabled: !!profile });
  const bodyGroupMutation = usePostBodyGroup();

  const createBodyGroup = () => {
    if (bodyGroupName === '') return;
    bodyGroupMutation.mutate({
      bodyGroupName: bodyGroupName,
    });
    setBodyGroupName('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBodyGroupName(event.target.value);
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="bodyGroupName"
              value={bodyGroupName}
              label="Search or Add Body Group"
              placeholder="BodyGroups Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={bodyGroupName === ''}
              onClick={createBodyGroup}
            >
              Create BodyGroup
            </Button>
          </Grid>
        </Grid>
        <BodyGroupList
          bodyGroups={queryInfo.data}
          bodyGroupsAvailable={!queryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
