import React, { useContext, useState } from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeLeagueTime } from '../../../types';
import { Check, DeleteForever, Edit, Restore } from '@mui/icons-material';
import { useDeleteLeagueTime, usePostLeagueTime } from '../../../api';
import { AuthContext, DialogContext, LeagueContext } from '../../../providers';
import { DeleteLeagueTimeDialog } from '../../_common';
import { formatTimeToStringTZ } from '../../_common/Utils/formatTimeToStringTZ';
import { TimePicker } from '@mui/x-date-pickers';
import { parseISOSafe } from '../../_common/Utils/parseISOSafe';

export const LeagueTimeList = ({
  leagueTimes,
  leagueTimesAvailable,
}: {
  leagueTimes: TypeLeagueTime[];
  leagueTimesAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { league } = useContext(LeagueContext);
  const { profile } = useContext(AuthContext);
  const [editLeagueTime, setEditLeagueTime] = useState<Date | null>(null);
  const [activeLeagueTime, setActiveLeagueTime] = useState<TypeLeagueTime>();
  const [leagueTimeToDelete, setLeagueTimeToDelete] =
    useState<TypeLeagueTime>();
  const { mutate: updateLeagueTime } = usePostLeagueTime();
  const { mutate: deleteLeagueTime } = useDeleteLeagueTime();
  const handleEdit = (leagueTime: TypeLeagueTime | null = null) => {
    if (leagueTime) {
      setEditLeagueTime(
        parseISOSafe(
          formatTimeToStringTZ(
            leagueTime?.time,
            profile?.timeZone,
            'dateTimeTZ',
          ),
        ),
      );
      setActiveLeagueTime(leagueTime);
    } else {
      setEditLeagueTime(null);
      setActiveLeagueTime(undefined);
    }
  };

  const handleSave = () => {
    if (activeLeagueTime) {
      updateLeagueTime(
        {
          id: activeLeagueTime.id,
          time: formatTimeToStringTZ(
            editLeagueTime,
            profile?.timeZone,
            'timeTZ',
          ),
          league: activeLeagueTime.league,
        },
        {
          onSuccess: () => handleEdit(),
        },
      );
    }
  };

  const handleDeleteLeagueTimeDialog = (leagueTime?: TypeLeagueTime) => {
    if (leagueTime) {
      setLeagueTimeToDelete(leagueTime);
      openDialog('deleteLeagueTimeDialog');
    } else {
      setLeagueTimeToDelete(undefined);
      closeDialog('deleteLeagueTimeDialog');
    }
  };

  if (!leagueTimesAvailable) {
    return <></>;
  }
  return (
    <>
      <TableContainer hidden={!leagueTimesAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leagueTimes?.map((leagueTime) => (
              <TableRow key={leagueTime.id}>
                <TableCell>
                  {editLeagueTime && activeLeagueTime?.id === leagueTime?.id ? (
                    <TimePicker
                      value={editLeagueTime}
                      onChange={(date) => setEditLeagueTime(date)}
                    />
                  ) : (
                    formatTimeToStringTZ(leagueTime?.time, profile?.timeZone)
                  )}
                </TableCell>
                <TableCell>
                  {editLeagueTime && activeLeagueTime?.id === leagueTime?.id ? (
                    <>
                      <IconButton onClick={handleSave}>
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => handleEdit()}>
                        <Restore />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEdit(leagueTime)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteLeagueTimeDialog(leagueTime)}
                        disabled={league?.matchesGenerated}
                      >
                        <DeleteForever />
                      </IconButton>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {leagueTimes?.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No Times Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {leagueTimeToDelete && (
        <DeleteLeagueTimeDialog
          deleteTitle={leagueTimeToDelete?.time!!}
          contentText={`This will delete ${leagueTimeToDelete?.time}, its practice slots, and time slots.`}
          deleteFn={() =>
            deleteLeagueTime(leagueTimeToDelete, {
              onSuccess: () => handleDeleteLeagueTimeDialog(),
            })
          }
        />
      )}
    </>
  );
};
