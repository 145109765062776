import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { MeasurementList } from './MeasurementList';
import { usePostMeasurement } from '../../../api';
import { genericSearch, genericSort, Loader } from '../../_common';
import { TypeMeasurement } from '../../../types';
import { ChefContext, ProjectContext } from '../../../providers';

export const Measurements = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { measurements, isChefReady, refetchAll } = useContext(ChefContext);
  const [measurementName, setMeasurementName] = useState<string>('');
  const { mutate: measurementMutation } = usePostMeasurement();

  const createMeasurement = () => {
    if (measurementName === '') return;
    measurementMutation(
      {
        measurementName: measurementName,
      },
      {
        onSuccess: () => {
          setSnackbar(`Measurement Created: ${measurementName}`);
          setMeasurementName('');
          refetchAll();
        },
        onError: () => setSnackbar('Error Creating Measurement', 'error'),
      },
    );
  };

  const filteredMeasurements = useMemo(
    () =>
      measurements
        ?.filter((measurement) =>
          genericSearch<TypeMeasurement>(measurement, {
            measurementName: measurementName,
          }),
        )
        .sort((a, b) =>
          genericSort<TypeMeasurement>(a, b, {
            property: 'measurementName',
            isDescending: false,
          }),
        ),
    [measurements, measurementName],
  );

  if (!isChefReady) return <Loader />;

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="measurementName"
              value={measurementName}
              label="Search or Add Measurement"
              placeholder="Measurements Title"
              onChange={(event) => setMeasurementName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={measurementName === ''}
              onClick={createMeasurement}
            >
              Create Measurement
            </Button>
          </Grid>
        </Grid>
        <MeasurementList
          measurements={filteredMeasurements}
          measurementsAvailable={!!filteredMeasurements?.length}
        />
      </Container>
    </Box>
  );
};
