import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeWorkflow } from '../../../types';

export const fetchWorkflow = async (
  workflowId: number,
): Promise<TypeWorkflow> => {
  const res = await axios.get(`api/workflow/${workflowId}`);
  return res.data;
};

export const PrefetchWorkflow = (workflowId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['workflow', workflowId],
    () => fetchWorkflow(workflowId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetWorkflow = (
  workflowId: number,
  options?: UseQueryOptions<TypeWorkflow, AxiosError, TypeWorkflow, any>,
) => {
  return useQuery(
    ['workflow', workflowId],
    () => fetchWorkflow(workflowId),
    options,
  );
};
