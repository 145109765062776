import { Grid } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import React, { useContext } from 'react';
import { AppointmentCalendarContext } from '../../../providers';

export const AppointmentCalendar = () => {
  const { events, handleDates } = useContext(AppointmentCalendarContext);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FullCalendar
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          weekends={true}
          events={events}
          datesSet={handleDates}
        />
      </Grid>
    </Grid>
  );
};
