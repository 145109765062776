import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMatch } from '../../../types';

const fetchMatches = async (coachId: number): Promise<TypeMatch[]> => {
  const res = await axios.get(`api/match/coach/${coachId}`);
  return res.data;
};

export const PrefetchMatch = (coachId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['match/coach', coachId],
    () => fetchMatches(coachId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetMatchesByCoach = (
  coachId: number,
  options?: UseQueryOptions<TypeMatch[], AxiosError, TypeMatch[], any>,
) => {
  return useQuery(
    ['match/coach', coachId],
    () => fetchMatches(coachId),
    options,
  );
};
