import { TypeAppRoute } from '../../types';
import {
  Account,
  PrivacyPolicy,
  StripePrices,
  StripeProducts,
} from '../../components';

export const commonSysAdminAppRoutes = (): TypeAppRoute[] => [
  {
    name: 'Stripe Products',
    path: '/stripe-products',
    component: StripeProducts,
    showInDrawer: true,
  },
  {
    name: 'Stripe Product',
    path: '/stripe-product/:stripeProductId',
    component: StripePrices,
  },
];

export const commonAppRoutes = (): TypeAppRoute[] => [
  {
    name: 'Account',
    path: '/account',
    component: Account,
  },
  {
    name: 'Privacy Policy',
    path: '/privacy-policy',
    component: PrivacyPolicy,
    public: true,
  },
];
