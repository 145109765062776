import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostScheduledJobEntry = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (scheduledJobEntryId: number) =>
      axios
        .post(`api/scheduled-job/${scheduledJobEntryId}`)
        .then((res) => res.data),
    {
      onMutate: (_scheduledJobEntryId: number) => {
        queryClient.cancelQueries('scheduled-job');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('scheduled-job');
        queryClient.invalidateQueries('workflow');
      },
    },
  );
};
