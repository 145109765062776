import React, { useContext } from 'react';
import { AppointmentContext } from '../../../../providers';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

export const SelectAppointmentType = () => {
  const { appointmentTypes, setSelectedAppointmentType } =
    useContext(AppointmentContext);

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Select Appointment Type</Typography>
      </Grid>
      {appointmentTypes.map((appointmentType, index) => (
        <Grid item xs={12} key={`calendar-${index}`}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1">{appointmentType?.name}</Typography>
                <Button
                  color="primary"
                  onClick={() => setSelectedAppointmentType(appointmentType)}
                >
                  Select
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
