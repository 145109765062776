import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipe } from '../../../types';

const fetchMaterialRecipes = async (): Promise<TypeRecipe[]> => {
  const res = await axios.get('api/material/recipe');
  return res.data;
};

export const PrefetchMaterialRecipe = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['material/recipe'], fetchMaterialRecipes, {
    staleTime: 5000,
  });
};

export const useGetMaterialRecipes = (
  options?: UseQueryOptions<TypeRecipe[], AxiosError, TypeRecipe[], any>,
) => {
  return useQuery('material/recipe', fetchMaterialRecipes, options);
};
