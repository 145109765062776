import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { ProjectContext } from '../../../providers';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    cloudinary: any; // You can specify a more specific type if needed
  }
}

type CloudinaryUploadProps = {
  onImageUpload: (_imageUrl: string) => void;
};

export const CloudinaryUpload = ({ onImageUpload }: CloudinaryUploadProps) => {
  const { cldCloudName, cldUploadPreset } = useContext(ProjectContext);

  const uploadImageWidget = () => {
    let myUploadWidget = window.cloudinary.openUploadWidget(
      {
        cloudName: cldCloudName,
        uploadPreset: cldUploadPreset,
        maxImageWidth: 600,
        sources: ['local', 'url', 'camera'],
      },
      function (error, result) {
        if (!error && result.event === 'success') {
          onImageUpload(result.info.public_id);
        }
      },
    );
    myUploadWidget.open();
  };

  return (
    <Button
      variant={'outlined'}
      onClick={uploadImageWidget}
      sx={{ marginRight: 2 }}
    >
      Upload Image
    </Button>
  );
};
