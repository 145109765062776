import { useGetAllProfiles, usePostProfileSysAdmin } from '../../../api';
import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { TypeProfile } from '../../../types';
import Autocomplete from '@mui/material/Autocomplete';
import { Loader } from '../Loader';

export const CreateSysAdmin = () => {
  const {
    data: allProfilesData,
    isLoading: allProfilesLoading,
    error: allProfilesError,
  } = useGetAllProfiles();
  const { mutate: createSysAdminMutation } = usePostProfileSysAdmin();
  const [sysAdminProfile, setSysAdminProfile] = useState<TypeProfile | null>(
    null,
  );
  const [nonAdminProfiles, setNonAdminProfiles] = useState<TypeProfile[]>([]);

  useEffect(() => {
    if (allProfilesData === undefined) return;
    const nonAdminProfiles = allProfilesData.filter((profile) => {
      return !profile.authorities?.some(
        (authority) => authority.authority === 'SYSADMIN',
      );
    });
    setNonAdminProfiles(nonAdminProfiles);
  }, [allProfilesData]);

  if (allProfilesLoading || allProfilesError || allProfilesData === undefined) {
    return <Loader />;
  }

  const createSysAdmin = () => {
    if (sysAdminProfile === null) return;
    createSysAdminMutation(sysAdminProfile.id!!);
    setSysAdminProfile(null);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          value={sysAdminProfile}
          id="sysAdminProfile"
          options={nonAdminProfiles || []}
          getOptionLabel={(option) => `${option.name} (${option.email})` || ''}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(_event, newValue) => setSysAdminProfile(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Profile" placeholder="Profile" />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Button
          variant="outlined"
          disabled={sysAdminProfile === null}
          onClick={createSysAdmin}
        >
          Add SysAdmin
        </Button>
      </Grid>
    </Grid>
  );
};
