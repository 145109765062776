import React, { useContext } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { AppointmentContext } from '../../../../providers';

export const NewAppointmentDetails = () => {
  const { newAppointment, createdAppointment, appointmentHeadlineDetails } =
    useContext(AppointmentContext);

  const newAppointmentDetails = appointmentHeadlineDetails(newAppointment);

  if (!newAppointmentDetails || createdAppointment) {
    return <></>;
  }

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">New Appointment Details</Typography>
            <Typography variant={'body1'}>{newAppointmentDetails}</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
