import React, { useEffect, useState } from 'react';
import { TypeTagEntityMap } from '../../../types';
import { useGetRecipeTagEntityMap } from '../../../api';
import { Tags } from '../../_common/Tag';

export const RecipeTags = () => {
  const [map, setMap] = useState<TypeTagEntityMap>({});
  const { data: recipeTagEntityMap } = useGetRecipeTagEntityMap();

  useEffect(() => {
    if (!recipeTagEntityMap) return;
    setMap(recipeTagEntityMap);
  }, [recipeTagEntityMap]);

  return <Tags type={'recipe'} mapFieldName={'Recipes'} map={map} />;
};
