import React, { useContext } from 'react';
import { DialogContext } from '../../../providers';
import { DeleteDialog } from './DeleteDialog';

type DeleteTeamDialogProps = {
  deleteTitle: string;
  contentText: string;
  deleteFn: () => void;
};

export const DeleteTeamDialog = ({
  deleteTitle,
  contentText,
  deleteFn,
}: DeleteTeamDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteTeamDialog } = dialogs;

  return (
    <DeleteDialog
      deleteTitle={deleteTitle}
      contentText={contentText}
      deleteFn={deleteFn}
      closeFn={() => closeDialog('deleteTeamDialog')}
      open={deleteTeamDialog}
    />
  );
};
