import React, { useMemo, useState } from 'react';
import { TypeCourse } from '../../../types';
import { useGetCourses, usePostCourse } from '../../../api';
import { genericSearch, genericSort } from '../Utils';
import { Loader } from '../Loader';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { CourseList } from './CourseList';

export const Courses = () => {
  const [courseName, setCourseName] = useState<string>('');
  const [courses, setCourses] = useState<TypeCourse[]>([]);
  const { ...courseQueryInfo } = useGetCourses({
    onSuccess: (data) => setCourses(data),
  });
  const courseMutation = usePostCourse();

  const createCourse = () => {
    if (courseName === '') return;
    courseMutation.mutate(
      {
        name: courseName,
      },
      {
        onSuccess: () => {
          setCourseName('');
        },
      },
    );
  };

  const filteredCourses = useMemo(() => {
    const queryObject: object = {};
    if (courseName) queryObject['name'] = courseName;
    return courses
      .filter((course) => genericSearch<TypeCourse>(course, queryObject))
      .sort((a, b) =>
        genericSort<TypeCourse>(a, b, {
          property: 'name',
          isDescending: false,
        }),
      );
  }, [courseName, courses]);

  if (courseQueryInfo.isLoading || courseQueryInfo.error) return <Loader />;

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="courseName"
              value={courseName}
              label="Search or Add Course"
              placeholder="Courses Name"
              onChange={(event) => setCourseName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              disabled={courseName === ''}
              onClick={createCourse}
            >
              Create Course
            </Button>
          </Grid>
        </Grid>
        <CourseList
          courses={filteredCourses}
          coursesAvailable={!courseQueryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
