import React, { Fragment, ReactElement, useContext, useState } from 'react';

import { Link as RouterLink } from 'react-router-dom';

import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuItem,
  ToggleButton,
  ToggleButtonGroup,
  Toolbar,
  Typography,
} from '@mui/material';

import { UserAvatar } from '../index';
import MenuIcon from '@mui/icons-material/Menu';
import { AuthContext, DialogContext, ProjectContext } from '../../providers';
import {
  DarkModeSharp,
  Info,
  InfoOutlined,
  LightMode,
  SettingsBrightness,
} from '@mui/icons-material';

type MenuItemsType = {
  name?: string;
  to?: string | null;
  onClick?: () => void;
  actions?: ReactElement;
};
export const Bar = () => {
  const { user, profile, setAppearance, setHelpdesk, matchingRoute } =
    useContext(AuthContext);
  const { openDialog } = useContext(DialogContext);
  const { performingAction, toggleMobileOpen } = useContext(ProjectContext);
  const [userMenu, setUserMenu] = useState({
    anchorEl: null,
  });

  const openUserMenu = (event: { currentTarget: any }): void =>
    setUserMenu({ anchorEl: event.currentTarget });
  const closeUserMenu = () => setUserMenu({ anchorEl: null });

  const brightnessActions = () => {
    return (
      <ToggleButtonGroup
        value={profile?.appearance}
        exclusive
        onChange={(event, newAppearance) => setAppearance(newAppearance)}
      >
        <ToggleButton value={'LIGHT'}>
          <LightMode />
        </ToggleButton>
        <ToggleButton value={'SYSTEM'}>
          <SettingsBrightness />
        </ToggleButton>
        <ToggleButton value={'DARK'}>
          <DarkModeSharp />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const helpdeskActions = () => {
    return (
      <ToggleButtonGroup
        value={profile?.helpdesk}
        exclusive
        onChange={(event, newHelpdesk) => setHelpdesk(newHelpdesk)}
      >
        <ToggleButton value={true}>
          <Info />
        </ToggleButton>
        <ToggleButton value={false}>
          <InfoOutlined />
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  const menuItems: MenuItemsType[] = [
    {
      actions: brightnessActions(),
    },
    {
      actions: helpdeskActions(),
    },
    {
      name: 'Account',
      to: user ? `/account` : null,
    },
    {
      name: 'Sign out',
      onClick: () => openDialog('signOutDialog'),
    },
  ];

  if (matchingRoute?.hideNavbar) {
    return <></>;
  }

  return (
    <AppBar color="primary" position="static">
      <Toolbar>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Box display="flex" alignItems="flexStart">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleMobileOpen}
              sx={{
                borderRadius: '50%',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.08)',
                },
                transition: 'background-color 0.3s',
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box m={1} p={1}>
              <Typography variant={'h6'} noWrap>
                {matchingRoute?.name || 'Home'}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="flexEnd">
            {user && (
              <>
                <IconButton
                  color="inherit"
                  disabled={performingAction}
                  onClick={openUserMenu}
                >
                  <UserAvatar />
                </IconButton>

                <Menu
                  anchorEl={userMenu.anchorEl}
                  open={Boolean(userMenu.anchorEl)}
                  onClose={closeUserMenu}
                >
                  {menuItems.map((menuItem, index) => {
                    if (menuItem.actions) {
                      return (
                        <MenuItem key={index}>{menuItem.actions}</MenuItem>
                      );
                    } else if (menuItem.to) {
                      return (
                        <MenuItem
                          key={index}
                          component={RouterLink}
                          to={menuItem.to}
                          onClick={closeUserMenu}
                        >
                          {menuItem.name}
                        </MenuItem>
                      );
                    } else {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            closeUserMenu();

                            menuItem.onClick && menuItem.onClick();
                          }}
                        >
                          {menuItem.name}
                        </MenuItem>
                      );
                    }
                  })}
                </Menu>
              </>
            )}

            {!user && (
              <ButtonGroup
                color="inherit"
                disabled={performingAction}
                variant="outlined"
              >
                <Button onClick={() => openDialog('signUpDialog')}>
                  Sign up
                </Button>
                <Button onClick={() => openDialog('signInDialog')}>
                  Sign in
                </Button>
              </ButtonGroup>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
