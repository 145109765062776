import React, { useContext, useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Collapse,
  Container,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { AuthContext } from '../../../providers';
import { useGetAnnouncements, useGetAnnouncementsByLeague } from '../../../api';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import { TypeRoleOptions } from '../../../types';
import MDEditor from '@uiw/react-md-editor';
import { format, parseISO } from 'date-fns';

type AnnouncementsViewProps = {
  role: TypeRoleOptions;
  leagueId?: number;
};
export const AnnouncementsView = ({
  role,
  leagueId,
}: AnnouncementsViewProps) => {
  const { hasRole } = useContext(AuthContext);
  const theme = useTheme();
  const { data: announcementsData } = useGetAnnouncements({
    enabled: !leagueId,
  });
  const { data: announcementsByLeagueData } = useGetAnnouncementsByLeague(
    leagueId ?? 0,
    {
      enabled: !!leagueId,
    },
  );
  const [selectedAnnouncementId, setSelectedAnnouncementId] = useState<
    number | null
  >(null);

  const announcements = useMemo(() => {
    const announcementsBase = leagueId
      ? announcementsByLeagueData
      : announcementsData;
    return (
      announcementsBase?.filter(
        (a) =>
          a.roles?.map((r) => r.authority).includes(role) &&
          hasRole([role, 'SYSADMIN']),
      ) ?? []
    );
  }, [announcementsData, announcementsByLeagueData, leagueId, role]);

  return (
    <Container>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Typography variant={'h5'}>League Topics & Notes</Typography>
        </Grid>
        {announcements.map((announcement) => (
          <Grid item xs={12} key={announcement?.id}>
            <Card>
              <CardContent>
                <Typography variant={'h6'}>
                  <IconButton
                    onClick={() =>
                      setSelectedAnnouncementId(
                        selectedAnnouncementId === announcement?.id
                          ? null
                          : announcement?.id ?? null,
                      )
                    }
                  >
                    {selectedAnnouncementId === announcement?.id ? (
                      <ExpandMore />
                    ) : (
                      <ChevronRight />
                    )}
                  </IconButton>
                  {announcement?.announcementTitle}
                  {announcement.updatedAt && (
                    <Typography variant="subtitle2">
                      {'Updated at: '}
                      {format(
                        parseISO(announcement.updatedAt),
                        'MMM do, yyyy - hh:mmaaa',
                      )}
                    </Typography>
                  )}
                </Typography>
                <Collapse
                  in={selectedAnnouncementId === announcement?.id}
                  timeout="auto"
                  unmountOnExit
                >
                  <MDEditor.Markdown
                    source={announcement.announcementNotes}
                    style={{
                      backgroundColor: theme.palette.background.paper,
                      color: theme.palette.text.primary,
                    }}
                  />
                </Collapse>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
