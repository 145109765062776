import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeAnnouncement } from '../../../types';

const fetchAnnouncements = async (): Promise<TypeAnnouncement[]> => {
  const res = await axios.get('api/announcement');
  return res.data;
};

export const PrefetchAnnouncement = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['announcement'], fetchAnnouncements, {
    staleTime: 5000,
  });
};

export const useGetAnnouncements = (
  options?: UseQueryOptions<
    TypeAnnouncement[],
    AxiosError,
    TypeAnnouncement[],
    any
  >,
) => {
  return useQuery('announcement', fetchAnnouncements, options);
};
