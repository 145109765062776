import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  TypeIngredient,
  TypeInstruction,
  TypeMaterialOption,
  TypeRecipe,
  TypeRecipeEvent,
} from '../../types';
import {
  useGetLinkedMaterialRecipes,
  useGetRecipe,
  useGetRecipeEventsByRecipe,
  usePostRecipe,
  usePostRecipeEvent,
} from '../../api';
import { genericSort, stableSort } from '../../components';
import { ProjectContext } from '../ProjectProvider';
import { ChefContext } from './ChefProvider';
import { AuthContext } from '../AuthProvider';
import { formatInTimeZone } from 'date-fns-tz';

type RecipeContextType = {
  recipe: TypeRecipe;
  setRecipe: (_recipe: TypeRecipe) => void;
  updateRecipe: () => void;
  resetRecipe: () => void;
  handleAddRecipeEvent: (_recipeId: TypeRecipe, _madeAt: Date) => void;
  materialOptionsForRecipe: TypeMaterialOption[];
  linkedMaterialRecipes: TypeRecipe[];
  recipeEvents: TypeRecipeEvent[];
  isRecipeReady: boolean;
  isRecipeValid: boolean;
};

export const RecipeContext = createContext<RecipeContextType>({
  recipe: {},
  setRecipe: (_recipe: TypeRecipe) => {},
  updateRecipe: () => {},
  resetRecipe: () => {},
  handleAddRecipeEvent: (_recipe, _madeAt) => {},
  materialOptionsForRecipe: [],
  linkedMaterialRecipes: [],
  recipeEvents: [],
  isRecipeReady: false,
  isRecipeValid: false,
});

export type RecipeProviderProps = {
  recipeId: number;
  children: React.ReactNode;
};

export const RecipeProvider = ({ recipeId, children }: RecipeProviderProps) => {
  const { profile } = useContext(AuthContext);
  const { materialOptions, materialRecipes } = useContext(ChefContext);
  const { setSnackbar, setPerformingAction } = useContext(ProjectContext);
  const [recipe, setRecipe] = useState<TypeRecipe>({});
  const [linkedMaterialRecipes, setLinkedMaterialRecipes] = useState<
    TypeRecipe[]
  >([]);
  const [materialOptionsForRecipe, setMaterialOptionsForRecipe] = useState<
    TypeMaterialOption[]
  >([]);
  const [recipeEvents, setRecipeEvents] = useState<TypeRecipe[]>([]);

  const { mutate: recipeMutation } = usePostRecipe();
  const { refetch: refetchRecipe } = useGetRecipe(recipeId, {
    onSuccess: (data) => processRecipeData(data),
    refetchOnWindowFocus: false,
    enabled: !!recipeId,
  });

  const { refetch: refetchLinkedMaterialRecipes } = useGetLinkedMaterialRecipes(
    Number(recipeId),
    {
      onSuccess: (data) => setLinkedMaterialRecipes(data),
      refetchOnWindowFocus: false,
      enabled: !!recipe?.isMaterial,
    },
  );

  const { mutate: recipeEventMutation } = usePostRecipeEvent();
  const { refetch: refetchRecipeEventsByRecipe } = useGetRecipeEventsByRecipe(
    Number(recipeId),
    {
      onSuccess: (data) =>
        setRecipeEvents(
          stableSort(data, {
            property: 'madeAt',
            isDescending: true,
          }),
        ),
      refetchOnWindowFocus: false,
      enabled: !!recipeId,
    },
  );

  const updateRecipe = () => {
    if (!recipe) return;
    recipeMutation(recipe, {
      onSuccess: () => {
        setSnackbar('Recipe saved successfully');
      },
    });
  };

  const resetRecipe = () => {
    refetchRecipe();
    setSnackbar('Recipe reset successfully');
  };

  const handleAddRecipeEvent = (recipe: TypeRecipe, madeAt: Date) => {
    setPerformingAction(true);
    recipeEventMutation(
      {
        recipe,
        madeAt: formatInTimeZone(
          madeAt,
          profile?.timeZone ?? 'UTC',
          `yyyy-MM-dd'T'HH:mm:ssXXX`,
        ),
        profile: profile ?? undefined,
      },
      {
        onSuccess: () => {
          setSnackbar('Recipe event added successfully');
          refetchRecipeEventsByRecipe();
        },
        onSettled: () => {
          setPerformingAction(false);
        },
      },
    );
  };

  const processRecipeData = (recipeData: TypeRecipe) => {
    if (!recipeData) return;
    const ingredientList = (recipeData.ingredientList || [])
      .slice()
      .sort((a, b) =>
        genericSort<TypeIngredient>(a, b, {
          property: 'position',
          isDescending: false,
        }),
      );
    const instructionList = (recipeData.instructionList || [])
      .slice()
      .sort((a, b) =>
        genericSort<TypeInstruction>(a, b, {
          property: 'position',
          isDescending: false,
        }),
      );
    setRecipe({
      ...recipeData,
      ingredientList: ingredientList,
      instructionList: instructionList,
    });
  };

  useEffect(() => {
    refetchRecipe();
    refetchLinkedMaterialRecipes();
    refetchRecipeEventsByRecipe();
  }, [profile?.organizationContext]);

  useEffect(() => {
    if (materialRecipes && materialOptions && recipe) {
      if (!recipe?.isMaterial) {
        const options: TypeMaterialOption[] = materialRecipes
          .filter((recipe) => recipe?.id !== recipeId)
          .map((recipe) => {
            return {
              id: recipe.id,
              name: recipe.recipeTitle,
              recipe: recipe,
            };
          });
        options.push(...materialOptions);
        setMaterialOptionsForRecipe(options);
      } else {
        setMaterialOptionsForRecipe(materialOptions);
      }
    }
  }, [recipe, materialRecipes, materialOptions]);

  const isRecipeValid = useMemo(() => {
    if (!recipe) return false;
    const ingredientList = recipe?.ingredientList || [];
    return ingredientList.every(
      (ingredient) => ingredient.valid === undefined || ingredient.valid,
    );
  }, [recipe]);

  return (
    <RecipeContext.Provider
      value={{
        recipe,
        setRecipe,
        updateRecipe,
        resetRecipe,
        handleAddRecipeEvent,
        materialOptionsForRecipe,
        linkedMaterialRecipes,
        recipeEvents,
        isRecipeReady: !!recipe,
        isRecipeValid: isRecipeValid,
      }}
    >
      {children}
    </RecipeContext.Provider>
  );
};
