import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeOrganization } from '../../../types';

export const usePostOrganization = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (organization: TypeOrganization) =>
      axios.post(`api/organization`, organization).then((res) => res.data),
    {
      onMutate: (organization: TypeOrganization) => {
        queryClient.cancelQueries('organization');

        const oldOrganization = queryClient.getQueryData([
          'organization',
          organization.id,
        ]);

        queryClient.setQueryData(
          ['organization', organization.id],
          organization,
        );

        return () =>
          queryClient.setQueryData(
            ['organization', organization.id],
            oldOrganization,
          );
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('organization');
        queryClient.invalidateQueries('organization/all');
      },
    },
  );
};
