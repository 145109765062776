import React, { useContext } from 'react';
import { BaseDialog } from './BaseDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';
import { Send } from '@mui/icons-material';

type ClaimPracticeAvailabilityProps = {
  recipientCount: number;
  sendFn: () => void;
};

export const SendEmailDialog = ({
  recipientCount,
  sendFn,
}: ClaimPracticeAvailabilityProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { sendEmailDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Send Email?`,
    contentText: `This will send to ${recipientCount} recipients.`,
    dismissiveAction: (
      <Button color="primary" onClick={() => closeDialog('sendEmailDialog')}>
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={sendFn}
        endIcon={<Send />}
      >
        Send
      </Button>
    ),
  };

  return <BaseDialog dialogProps={{ open: sendEmailDialog }} {...props} />;
};
