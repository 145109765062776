import React, { useContext } from 'react';
import { AppointmentContext } from '../../../../providers';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

export const SelectTherapist = () => {
  const {
    appointmentCalendars,
    setSelectedAppointmentCalendar,
    appointmentCalendarGroups,
    setSelectedAppointmentCalendarGroup,
  } = useContext(AppointmentContext);

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Typography variant="h6">Select Therapist</Typography>
      </Grid>
      {appointmentCalendarGroups.map((appointmentCalendarGroup, index) => (
        <Grid item xs={12} key={`calendar-${index}`}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1">
                  {appointmentCalendarGroup?.displayName}
                </Typography>
                <Button
                  color="primary"
                  onClick={() =>
                    setSelectedAppointmentCalendarGroup(
                      appointmentCalendarGroup,
                    )
                  }
                >
                  Select
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
      {appointmentCalendars.map((appointmentCalendar, index) => (
        <Grid item xs={12} key={`calendar-${index}`}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="body1">
                  {appointmentCalendar?.profile?.name ??
                    appointmentCalendar?.name}
                </Typography>
                <Button
                  color="primary"
                  onClick={() =>
                    setSelectedAppointmentCalendar(appointmentCalendar)
                  }
                >
                  Select
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
