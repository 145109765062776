import { Alert, Button, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  useDeleteMatchSchedulePDF,
  useGetDivisions,
  usePostMatchSchedulePDF,
} from '../../../api';
import { LeagueContext, ProjectContext } from '../../../providers';
import { AlertColor } from '@mui/material/Alert/Alert';
import { useSubscription } from 'react-stomp-hooks';
import { DivisionPDFList } from './DivisionPDFList';

export const SchedulePDFs = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { league, refetchLeague, divisions } = useContext(LeagueContext);
  const { mutate: matchSchedulerGeneratePDFsMutation } =
    usePostMatchSchedulePDF();
  const { mutate: matchSchedulerDeletePDFsMutation } =
    useDeleteMatchSchedulePDF();
  const { refetch: refetchLeagueDivisions } = useGetDivisions(league?.id!!);
  // const [scheduleView, setScheduleView] =
  //   React.useState<TypeMatchSchedule>('Coach');
  // const options: TypeMatchSchedule[] = ['Coach', 'Week', 'Division'];
  const [schedulePDFsMessage, setSchedulePDFsMessage] = useState<string>(
    'Schedule PDFs Not Generated',
  );
  const [schedulePDFsMessageSeverity, setSchedulePDFsMessageSeverity] =
    useState<AlertColor>('info');

  useEffect(() => {
    if (league?.schedulePdfsGenerated) {
      setSchedulePDFsMessage('Schedule PDFs Generated Successfully');
      setSchedulePDFsMessageSeverity('success');
    }
  }, [league]);

  const generateSchedulePDFs = () => {
    if (!league) return;
    matchSchedulerGeneratePDFsMutation(league.id!!, {
      onSuccess: () => setSnackbar('Started Schedule PDF Generation'),
      onError: () =>
        setSnackbar('Error Starting Schedule PDF Generation', 'error'),
      onSettled: () => {
        setSchedulePDFsMessage(
          'Schedule PDFs are being generated. This may take a few minutes',
        );
        setSchedulePDFsMessageSeverity('warning');
      },
    });
  };

  const deleteSchedulePDFs = () => {
    if (!league) return;
    matchSchedulerDeletePDFsMutation(league.id!!, {
      onSuccess: () => setSnackbar('Schedule PDFs Deleted'),
      onError: () => setSnackbar('Error Deleting Schedule PDFs', 'error'),
      onSettled: () => {
        setSchedulePDFsMessage('Schedule PDFs Not Generated');
        setSchedulePDFsMessageSeverity('info');
        refetchLeague();
        refetchLeagueDivisions();
      },
    });
  };

  useSubscription('/topic/coach/matches-pdf', (message) => {
    if (message.body === 'success') {
      setSnackbar('Schedule PDFs Generated');
      setSchedulePDFsMessage('Schedule PDFs Generated Successfully');
      setSchedulePDFsMessageSeverity('success');
    } else {
      setSnackbar('Error Generating Schedule PDFs', 'error');
      setSchedulePDFsMessage('Error Generating Schedule PDFs');
      setSchedulePDFsMessageSeverity('error');
    }
    refetchLeague();
    refetchLeagueDivisions();
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant={'contained'}
            onClick={generateSchedulePDFs}
            sx={{ marginRight: 2 }}
            disabled={!league?.matchesGenerated}
          >
            Generate All Schedule PDFs
          </Button>
          <Button
            variant={'outlined'}
            color={'secondary'}
            onClick={deleteSchedulePDFs}
            disabled={
              !league?.matchesGenerated || !league?.schedulePdfsGenerated
            }
          >
            Delete All Schedule PDFs
          </Button>
        </Grid>
        <Grid item xs={12}>
          {schedulePDFsMessage && (
            <Alert severity={schedulePDFsMessageSeverity}>
              <Typography variant={'body1'}>{schedulePDFsMessage}</Typography>
            </Alert>
          )}
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*  <Autocomplete*/}
        {/*    value={scheduleView}*/}
        {/*    onChange={(event, newValue) =>*/}
        {/*      newValue && setScheduleView(newValue)*/}
        {/*    }*/}
        {/*    filterSelectedOptions*/}
        {/*    clearOnBlur*/}
        {/*    options={options}*/}
        {/*    getOptionLabel={(option) => option || ''}*/}
        {/*    isOptionEqualToValue={(option, value) => option === value}*/}
        {/*    renderOption={(props, option) => <li {...props}>{option}</li>}*/}
        {/*    renderInput={(params) => (*/}
        {/*      <TextField {...params} label="Schedule View" variant="outlined" />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</Grid>*/}
        {league?.schedulePdfsGenerated && (
          <DivisionPDFList divisions={divisions} />
        )}
      </Grid>
    </>
  );
};
