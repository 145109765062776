import React, { useMemo } from 'react';
import { Chip } from '@mui/material';

type DateViewProps = {
  title: string;
  duration?: number; // seconds
};
export const DurationView = ({ title, duration = 0 }: DateViewProps) => {
  const formattedDuration = useMemo(() => {
    const days = Math.floor(duration / 86400);
    const hours = Math.floor((duration % 86400) / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const daysText = days > 0 ? `${days}d ` : '';
    const hoursText = hours > 0 ? `${hours}h ` : '';
    const minutesText = minutes > 0 ? `${minutes}m` : '';
    return `${daysText}${hoursText}${minutesText}`;
  }, [duration]);
  if (!duration) {
    return <></>;
  }
  return <Chip label={`${title}: ${formattedDuration}`} color="default" />;
};
