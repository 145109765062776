import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeMaterial } from '../../../types';

export const useDeleteMaterial = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (material: TypeMaterial) => axios.delete(`api/material/${material.id}`),
    {
      onMutate: (material: TypeMaterial) => {
        queryClient.cancelQueries('material');

        const previousMaterial = queryClient.getQueryData([
          'material',
          material.id,
        ]);

        queryClient.setQueryData(['material', material.id], material);

        return { previousMaterial };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('material');
        queryClient.invalidateQueries('material-group/material');
      },
    },
  );
};
