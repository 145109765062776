import { useMutation, useQueryClient } from 'react-query';
import { TypeElasticSearchDTO } from '../../../types';
import axios from 'axios';

export const useSearchDocument = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (document: TypeElasticSearchDTO) =>
      axios.post(`api/document/search`, document).then((res) => res.data),
    {
      onMutate: (_document: TypeElasticSearchDTO) => {
        queryClient.cancelQueries('document/search');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('document/search');
      },
    },
  );
};
