import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeAppearance } from '../../../types';

export const usePostProfileAppearance = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (appearance: TypeAppearance) =>
      axios
        .post(`api/profile/appearance/${appearance}`, {})
        .then((res) => res.data),
    {
      onMutate: (_appearance: TypeAppearance) => {
        queryClient.cancelQueries('profile/appearance');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
      },
    },
  );
};
