import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeagueEmailSent } from '../../../types';

export const usePostLeagueEmailSent = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (leagueEmailSent: TypeLeagueEmailSent) =>
      axios.post(`api/league-email`, leagueEmailSent).then((res) => res.data),
    {
      onMutate: (_leagueEmailSent: TypeLeagueEmailSent) => {
        queryClient.cancelQueries('league-email');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-email');
        // queryClient.invalidateQueries(['EmailSent', variables.id]).then(_ => {})
      },
    },
  );
};
