import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeProfile } from '../../../types';

export const ProfileList = ({
  profiles,
  profilesAvailable,
}: {
  profiles: TypeProfile[] | undefined;
  profilesAvailable: boolean;
}) => (
  <TableContainer hidden={!profilesAvailable}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>Email</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {profiles?.map((profile) => (
          <TableRow key={profile.id}>
            <TableCell>{profile.name}</TableCell>
            <TableCell>{profile.email}</TableCell>
          </TableRow>
        ))}
        {profiles?.length === 0 && (
          <TableRow>
            <TableCell colSpan={4}>No Profiles Yet</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
