import React, { useContext } from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeMeasurement } from '../../../types';
import { DialogContext } from '../../../providers';
import { useDeleteMeasurement, useGetMeasurement } from '../../../api';
import { DeleteMeasurementDialog } from '../../_common';
import { DeleteForever } from '@mui/icons-material';

export const MeasurementList = ({
  measurements,
  measurementsAvailable,
}: {
  measurements?: TypeMeasurement[];
  measurementsAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { mutate: deleteMeasurement } = useDeleteMeasurement();
  const [measurementToDelete, setMeasurementToDelete] =
    React.useState<TypeMeasurement>();
  const { data: refetchMeasurement } = useGetMeasurement(
    measurementToDelete?.id!!,
    {
      enabled: !!measurementToDelete,
    },
  );

  const handleDeleteMeasurementDialog = (measurement?: TypeMeasurement) => {
    if (measurement) {
      setMeasurementToDelete(measurement);
      openDialog('deleteMeasurementDialog');
    } else {
      setMeasurementToDelete(undefined);
      closeDialog('deleteMeasurementDialog');
    }
  };

  return (
    <>
      <TableContainer hidden={!measurementsAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Measurement</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {measurements?.map((measurement) => (
              <TableRow key={measurement.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/measurement/${measurement.id}`}
                    underline="none"
                  >
                    {measurement.measurementName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleDeleteMeasurementDialog(measurement)}
                  >
                    <DeleteForever />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {measurements?.length === 0 && (
              <TableRow>
                <TableCell>No Measurements Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {refetchMeasurement && (
        <DeleteMeasurementDialog
          measurementName={refetchMeasurement.measurementName!!}
          usages={refetchMeasurement.usages || 0}
          deleteFn={() =>
            deleteMeasurement(refetchMeasurement, {
              onSuccess: () => handleDeleteMeasurementDialog(),
            })
          }
        />
      )}
    </>
  );
};
