import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeMeasurement } from '../../../types';

export const useDeleteMeasurement = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (measurement: TypeMeasurement) =>
      axios.delete(`api/measurement/${measurement.id}`),
    {
      onMutate: (measurement: TypeMeasurement) => {
        queryClient.cancelQueries('measurement');

        const previousMeasurement = queryClient.getQueryData([
          'measurement',
          measurement.id,
        ]);

        queryClient.setQueryData(['measurement', measurement.id], measurement);

        return { previousMeasurement };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('measurement');
        queryClient.invalidateQueries('measurement-group/measurement');
      },
    },
  );
};
