import React from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeLeague } from '../../../types';

export const LeagueList = ({
  leagues,
  leaguesAvailable,
}: {
  leagues: TypeLeague[] | undefined;
  leaguesAvailable: boolean;
}) => (
  <TableContainer hidden={!leaguesAvailable}>
    <Table style={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>League</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {leagues?.map((league) => (
          <TableRow key={league.id}>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/league/${league.id}`}
                underline="none"
              >
                {league.name}
              </Link>
            </TableCell>
          </TableRow>
        ))}
        {leagues?.length === 0 && (
          <TableRow>
            <TableCell>No Leagues Yet</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
