import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMaterial } from '../../../types';

const fetchMaterial = async (materialId: number): Promise<TypeMaterial> => {
  const res = await axios.get(`api/material/${materialId}`);
  return res.data;
};

export const PrefetchMaterial = (materialId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['material', materialId],
    () => fetchMaterial(materialId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetMaterial = (
  materialId: number,
  options?: UseQueryOptions<TypeMaterial, AxiosError, TypeMaterial, any>,
) => {
  return useQuery(
    ['material', materialId],
    () => fetchMaterial(materialId),
    options,
  );
};
