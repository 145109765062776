import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  TypeCoach,
  TypeDivision,
  TypeLeague,
  TypeLeagueDate,
  TypeLeagueDay,
  TypeLeagueField,
  TypeLeagueTime,
  TypeLeagueWeek,
  TypePracticeDivisionGroup,
  TypeTimeSlot,
} from '../../types';
import {
  useGetCoaches,
  useGetDivisions,
  useGetLeague,
  useGetLeagueDates,
  useGetLeagueDays,
  useGetLeagueFields,
  useGetLeagueTimes,
  useGetLeagueWeeks,
  useGetPracticeDivisionGroups,
  useGetTimeSlots,
} from '../../api';
import { AuthContext } from '../AuthProvider';
import { formatTimeToStringTZ } from '../../components/_common/Utils/formatTimeToStringTZ';
import { parse } from 'date-fns';

type LeagueContextType = {
  league: TypeLeague | undefined;
  refetchLeague: () => void;
  refreshLeagueData: () => void;
  selectedDivision: TypeDivision | null;
  setSelectedDivision: (_division: TypeDivision | null) => void;
  divisions: TypeDivision[];
  practiceDivisionGroups: TypePracticeDivisionGroup[];
  divisionTimeSlots: TypeTimeSlot[];
  weeks: TypeLeagueWeek[];
  fields: TypeLeagueField[];
  dates: TypeLeagueDate[];
  times: TypeLeagueTime[];
  days: TypeLeagueDay[];
  coaches: TypeCoach[];
  isLeagueReady: boolean;
};

export const LeagueContext = createContext<LeagueContextType>({
  league: undefined,
  refetchLeague: () => {},
  refreshLeagueData: () => {},
  selectedDivision: null,
  setSelectedDivision: (_division: TypeDivision | null) => {},
  divisions: [],
  practiceDivisionGroups: [],
  divisionTimeSlots: [],
  weeks: [],
  fields: [],
  dates: [],
  times: [],
  days: [],
  coaches: [],
  isLeagueReady: false,
});

export type LeagueProviderProps = {
  leagueId: number;
  children: React.ReactNode;
};

export const LeagueProvider = ({ leagueId, children }: LeagueProviderProps) => {
  const { profile } = useContext(AuthContext);
  const [league, setLeague] = useState<TypeLeague>({});
  const [selectedDivision, setSelectedDivision] = useState<TypeDivision | null>(
    null,
  );
  const { data: divisionTimeSlotsData, isLoading: divisionTimeSlotsLoading } =
    useGetTimeSlots(selectedDivision?.id!!, {
      enabled: !!selectedDivision,
      refetchOnWindowFocus: false,
    });
  const handleRefreshDivisions = (divisionList: TypeDivision[] | null) => {
    if (divisionList && divisionList.length) {
      setSelectedDivision(divisionList[0]);
    }
  };

  const { refetch: refetchLeague } = useGetLeague(leagueId, {
    onSuccess: (data) => setLeague(data),
    refetchOnWindowFocus: false,
  });
  const {
    data: divisionsData,
    isLoading: divisionsLoading,
    refetch: refetchDivisions,
  } = useGetDivisions(leagueId, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => handleRefreshDivisions(data),
  });
  const {
    data: practiceDivisionGroupsData,
    isLoading: practiceDivisionGroupsLoading,
  } = useGetPracticeDivisionGroups(Number(league.id!!));
  const {
    data: weeksData,
    isLoading: weeksLoading,
    refetch: refetchLeagueWeeks,
  } = useGetLeagueWeeks(leagueId, { refetchOnWindowFocus: false });
  const {
    data: fieldsData,
    isLoading: fieldsLoading,
    refetch: refetchLeagueFields,
  } = useGetLeagueFields(leagueId, { refetchOnWindowFocus: false });
  const {
    data: datesData,
    isLoading: datesLoading,
    refetch: refetchLeagueDates,
  } = useGetLeagueDates(leagueId, { refetchOnWindowFocus: false });
  const {
    data: timesData,
    isLoading: timesLoading,
    refetch: refetchLeagueTimes,
  } = useGetLeagueTimes(leagueId, { refetchOnWindowFocus: false });
  const {
    data: daysData,
    isLoading: daysLoading,
    refetch: refetchLeagueDays,
  } = useGetLeagueDays(leagueId, { refetchOnWindowFocus: false });
  const {
    data: coachesData,
    isLoading: coachesLoading,
    refetch: refetchCoaches,
  } = useGetCoaches(leagueId, { refetchOnWindowFocus: false });

  const sortedTimes = useMemo(() => {
    return (
      timesData?.sort((a, b) => {
        const aTime = parse(
          formatTimeToStringTZ(a.time, profile?.timeZone)!!,
          'hh:mm a',
          new Date(),
        );
        const bTime = parse(
          formatTimeToStringTZ(b.time, profile?.timeZone)!!,
          'hh:mm a',
          new Date(),
        );
        if (aTime === null || bTime === null) {
          return 0;
        }
        return aTime.getTime() - bTime.getTime();
      }) || []
    );
  }, [timesData, profile?.timeZone]);

  const leagueMemo = useMemo(() => league, [league]);
  const selectedDivisionMemo = useMemo(
    () => selectedDivision,
    [selectedDivision],
  );

  const refreshLeagueData = () => {
    refetchLeague();
    refetchDivisions();
    refetchLeagueWeeks();
    refetchLeagueFields();
    refetchLeagueDates();
    refetchLeagueTimes();
    refetchLeagueDays();
    refetchCoaches();
  };

  useEffect(() => {
    refreshLeagueData();
  }, [profile?.organizationContext]);

  return (
    <LeagueContext.Provider
      value={{
        league: leagueMemo,
        refetchLeague,
        refreshLeagueData,
        selectedDivision: selectedDivisionMemo,
        setSelectedDivision,
        practiceDivisionGroups: practiceDivisionGroupsData || [],
        divisions: divisionsData || [],
        divisionTimeSlots: divisionTimeSlotsData || [],
        weeks: weeksData || [],
        fields: fieldsData || [],
        dates: datesData || [],
        times: sortedTimes || [],
        days: daysData || [],
        coaches: coachesData || [],
        isLeagueReady:
          !divisionsLoading &&
          !practiceDivisionGroupsLoading &&
          !divisionTimeSlotsLoading &&
          !weeksLoading &&
          !fieldsLoading &&
          !datesLoading &&
          !timesLoading &&
          !daysLoading &&
          !coachesLoading,
      }}
    >
      {children}
    </LeagueContext.Provider>
  );
};
