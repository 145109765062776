import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { DiscussionTopicList } from './DiscussionTopicList';
import { useGetDiscussionTopics, usePostDiscussionTopic } from '../../../api';

export const DiscussionTopics = () => {
  const [discussionTopicTitle, setDiscussionTopicTitle] = useState<string>('');
  const { ...queryInfo } = useGetDiscussionTopics();
  const discussionTopicMutation = usePostDiscussionTopic();

  const createDiscussionTopic = () => {
    if (discussionTopicTitle === '') return;
    discussionTopicMutation.mutate({
      discussionTopicTitle: discussionTopicTitle,
    });
    setDiscussionTopicTitle('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscussionTopicTitle(event.target.value);
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="discussionTopicTitle"
              value={discussionTopicTitle}
              label="Search or Add Discussion Topic"
              placeholder="Discussion Topics Title"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={discussionTopicTitle === ''}
              onClick={createDiscussionTopic}
            >
              Create Discussion Topic
            </Button>
          </Grid>
        </Grid>
        <DiscussionTopicList
          discussionTopics={queryInfo.data}
          discussionTopicsAvailable={!queryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
