import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { TypeMaterial } from '../../../types';
import { useParams } from 'react-router-dom';
import {
  useGetMaterial,
  useGetRecipesByMaterial,
  usePostMaterial,
} from '../../../api';
import { RecipeList } from '../Recipe';

export const Material = () => {
  const { materialId } = useParams();
  const [material, setMaterial] = useState<TypeMaterial>({});
  useGetMaterial(Number(materialId), {
    onSuccess: (data) => setMaterial(data),
    refetchOnWindowFocus: false,
  });
  const materialMutation = usePostMaterial();
  const { data: recipeList, isLoading: recipeListLoading } =
    useGetRecipesByMaterial(Number(materialId));

  const updateMaterial = () => {
    materialMutation.mutate(material);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setMaterial(
      Object.assign({}, material, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="materialName"
              value={material?.materialName || ''}
              label="Enter Material Name"
              placeholder="Material Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateMaterial}>
              Update Material
            </Button>
          </Grid>
        </Grid>

        <RecipeList
          recipes={recipeList ?? []}
          recipesAvailable={!recipeListLoading}
          flatList={true}
        />
      </Container>
    </Box>
  );
};
