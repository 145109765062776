import React, { useContext } from 'react';
import { UserAnnouncements } from '../../_common';
import { ScheduleAppointment } from '../Scheduling';
import { AuthContext } from '../../../providers';
import { Box, Container } from '@mui/material';

export const Dashboard = () => {
  const { profile } = useContext(AuthContext);

  if (!profile) return <></>;

  return (
    <>
      <UserAnnouncements />
      {/*<CanvaLinkSubscriptions />*/}
      <Box mt={2}>
        <Container>
          <ScheduleAppointment profile={profile} />
        </Container>
      </Box>
    </>
  );
};
