import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeAppointment } from '../../../types';

export const useCancelAppointment = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (appointment: TypeAppointment) =>
      axios.delete(`api/appointment/${appointment.id}`),
    {
      onMutate: (appointment: TypeAppointment) => {
        queryClient.cancelQueries('appointment');

        const previousCoach = queryClient.getQueryData([
          'appointment',
          appointment.id,
        ]);

        queryClient.setQueryData(['appointment', appointment.id], appointment);

        return { previousCoach };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('appointment');
      },
    },
  );
};
