import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeStripeProduct } from '../../../types';

const fetchStripeProduct = async (
  stripeProductId: number,
): Promise<TypeStripeProduct> => {
  const res = await axios.get(`api/stripe-product/${stripeProductId}`);
  return res.data;
};

export const PrefetchStripeProduct = (stripeProductId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['stripe-product', stripeProductId],
    () => fetchStripeProduct(stripeProductId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetStripeProduct = (
  stripeProductId: number,
  options?: UseQueryOptions<
    TypeStripeProduct,
    AxiosError,
    TypeStripeProduct,
    any
  >,
) => {
  return useQuery(
    ['stripe-product', stripeProductId],
    () => fetchStripeProduct(stripeProductId),
    options,
  );
};
