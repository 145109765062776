import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypePreparation } from '../../../types';

export const usePostPreparation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (preparation: TypePreparation) =>
      axios.post(`api/preparation`, preparation).then((res) => res.data),
    {
      onMutate: (_preparation: TypePreparation) => {
        queryClient.cancelQueries('preparation');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('preparation');
        // queryClient.invalidateQueries(['preparation', variables.id]).then(_ => {})
      },
    },
  );
};
