import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import {
  Chat,
  FitnessCenter,
  Schedule,
  Security,
  ThumbUp,
  VerifiedUser,
} from '@mui/icons-material';

const Hero = () => (
  <Box
    sx={{
      bgcolor: 'background.paper',
      pt: 8,
      pb: 6,
    }}
  >
    <Container maxWidth="md">
      <Typography component="h1" variant="h2" color="text.primary" gutterBottom>
        Transform Your Training
      </Typography>
      <Typography variant="h5" color="text.secondary" paragraph>
        Connect with expert coaches and access customized workout plans tailored
        to your goals and needs.
      </Typography>
      <Button variant="contained" color="primary" size="large" sx={{ mt: 4 }}>
        Get Started
      </Button>
    </Container>
  </Box>
);

type FeatureCardProps = {
  icon: React.ReactNode;
  title: string;
  description: string;
};
const FeatureCard = ({ icon, title, description }: FeatureCardProps) => (
  <Card>
    <CardContent>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        {icon}
        <Typography variant="h5" component="div" sx={{ mt: 2 }}>
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {description}
        </Typography>
      </Box>
    </CardContent>
  </Card>
);

const Features = () => (
  <Box sx={{ py: 8 }}>
    <Container>
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        Features
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <FeatureCard
            icon={<FitnessCenter fontSize="large" color="primary" />}
            title="Expert Coaches"
            description="Connect with certified fitness professionals for personalized guidance."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FeatureCard
            icon={<Schedule fontSize="large" color="primary" />}
            title="Flexible Scheduling"
            description="Book sessions that fit your busy lifestyle with our easy-to-use platform."
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FeatureCard
            icon={<Chat fontSize="large" color="primary" />}
            title="Ongoing Support"
            description="Get motivation and answers to your questions with in-app messaging."
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
);

type TrustBadgeProps = {
  icon: React.ReactNode;
  text: string;
};

const TrustBadge = ({ icon, text }: TrustBadgeProps) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      mb: { xs: 2, md: 0 },
    }}
  >
    {icon}
    <Typography variant="subtitle1" align="center" sx={{ mt: 1 }}>
      {text}
    </Typography>
  </Box>
);

const TrustBadges = () => (
  <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
    <Container maxWidth="md">
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        Trusted by Athletes Worldwide
      </Typography>
      <Grid container spacing={2} justifyContent="space-around">
        <Grid item xs={12} sm={4}>
          <TrustBadge
            icon={<VerifiedUser fontSize="large" color="primary" />}
            text="Certified Trainers"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TrustBadge
            icon={<Security fontSize="large" color="primary" />}
            text="Secure Platform"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TrustBadge
            icon={<ThumbUp fontSize="large" color="primary" />}
            text="Satisfaction Guaranteed"
          />
        </Grid>
      </Grid>
    </Container>
  </Box>
);

const FAQ = () => (
  <Box sx={{ py: 8 }}>
    <Container>
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        Frequently Asked Questions
      </Typography>
    </Container>
  </Box>
);

const Footer = () => (
  <Box sx={{ bgcolor: 'background.paper', py: 6 }}>
    <Container maxWidth="lg">
      <Typography variant="h6" align="center" gutterBottom>
        Practice Makes Coach
      </Typography>
      <Typography
        variant="subtitle1"
        align="center"
        color="text.secondary"
        component="p"
      >
        Empowering your fitness journey, one connection at a time.
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="/">
          Practice Makes Coach
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Container>
  </Box>
);

export const CoachHome = () => (
  <Box>
    <Hero />
    <Features />
    <TrustBadges />
    <FAQ />
    <Footer />
  </Box>
);
