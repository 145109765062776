import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeDivision } from '../../../types';

const fetchDivisions = async (leagueId: number): Promise<TypeDivision[]> => {
  const res = await axios.get(`api/division/league/${leagueId}`);
  return res.data;
};

export const PrefetchDivision = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['division/league', leagueId],
    () => fetchDivisions(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetDivisions = (
  leagueId: number,
  options?: UseQueryOptions<TypeDivision[], AxiosError, TypeDivision[], any>,
) => {
  return useQuery(
    ['division/league', leagueId],
    () => fetchDivisions(leagueId),
    options,
  );
};
