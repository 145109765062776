import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeMembership } from '../../../types';

export const usePostMembership = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (membership: TypeMembership) =>
      axios.post(`api/membership`, membership).then((res) => res.data),
    {
      onMutate: () => {
        queryClient.cancelQueries('membership');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('membership');
      },
    },
  );
};
