import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeOrgInvite } from '../../../types';

const fetchOrgInvitesByOrganization = async (
  organizationId: number,
): Promise<TypeOrgInvite[]> => {
  const res = await axios.get(`api/org-invite/organization/${organizationId}`);
  return res.data;
};

export const PrefetchOrgInvite = (organizationId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['org-invite', organizationId],
    () => fetchOrgInvitesByOrganization(organizationId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetOrgInvitesByOrganization = (
  organizationId: number,
  options?: UseQueryOptions<TypeOrgInvite[], AxiosError, TypeOrgInvite[], any>,
) => {
  return useQuery(
    ['org-invite', organizationId],
    () => fetchOrgInvitesByOrganization(organizationId),
    options,
  );
};
