import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipeEvent } from '../../../types';

const fetchRecipeEvents = async (): Promise<TypeRecipeEvent[]> => {
  const res = await axios.get('api/recipe-event');
  return res.data;
};

export const PrefetchRecipeEvent = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['recipe-event'], fetchRecipeEvents, {
    staleTime: 5000,
  });
};

export const useGetRecipeEvents = (
  options?: UseQueryOptions<
    TypeRecipeEvent[],
    AxiosError,
    TypeRecipeEvent[],
    any
  >,
) => {
  return useQuery('recipe-event', fetchRecipeEvents, options);
};
