import { Button, Grid } from '@mui/material';
import React, { useContext } from 'react';
import {
  usePostSchedulingAppointments,
  usePostSchedulingClients,
} from '../../../api';
import { AppointmentCalendarContext, ProjectContext } from '../../../providers';

export const Tools = () => {
  const { performingAction, setSnackbar } = useContext(ProjectContext);
  const { refetchProfiles } = useContext(AppointmentCalendarContext);

  const { mutate: postClients } = usePostSchedulingClients();
  const { mutate: postAppointments } = usePostSchedulingAppointments();

  const handleRepopulateClients = () => {
    postClients(undefined, {
      onSuccess: () => setSnackbar('Clients repopulated successfully'),
      onError: () => setSnackbar('Clients repopulation failed', 'error'),
      onSettled: () => refetchProfiles(),
    });
  };

  const handleRepopulateAppointments = () => {
    postAppointments(undefined, {
      onSuccess: () => setSnackbar('Appointments repopulated successfully'),
      onError: () => setSnackbar('Appointments repopulation failed', 'error'),
    });
  };

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRepopulateClients}
          disabled={performingAction}
        >
          Repopulate Clients
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRepopulateAppointments}
          disabled={performingAction}
        >
          Repopulate Appointments
        </Button>
      </Grid>
    </Grid>
  );
};
