import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTag } from '../../../types';

export const usePostProfileTagMap = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (profileTagMap: { [tagId: number]: boolean }) =>
      axios.post(`api/profile-tag`, profileTagMap).then((res) => res.data),
    {
      onMutate: (_tag: TypeTag) => {
        queryClient.cancelQueries('profile-tag');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('profile-tag');
        queryClient.invalidateQueries('tag');
        queryClient.invalidateQueries('tag/type');
        // queryClient.invalidateQueries(['Tag', variables.id]).then(_ => {})
      },
    },
  );
};
