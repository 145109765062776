import React, { useContext } from 'react';

import {
  DeleteAccountDialog,
  SettingsDialog,
  SignInDialog,
  SignOutDialog,
  SignUpDialog,
} from '../index';
import { AuthContext } from '../../providers';

export const DialogHost = () => {
  const { user } = useContext(AuthContext);

  return (
    <>
      {user && (
        <>
          <SignOutDialog />
          <SettingsDialog />
          <DeleteAccountDialog />
        </>
      )}

      {!user && (
        <>
          <SignUpDialog />
          <SignInDialog />
        </>
      )}
    </>
  );
};
