import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeProfile, TypeSquareAppointmentBalance } from '../../../types';

const fetchSquareAppointmentBalance = async (
  profile: TypeProfile,
): Promise<TypeSquareAppointmentBalance> => {
  const res = await axios.get(
    `api/square-appointment-balance/client/${profile.id}`,
  );
  return res.data;
};

export const PrefetchSquareAppointmentBalance = (profile: TypeProfile) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['square-appointment-balance/client'],
    () => fetchSquareAppointmentBalance(profile),
    {
      staleTime: 5000,
    },
  );
};

export const useGetSquareAppointmentBalance = (
  profile: TypeProfile,
  options?: UseQueryOptions<
    TypeSquareAppointmentBalance,
    AxiosError,
    TypeSquareAppointmentBalance,
    any
  >,
) => {
  return useQuery(
    ['square-appointment-balance/client'],
    () => fetchSquareAppointmentBalance(profile),
    options,
  );
};
