import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useGetLeagueDays, usePostLeagueDays } from '../../../api';
import { LeagueContext, ProjectContext } from '../../../providers';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
} from '@mui/material';
import { Loader } from '../../_common';
import { TypeLeagueDay } from '../../../types';

export const LeagueDays = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { league } = useContext(LeagueContext);
  const [leagueDays, setLeagueDays] = useState<TypeLeagueDay[]>([]);
  const { data: leagueDaysData, isLoading } = useGetLeagueDays(league?.id!!);
  const { mutate: leagueDayMutation } = usePostLeagueDays();

  useEffect(() => {
    if (leagueDaysData) {
      setLeagueDays(leagueDaysData);
    }
  }, [leagueDaysData]);

  const handleChange = (leagueDay: TypeLeagueDay, checked: boolean) => {
    const updatedLeagueDays = leagueDays.map((ld) => {
      if (ld.day === leagueDay.day) {
        return {
          ...ld,
          enabled: checked,
        };
      }
      return ld;
    });
    setLeagueDays(updatedLeagueDays);
  };

  const handleSaveLeagueDays = () =>
    leagueDayMutation(leagueDays, {
      onSuccess: () => setSnackbar('Saved Days'),
      onError: () => setSnackbar('Saved Days'),
    });

  if (isLoading) return <Loader />;

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormGroup row>
            {leagueDays?.map((leagueDay, index) => (
              <Fragment key={`league-day-${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={leagueDay.enabled}
                      onChange={(_event, checked) =>
                        handleChange(leagueDay, checked)
                      }
                    />
                  }
                  label={leagueDay.day}
                />
                {index !== leagueDays.length - 1 && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ marginRight: 2 }}
                  />
                )}
              </Fragment>
            ))}
          </FormGroup>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleSaveLeagueDays}>
            Save Days
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
