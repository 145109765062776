import { TypeProjectConfig } from '../../types/_common/ProjectConfig';
import {
  AdminAppointments,
  Announcements,
  BodyGroup,
  BodyGroups,
  CanvaLinkContent,
  Courses,
  Dashboard,
  Image,
  Images,
  ImageTag,
  ImageTags,
  Memberships,
  PBSHome,
  Questionnaire,
  QuestionnaireAnswer,
  QuestionnaireQuestion,
  Store,
} from '../../components';
import { commonAppRoutes, commonSysAdminAppRoutes } from './CommonRouteConfig';
import { TypeAppRoute } from '../../types';

const proBodySportsCommonRoutes = (): TypeAppRoute[] => [
  {
    name: 'Home',
    path: '/',
    component: PBSHome,
    hideNavbar: true,
    showInDrawer: true,
  },
];

const proBodySportsUserRoutes = (): TypeAppRoute[] => [
  {
    name: 'Content',
    path: '/content/:membershipId',
    component: CanvaLinkContent,
  },
  {
    name: 'Store',
    path: '/store',
    component: Store,
    showInDrawer: true,
  },
];

const proBodySportsAdminRoutes = (): TypeAppRoute[] => [
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard,
    showInDrawer: true,
  },
  {
    name: 'Announcements',
    path: '/announcements',
    component: Announcements,
    showInDrawer: true,
  },
  {
    name: 'Appointments',
    path: '/appointments',
    component: AdminAppointments,
    showInDrawer: true,
  },
  {
    name: 'Body Groups',
    path: '/body-groups',
    component: BodyGroups,
    showInDrawer: true,
  },
  {
    name: 'Body Group',
    path: '/body-group/:bodyGroupId',
    component: BodyGroup,
  },
  {
    name: 'Image Tags',
    path: '/image-tags',
    component: ImageTags,
    showInDrawer: true,
  },
  {
    name: 'Image Tag',
    path: '/image-tag/:tagId',
    component: ImageTag,
  },
  {
    name: 'Image',
    path: '/image/:imageId',
    component: Image,
  },
  {
    name: 'Questionnaire',
    path: '/questionnaire',
    component: Questionnaire,
    showInDrawer: true,
  },
  {
    name: 'Edit Question',
    path: '/question/:questionId',
    component: QuestionnaireQuestion,
  },
  {
    name: 'Edit Answer',
    path: '/answer/:answerId',
    component: QuestionnaireAnswer,
  },
];

const proBodySportsSysAdminRoutes = (): TypeAppRoute[] => [
  {
    name: 'Memberships',
    path: '/memberships',
    component: Memberships,
    showInDrawer: true,
  },
  {
    name: 'Membership',
    path: '/membership/:membershipId',
    component: Memberships,
  },
  {
    name: 'Courses',
    path: '/courses',
    component: Courses,
    showInDrawer: true,
  },
  {
    name: 'Images',
    path: '/images',
    component: Images,
    showInDrawer: true,
  },
];

export const ProBodySportsRouteConfig = (): TypeProjectConfig => ({
  cldCloudName: 'hzcju0e8n',
  cldUploadPreset: 'am9s6xuj',
  roleProjectRoutes: {
    ANONYMOUS: {
      homeRoute: '/',
      appRoutes: [...proBodySportsCommonRoutes(), ...commonAppRoutes()],
    },
    USER: {
      homeRoute: '/dashboard',
      appRoutes: [
        ...proBodySportsCommonRoutes(),
        ...proBodySportsUserRoutes(),
        ...commonAppRoutes(),
      ],
    },
    ADMIN: {
      homeRoute: '/appointments',
      appRoutes: [
        ...proBodySportsCommonRoutes(),
        ...proBodySportsAdminRoutes(),
        ...commonAppRoutes(),
      ],
    },
    SYSADMIN: {
      homeRoute: '/appointments',
      appRoutes: [
        ...proBodySportsCommonRoutes(),
        ...proBodySportsAdminRoutes(),
        ...proBodySportsSysAdminRoutes(),
        ...commonAppRoutes(),
        ...commonSysAdminAppRoutes(),
      ],
    },
  },
});
