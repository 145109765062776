import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeDiscussionTopic } from '../../../types';

export const useDeleteDiscussionTopic = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (discussionTopic: TypeDiscussionTopic) =>
      axios.delete(`api/discussion-topic/${discussionTopic.id}`),
    {
      onMutate: (discussionTopic: TypeDiscussionTopic) => {
        queryClient.cancelQueries('discussion-topic');

        const previousDiscussionTopic = queryClient.getQueryData([
          'discussion-topic',
          discussionTopic.id,
        ]);

        queryClient.setQueryData(
          ['discussion-topic', discussionTopic.id],
          discussionTopic,
        );

        return { previousDiscussionTopic };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('discussion-topic');
      },
    },
  );
};
