import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeImage } from '../../../types';

const fetchImagesByTag = async (tagId: number): Promise<TypeImage[]> => {
  const res = await axios.get(`api/image/tag/${tagId}`);
  return res.data;
};

export const PrefetchImageByTag = (tagId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['image/tag', tagId],
    () => fetchImagesByTag(tagId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetImagesByTag = (
  tagId: number,
  options?: UseQueryOptions<TypeImage[], AxiosError, TypeImage[], any>,
) => {
  return useQuery(['image/tag', tagId], () => fetchImagesByTag(tagId), options);
};
