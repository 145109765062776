import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTagEntityMap } from '../../../types';

const fetchTaskTagEntityMap = async (): Promise<TypeTagEntityMap> => {
  const res = await axios.get('api/task/mapped');
  return res.data;
};

export const PrefetchTaskTagEntityMap = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['task/mapped'], fetchTaskTagEntityMap, {
    staleTime: 5000,
  });
};

export const useGetTaskTagEntityMap = (
  options?: UseQueryOptions<
    TypeTagEntityMap,
    AxiosError,
    TypeTagEntityMap,
    any
  >,
) => {
  return useQuery('task/mapped', fetchTaskTagEntityMap, options);
};
