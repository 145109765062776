import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeOrganization } from '../../../types';

const fetchOrganizations = async (): Promise<TypeOrganization[]> => {
  const res = await axios.get('api/organization');
  return res.data;
};

export const PrefetchOrganization = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['organization'], fetchOrganizations, {
    staleTime: 5000,
  });
};

export const useGetOrganizations = (
  options?: UseQueryOptions<
    TypeOrganization[],
    AxiosError,
    TypeOrganization[],
    any
  >,
) => {
  return useQuery('organization', fetchOrganizations, options);
};
