import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTag } from '../../../types';

const fetchProfileTagsEnabled = async (): Promise<TypeTag[]> => {
  const res = await axios.get(`api/profile-tag/enabled`);
  return res.data;
};

export const PrefetchProfileTagsEnabled = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['profile-tag/enabled'],
    () => fetchProfileTagsEnabled(),
    {
      staleTime: 5000,
    },
  );
};

export const useGetProfileTagsEnabled = (
  options?: UseQueryOptions<TypeTag[], AxiosError, TypeTag[], any>,
) => {
  return useQuery(
    ['profile-tag/enabled'],
    () => fetchProfileTagsEnabled(),
    options,
  );
};
