import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  TypeCalendarEvent,
  TypeDocument,
  TypeFieldActiveFilters,
  TypeFieldFilterDefaults,
  TypePlannerTypes,
  TypeTag,
  TypeTask,
  TypeWorkflow,
} from '../../types';
import {
  useDeleteTask,
  usePostDocument,
  usePostTask,
  usePostWorkflow,
  useSearchDocument,
  useSearchWorkflow,
} from '../../api';
import { ProjectContext } from '../ProjectProvider';
import { DialogContext } from '../DialogProvider';
import { TimeContext } from '../TimeProvider';
import { uniq, without } from 'lodash';
import { useSubscription } from 'react-stomp-hooks';
import { AuthContext } from '../AuthProvider';
import { useQueryClient } from 'react-query';
import { fetchWorkflow } from '../../api/coach/Workflow/useGetWorkflow';
import { useSearchTask } from '../../api/coach/Task/useSearchTask';

export type ParentFieldActiveFilters<Types> = {
  [K in keyof Types]: TypeFieldActiveFilters<Types[K]>;
};
export type ParentFieldFilterDefaults<Types> = {
  [K in keyof Types]: TypeFieldFilterDefaults<Types[K]>;
};

const allDefaults: ParentFieldFilterDefaults<TypePlannerTypes> = {
  task: {
    defaultFields: ['name', 'dueAt', 'updatedAt', 'priorityValue'],
    fieldIsDescending: {
      name: false,
      dueAt: false,
      updatedAt: true,
      priorityValue: true,
      completedAt: true,
      deletedAt: true,
      snoozeUntil: false,
    },
    defaultSortField: 'priorityValue',
  },
  workflow: {
    defaultFields: ['name', 'updatedAt', 'workflowRunStartAt'],
    fieldIsDescending: {
      name: false,
      updatedAt: true,
      workflowRunStartAt: true,
    },
    defaultSortField: 'updatedAt',
  },
  document: {
    defaultFields: ['name', 'updatedAt'],
    fieldIsDescending: {
      name: false,
      updatedAt: true,
    },
    defaultSortField: 'updatedAt',
  },
};

type PlannerContextType = {
  // global view
  selectedTab: keyof TypePlannerTypes;
  setSelectedTab: (_tab: keyof TypePlannerTypes) => void;
  showFilters: boolean;
  setShowFilters: (_showFilters: boolean) => void;
  allFilters: ParentFieldActiveFilters<TypePlannerTypes>;
  handleShowHideField: <K extends keyof TypePlannerTypes>(
    _typeKey: K,
    _field: keyof TypePlannerTypes[K],
    _checked: boolean,
  ) => void;
  handleSetSelectedField: <K extends keyof TypePlannerTypes>(
    _typeKey: K,
    _field: keyof TypePlannerTypes[K],
  ) => void;
  showChips: boolean;
  setShowChips: (_checked: boolean) => void;
  selectedTags: TypeTag[];
  handleSelectTag: (_tag: TypeTag) => void;
  searchValue: string;
  setSearchValue: (_value: string) => void;
  searchValueRef: React.RefObject<HTMLInputElement | null> | null;
  // task view
  activeTask: TypeTask | null;
  tasks: TypeTask[];
  setTasks: (_tasks: TypeTask[]) => void;
  handleSetEditTask: (_task: TypeTask) => void;
  handleSaveTask: (_updatedTask: TypeTask) => void;
  handleDeleteTask: (_task: TypeTask) => void;
  // workflow view
  activeWorkflow: TypeWorkflow | null;
  workflows: TypeWorkflow[];
  setWorkflows: (_workflows: TypeWorkflow[]) => void;
  handleSetEditWorkflow: (_workflow: TypeWorkflow) => void;
  handleSaveWorkflow: (_updatedWorkflow: TypeWorkflow) => void;
  handleRefreshActiveWorkflow: (_workflow?: TypeWorkflow) => void;
  // documents
  activeDocument: TypeDocument | null;
  documents: TypeDocument[];
  setDocuments: (_documents: TypeDocument[]) => void;
  handleSetEditDocument: (_document: TypeDocument) => void;
  handleSaveDocument: (_updatedDocument: TypeDocument) => void;
  // events
  events: TypeCalendarEvent[];

  handleRefresh: () => Promise<any>;
  isPlannerReady: boolean;
};

export const PlannerContext = createContext<PlannerContextType>({
  // global view
  selectedTab: 'task',
  setSelectedTab: () => {},
  showFilters: true,
  setShowFilters: () => {},
  allFilters: {
    task: {
      showFields: [],
      toggleFields: [],
      selectedField: 'id',
      isDescending: false,
    },
    workflow: {
      showFields: [],
      toggleFields: [],
      selectedField: 'id',
      isDescending: false,
    },
    document: {
      showFields: [],
      toggleFields: [],
      selectedField: 'id',
      isDescending: false,
    },
  },
  handleShowHideField: (_typeKey, _field, _checked) => {},
  handleSetSelectedField: (_typeKey, _field) => {},
  showChips: true,
  setShowChips: () => {},
  selectedTags: [],
  handleSelectTag: () => {},
  searchValue: '',
  setSearchValue: () => {},
  searchValueRef: null,
  activeTask: null,
  // task view
  tasks: [],
  setTasks: () => {},
  handleSetEditTask: () => {},
  handleSaveTask: () => {},
  handleDeleteTask: () => {},
  // workflow view
  activeWorkflow: null,
  workflows: [],
  setWorkflows: () => {},
  handleSetEditWorkflow: () => {},
  handleSaveWorkflow: () => {},
  handleRefreshActiveWorkflow: () => {},
  // documents
  activeDocument: null,
  documents: [],
  setDocuments: () => {},
  handleSetEditDocument: () => {},
  handleSaveDocument: () => {},
  // events
  events: [],
  handleRefresh: async () => {},
  isPlannerReady: false,
});

export type PlannerProviderProps = {
  children: React.ReactNode;
};

export const PlannerProvider = ({ children }: PlannerProviderProps) => {
  const { profile } = useContext(AuthContext);
  const { setPerformingAction, setSnackbar } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { currentMinute } = useContext(TimeContext);

  const [activeTask, setActiveTask] = useState<TypeTask | null>(null);
  const [activeWorkflow, setActiveWorkflow] = useState<TypeWorkflow | null>(
    null,
  );
  const [activeDocument, setActiveDocument] = useState<TypeDocument | null>(
    null,
  );
  const [showChips, setShowChips] = useState<boolean>(true);
  const [selectedTags, setSelectedTags] = useState<TypeTag[]>([]);
  const [showFilters, setShowFilters] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [tasks, setTasks] = useState<TypeTask[]>([]);
  const [workflows, setWorkflows] = useState<TypeWorkflow[]>([]);
  const [documents, setDocuments] = useState<TypeDocument[]>([]);
  const [selectedTab, setSelectedTab] =
    useState<keyof TypePlannerTypes>('task');
  const [tasksLoading, setTasksLoading] = useState<boolean>(true);
  const [workflowsLoading, setWorkflowsLoading] = useState<boolean>(true);
  const [documentsLoading, setDocumentsLoading] = useState<boolean>(true);

  const [allFilters, setAllFilters] = useState<
    ParentFieldActiveFilters<TypePlannerTypes>
  >({
    task: {
      showFields: ['name', 'dueAt', 'updatedAt', 'priorityValue'],
      toggleFields: ['completedAt', 'snoozeUntil', 'deletedAt'],
      selectedField: allDefaults['task'].defaultSortField,
      isDescending:
        allDefaults['task'].fieldIsDescending[
          allDefaults['task'].defaultSortField
        ] ?? false,
    },
    workflow: {
      showFields: ['name', 'updatedAt', 'workflowRunStartAt'],
      toggleFields: [],
      selectedField: allDefaults['workflow'].defaultSortField,
      isDescending:
        allDefaults['workflow'].fieldIsDescending[
          allDefaults['workflow'].defaultSortField
        ] ?? false,
    },
    document: {
      showFields: ['name', 'updatedAt'],
      toggleFields: [],
      selectedField: allDefaults['document'].defaultSortField,
      isDescending:
        allDefaults['document'].fieldIsDescending[
          allDefaults['document'].defaultSortField
        ] ?? false,
    },
  });

  const queryClient = useQueryClient();
  const { mutate: updateTask } = usePostTask();
  const { mutate: deleteTask } = useDeleteTask();
  const { mutate: updateWorkflow } = usePostWorkflow();
  const { mutate: updateDocument } = usePostDocument();
  const { mutate: searchTasks } = useSearchTask();
  const { mutate: searchWorkflows } = useSearchWorkflow();
  const { mutate: searchDocuments } = useSearchDocument();

  const searchValueRef = useRef<HTMLInputElement | null>(null);

  const refetchTasks = () => {
    searchTasks(
      {
        searchTerm: searchValue,
        filters: {
          showFields: allFilters['task'].showFields as string[],
          selectedTags: selectedTags.map((tag) => tag.name!!),
        },
        sort: {
          field: allFilters['task'].selectedField,
          direction: allFilters['task'].isDescending ? 'DESC' : 'ASC',
        },
      },
      {
        onSuccess: (data: TypeTask[]) => {
          setTasks(data);
        },
        onSettled: () => setTasksLoading(false),
      },
    );
  };

  const refetchWorkflows = () => {
    searchWorkflows(
      {
        searchTerm: searchValue,
        filters: {
          showFields: allFilters['workflow'].showFields as string[],
          selectedTags: selectedTags.map((tag) => tag.name!!),
        },
        sort: {
          field: allFilters['workflow'].selectedField,
          direction: allFilters['workflow'].isDescending ? 'DESC' : 'ASC',
        },
      },
      {
        onSuccess: (data: TypeWorkflow[]) => {
          setWorkflows(data);
        },
        onSettled: () => setWorkflowsLoading(false),
      },
    );
  };

  const refetchDocuments = () => {
    searchDocuments(
      {
        searchTerm: searchValue,
        filters: {
          showFields: allFilters['document'].showFields as string[],
          selectedTags: selectedTags.map((tag) => tag.name!!),
        },
        sort: {
          field: allFilters['document'].selectedField,
          direction: allFilters['document'].isDescending ? 'DESC' : 'ASC',
        },
      },
      {
        onSuccess: (data: TypeDocument[]) => {
          setDocuments(data);
        },
        onSettled: () => setDocumentsLoading(false),
      },
    );
  };

  const handleRefresh = async () => {
    refetchTasks();
    refetchWorkflows();
    refetchDocuments();
  };

  useSubscription(`/user/${profile?.id ?? 0}/topic/planner`, (_message) => {
    console.log('refetching tasks and workflows with profile');
    handleRefresh();
  });

  useEffect(() => {
    if (selectedTab === 'task') {
      refetchTasks();
    } else if (selectedTab === 'workflow') {
      refetchWorkflows();
    } else if (selectedTab === 'document') {
      refetchDocuments();
    }
  }, [allFilters['task'], searchValue, selectedTags, selectedTab]);

  useEffect(() => {
    refetchTasks();
  }, [currentMinute]);

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleSetEditTask = (task: TypeTask) => {
    setActiveTask(task);
    openDialog('taskDialog');
  };

  const handleSetEditWorkflow = (workflow: TypeWorkflow) => {
    setActiveWorkflow(workflow);
    if (workflow.id) {
      handleRefreshActiveWorkflow(workflow);
    }
    openDialog('workflowDialog');
  };

  const handleSetEditDocument = (document: TypeDocument) => {
    setActiveDocument(document);
    openDialog('documentDialog');
  };

  const handleSaveTask = (updatedTask: TypeTask) => {
    setPerformingAction(true);
    updateTask(updatedTask, {
      onSuccess: (data) => {
        if (data.workflowRun) {
          refetchWorkflows();
        }
        setSnackbar(`Saved: ${data.name}`);
        setActiveTask(data);
        refetchTasks();
        closeDialog('taskDialog');
      },
      onSettled: () => setPerformingAction(false),
    });
  };

  const handleDeleteTask = (task: TypeTask) => {
    setPerformingAction(true);
    deleteTask(task, {
      onSuccess: () => {
        setSnackbar(`Deleted: ${task.name}`);
        setActiveTask(null);
        closeDialog('taskDialog');
      },
      onSettled: () => setPerformingAction(false),
    });
  };

  const handleSaveWorkflow = (updatedWorkflow: TypeWorkflow) => {
    setPerformingAction(true);
    updateWorkflow(updatedWorkflow, {
      onSuccess: (data) => {
        setSnackbar(`Saved: ${data.name}`);
        setActiveWorkflow(data);
        refetchWorkflows();
        closeDialog('workflowDialog');
      },
      onSettled: () => setPerformingAction(false),
    });
  };

  const refetchWorkflowById = async (workflowId: number) => {
    return await queryClient.fetchQuery<TypeWorkflow>(
      ['workflow', workflowId],
      {
        queryFn: () => fetchWorkflow(workflowId),
      },
    );
  };

  const handleRefreshActiveWorkflow = async (workflow?: TypeWorkflow) => {
    if (activeWorkflow || workflow) {
      setActiveWorkflow(
        await refetchWorkflowById(workflow?.id ?? activeWorkflow?.id!!),
      );
    }
  };

  const handleSaveDocument = (updatedDocument: TypeDocument) => {
    setPerformingAction(true);
    updateDocument(updatedDocument, {
      onSuccess: (data) => {
        setSnackbar(`Saved: ${data.name}`);
        setActiveDocument(data);
        refetchDocuments();
        closeDialog('documentDialog');
      },
      onSettled: () => setPerformingAction(false),
    });
  };

  const handleShowHideField = <K extends keyof TypePlannerTypes>(
    typeKey: K,
    field: keyof TypePlannerTypes[K],
    checked: boolean,
  ) => {
    if (checked) {
      setAllFilters({
        ...allFilters,
        [typeKey]: {
          ...allFilters[typeKey],
          showFields: uniq([...allFilters[typeKey].showFields, field]),
          selectedField: field,
          isDescending: allDefaults[typeKey].fieldIsDescending[field],
        },
      });
    } else {
      const removingField = field === allFilters[typeKey].selectedField;
      const defaultField = allDefaults[typeKey].defaultSortField;
      setAllFilters({
        ...allFilters,
        [typeKey]: {
          ...allFilters[typeKey],
          showFields: without(allFilters[typeKey].showFields, field),
          selectedField: removingField
            ? defaultField
            : allFilters[typeKey].selectedField,
          isDescending: removingField
            ? allDefaults[typeKey].fieldIsDescending[defaultField]
            : allFilters[typeKey].isDescending,
        },
      });
    }
  };

  const handleSetSelectedField = <K extends keyof TypePlannerTypes>(
    typeKey: K,
    field: keyof TypePlannerTypes[K],
  ) => {
    const selectingField = field !== allFilters[typeKey].selectedField;
    setAllFilters({
      ...allFilters,
      [typeKey]: {
        ...allFilters[typeKey],
        selectedField: field,
        isDescending: selectingField
          ? allDefaults[typeKey].fieldIsDescending[field]
          : !allFilters[typeKey].isDescending,
      },
    });
  };

  const handleSelectTag = (tag: TypeTag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(without(selectedTags, tag));
    } else {
      setSelectedTags(uniq(selectedTags.concat([tag])));
    }
  };

  return (
    <PlannerContext.Provider
      value={{
        // global view
        selectedTab,
        setSelectedTab,
        showFilters,
        setShowFilters,
        allFilters,
        handleShowHideField,
        handleSetSelectedField,
        showChips,
        setShowChips,
        selectedTags,
        handleSelectTag,
        searchValue,
        setSearchValue,
        searchValueRef,
        // task view
        activeTask,
        tasks,
        setTasks,
        handleSetEditTask,
        handleSaveTask,
        handleDeleteTask,
        // workflow view
        activeWorkflow,
        workflows,
        setWorkflows,
        handleSetEditWorkflow,
        handleSaveWorkflow,
        handleRefreshActiveWorkflow,
        // documents
        activeDocument,
        documents,
        setDocuments,
        handleSetEditDocument,
        handleSaveDocument,
        // events
        events: [],
        handleRefresh,
        isPlannerReady: !tasksLoading && !workflowsLoading && !documentsLoading,
      }}
    >
      {children}
    </PlannerContext.Provider>
  );
};
