import { TypeDocument, TypeEditDocumentField } from '../../../types';
import { Box, Chip, ListItem, ListItemText, Paper } from '@mui/material';
import React from 'react';
import { DateView } from '../../_common';

type ViewDocumentProps = {
  sortBy: TypeEditDocumentField;
  showChips: boolean;
  document: TypeDocument;
  onSetEditDocument: (
    _document: TypeDocument,
    _documentFocusField: TypeEditDocumentField | null,
  ) => void;
  onSave: (_document: TypeDocument) => void;
};

export const ViewDocumentCard = ({
  sortBy,
  showChips,
  document,
  onSetEditDocument,
}: ViewDocumentProps) => {
  const chipsView = () => {
    if (sortBy === 'updatedAt') {
      return <DateView title={'Updated At'} date={document.updatedAt} />;
    }
    if (document.tags) {
      return document.tags.map((tag) => (
        <Box key={tag.id} sx={{ marginRight: 1 }}>
          <Chip label={tag.name} />
        </Box>
      ));
    }
    return null;
  };

  return (
    <Paper elevation={3} sx={{ width: '100%', marginBottom: 1 }}>
      <ListItem
        sx={{
          height: '40px',
          pb: 0,
          pt: 0,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <ListItemText
            onClick={() => onSetEditDocument(document, 'name')}
            primary={document.name}
            sx={{
              cursor: 'pointer',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflowX: 'clip',
              margin: '0 auto',
              display: 'inline-grid',
              maxWidth: '100%',
            }}
          ></ListItemText>
          {showChips && chipsView()}
        </Box>
      </ListItem>
    </Paper>
  );
};
