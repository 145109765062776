import React, { useContext } from 'react';
import { DialogContext } from '../../../providers';
import { DeleteDialog } from './DeleteDialog';

type DeleteDivisionDialogProps = {
  deleteTitle: string;
  contentText: string;
  deleteFn: () => void;
};

export const DeleteDivisionDialog = ({
  deleteTitle,
  contentText,
  deleteFn,
}: DeleteDivisionDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteDivisionDialog } = dialogs;

  return (
    <DeleteDialog
      deleteTitle={deleteTitle}
      contentText={contentText}
      deleteFn={deleteFn}
      closeFn={() => closeDialog('deleteDivisionDialog')}
      open={deleteDivisionDialog}
    />
  );
};
