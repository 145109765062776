import { TypeIngredient, TypeInstruction } from '../chef';

export const DefaultIngredient: TypeIngredient = {
  id: 0,
  amount: 0,
  fractionalNumerator: 0,
  fractionalDenominator: 0,
  position: 0,
  measurement: null,
  material: null,
  materialRecipe: null,
  preparation: null,
  materialOption: null,
};

export const DefaultInstruction: TypeInstruction = {
  id: 0,
  instructionText: '',
  position: 0,
};
