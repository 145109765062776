import { Alert, Button, Grid, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { LeagueContext, ProjectContext } from '../../providers';
import { useGetMatchValidation } from '../../api';
import { AlertColor } from '@mui/material/Alert/Alert';

export const ValidationList = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { league } = useContext(LeagueContext);
  const { data, refetch } = useGetMatchValidation(league?.id!!, {
    enabled: !!league?.id,
    onSuccess: () => setSnackbar('Started Match Validation'),
    onError: () => setSnackbar('Error Starting Match Validation', 'error'),
  });

  const runValidation = () => {
    if (!league) return;
    refetch();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={runValidation}
            sx={{ marginRight: 2 }}
          >
            Run Validation
          </Button>
        </Grid>
        <Grid item xs={12}>
          {data &&
            data.map((msg, index) => (
              <Alert
                key={index}
                severity={msg.status.toLowerCase() as AlertColor}
                sx={{ marginBottom: 2 }}
              >
                <Typography variant={'body1'}>{msg.message}</Typography>
              </Alert>
            ))}
        </Grid>
      </Grid>
    </>
  );
};
