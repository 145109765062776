import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTag } from '../../../types';

export const usePostTag = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (tag: TypeTag) => axios.post(`api/tag`, tag).then((res) => res.data),
    {
      onMutate: (_tag: TypeTag) => {
        queryClient.cancelQueries('tag');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('tag');
        queryClient.invalidateQueries('tag/type');
        // queryClient.invalidateQueries(['Tag', variables.id]).then(_ => {})
      },
    },
  );
};
