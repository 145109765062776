import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  TypeChronoUnit,
  TypeDayOfWeek,
  TypeRecurrencePattern,
} from '../../types';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { ChronoUnitValues } from '../../types/_common/ChronoUnit';
import { DayOfWeekValues } from '../../types/_common/DayOfWeek';
import { Clear, MoreTime } from '@mui/icons-material';
import { AuthContext, ProjectContext } from '../../providers';
import { TimePickerWithZone } from './TimePickerWithZone';

type RecurrencePatternProps = {
  label: string;
  recurrencePattern: TypeRecurrencePattern | null;
  onChange: (_value: TypeRecurrencePattern) => void;
};

export const RecurrencePattern = ({
  label,
  recurrencePattern,
  onChange,
}: RecurrencePatternProps) => {
  const { isMediumScreen } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const [showPattern, setShowPattern] = useState<boolean>(false);
  const [durationAmount, setDurationAmount] = useState<number | null>(
    recurrencePattern?.durationAmount ?? 0,
  );
  const [durationScale, setDurationScale] = useState<TypeChronoUnit | null>(
    recurrencePattern?.durationScale ?? 'DAYS',
  );
  const [dayOfWeek, setDayOfWeek] = useState<TypeDayOfWeek | null>(null);
  const [dayOfMonth, setDayOfMonth] = useState<number | null>(null);
  const [monthOfYear, setMonthOfYear] = useState<number | null>(null);

  useEffect(() => {
    setShowPattern(!!recurrencePattern?.durationAmount);
    setDurationAmount(recurrencePattern?.durationAmount ?? 0);
    setDurationScale(recurrencePattern?.durationScale ?? null);
    setDayOfWeek(recurrencePattern?.dayOfWeek ?? null);
    setDayOfMonth(recurrencePattern?.dayOfMonth ?? null);
    setMonthOfYear(recurrencePattern?.monthOfYear ?? null);
  }, [recurrencePattern]);

  const visibleFields = useMemo(() => {
    switch (durationScale) {
      case 'DAYS':
        return {
          monthOfYear: 0,
          dayOfMonth: 0,
          dayOfWeek: 0,
          timeOfDay: 10, // What time of day
        };

      case 'WEEKS':
        return {
          monthOfYear: 0,
          dayOfMonth: 0,
          dayOfWeek: 5, // Which day of week
          timeOfDay: 5, // What time of day
        };

      case 'MONTHS':
        return {
          monthOfYear: 0,
          dayOfMonth: 5, // Which day of month
          dayOfWeek: 0,
          timeOfDay: 5, // What time of day
        };

      case 'YEARS':
        return {
          monthOfYear: isMediumScreen ? 3 : 6, // Which month
          dayOfMonth: isMediumScreen ? 3 : 6, // Which day of month
          dayOfWeek: 0,
          timeOfDay: isMediumScreen ? 4 : 10, // What time of day
        };

      default:
        return {
          monthOfYear: 0,
          dayOfMonth: 0,
          dayOfWeek: 0,
          timeOfDay: 0,
        };
    }
  }, [durationScale]);

  return showPattern ? (
    <Box display="flex" alignItems="flex-start" flexDirection="column" gap={2}>
      <Typography variant="subtitle1">{label}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={durationScale === 'HOURS' ? 5 : 6}>
          <TextField
            label={'Duration Amount'}
            value={durationAmount}
            type="number"
            fullWidth
            onChange={(e) =>
              onChange({
                ...recurrencePattern,
                durationAmount: e.target.value
                  ? parseInt(e.target.value, 10)
                  : undefined,
              })
            }
          />
        </Grid>
        <Grid item xs={durationScale === 'HOURS' ? 5 : 6}>
          <Autocomplete
            value={durationScale}
            options={ChronoUnitValues}
            onChange={(_e, value) =>
              onChange({
                ...recurrencePattern,
                durationScale: value ? (value as TypeChronoUnit) : undefined,
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Duration Scale"
                placeholder="Duration Scale"
              />
            )}
          />
        </Grid>
        {visibleFields.monthOfYear > 0 && (
          <Grid item xs={visibleFields.monthOfYear}>
            <Autocomplete
              placeholder={'Month of Year'}
              value={monthOfYear}
              options={Array.from({ length: 12 }, (_, i) => i + 1)}
              getOptionLabel={(option) => option.toString()}
              onChange={(_e, value) =>
                onChange({
                  ...recurrencePattern,
                  monthOfYear: value ?? undefined,
                })
              }
              renderInput={(params) => (
                <TextField {...params} label="Month" placeholder="Month" />
              )}
            />
          </Grid>
        )}
        {visibleFields.dayOfMonth > 0 && (
          <Grid item xs={visibleFields.dayOfMonth}>
            <Autocomplete
              placeholder={'Day of Month'}
              value={dayOfMonth}
              options={Array.from({ length: 30 }, (_, i) => i + 1)}
              getOptionLabel={(option) => option.toString()}
              onChange={(_e, value) =>
                onChange({
                  ...recurrencePattern,
                  dayOfMonth: value ?? undefined,
                })
              }
              renderInput={(params) => (
                <TextField {...params} label="Day" placeholder="Day" />
              )}
            />
          </Grid>
        )}
        {visibleFields.dayOfWeek > 0 && (
          <Grid item xs={visibleFields.dayOfWeek}>
            <Autocomplete
              placeholder={'Day of Week'}
              value={dayOfWeek}
              options={DayOfWeekValues}
              getOptionLabel={(option) => option}
              onChange={(_e, value) =>
                onChange({
                  ...recurrencePattern,
                  dayOfWeek: value ? (value as TypeDayOfWeek) : undefined,
                })
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Day of Week"
                  placeholder="Day of Week"
                />
              )}
            />
          </Grid>
        )}
        {visibleFields.timeOfDay > 0 && (
          <Grid item xs={visibleFields.timeOfDay}>
            <TimePickerWithZone
              value={recurrencePattern?.timeOfDay ?? null}
              onChange={(value) =>
                onChange({
                  ...recurrencePattern,
                  timeOfDay: value ?? undefined,
                })
              }
              userTimeZone={profile?.timeZone!!}
              label={'Time of Day'}
            />
          </Grid>
        )}
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              setShowPattern(false);
              onChange({
                id: recurrencePattern?.id,
              });
            }}
          >
            <Clear />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Button
      variant="text"
      onClick={() => {
        setShowPattern(true);
        onChange({
          ...recurrencePattern,
          durationAmount: 1,
          durationScale: 'DAYS',
        });
      }}
      startIcon={<MoreTime />}
    >
      Add {label}
    </Button>
  );
};
