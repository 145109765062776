import { Button, Grid, IconButton, TextField } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import React, { useContext } from 'react';
import { TypeRecurrencePattern, TypeWorkflowTask } from '../../../types';
import { ProjectContext } from '../../../providers';
import { Add } from '@mui/icons-material';
import { RecurrencePattern } from '../RecurrencePattern';

type WorkflowTaskListProps = {
  stepIndex: number;
  tasks: TypeWorkflowTask[];
  handleSetEditWorkflowTasks: (
    _stepIndex: number,
    _tasks: TypeWorkflowTask[],
  ) => void;
};

export const WorkflowTaskList = ({
  stepIndex,
  tasks,
  handleSetEditWorkflowTasks,
}: WorkflowTaskListProps) => {
  const { performingAction } = useContext(ProjectContext);

  const handleEditStepTask = (
    stepIndex: number,
    taskIndex: number,
    value: string,
  ) => {
    const newTasks = tasks.map((task, j) => {
      if (j === taskIndex) {
        return { ...task, name: value };
      }
      return task;
    });
    handleSetEditWorkflowTasks(stepIndex, newTasks);
  };

  const handleEditStepTaskSnoozePattern = (
    taskIndex: number,
    recurrencePattern: TypeRecurrencePattern | null,
  ) => {
    const newTasks = tasks.map((task, j) =>
      j === taskIndex
        ? {
            ...task,
            snoozePattern: recurrencePattern ?? undefined,
          }
        : task,
    );
    handleSetEditWorkflowTasks(stepIndex, newTasks);
  };

  const handleRemoveStepTask = (taskIndex: number) => {
    const newTasks = tasks.filter((_task, i) => i !== taskIndex);
    handleSetEditWorkflowTasks(stepIndex, newTasks);
  };

  const handleAddStepTask = () => {
    const newTasks = [...tasks, { name: '', position: tasks.length + 1 }];
    handleSetEditWorkflowTasks(stepIndex, newTasks);
  };

  return (
    <>
      {tasks.map((task, taskIndex) => (
        <Grid
          key={`edit-task-list-${stepIndex}-${taskIndex}`}
          container
          item
          xs={12}
          spacing={1}
          mb={4}
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={`Step ${stepIndex + 1} - Task ${taskIndex + 1}`}
              placeholder={`Step ${stepIndex + 1} - Task ${taskIndex + 1}`}
              value={task.name}
              onChange={(event) => {
                handleEditStepTask(stepIndex, taskIndex, event.target.value);
              }}
              disabled={performingAction}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => {
                      handleRemoveStepTask(taskIndex);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <RecurrencePattern
              label={'Snooze Recurrence'}
              recurrencePattern={task.snoozePattern ?? null}
              onChange={(recurrencePattern) =>
                handleEditStepTaskSnoozePattern(taskIndex, recurrencePattern)
              }
            />
          </Grid>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button
          variant="contained"
          disabled={performingAction}
          onClick={() => handleAddStepTask()}
          startIcon={<Add />}
        >
          Add Task
        </Button>
      </Grid>
    </>
  );
};
