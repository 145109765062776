import React, { useContext, useState } from 'react';
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { TypeCoach, TypeDivision, TypeEmailInviteDTO } from '../../../types';
import { Check, DeleteForever, Edit, Restore, Send } from '@mui/icons-material';
import {
  useDeleteCoach,
  useGetLeagueInvitedCoachEmails,
  usePostCoach,
  usePostLeagueCoachInviteEmail,
} from '../../../api';
import { DialogContext, LeagueContext } from '../../../providers';
import { DeleteCoachDialog } from '../../_common';
import { format, parseISO } from 'date-fns';

export const CoachList = ({
  coaches,
  coachesAvailable,
}: {
  coaches: TypeCoach[];
  coachesAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { league } = useContext(LeagueContext);
  const [editCoach, setEditCoach] = useState<string>('');
  const [editCoachEmail, setEditCoachEmail] = useState<string>('');
  const [activeCoach, setActiveCoach] = useState<TypeCoach>();
  const [coachToDelete, setCoachToDelete] = useState<TypeDivision>();
  const [invitedCoachEmails, setInvitedCoachEmails] = useState<
    TypeEmailInviteDTO[]
  >([]);
  const { mutate: updateCoach } = usePostCoach();
  const { mutate: deleteCoach } = useDeleteCoach();
  const { mutate: sendLeagueCoachInviteMutation } =
    usePostLeagueCoachInviteEmail();
  useGetLeagueInvitedCoachEmails(league?.id!!, {
    onSuccess: (data) => setInvitedCoachEmails(data),
  });
  const handleEdit = (coach: TypeCoach | null = null) => {
    if (coach) {
      setEditCoach(coach?.name || '');
      setEditCoachEmail(coach?.email || '');
      setActiveCoach(coach);
    } else {
      setEditCoach('');
      setEditCoachEmail('');
      setActiveCoach(undefined);
    }
  };

  const handleSave = () => {
    if (activeCoach) {
      updateCoach(
        {
          ...activeCoach,
          name: editCoach,
          email: editCoachEmail,
        },
        { onSuccess: () => handleEdit() },
      );
    }
  };

  const handleDeleteCoachDialog = (coach?: TypeCoach) => {
    if (coach) {
      setCoachToDelete(coach);
      openDialog('deleteCoachDialog');
    } else {
      setCoachToDelete(undefined);
      closeDialog('deleteCoachDialog');
    }
  };

  const sendSingleCoachInvite = (coachId: number) =>
    sendLeagueCoachInviteMutation(coachId);

  const getCoachStatus = (coach: TypeCoach) => {
    const invitedCoach = invitedCoachEmails.find(
      (ic) => ic.email === coach.email,
    );
    if (coach.profile?.email) {
      return 'Active';
    } else if (invitedCoach) {
      const invitedAt = format(
        parseISO(invitedCoach.invitedAt),
        'MM/dd hh:mm a',
      );
      return `Invited on ${invitedAt}`;
    }
    return 'New';
  };

  if (!coachesAvailable) {
    return <></>;
  }
  return (
    <>
      <TableContainer hidden={!coachesAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Coach</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
              <TableCell>Invite</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coaches?.map((coach) => (
              <TableRow key={coach.id}>
                <TableCell>
                  {editCoach && activeCoach?.id === coach?.id ? (
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={`name-${coach.id}`}
                      value={editCoach}
                      label="Coach Name"
                      type={'text'}
                      onChange={(event) => setEditCoach(event.target.value)}
                    />
                  ) : (
                    coach?.name
                  )}
                </TableCell>
                <TableCell>{coach?.profile?.email || coach?.email}</TableCell>
                <TableCell>{getCoachStatus(coach)}</TableCell>
                <TableCell>
                  {editCoach && activeCoach?.id === coach?.id ? (
                    <>
                      <IconButton onClick={handleSave}>
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => handleEdit()}>
                        <Restore />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      <IconButton onClick={() => handleEdit(coach)}>
                        <Edit />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteCoachDialog(coach)}
                        disabled={league?.matchesGenerated}
                      >
                        <DeleteForever />
                      </IconButton>
                    </>
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant={'outlined'}
                    startIcon={<Send />}
                    onClick={() => sendSingleCoachInvite(coach.id!!)}
                  >
                    Send Invite
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {coaches?.length === 0 && (
              <TableRow>
                <TableCell colSpan={5}>No Coaches Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {coachToDelete && (
        <DeleteCoachDialog
          deleteTitle={coachToDelete?.name!!}
          contentText={`This will delete ${coachToDelete?.name} and their team associations.`}
          deleteFn={() =>
            deleteCoach(coachToDelete, {
              onSuccess: () => handleDeleteCoachDialog(),
            })
          }
        />
      )}
    </>
  );
};
