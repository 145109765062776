import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeBodyGroup } from '../../../types';

const fetchBodyGroups = async (): Promise<TypeBodyGroup[]> => {
  const res = await axios.get('api/body-group');
  return res.data;
};

export const PrefetchBodyGroup = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['bodyGroup'], fetchBodyGroups, {
    staleTime: 5000,
  });
};

export const useGetBodyGroups = (
  options?: UseQueryOptions<TypeBodyGroup[], AxiosError, TypeBodyGroup[], any>,
) => {
  return useQuery('bodyGroup', fetchBodyGroups, options);
};
