import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostStripeProductRehydrate = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () =>
      axios.post(`api/stripe-product/rehydrate`, {}).then((res) => res.data),
    {
      onMutate: () => {
        queryClient.cancelQueries('stripe-product');
        queryClient.cancelQueries('stripe-price');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('stripe-product');
        queryClient.invalidateQueries('stripe-price');
      },
    },
  );
};
