import React, { useContext, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { LeagueTimeList } from './index';
import { useGetLeagueTimes, usePostLeagueTime } from '../../../api';
import { AuthContext, LeagueContext, ProjectContext } from '../../../providers';
import { TimePicker, TimeView } from '@mui/x-date-pickers';
import { formatTimeToStringTZ } from '../../_common/Utils/formatTimeToStringTZ';

export const LeagueTimes = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { league } = useContext(LeagueContext);
  const { profile } = useContext(AuthContext);
  const [leagueTime, setLeagueTime] = useState<Date | null>(null);
  const { data: leagueTimesData, isLoading: leagueTimesLoading } =
    useGetLeagueTimes(Number(league?.id), { enabled: !!league?.id });
  const { mutate: leagueTimeMutation } = usePostLeagueTime();

  const createLeagueTime = () => {
    if (leagueTime === null) return;
    leagueTimeMutation(
      {
        time: formatTimeToStringTZ(leagueTime, profile?.timeZone, 'timeTZ'),
        league: league,
      },
      {
        onSuccess: () => setSnackbar('New Time Saved'),
      },
    );
    setLeagueTime(null);
  };

  const handleChange = (newValue: Date | null) => {
    if (newValue === null) return;
    setLeagueTime(newValue);
  };

  // pretend both are in UTC
  const shouldDisableTime = (time: Date, _value: TimeView) => {
    const timeString = formatTimeToStringTZ(time, 'UTC', 'zuluTimeOnly');
    return leagueTimesData?.some((lt) => lt.time === timeString) ?? false;
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TimePicker
            label="Search or Add Time"
            value={leagueTime}
            onChange={handleChange}
            shouldDisableTime={shouldDisableTime}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            disabled={leagueTime === null}
            onClick={createLeagueTime}
          >
            Create Time
          </Button>
        </Grid>
      </Grid>
      <LeagueTimeList
        leagueTimes={leagueTimesData || []}
        leagueTimesAvailable={!leagueTimesLoading}
      />
    </Box>
  );
};
