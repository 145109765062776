import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeImage } from '../../../types';

const fetchImage = async (imageId: number): Promise<TypeImage> => {
  const res = await axios.get(`api/image/${imageId}`);
  return res.data;
};

export const PrefetchImage = (imageId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['image', imageId],
    () => fetchImage(imageId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetImage = (
  imageId: number,
  options?: UseQueryOptions<TypeImage, AxiosError, TypeImage, any>,
) => {
  return useQuery(['image', imageId], () => fetchImage(imageId), options);
};
