import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeCartDTO } from '../../../types';

const fetchProfileProducts = async (): Promise<TypeCartDTO> => {
  const res = await axios.get(`api/profile/products`);
  return res.data;
};

export const PrefetchProfileProducts = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['profile/products'],
    () => fetchProfileProducts(),
    {
      staleTime: 5000,
    },
  );
};

export const useGetProfileProducts = (
  options?: UseQueryOptions<TypeCartDTO, AxiosError, TypeCartDTO, any>,
) => {
  return useQuery(['profile/products'], () => fetchProfileProducts(), options);
};
