import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipe } from '../../../types';

const fetchLinkedMaterialRecipes = async (
  recipeId: number,
): Promise<TypeRecipe[]> => {
  const res = await axios.get(`api/recipe/material-recipe/${recipeId}`);
  return res.data;
};

export const PrefetchLinkedMaterialRecipe = (recipeId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    [`recipe/material-recipe`, recipeId],
    () => fetchLinkedMaterialRecipes(recipeId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLinkedMaterialRecipes = (
  recipeId: number,
  options?: UseQueryOptions<TypeRecipe[], AxiosError, TypeRecipe[], any>,
) => {
  return useQuery(
    [`recipe/material-recipe`, recipeId],
    () => fetchLinkedMaterialRecipes(recipeId),
    options,
  );
};
