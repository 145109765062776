import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMembership } from '../../../types';

const fetchMemberships = async (): Promise<TypeMembership[]> => {
  const res = await axios.get('api/public/membership');
  return res.data;
};

export const PrefetchMembership = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['membership'], fetchMemberships, {
    staleTime: 5000,
  });
};

export const useGetMemberships = (
  options?: UseQueryOptions<
    TypeMembership[],
    AxiosError,
    TypeMembership[],
    any
  >,
) => {
  return useQuery('membership', fetchMemberships, options);
};
