import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTeam } from '../../../types';

const fetchTeams = async (divisionId: number): Promise<TypeTeam[]> => {
  const res = await axios.get(`api/team/division/${divisionId}`);
  return res.data;
};

export const PrefetchTeams = (divisionId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['team/division', divisionId],
    () => fetchTeams(divisionId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetTeams = (
  divisionId: number,
  options?: UseQueryOptions<TypeTeam[], AxiosError, TypeTeam[], any>,
) => {
  return useQuery(
    ['team/division', divisionId],
    () => fetchTeams(divisionId),
    options,
  );
};
