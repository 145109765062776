import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeRecipeEvent } from '../../../types';

export const usePostRecipeEvent = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (recipeEvent: TypeRecipeEvent) =>
      axios.post(`api/recipe-event`, recipeEvent).then((res) => res.data),
    {
      onMutate: (_recipeEvent: TypeRecipeEvent) => {
        queryClient.cancelQueries('recipe-event');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('recipe-event');
        // queryClient.invalidateQueries(['recipeEvent', variables.id]).then(_ => {})
      },
    },
  );
};
