import { Box, Container, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
// import { Scheduler } from 'react-mui-scheduler';
import { AppointmentCalendarProvider } from '../../../providers';
import {
  AccountCircle as AccountCircleIcon,
  CalendarToday,
} from '@mui/icons-material';
import { Clients } from './Clients';
import { DayLimits } from './DayLimits';
import { Limits } from './Limits';
import { Tools } from './Tools';
import { Calendars } from './Calendars';
import { ScheduleAppointment } from './ScheduleAppointment';
import { AppointmentCalendar } from './AppointmentCalendar';

export const AdminAppointmentsWrapped = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    {
      key: 'calendar',
      icon: <AccountCircleIcon />,
      label: 'Calendar',
      component: AppointmentCalendar,
    },
    {
      key: 'appointments',
      icon: <AccountCircleIcon />,
      label: 'Appointments',
      component: ScheduleAppointment,
    },
    {
      key: 'clients',
      icon: <AccountCircleIcon />,
      label: 'Clients',
      component: Clients,
    },
    {
      key: 'calendars',
      icon: <CalendarToday />,
      label: 'Calendars',
      component: Calendars,
    },
    {
      key: 'day-limits',
      icon: <AccountCircleIcon />,
      label: 'Day Settings',
      component: DayLimits,
    },
    {
      key: 'limits',
      icon: <AccountCircleIcon />,
      label: 'Limits',
      component: Limits,
    },
    {
      key: 'tools',
      icon: <AccountCircleIcon />,
      label: 'Tools',
      component: Tools,
    },
  ];

  return (
    <Box mt={2}>
      <Container>
        <Tabs
          style={{
            overflow: 'initial',
            minHeight: 'initial',
            marginBottom: 10,
          }}
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          variant="scrollable"
          onChange={(event, value) => setSelectedTab(value)}
        >
          {tabs.map((tab) => {
            return <Tab key={tab.key} icon={tab.icon} label={tab.label} />;
          })}
        </Tabs>

        {tabs.map(
          (tab, index) =>
            index === selectedTab && <tab.component key={index} />,
        )}
      </Container>
    </Box>
  );
};

export const AdminAppointments = () => {
  return (
    <AppointmentCalendarProvider>
      <AdminAppointmentsWrapped />
    </AppointmentCalendarProvider>
  );
};
