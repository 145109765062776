import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeagueDate } from '../../../types';

export const useDeleteLeagueDate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (leagueDate: TypeLeagueDate) =>
      axios.delete(`api/league-date/${leagueDate.id}`),
    {
      onMutate: (leagueDate: TypeLeagueDate) => {
        queryClient.cancelQueries('league-date');

        const previousLeagueDate = queryClient.getQueryData([
          'league-date',
          leagueDate.id,
        ]);

        queryClient.setQueryData(['league-date', leagueDate.id], leagueDate);

        return { previousLeagueDate };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-date');
        queryClient.invalidateQueries('league-date/league');
      },
    },
  );
};
