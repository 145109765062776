import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTask } from '../../../types';

export const usePostTask = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (task: TypeTask) => axios.post(`api/task`, task).then((res) => res.data),
    {
      onMutate: (_task: TypeTask) => {
        queryClient.cancelQueries('task');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('task');
        queryClient.invalidateQueries('tag/type');
        queryClient.invalidateQueries('profile-tag/enabled');
        // queryClient.invalidateQueries(['task', variables.id]).then(_ => {})
      },
    },
  );
};
