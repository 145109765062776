import React, { useContext } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';
import { LeagueContext } from '../../../providers';

export const DivisionSelector = () => {
  const { divisions, selectedDivision, setSelectedDivision } =
    useContext(LeagueContext);

  return (
    <Autocomplete
      value={selectedDivision}
      id="setSelectedDivision"
      options={divisions || []}
      getOptionLabel={(option) => option.name || ''}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      onChange={(_event, newValue) => setSelectedDivision(newValue)}
      renderInput={(params) => (
        <TextField {...params} label="Division" placeholder="Division" />
      )}
    />
  );
};
