import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypePreparation } from '../../../types';

const fetchPreparation = async (
  preparationId: number,
): Promise<TypePreparation> => {
  const res = await axios.get(`api/preparation/${preparationId}`);
  return res.data;
};

export const PrefetchPreparation = (preparationId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['preparation', preparationId],
    () => fetchPreparation(preparationId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetPreparation = (
  preparationId: number,
  options?: UseQueryOptions<TypePreparation, AxiosError, TypePreparation, any>,
) => {
  return useQuery(
    ['preparation', preparationId],
    () => fetchPreparation(preparationId),
    options,
  );
};
