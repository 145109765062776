import React from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeMeetingRecap } from '../../../types';
import { Link as RouterLink } from 'react-router-dom';

export const MeetingRecapList = ({
  meetingRecaps,
  meetingRecapsAvailable,
}: {
  meetingRecaps?: TypeMeetingRecap[];
  meetingRecapsAvailable: boolean;
}) => (
  <TableContainer hidden={!meetingRecapsAvailable}>
    <Table style={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>Meeting Time</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {meetingRecaps?.map((meetingRecap) => (
          <TableRow key={meetingRecap.id}>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/meeting-recap/${meetingRecap.id}`}
                underline="none"
              >
                {meetingRecap.meetingTime}
              </Link>
            </TableCell>
          </TableRow>
        ))}
        {meetingRecaps?.length === 0 && (
          <TableRow>
            <TableCell>No MeetingRecaps Yet</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
