import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { TypeTab } from '../../types';

export interface TabViewProps {
  tabs: TypeTab[];
  title?: string;
  inputSelectedTab?: number;
  onSelectTab?: (_selectedTab: number) => void;
}

export const TabView = ({
  tabs,
  title,
  inputSelectedTab,
  onSelectTab,
}: TabViewProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (inputSelectedTab !== undefined) {
      setSelectedTab(inputSelectedTab);
      console.log('please no infinite');
    }
  }, [inputSelectedTab]);

  return (
    <Box sx={{ width: '100%' }}>
      {title && (
        <Box mt={2}>
          <Typography variant="h4">{title}</Typography>
        </Box>
      )}
      <Box mt={2}>
        <Tabs
          style={{ overflow: 'initial', minHeight: 'initial' }}
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(event, value) => {
            setSelectedTab(value);
            if (onSelectTab) {
              onSelectTab(value);
            }
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                icon={tab.icon}
                label={tab.name}
                onClick={tab.onClick}
              />
            );
          })}
        </Tabs>
        {tabs.map(
          (tab, index) =>
            index === selectedTab && (
              <Box sx={{ mt: 2 }} key={index}>
                {tab.component}
              </Box>
            ),
        )}
      </Box>
    </Box>
  );
};
