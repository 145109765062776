import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypePracticeSlotDay } from '../../../types';

const fetchPracticeSlotDaysByTeam = async (
  teamId: number,
): Promise<TypePracticeSlotDay[]> => {
  const res = await axios.get(`api/practice-slot-day/team/${teamId}`);
  return res.data;
};

export const PrefetchPracticeSlotDaysByTeam = (teamId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['practice-slot-day/team', teamId],
    () => fetchPracticeSlotDaysByTeam(teamId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetPracticeSlotDaysByTeam = (
  teamId: number,
  options?: UseQueryOptions<
    TypePracticeSlotDay[],
    AxiosError,
    TypePracticeSlotDay[],
    any
  >,
) => {
  return useQuery(
    ['practice-slot-day/team', teamId],
    () => fetchPracticeSlotDaysByTeam(teamId),
    options,
  );
};
