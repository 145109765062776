import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeBodyGroup } from '../../../types';

const fetchBodyGroup = async (bodyGroupId: number): Promise<TypeBodyGroup> => {
  const res = await axios.get(`api/body-group/${bodyGroupId}`);
  return res.data;
};

export const PrefetchBodyGroup = (bodyGroupId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['bodyGroup', bodyGroupId],
    () => fetchBodyGroup(bodyGroupId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetBodyGroup = (
  bodyGroupId: number,
  options?: UseQueryOptions<TypeBodyGroup, AxiosError, TypeBodyGroup, any>,
) => {
  return useQuery(
    ['bodyGroup', bodyGroupId],
    () => fetchBodyGroup(bodyGroupId),
    options,
  );
};
