import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import { usePostPracticeDivisionGroup } from '../../../api';
import {
  TypeDivision,
  TypeLeagueField,
  TypeLeagueTime,
  TypePracticeDivisionGroup,
} from '../../../types';
import Autocomplete from '@mui/material/Autocomplete';
import { LeagueContext, ProjectContext } from '../../../providers';

type EditPracticeDivisionGroupProps = {
  practiceDivisionGroup?: TypePracticeDivisionGroup;
};

export const PracticeDivisionGroupDetails = ({
  practiceDivisionGroup,
}: EditPracticeDivisionGroupProps) => {
  const { setSnackbar } = useContext(ProjectContext);
  const { league, divisions, fields, times } = useContext(LeagueContext);
  const [practiceDivisionGroupName, setPracticeDivisionGroupName] =
    useState<string>('');
  const [divisionList, setDivisionList] = useState<TypeDivision[]>([]);
  const [leagueFieldList, setLeagueFieldList] = useState<TypeLeagueField[]>([]);
  const [leagueTimeList, setLeagueTimeList] = useState<TypeLeagueTime[]>([]);
  const [slotsPerField, setSlotsPerField] = useState<number>(2);
  const [slotsPerTeam, setSlotsPerTeam] = useState<number>(1);
  const [enforceTargetDivision, setEnforceTargetDivision] =
    useState<boolean>(true);
  const [allowSameDayPractices, setallowSameDayPractices] =
    useState<boolean>(false);
  const practiceDivisionGroupMutation = usePostPracticeDivisionGroup();

  useEffect(() => {
    if (practiceDivisionGroup) {
      setPracticeDivisionGroupName(practiceDivisionGroup.name!!);
      setDivisionList(practiceDivisionGroup.divisionList!!);
      setLeagueFieldList(practiceDivisionGroup.leagueFieldList!!);
      setLeagueTimeList(practiceDivisionGroup.leagueTimeList!!);
      setSlotsPerField(practiceDivisionGroup.slotsPerField!!);
      setSlotsPerTeam(practiceDivisionGroup.slotsPerTeam!!);
      setEnforceTargetDivision(practiceDivisionGroup.enforceTargetDivision!!);
      setallowSameDayPractices(practiceDivisionGroup.allowSameDayPractices!!);
    }
  }, [practiceDivisionGroup]);

  const createPracticeDivisionGroup = () => {
    if (practiceDivisionGroupName === '') return;
    practiceDivisionGroupMutation.mutate(
      {
        id: practiceDivisionGroup?.id,
        name: practiceDivisionGroupName,
        slotsPerField: slotsPerField,
        slotsPerTeam: slotsPerTeam,
        enforceTargetDivision: enforceTargetDivision,
        allowSameDayPractices: allowSameDayPractices,
        divisionList: divisionList,
        leagueFieldList: leagueFieldList,
        leagueTimeList: leagueTimeList,
        league: league,
      },
      {
        onSuccess: () => setSnackbar('Practice Division Group Saved'),
      },
    );
    if (!practiceDivisionGroup) {
      setPracticeDivisionGroupName('');
      setDivisionList([]);
      setLeagueFieldList([]);
      setLeagueTimeList([]);
      setSlotsPerField(2);
      setSlotsPerTeam(1);
      setEnforceTargetDivision(true);
    }
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            id="practiceDivisionGroupName"
            value={practiceDivisionGroupName}
            label="Add Division Group"
            placeholder="Division Group Name"
            onChange={(event) =>
              setPracticeDivisionGroupName(event.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            multiple
            value={divisionList}
            id="divisions"
            options={divisions || []}
            getOptionLabel={(option) => option.name || ''}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => setDivisionList(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Divisions"
                placeholder="Divisions"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            multiple
            value={leagueFieldList}
            id="leagueFields"
            options={fields || []}
            getOptionLabel={(option) => option.name || ''}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => setLeagueFieldList(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Fields" placeholder="Fields" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            multiple
            value={leagueTimeList}
            id="leagueTimes"
            options={times || []}
            getOptionLabel={(option) => option.time || ''}
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => setLeagueTimeList(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Times" placeholder="Times" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            id="slotsPerField"
            type="number"
            value={slotsPerField}
            label="Slots Per Field"
            placeholder="Slots Per Field"
            onChange={(event) => setSlotsPerField(Number(event.target.value))}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            variant="outlined"
            id="slotsPerTeam"
            type="number"
            value={slotsPerTeam}
            label="Slots Per Team"
            placeholder="Slots Per Team"
            onChange={(event) => setSlotsPerTeam(Number(event.target.value))}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                checked={enforceTargetDivision}
                onChange={(_event, checked) =>
                  setEnforceTargetDivision(checked)
                }
              />
            }
            label="Enforce Target Division?"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                checked={allowSameDayPractices}
                onChange={(_event, checked) =>
                  setallowSameDayPractices(checked)
                }
              />
            }
            label="Allow Same Day Practices?"
            labelPlacement="start"
          />
        </Grid>
        <Grid item xs={12} sm={4} justifyContent={'flex-end'} display={'flex'}>
          <Button
            variant="contained"
            disabled={practiceDivisionGroupName === ''}
            onClick={createPracticeDivisionGroup}
          >
            {practiceDivisionGroup ? 'Update' : 'Create'} Division Group
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
