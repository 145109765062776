import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeRecipe } from '../../../types';

export const useDeleteRecipe = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (recipe: TypeRecipe) => axios.delete(`api/recipe/${recipe.id}`),
    {
      onMutate: (recipe: TypeRecipe) => {
        queryClient.cancelQueries('recipe');

        const previousRecipe = queryClient.getQueryData(['recipe', recipe.id]);

        queryClient.setQueryData(['recipe', recipe.id], recipe);

        return { previousRecipe };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('recipe');
        queryClient.invalidateQueries('recipe-group/recipe');
      },
    },
  );
};
