import { TypeProjectConfig } from '../../types/_common/ProjectConfig';
import {
  ChefHome,
  Images,
  League,
  Leagues,
  SportsCoachHome,
} from '../../components';
import { TypeAppRoute } from '../../types';
import { commonAppRoutes, commonSysAdminAppRoutes } from './index';

const sportsCommonRoutes = (): TypeAppRoute[] => [
  {
    name: 'Home',
    path: '/',
    component: ChefHome,
  },
];

const sportsCoachRoutes = (): TypeAppRoute[] => [
  {
    name: 'Teams',
    path: '/teams',
    component: SportsCoachHome,
    showInDrawer: true,
  },
];

const sportsAdminRoutes = (): TypeAppRoute[] => [
  {
    path: '/leagues',
    component: Leagues,
    name: 'Leagues',
    showInDrawer: true,
  },
  {
    name: 'Leagues',
    path: '/league/:leagueId',
    component: League,
  },
  {
    name: 'Images',
    path: '/images',
    component: Images,
    showInDrawer: true,
  },
];

export const SportsRouteConfig = (): TypeProjectConfig => ({
  cldCloudName: 'hkgss7uwm',
  cldUploadPreset: 'tvlwmfw3',
  roleProjectRoutes: {
    ANONYMOUS: {
      homeRoute: '/',
      appRoutes: [...sportsCommonRoutes(), ...commonAppRoutes()],
    },
    COACH: {
      homeRoute: '/teams',
      appRoutes: [
        ...sportsCommonRoutes(),
        ...sportsCoachRoutes(),
        ...commonAppRoutes(),
      ],
    },
    ADMIN: {
      homeRoute: '/leagues',
      appRoutes: [
        ...sportsCommonRoutes(),
        ...sportsAdminRoutes(),
        ...commonAppRoutes(),
      ],
    },
    SYSADMIN: {
      homeRoute: '/leagues',
      appRoutes: [
        ...sportsCommonRoutes(),
        ...sportsAdminRoutes(),
        ...commonAppRoutes(),
        ...commonSysAdminAppRoutes(),
      ],
    },
  },
});
