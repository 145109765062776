import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeQuestionnaireQuestion } from '../../../types';

export const usePostQuestionnaireQuestionList = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (questionnaireQuestionList: TypeQuestionnaireQuestion[]) =>
      axios
        .post(`api/questionnaire-question/list`, questionnaireQuestionList)
        .then((res) => res.data),
    {
      onMutate: (_questionnaireQuestionList: TypeQuestionnaireQuestion[]) => {
        queryClient.cancelQueries('questionnaire-question-list');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('questionnaire-question-list');
        // queryClient.invalidateQueries(['questionnaire-question', variables.id]).then(_ => {})
      },
    },
  );
};
