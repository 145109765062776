import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeAppointmentCalendarGroup } from '../../../types';

const fetchAppointmentCalendarGroups = async (): Promise<
  TypeAppointmentCalendarGroup[]
> => {
  const res = await axios.get('api/appointment-calendar-group');
  return res.data;
};

export const PrefetchAppointmentCalendarGroup = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['appointment-calendar-group'],
    fetchAppointmentCalendarGroups,
    {
      staleTime: 5000,
    },
  );
};

export const useGetAppointmentCalendarGroups = (
  options?: UseQueryOptions<
    TypeAppointmentCalendarGroup[],
    AxiosError,
    TypeAppointmentCalendarGroup[],
    any
  >,
) => {
  return useQuery(
    'appointment-calendar-group',
    fetchAppointmentCalendarGroups,
    options,
  );
};
