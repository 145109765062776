import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeQuestionnaireQuestion } from '../../../types';

const fetchQuestionnaireQuestions = async (): Promise<
  TypeQuestionnaireQuestion[]
> => {
  const res = await axios.get('api/questionnaire-question');
  return res.data;
};

export const PrefetchQuestionnaireQuestion = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['questionnaire-question'],
    fetchQuestionnaireQuestions,
    {
      staleTime: 5000,
    },
  );
};

export const useGetQuestionnaireQuestions = (
  options?: UseQueryOptions<
    TypeQuestionnaireQuestion[],
    AxiosError,
    TypeQuestionnaireQuestion[],
    any
  >,
) => {
  return useQuery(
    'questionnaire-question',
    fetchQuestionnaireQuestions,
    options,
  );
};
