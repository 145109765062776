import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostAllClientInviteEmails = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => axios.post(`api/email/invite-client`, {}).then((res) => res.data),
    {
      onMutate: () => {
        queryClient.cancelQueries('email/invite-client');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('email/invite-client');
        // queryClient.invalidateQueries(['EmailSent', variables.id]).then(_ => {})
      },
    },
  );
};
