import React, { useContext } from 'react';
import { PlannerContext } from '../../../providers';
import { Box, List } from '@mui/material';
import { ViewDocumentCard } from './ViewDocumentCard';

export const DocumentList = () => {
  const {
    documents,
    showChips,
    allFilters,
    handleSetEditDocument,
    handleSaveTask,
  } = useContext(PlannerContext);
  return (
    <Box>
      <List>
        {documents.map((item) => (
          <ViewDocumentCard
            key={`document-${item.id}`}
            sortBy={allFilters['document'].selectedField}
            showChips={showChips}
            document={item}
            onSetEditDocument={handleSetEditDocument}
            onSave={handleSaveTask}
          />
        ))}
      </List>
    </Box>
  );
};
