import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeagueTime } from '../../../types';

export const useDeleteLeagueTime = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (leagueTime: TypeLeagueTime) =>
      axios.delete(`api/league-time/${leagueTime.id}`),
    {
      onMutate: (leagueTime: TypeLeagueTime) => {
        queryClient.cancelQueries('league-time');

        const previousLeagueTime = queryClient.getQueryData([
          'league-time',
          leagueTime.id,
        ]);

        queryClient.setQueryData(['league-time', leagueTime.id], leagueTime);

        return { previousLeagueTime };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-time');
        queryClient.invalidateQueries('league-time/league');
      },
    },
  );
};
