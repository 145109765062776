import React from 'react';
import { TypeWorkflowStep, TypeWorkflowTask } from '../../../types';
import { stableSort } from '../../_common';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import { WorkflowTaskList } from './WorkflowTaskList';

export type EditWorkflowStepProps = {
  workflowStep: TypeWorkflowStep;
  stepIndex: number;
  handleRemoveStep: (_stepIndex: number) => void;
  handleSetEditWorkflowTasks: (
    _stepIndex: number,
    _tasks: TypeWorkflowTask[],
  ) => void;
};

export const EditWorkflowStep = ({
  workflowStep,
  stepIndex,
  handleRemoveStep,
  handleSetEditWorkflowTasks,
}: EditWorkflowStepProps) => {
  const tasks = stableSort<TypeWorkflowTask>(
    workflowStep.workflowTaskList || [],
    {
      property: 'position',
      isDescending: false,
    },
  );
  return (
    <Box mt={2}>
      <Card>
        <CardContent>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={6}>
              <Typography variant={'h6'}>Step {stepIndex + 1}</Typography>
            </Grid>
            <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
              <Button
                onClick={() => handleRemoveStep(stepIndex)}
                variant={'outlined'}
                color={'error'}
                endIcon={<Clear />}
              >
                Remove Step
              </Button>
            </Grid>
            <WorkflowTaskList
              stepIndex={stepIndex}
              tasks={tasks}
              handleSetEditWorkflowTasks={handleSetEditWorkflowTasks}
            />
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
