import React, { useContext, useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { AuthContext } from '../../providers';

export const IntercomInstance = () => {
  const { user, profile } = useContext(AuthContext);
  const { boot, shutdown } = useIntercom();
  useEffect(() => {
    shutdown();
    if (user && profile?.hmac) {
      if (profile.helpdesk) {
        boot({
          email: user.email || '',
          name: profile?.name || user.displayName || '',
          userHash: profile.hmac,
        });
      }
    } else {
      boot();
    }
  }, [user, profile]);
  return <></>;
};
