import React, { useContext } from 'react';
import { Alert } from '@mui/material';
import { SportsCoachContext } from '../../../providers';
import { format, parseISO } from 'date-fns';

export const PracticeSelectionOpen = () => {
  const { isSelectionOpen, selectionOpenTime, selectionCloseTime } =
    useContext(SportsCoachContext);
  const selectionOpenString = isSelectionOpen ? 'open' : 'closed';
  const startDateString = selectionOpenTime
    ? format(parseISO(selectionOpenTime), 'MMM Do h:mm a')
    : '';
  const endDateString = selectionCloseTime
    ? format(parseISO(selectionCloseTime), 'MMM Do h:mm a')
    : '';

  return (
    <Alert severity="info">
      {`Practice slot selection is ${selectionOpenString}. ${startDateString} ${endDateString}`}
    </Alert>
  );
};
