import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { TypeEmailRecipient, TypeEmailSent } from '../../../types';
import { DeleteForever, Send } from '@mui/icons-material';
import { DialogContext } from '../../../providers';
import { SendEmailDialog } from '../Dialogs';
import { FeatureFlagContext } from '../../../providers/FeatureFlagProvider';

type EmailsProps = {
  recipientList: TypeEmailRecipient[];
  sendEmail: (_emailSent: TypeEmailSent) => void;
};

export const Emails = ({ recipientList, sendEmail }: EmailsProps) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { isFeatureEnabled } = useContext(FeatureFlagContext);

  const [emailSent, setEmailSent] = useState<TypeEmailSent>({});
  const [sendDisabled, setSendDisabled] = useState<boolean>(false);

  const handleSendEmail = () => {
    sendEmail(emailSent);
    closeDialog('sendEmailDialog');
  };

  useEffect(() => {
    setEmailSent({
      ...emailSent,
      emailRecipientList: recipientList,
    });
  }, [recipientList]);

  useEffect(() => {
    setSendDisabled(
      recipientList.length === 0 || !emailSent.body || !emailSent.subject,
    );
  }, [recipientList, emailSent]);

  const handleEmailSentFieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setEmailSent({
      ...emailSent,
      [event.target.id]: event.target.value as string,
    });
  };

  const removeEmailRecipient = (id: number) => {
    setEmailSent({
      ...emailSent,
      emailRecipientList: emailSent.emailRecipientList?.filter(
        (item) => item.id !== id,
      ),
    });
  };

  const sendToRealUsers = isFeatureEnabled('SEND_EMAILS_TO_REAL_USERS');

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          id={'subject'}
          label={'Subject'}
          value={emailSent.subject || ''}
          placeholder="Subject"
          onChange={handleEmailSentFieldChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="outlined"
          id={'body'}
          label="Email Body"
          value={emailSent.body || ''}
          placeholder="Email Body"
          inputProps={{ maxLength: 4000 }}
          multiline
          maxRows={20}
          onChange={handleEmailSentFieldChange}
        />
      </Grid>
      <Grid item xs={12}>
        {sendToRealUsers ? (
          <Alert severity={'warning'}>Emails will be sent to real users.</Alert>
        ) : (
          <Alert severity={'info'}>
            Emails will be sent to Sysadmins instead of the users listed.
          </Alert>
        )}
      </Grid>
      <Grid item xs={12}>
        <Button
          variant={'contained'}
          color={'primary'}
          disabled={sendDisabled}
          onClick={() => openDialog('sendEmailDialog')}
          endIcon={<Send />}
        >
          Send Email
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'h5'}>Selected Recipients</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {emailSent.emailRecipientList?.map((recipient, index) => (
            <Fragment key={`email-recipient-${index}`}>
              <Grid item xs={5}>
                {recipient.name}
              </Grid>
              <Grid item xs={5}>
                {recipient.email}
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => removeEmailRecipient(recipient.id!!)}
                >
                  <DeleteForever />
                </IconButton>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </Grid>
      <SendEmailDialog
        recipientCount={recipientList.length}
        sendFn={handleSendEmail}
      />
    </>
  );
};
