import React, { useContext } from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeDiscussionTopic } from '../../../types';
import { DialogContext } from '../../../providers';
import { useDeleteDiscussionTopic, useGetDiscussionTopic } from '../../../api';
import { DeleteDiscussionTopicDialog } from '../../_common/Dialogs';
import { DeleteForever } from '@mui/icons-material';

export const DiscussionTopicList = ({
  discussionTopics,
  discussionTopicsAvailable,
}: {
  discussionTopics?: TypeDiscussionTopic[];
  discussionTopicsAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { mutate: deleteDiscussionTopic } = useDeleteDiscussionTopic();
  const [discussionTopicToDelete, setDiscussionTopicToDelete] =
    React.useState<TypeDiscussionTopic>();
  const { data: refetchDiscussionTopic } = useGetDiscussionTopic(
    discussionTopicToDelete?.id!!,
    {
      enabled: !!discussionTopicToDelete,
    },
  );

  const handleDeleteDiscussionTopicDialog = (
    discussionTopic?: TypeDiscussionTopic,
  ) => {
    if (discussionTopic) {
      setDiscussionTopicToDelete(discussionTopic);
      openDialog('deleteDiscussionTopicDialog');
    } else {
      setDiscussionTopicToDelete(undefined);
      closeDialog('deleteDiscussionTopicDialog');
    }
  };

  return (
    <>
      <TableContainer hidden={!discussionTopicsAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Discussion Topic</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discussionTopics?.map((discussionTopic) => (
              <TableRow key={discussionTopic.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/discussion-topic/${discussionTopic.id}`}
                    underline="none"
                  >
                    {discussionTopic.discussionTopicTitle}
                  </Link>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() =>
                      handleDeleteDiscussionTopicDialog(discussionTopic)
                    }
                  >
                    <DeleteForever />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {discussionTopics?.length === 0 && (
              <TableRow>
                <TableCell colSpan={2}>No Discussion Topics Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {refetchDiscussionTopic && (
        <DeleteDiscussionTopicDialog
          discussionTopicTitle={refetchDiscussionTopic.discussionTopicTitle!!}
          usages={refetchDiscussionTopic.usages!!}
          deleteFn={() =>
            deleteDiscussionTopic(refetchDiscussionTopic, {
              onSuccess: () => handleDeleteDiscussionTopicDialog(),
            })
          }
        />
      )}
    </>
  );
};
