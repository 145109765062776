import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeStripePrice } from '../../../types';

const fetchStripePricesByStripeProductId = async (
  stripeProductId: number,
): Promise<TypeStripePrice[]> => {
  const res = await axios.get(
    `api/stripe-price/stripe-product/${stripeProductId}`,
  );
  return res.data;
};

export const PrefetchStripePricesByStripeProductId = (
  stripeProductId: number,
) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['stripe-price/stripe-product', stripeProductId],
    () => fetchStripePricesByStripeProductId(stripeProductId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetStripePricesByStripeProductId = (
  stripeProductId: number,
  options?: UseQueryOptions<
    TypeStripePrice[],
    AxiosError,
    TypeStripePrice[],
    any
  >,
) => {
  return useQuery(
    ['stripe-price/stripe-product', stripeProductId],
    () => fetchStripePricesByStripeProductId(stripeProductId),
    options,
  );
};
