import React, { useContext } from 'react';
import {
  DocumentList,
  EditTaskCard,
  TabView,
  TaskList,
  WorkflowList,
} from '../../index';
import { PlannerContext, PlannerProvider } from '../../../providers';
import { Box, Container } from '@mui/material';
import { TagsAndFilters } from './';
import { PlannerSearch } from './PlannerSearch';
import {
  ArrowDownward,
  CheckCircleOutlined,
  Checklist,
  Event,
  FormatListNumbered,
  Notes,
} from '@mui/icons-material';
import { EditWorkflowCard } from '../Workflow';
import { EventList } from '../Event';
import { EditDocumentCard } from '../Document/EditDocumentCard';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { flexCenterCenter } from '../../../data/styles';

export const PlannerWrapper = () => {
  const { setSelectedTab, handleRefresh } = useContext(PlannerContext);

  const tabs = [
    { name: 'Tasks', icon: <Checklist />, component: <TaskList /> },
    {
      name: 'Workflows',
      icon: <FormatListNumbered />,
      component: <WorkflowList />,
    },
    {
      name: 'Events',
      icon: <Event />,
      component: <EventList />,
    },
    {
      name: 'Documents',
      icon: <Notes />,
      component: <DocumentList />,
    },
  ];

  const handleSetSelectedTab = (selectedTab: number) => {
    switch (selectedTab) {
      case 0:
        setSelectedTab('task');
        break;
      case 1:
        setSelectedTab('workflow');
        break;
      case 2:
        setSelectedTab('workflow');
        break;
      case 3:
        setSelectedTab('document');
        break;
    }
  };

  return (
    <Container sx={{ mb: 2, mt: 2 }}>
      <PullToRefresh
        onRefresh={handleRefresh}
        pullingContent={
          <Box sx={{ ...flexCenterCenter }}>
            <ArrowDownward fontSize={'large'} />
          </Box>
        }
        refreshingContent={<CheckCircleOutlined fontSize={'large'} />}
      >
        <>
          <TagsAndFilters />
          <PlannerSearch />
          <TabView tabs={tabs} onSelectTab={handleSetSelectedTab} />
          <EditTaskCard />
          <EditWorkflowCard />
          <EditDocumentCard />
        </>
      </PullToRefresh>
    </Container>
  );
};

export const Planner = () => {
  return (
    <PlannerProvider>
      <PlannerWrapper />
    </PlannerProvider>
  );
};
