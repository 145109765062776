import React, { useContext, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { usePostDivision } from '../../../api';
import { LeagueContext } from '../../../providers';

export const EditDivision = () => {
  const { league } = useContext(LeagueContext);
  const [divisionName, setDivisionName] = useState<string>('');
  const divisionMutation = usePostDivision();

  const createDivision = () => {
    if (divisionName === '') return;
    divisionMutation.mutate({
      name: divisionName,
      league: league,
    });
    setDivisionName('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDivisionName(event.target.value);
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            variant="outlined"
            id="divisionName"
            value={divisionName}
            label="Add Division"
            placeholder="Division Title"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            disabled={divisionName === ''}
            onClick={createDivision}
          >
            Create Division
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
