import React, { useContext } from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypePreparation } from '../../../types';
import { DialogContext } from '../../../providers';
import { useDeletePreparation, useGetPreparation } from '../../../api';
import { DeletePreparationDialog } from '../../_common';
import { DeleteForever } from '@mui/icons-material';

export const PreparationList = ({
  preparations,
  preparationsAvailable,
}: {
  preparations?: TypePreparation[];
  preparationsAvailable: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { mutate: deletePreparation } = useDeletePreparation();
  const [preparationToDelete, setPreparationToDelete] =
    React.useState<TypePreparation>();
  const { data: refetchPreparation } = useGetPreparation(
    preparationToDelete?.id!!,
    {
      enabled: !!preparationToDelete,
    },
  );

  const handleDeletePreparationDialog = (preparation?: TypePreparation) => {
    if (preparation) {
      setPreparationToDelete(preparation);
      openDialog('deletePreparationDialog');
    } else {
      setPreparationToDelete(undefined);
      closeDialog('deletePreparationDialog');
    }
  };

  return (
    <>
      {' '}
      <TableContainer hidden={!preparationsAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Preparation</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preparations?.map((preparation) => (
              <TableRow key={preparation.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/preparation/${preparation.id}`}
                    underline="none"
                  >
                    {preparation.preparationName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    onClick={() => handleDeletePreparationDialog(preparation)}
                  >
                    <DeleteForever />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {preparations?.length === 0 && (
              <TableRow>
                <TableCell>No Preparations Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {refetchPreparation && (
        <DeletePreparationDialog
          preparationName={refetchPreparation.preparationName!!}
          usages={refetchPreparation.usages || 0}
          deleteFn={() =>
            deletePreparation(refetchPreparation, {
              onSuccess: () => handleDeletePreparationDialog(),
            })
          }
        />
      )}
    </>
  );
};
