import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipe } from '../../../types';

const fetchRecipesByMeasurement = async (
  measurementId: number,
): Promise<TypeRecipe[]> => {
  const res = await axios.get(`api/recipe/measurement/${measurementId}`);
  return res.data;
};

export const PrefetchRecipeByMeasurement = (measurementId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['recipe/measurement', measurementId],
    () => fetchRecipesByMeasurement(measurementId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetRecipesByMeasurement = (
  measurementId: number,
  options?: UseQueryOptions<TypeRecipe[], AxiosError, TypeRecipe[], any>,
) => {
  return useQuery(
    ['recipe/measurement', measurementId],
    () => fetchRecipesByMeasurement(measurementId),
    options,
  );
};
