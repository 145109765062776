import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { TypeImage } from '../../../types';
import { useGetImage, usePostImage } from '../../../api';
import { useParams } from 'react-router-dom';
import { CloudinaryPhoto } from '../Cloudinary';

export const Image = () => {
  const { imageId } = useParams();

  const [image, setImage] = useState<TypeImage>({});
  useGetImage(Number(imageId), {
    onSuccess: (data) => setImage(data),
    refetchOnWindowFocus: false,
  });
  const imageMutation = usePostImage();

  const updateImage = () => {
    imageMutation.mutate(image);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setImage(
      Object.assign({}, image, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant="outlined"
              id="imageTitle"
              value={image?.imageTitle || ''}
              label="Enter Image Title"
              placeholder="Image Title"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant="outlined"
              id="imageUrl"
              value={image?.imageUrl || ''}
              label="Enter Image Url"
              placeholder="Image Url"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateImage}>
              Update Image
            </Button>
          </Grid>
          <Grid item xs={12} sm={4}>
            <CloudinaryPhoto publicId={image?.imageUrl} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
