import { Grid, TextField } from '@mui/material';
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useGetOrganizations, usePostOrganizationContext } from '../../../api';
import { TypeOrganization } from '../../../types';
import { Loader } from '../Loader';

export const OrganizationsDropdown = ({
  organization,
}: {
  organization?: TypeOrganization;
}) => {
  const {
    data: organizationsData,
    isLoading: organizationsLoading,
    error: organizationsError,
  } = useGetOrganizations();
  const { mutate: organizationContextMutation } = usePostOrganizationContext();
  if (organizationsLoading) {
    return <Loader />;
  } else if (organizationsError || (organizationsData?.length ?? 0) < 2) {
    return <></>;
  }

  const saveOrganizationContext = (organization: TypeOrganization) => {
    if (organization === null) return;
    organizationContextMutation(organization.id!!);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Autocomplete
          value={organization}
          id="organization"
          options={organizationsData || []}
          getOptionLabel={(option) => option.name || ''}
          filterSelectedOptions
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          onChange={(_event, newValue) =>
            newValue && saveOrganizationContext(newValue)
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Organization"
              placeholder="Organization"
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
