import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipe } from '../../../types';

const fetchRecipesByPreparation = async (
  preparationId: number,
): Promise<TypeRecipe[]> => {
  const res = await axios.get(`api/recipe/preparation/${preparationId}`);
  return res.data;
};

export const PrefetchRecipeByPreparation = (preparationId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['recipe/preparation', preparationId],
    () => fetchRecipesByPreparation(preparationId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetRecipesByPreparation = (
  preparationId: number,
  options?: UseQueryOptions<TypeRecipe[], AxiosError, TypeRecipe[], any>,
) => {
  return useQuery(
    ['recipe/preparation', preparationId],
    () => fetchRecipesByPreparation(preparationId),
    options,
  );
};
