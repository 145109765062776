import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipeEvent } from '../../../types';

const fetchRecipeEventsByRecipe = async (
  recipeId: number,
): Promise<TypeRecipeEvent[]> => {
  const res = await axios.get(`api/recipe-event/recipe/${recipeId}`);
  return res.data;
};

export const PrefetchRecipeEventsByRecipe = (recipeId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['recipe-event/recipe', recipeId],
    () => fetchRecipeEventsByRecipe(recipeId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetRecipeEventsByRecipe = (
  recipeId: number,
  options?: UseQueryOptions<
    TypeRecipeEvent[],
    AxiosError,
    TypeRecipeEvent[],
    any
  >,
) => {
  return useQuery(
    ['recipe-event/recipe', recipeId],
    () => fetchRecipeEventsByRecipe(recipeId),
    options,
  );
};
