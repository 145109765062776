import React, { createContext, useMemo, useState } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import {
  TypeProjectConfig,
  TypeProjectConfigs,
  TypeRoleProjectRoutes,
} from '../types/_common/ProjectConfig';
import { AlertColor, useMediaQuery } from '@mui/material';
import {
  ChefRouteConfig,
  CoachRouteConfig,
  FloristRouteConfig,
  ProBodySportsRouteConfig,
  SportsRouteConfig,
} from './_common';

interface SnackbarData {
  autoHideDuration: number;
  message: string;
  severity?: AlertColor;
  open: boolean;
}

type ProjectContextType = {
  appName: string;
  projectName: string;
  webUrl: string;
  roleProjectRoutes: TypeRoleProjectRoutes;
  cld?: Cloudinary;
  cldCloudName?: string;
  cldUploadPreset?: string;
  performingAction: boolean;
  setPerformingAction: (_performingAction: boolean) => void;
  mobileOpen: boolean;
  toggleMobileOpen: () => void;
  snackbarData: SnackbarData;
  setSnackbar: (_message?: string, _severity?: AlertColor) => void;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
};

export const ProjectContext = createContext<ProjectContextType>({
  appName: '',
  projectName: '',
  webUrl: '',
  roleProjectRoutes: {},
  cld: undefined,
  cldCloudName: '',
  cldUploadPreset: '',
  performingAction: false,
  setPerformingAction: (_performingAction: boolean) => {},
  mobileOpen: false,
  toggleMobileOpen: () => {},
  snackbarData: { autoHideDuration: 6000, message: '', open: false },
  setSnackbar: (_message?: string, _severity?: AlertColor) => {},
  isSmallScreen: false,
  isMediumScreen: false,
});

export type ProjectSwitcherProviderProps = {
  children: React.ReactNode;
};

export const ProjectProvider = ({ children }: ProjectSwitcherProviderProps) => {
  let roleProjectRoutes: TypeRoleProjectRoutes;
  let cld: Cloudinary | undefined;
  let cldCloudName: string | undefined = undefined;
  let cldUploadPreset: string | undefined;
  const [performingAction, setPerformingAction] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [snackbarData, setSnackbarData] = useState<SnackbarData>({
    autoHideDuration: 6000,
    message: '',
    severity: 'info',
    open: false,
  });
  const isSmallScreen = useMediaQuery('(max-width:599px)'); // Adjust the breakpoint as needed
  const isMediumScreen = useMediaQuery('(max-width:899px)'); // Adjust the breakpoint as needed
  const appName = process.env.REACT_APP_TITLE || '';
  const projectName = process.env.REACT_APP_PROJECT || '';
  const webUrl = process.env.REACT_APP_WEB_URL || '';

  const getReadingTimeMS = (text: string) =>
    Math.min(Math.max(text.split(/\W+/g).length * 300, 2000), 5000);

  const setSnackbar = (message?: string, severity?: AlertColor) => {
    if (message) {
      const duration = getReadingTimeMS(message);
      setSnackbarData({
        autoHideDuration: duration,
        message: message,
        severity: severity || 'success',
        open: true,
      });
    } else {
      setSnackbarData({
        autoHideDuration: 5000,
        message: '',
        severity: undefined,
        open: false,
      });
    }
  };

  const featureConfig: TypeProjectConfigs = {
    chef: ChefRouteConfig(),
    coach: CoachRouteConfig(),
    florist: FloristRouteConfig(),
    pbs: ProBodySportsRouteConfig(),
    sports: SportsRouteConfig(),
  };

  const config: TypeProjectConfig = useMemo(
    () => featureConfig[projectName] || {},
    [projectName],
  );

  roleProjectRoutes = config.roleProjectRoutes;
  cldCloudName = config.cldCloudName;
  cldUploadPreset = config.cldUploadPreset;

  cld = useMemo(() => {
    if (!cldCloudName) return undefined;
    return new Cloudinary({
      cloud: {
        cloudName: cldCloudName,
      },
    });
  }, [cldCloudName]);

  const snackbarDataMemo = useMemo(() => snackbarData, [snackbarData]);
  const performingActionMemo = useMemo(
    () => performingAction,
    [performingAction],
  );
  const toggleMobileOpen = () => setMobileOpen(!mobileOpen);
  const mobileOpenMemo = useMemo(() => mobileOpen, [mobileOpen]);

  return (
    <ProjectContext.Provider
      value={{
        appName,
        projectName,
        webUrl,
        roleProjectRoutes,
        cld,
        cldCloudName,
        cldUploadPreset,
        performingAction: performingActionMemo,
        setPerformingAction,
        mobileOpen: mobileOpenMemo,
        toggleMobileOpen,
        snackbarData: snackbarDataMemo,
        setSnackbar,
        isSmallScreen,
        isMediumScreen,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};
