import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMapboxAddress } from '../../../types';

const fetchAddressesByProfile = async (
  profileId: number,
): Promise<TypeMapboxAddress[]> => {
  const res = await axios.get(`api/address/profile/${profileId}`);
  return res.data;
};

export const PrefetchAddressesByProfile = (profileId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['address/profile', profileId],
    () => fetchAddressesByProfile(profileId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetAddressesByProfile = (
  profileId: number,
  options?: UseQueryOptions<
    TypeMapboxAddress[],
    AxiosError,
    TypeMapboxAddress[],
    any
  >,
) => {
  return useQuery(
    ['address/profile', profileId],
    () => fetchAddressesByProfile(profileId),
    options,
  );
};
