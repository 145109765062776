import React, { useContext } from 'react';

import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material';
import { AppearanceContext } from '../../../providers/AppearanceProvider';
import { Close } from '@mui/icons-material';

type AlertDialogProps = {
  dialogProps: DialogProps;
  title?: string;
  contentText?: string;
  children?: React.ReactNode;
  handleClose?: () => void;
  alert?: React.ReactNode;
  dismissiveAction?: React.ReactNode;
  confirmingAction?: React.ReactNode;
  secondaryAction?: React.ReactNode;
};
export const BaseDialog = ({
  dialogProps,
  title,
  contentText,
  children,
  handleClose,
  alert,
  dismissiveAction,
  confirmingAction,
  secondaryAction,
}: AlertDialogProps) => {
  const { defaultTheme } = useContext(AppearanceContext);
  const styles = {
    noTitlePadding: {
      paddingTop: defaultTheme.spacing(3),
    },
  };
  if ((contentText && children) || (!contentText && !children)) {
    console.error(
      'Dialogs should contain either contentText or children, but not both. ' +
        'If the dialog requires a more detailed explanation, consider using a dialog with a full-screen width. ' +
        'https://material.io/components/dialogs#full-screen-dialog',
    );
    return null;
  }

  return (
    <Dialog {...dialogProps}>
      <Container>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {title && (
            <DialogTitle sx={{ paddingLeft: 0, paddingRight: 0 }}>
              {title}
            </DialogTitle>
          )}
          {handleClose && (
            <IconButton aria-label="close" onClick={handleClose}>
              <Close />
            </IconButton>
          )}
        </Box>

        {contentText && (
          <DialogContent sx={title ? null : styles.noTitlePadding}>
            <DialogContentText>{contentText}</DialogContentText>
          </DialogContent>
        )}
        {children}

        {(dismissiveAction || confirmingAction || secondaryAction) && (
          <DialogActions
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-end',
              paddingLeft: 0,
              paddingRight: 0,
            }}
          >
            {alert}
            <Stack direction="row" spacing={1} mb={2}>
              {dismissiveAction}
              {secondaryAction}
              {confirmingAction}
            </Stack>
          </DialogActions>
        )}
      </Container>
    </Dialog>
  );
};
