import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeAppointmentType } from '../../../types';

const fetchAppointmentTypes = async (): Promise<TypeAppointmentType[]> => {
  const res = await axios.get('api/appointment-type');
  return res.data;
};

export const PrefetchAppointmentType = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['appointment-type'],
    fetchAppointmentTypes,
    {
      staleTime: 5000,
    },
  );
};

export const useGetAppointmentTypes = (
  options?: UseQueryOptions<
    TypeAppointmentType[],
    AxiosError,
    TypeAppointmentType[],
    any
  >,
) => {
  return useQuery('appointment-type', fetchAppointmentTypes, options);
};
