import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLeagueWeekReschedule } from '../../../types';

const fetchLeagueWeekReschedule = async (
  leagueWeekId: number,
): Promise<TypeLeagueWeekReschedule> => {
  const res = await axios.get(
    `api/league-week-reschedule/league-week/${leagueWeekId}`,
  );
  return res.data;
};

export const PrefetchLeagueWeekReschedule = (leagueWeekId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['league-week-reschedule/league-week', leagueWeekId],
    () => fetchLeagueWeekReschedule(leagueWeekId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLeagueWeekReschedule = (
  leagueWeekId: number,
  options?: UseQueryOptions<
    TypeLeagueWeekReschedule,
    AxiosError,
    TypeLeagueWeekReschedule,
    any
  >,
) => {
  return useQuery(
    ['league-week-reschedule/league-week', leagueWeekId],
    () => fetchLeagueWeekReschedule(leagueWeekId),
    options,
  );
};
