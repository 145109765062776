import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';

export const PrivacyPolicy = () => {
  return (
    <Box mt={2} mb={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2">
              Practice Makes - Privacy Policy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Last updated: 18 April 2024
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy. This Privacy
              Policy has been created with the help of the{' '}
              <Link
                href={
                  'https://www.freeprivacypolicy.com/free-privacy-policy-generator/'
                }
                target={'_blank'}
              >
                Free Privacy Policy Generator
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">Interpretation and Definitions</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Interpretation</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Definitions</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              For the purposes of this Privacy Policy:
            </Typography>
            <Typography component="ul">
              <li>
                <strong>Account</strong> means a unique account created for You
                to access our Service or parts of our Service.
              </li>
              <li>
                <strong>Affiliate</strong> means an entity that controls, is
                controlled by or is under common control with a party, where
                &quot;control&quot; means ownership of 50% or more of the
                shares, equity interest or other securities entitled to vote for
                election of directors or other managing authority.
              </li>
              <li>
                <strong>Company</strong> (referred to as either &quot;the
                Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot;
                in this Agreement) refers to Practice Makes.
              </li>
              <li>
                <strong>Cookies</strong> are small files that are placed on Your
                computer, mobile device or any other device by a website,
                containing the details of Your browsing history on that website
                among its many uses.
              </li>
              <li>
                <strong>Country</strong> refers to the United States
              </li>
              <li>
                <strong>Device</strong> means any device that can access the
                Service such as a computer, a cellphone or a digital tablet.
              </li>
              <li>
                <strong>Personal Data</strong> is any information that relates
                to an identified or identifiable individual.
              </li>
              <li>
                <strong>Service</strong> refers to the Website.
              </li>
              <li>
                <strong>Service Provider</strong> means any natural or legal
                person who processes the data on behalf of the Company. It
                refers to third-party companies or individuals employed by the
                Company to facilitate the Service, to provide the Service on
                behalf of the Company, to perform services related to the
                Service or to assist the Company in analyzing how the Service is
                used.
              </li>
              <li>
                <strong>Third-party Social Media Service</strong> refers to any
                website or any social network website through which a User can
                log in or create an account to use the Service.
              </li>
              <li>
                <strong>Usage Data</strong> refers to data collected
                automatically, either generated by the use of the Service or
                from the Service infrastructure itself (for example, the
                duration of a page visit).
              </li>
              <li>
                <strong>Website</strong> refers to Practice Makes, accessible
                from{' '}
                <Link href={'https://practicemakes.io'}>
                  https://practicemakes.io
                </Link>{' '}
                and its subdomains.
              </li>
              <li>
                <strong>You</strong> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h2">
              Collecting and Using Your Personal Data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Types of Data Collected</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Personal Data</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              While using Our Service, We may ask You to provide Us with certain
              personally identifiable information that can be used to contact or
              identify You. Personally identifiable information may include, but
              is not limited to:
            </Typography>
            <Typography component="ul">
              <li>Usage Data</li>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Usage Data</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Usage Data is collected automatically when using the Service.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Usage Data may include information such as Your Device&apos;s
              Internet Protocol address (e.g. IP address), browser type, browser
              version, the pages of our Service that You visit, the time and
              date of Your visit, the time spent on those pages, unique device
              identifiers and other diagnostic data.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Information from Third-Party Social Media Services
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              The Company allows You to create an account and log in to use the
              Service through the following Third-party Social Media Services:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="ul">
              <li>Google</li>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              If You decide to register through or otherwise grant us access to
              a Third-Party Social Media Service, We may collect Personal data
              that is already associated with Your Third-Party Social Media
              Service&apos;s account, such as Your name, Your email address,
              Your activities or Your contact list associated with that account.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              You may also have the option of sharing additional information
              with the Company through Your Third-Party Social Media
              Service&apos;s account. If You choose to provide such information
              and Personal Data, during registration or otherwise, You are
              giving the Company permission to use, share, and store it in a
              manner consistent with this Privacy Policy.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Google API Services User Data Policy
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Company&apos;s use and transfer to any other app of information
              received from Google APIs will adhere to{' '}
              <Link
                href={
                  'https://developers.google.com/terms/api-services-user-data-policy'
                }
                target={'_blank'}
              >
                Google API Services User Data Policy
              </Link>
              , including the Limited Use requirements. Specifically,
              Company&apos;s use of the Google Calendar API will be used to
              facilitate reflecting the user&apos;s Google Calendar events on
              the Company&apos;s platform, and vice versa reflecting
              Company&apos;s events on your linked Google account.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              Tracking Technologies and Cookies
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We use some tracking technologies to track the activity on Our
              Service and store certain information. Tracking technologies used
              are beacons, tags, and scripts to collect and track information
              and to improve and analyze Our Service. The technologies We use
              may include:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="ul">
              <li>
                <strong>Local Storage or Session Storage</strong> We use Local
                Storage and Session Storage to store information and to provide
                the Service. Local Storage and Session Storage are part of your
                web browser. You can choose to disable Local Storage or Session
                Storage in your web browser, but this may affect the
                functionality of the Service. We do not store any sensitive
                information in Local Storage or Session Storage.
              </li>
              <li>
                <strong>Web Beacons</strong> Certain sections of our Service and
                our emails may contain small electronic files known as web
                beacons (also referred to as clear gifs, pixel tags, and
                single-pixel gifs) that permit the Company, for example, to
                count users who have visited those pages or opened an email and
                for other related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).
              </li>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Use of Your Personal Data</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              The Company may use Personal Data for the following purposes:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="ul">
              <li>
                <strong>To provide and maintain our Service,</strong> including
                to monitor the usage of our Service.
              </li>
              <li>
                <strong>To manage Your Account,</strong> to manage Your
                registration as a user of the Service. The Personal Data You
                provide can give You access to different functionalities of the
                Service that are available to You as a registered user.
              </li>
              <li>
                <strong>For the performance of a contract,</strong> the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service.
              </li>
              <li>
                <strong>To contact You,</strong> To contact You by email,
                telephone calls, SMS, or other equivalent forms of electronic
                communication, such as a mobile application&apos;s push
                notifications regarding updates or informative communications
                related to the functionalities, products or contracted services,
                including the security updates, when necessary or reasonable for
                their implementation.
              </li>
              <li>
                <strong>To provide You,</strong> with news, special offers and
                general information about other goods, services and events which
                we offer that are similar to those that you have already
                purchased or enquired about unless You have opted not to receive
                such information.
              </li>
              <li>
                <strong>To manage Your requests,</strong> To attend and manage
                Your requests to Us.
              </li>
              <li>
                <strong>For business transfers,</strong> We may use Your
                information to evaluate or conduct a merger, divestiture,
                restructuring, reorganization, dissolution, or other sale or
                transfer of some or all of Our assets, whether as a going
                concern or as part of bankruptcy, liquidation, or similar
                proceeding, in which Personal Data held by Us about our Service
                users is among the assets transferred.
              </li>
              <li>
                <strong>For other purposes,</strong> We may use Your information
                for other purposes, such as data analysis, identifying usage
                trends, determining the effectiveness of our promotional
                campaigns and to evaluate and improve our Service, products,
                services, marketing and your experience.
              </li>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We may share Your personal information in the following
              situations:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="ul">
              <li>
                <strong>With Service Providers,</strong> We may share Your
                personal information with Service Providers to monitor and
                analyze the use of our Service, to contact You.
              </li>
              <li>
                <strong>For business transfers,</strong> We may share or
                transfer Your personal information in connection with, or during
                negotiations of, any merger, sale of Company assets, financing,
                or acquisition of all or a portion of Our business to another
                company.
              </li>
              <li>
                <strong>With Affiliates,</strong> We may share Your information
                with Our affiliates, in which case we will require those
                affiliates to honor this Privacy Policy. Affiliates include Our
                parent company and any other subsidiaries, joint venture
                partners or other companies that We control or that are under
                common control with Us.
              </li>
              <li>
                <strong>With business partners,</strong> We may share Your
                information with Our business partners to offer You certain
                products, services or promotions.
              </li>
              <li>
                <strong>With other users,</strong> when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside. If You interact with other users
                or register through a Third-Party Social Media Service, Your
                contacts on the Third-Party Social Media Service may see Your
                name, profile, pictures and description of Your activity.
                Similarly, other users will be able to view descriptions of Your
                activity, communicate with You and view Your profile.
              </li>
              <li>
                <strong>With Your consent,</strong> We may disclose Your
                personal information for any other purpose with Your consent.
              </li>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              Retention of Your Personal Data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              The Company will retain Your Personal Data only for as long as is
              necessary for the purposes set out in this Privacy Policy. We will
              retain and use Your Personal Data to the extent necessary to
              comply with our legal obligations (for example, if we are required
              to retain your data to comply with applicable laws), resolve
              disputes, and enforce our legal agreements and policies.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              The Company will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period of
              time, except when this data is used to strengthen the security or
              to improve the functionality of Our Service, or We are legally
              obligated to retain this data for longer time periods.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Transfer of Your Personal Data</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Your information, including Personal Data, is processed at the
              Company&apos;s operating offices and in any other places where the
              parties involved in the processing are located. It means that this
              information may be transferred to — and maintained on — computers
              located outside of Your state, province, country or other
              governmental jurisdiction where the data protection laws may
              differ than those from Your jurisdiction.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Your consent to this Privacy Policy followed by Your submission of
              such information represents Your agreement to that transfer.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              The Company will take all steps reasonably necessary to ensure
              that Your data is treated securely and in accordance with this
              Privacy Policy and no transfer of Your Personal Data will take
              place to an organization or a country unless there are adequate
              controls in place including the security of Your data and other
              personal information.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Delete Your Personal Data</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              You have the right to delete or request that We assist in deleting
              the Personal Data that We have collected about You.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Our Service may give You the ability to delete certain information
              about You from within the Service.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              You may update, amend, or delete Your information at any time by
              signing in to Your Account, if you have one, and visiting the
              account settings section that allows you to manage Your personal
              information. You may also contact Us to request access to,
              correct, or delete any personal information that You have provided
              to Us.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Please note, however, that We may need to retain certain
              information when we have a legal obligation or lawful basis to do
              so.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">
              Disclosure of Your Personal Data
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Business Transactions</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              If the Company is involved in a merger, acquisition or asset sale,
              Your Personal Data may be transferred. We will provide notice
              before Your Personal Data is transferred and becomes subject to a
              different Privacy Policy.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Law enforcement</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Under certain circumstances, the Company may be required to
              disclose Your Personal Data if required to do so by law or in
              response to valid requests by public authorities (e.g. a court or
              a government agency).
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Other legal requirements</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              The Company may disclose Your Personal Data in the good faith
              belief that such action is necessary to:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Security of Your Personal Data</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              The security of Your Personal Data is important to Us, but
              remember that no method of transmission over the Internet, or
              method of electronic storage is 100% secure. While We strive to
              use commercially acceptable means to protect Your Personal Data,
              We cannot guarantee its absolute security.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Children&apos;s Privacy</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Our Service does not address anyone under the age of 13. We do not
              knowingly collect personally identifiable information from anyone
              under the age of 13. If You are a parent or guardian and You are
              aware that Your child has provided Us with Personal Data, please
              contact Us. If We become aware that We have collected Personal
              Data from anyone under the age of 13 without verification of
              parental consent, We take steps to remove that information from
              Our servers.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              If We need to rely on consent as a legal basis for processing Your
              information and Your country requires consent from a parent, We
              may require Your parent&apos;s consent before We collect and use
              that information.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Links to Other Websites</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Our Service may contain links to other websites that are not
              operated by Us. If You click on a third party link, You will be
              directed to that third party&apos;s site. We strongly advise You
              to review the Privacy Policy of every site You visit.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Changes to this Privacy Policy</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We may update Our Privacy Policy from time to time. We will notify
              You of any changes by posting the new Privacy Policy on this page.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              We will let You know via email and/or a prominent notice on Our
              Service, prior to the change becoming effective and update the
              &quot;Last updated&quot; date at the top of this Privacy Policy.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Contact Us</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              If you have any questions about this Privacy Policy, You can
              contact us:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="ul">
              <li>By email: john.fonte@practicemakes.io</li>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
