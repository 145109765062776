import React, { useContext } from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { AppointmentContext } from '../../../../providers';
import { FormattedCurrency } from '../../../_common';

export const CatalogItemDetails = () => {
  const { selectedCatalogItem } = useContext(AppointmentContext);

  if (!selectedCatalogItem) {
    return <></>;
  }

  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6">Selected Package</Typography>
            <FormattedCurrency
              title={selectedCatalogItem.name}
              amount={
                selectedCatalogItem.defaultSquareCatalogItemVariation
                  .unitAmountDecimal
              }
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
