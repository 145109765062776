import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  createFilterOptions,
  FilterOptionsState,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { TypeMeasurement, TypeMeasurementConversion } from '../../../types';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetMeasurement,
  useGetMeasurements,
  useGetRecipesByMeasurement,
  usePostMeasurementConversion,
} from '../../../api';
import Autocomplete from '@mui/material/Autocomplete';
import { RecipeList } from '../Recipe';

export const Measurement = () => {
  const { measurementId } = useParams();
  const [measurement, setMeasurement] = useState<TypeMeasurement>({});
  const [destinationMeasurement, setDestinationMeasurement] =
    useState<TypeMeasurement>({});
  const [measurementConversion, setMeasurementConversion] =
    useState<TypeMeasurementConversion>({
      multiplier: 1,
    });
  useGetMeasurement(Number(measurementId), {
    onSuccess: (data) => {
      setMeasurement(data);
      setDestinationMeasurement(data);
      setMeasurementConversion({
        sourceId: data.id,
        destinationId: data.id,
        destinationName: data.measurementName,
        multiplier: 1,
      });
    },
    refetchOnWindowFocus: false,
  });
  const [measurements, setMeasurements] = useState<TypeMeasurement[]>([]);
  const measurementConversionMutation = usePostMeasurementConversion();
  useGetMeasurements({
    onSuccess: (data) => setMeasurements(data),
    refetchOnWindowFocus: false,
  });
  const { data: recipeList, isLoading: recipeListLoading } =
    useGetRecipesByMeasurement(Number(measurementId));

  const navigate = useNavigate();
  const convertMeasurement = () => {
    measurementConversionMutation.mutate(measurementConversion, {
      onSuccess: () => {
        navigate('/measurements');
      },
    });
  };

  const measurementFilter = createFilterOptions<TypeMeasurement>();

  const getMeasurementFilterOptions = (
    options: TypeMeasurement[],
    params: FilterOptionsState<TypeMeasurement>,
  ) => {
    const filtered = measurementFilter(options, params);

    const { inputValue } = params;
    console.log('inputValue', options);
    // Suggest the creation of a new value
    const isExisting = options.some(
      (option) => inputValue === option.measurementName,
    );
    if (inputValue !== '' && !isExisting) {
      filtered.push({
        measurementName: inputValue,
      });
    }

    return filtered;
  };

  const getMeasurementOptionLabel = (option: TypeMeasurement | string) => {
    // Value selected with enter, right from the input
    if (typeof option === 'string') {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.measurementName) {
      return option.measurementName;
    }
    // Regular option
    return '';
  };

  const handleMeasurementChange = (
    event: React.SyntheticEvent,
    newValue: TypeMeasurement | string | null,
  ) => {
    let changeValue: TypeMeasurement | undefined;
    if (typeof newValue === 'string') {
      changeValue = {
        measurementName: newValue,
      };
    } else if (newValue && newValue.measurementName) {
      changeValue = measurements.find(
        (measurement) =>
          newValue.measurementName === measurement.measurementName,
      );
      if (changeValue === undefined) changeValue = newValue;
    } else {
      console.log(newValue);
    }
    if (changeValue !== undefined) {
      setMeasurementConversion({
        ...measurementConversion,
        destinationId: changeValue.id,
        destinationName: changeValue.measurementName,
      });
      setDestinationMeasurement(changeValue);
    }
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" mb={2}>
              Measurement: {measurement.measurementName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              value={destinationMeasurement}
              options={measurements}
              onChange={(event, newValue) =>
                handleMeasurementChange(event, newValue)
              }
              filterOptions={getMeasurementFilterOptions}
              getOptionLabel={getMeasurementOptionLabel}
              isOptionEqualToValue={(option, value) =>
                value !== undefined &&
                option.measurementName === value.measurementName
              }
              renderOption={(props, option) => (
                <li {...props}>{option.measurementName}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Replace Measurement"
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant="outlined"
              label="Multiplier"
              value={measurementConversion.multiplier}
              type="number"
              onChange={(event) =>
                setMeasurementConversion({
                  ...measurementConversion,
                  multiplier: Number(event.target.value),
                })
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              onClick={convertMeasurement}
              disabled={
                measurementConversion.sourceId ===
                measurementConversion.destinationId
              }
            >
              Convert Measurement
            </Button>
          </Grid>
        </Grid>

        <RecipeList
          recipes={recipeList ?? []}
          recipesAvailable={!recipeListLoading}
          flatList={true}
        />
      </Container>
    </Box>
  );
};
