import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeProfile } from '../../../types';

const fetchProfile = async (): Promise<TypeProfile> => {
  const res = await axios.get(`api/profile`);
  return res.data;
};

export const PrefetchProfile = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['profile'], () => fetchProfile(), {
    staleTime: 5000,
  });
};

export const useGetProfile = (
  options?: UseQueryOptions<TypeProfile, AxiosError, TypeProfile, any>,
) => {
  return useQuery(['profile'], () => fetchProfile(), options);
};
