import React, { useContext } from 'react';
import { Button, Grid } from '@mui/material';
import { TypeDivision } from '../../../types';
import { Link } from 'react-router-dom';
import { Launch } from '@mui/icons-material';
import { ProjectContext } from '../../../providers';

export type DivisionPDFListProps = {
  divisions: TypeDivision[];
};

export const DivisionPDFList = ({ divisions }: DivisionPDFListProps) => {
  const { cld } = useContext(ProjectContext);

  if (!cld) return <></>;
  return (
    <>
      {divisions
        .filter((div) => div.matchScheduleImageReady)
        .map((division) => {
          const scheduleImageLink = cld
            .image(division?.matchScheduleImage?.imageUrl)
            .toURL();
          return (
            <Grid item key={division.id} xs={12} sm={6} md={4} lg={3}>
              <Link to={scheduleImageLink} target={'_blank'}>
                <Button variant="outlined" endIcon={<Launch />}>
                  View {division.name} Schedule
                </Button>
              </Link>
            </Grid>
          );
        })}
    </>
  );
};
