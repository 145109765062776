import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostSchedulingClients = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => axios.post(`api/scheduling/clients`).then((res) => res.data),
    {
      onMutate: () => {
        queryClient.cancelQueries('scheduling/clients');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('scheduling/clients');
      },
    },
  );
};
