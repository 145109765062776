import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLeague } from '../../../types';

const fetchLeagues = async (): Promise<TypeLeague[]> => {
  const res = await axios.get('api/league');
  return res.data;
};

export const PrefetchLeague = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['league'], fetchLeagues, {
    staleTime: 5000,
  });
};

export const useGetLeagues = (
  options?: UseQueryOptions<TypeLeague[], AxiosError, TypeLeague[], any>,
) => {
  return useQuery('league', fetchLeagues, options);
};
