import React, { useContext, useEffect, useState } from 'react';
import { DialogContext, ProjectContext } from '../../../providers';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import {
  TypePracticeSlot,
  TypePracticeSlotDay,
  TypeTeam,
} from '../../../types';
import Autocomplete from '@mui/material/Autocomplete';

type ClaimPracticeSlotDayProps = {
  practiceSlot: TypePracticeSlot;
  practiceSlotDay: TypePracticeSlotDay;
  teams: TypeTeam[];
  savePracticeSlotDay: (
    _practiceSlotDay: TypePracticeSlotDay,
    _team: TypeTeam | null,
    _disabled: boolean,
  ) => void;
};

export const ClaimPracticeSlotDayDialog = ({
  practiceSlot,
  practiceSlotDay,
  teams,
  savePracticeSlotDay,
}: ClaimPracticeSlotDayProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { claimPracticeSlotDayDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);
  const [selectedTeam, setSelectedTeam] = useState<TypeTeam | null>(null);
  const [disabled, setDisabled] = useState(false);

  const dayTimeField = `${practiceSlotDay?.leagueDay?.day} ${practiceSlotDay.leagueTime?.time} on ${practiceSlotDay.leagueField?.name}`;
  const teamDetails = practiceSlotDay?.team
    ? `${practiceSlotDay?.team?.headCoach?.name} - ${practiceSlotDay?.team?.division?.name} - ${practiceSlotDay?.team?.name}`
    : 'None';

  const isDivisionNonMatching =
    !!selectedTeam &&
    practiceSlot?.targetDivision !== undefined &&
    practiceSlot?.targetDivision?.id !== selectedTeam?.division?.id!!;

  const toggleDisabled = () => {
    setDisabled(!disabled);
    setSelectedTeam(null);
  };

  useEffect(() => {
    setSelectedTeam(practiceSlotDay?.team || null);
    setDisabled(practiceSlotDay?.disabled || false);
  }, [practiceSlotDay]);

  return (
    <Dialog open={claimPracticeSlotDayDialog}>
      <DialogTitle>Select Coach for {dayTimeField}</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>Old team: {teamDetails}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Button
              color={disabled ? 'success' : 'warning'}
              variant={'outlined'}
              onClick={toggleDisabled}
            >
              {disabled ? 'Enable' : 'Disable'} Time Slot
            </Button>
          </Grid>
          {disabled ? (
            <Grid item xs={12}>
              <Alert severity={'warning'}>
                This practice slot is disabled. Any associated team will be
                removed.
              </Alert>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Autocomplete
                value={selectedTeam}
                id="selectedPracticeAvailabilityDay"
                options={teams || []}
                getOptionLabel={(option) =>
                  `${option.headCoach?.name} - ${option?.division?.name} - ${option?.name}`
                }
                filterSelectedOptions
                isOptionEqualToValue={(option, value) =>
                  `${option.headCoach?.name} - ${option?.division?.name} - ${option?.name}` ===
                  `${value.headCoach?.name} - ${value?.division?.name} - ${value?.name}`
                }
                onChange={(_event, newValue) => setSelectedTeam(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Team"
                    placeholder="Select Team"
                  />
                )}
                sx={{ marginTop: 2 }}
              />
            </Grid>
          )}
          {isDivisionNonMatching && (
            <Grid item xs={12}>
              <Alert severity={'warning'}>
                The selected team&lsquo;s division does not match the target
                division.
              </Alert>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          onClick={() => closeDialog('claimPracticeSlotDayDialog')}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={performingAction}
          variant="contained"
          onClick={() =>
            savePracticeSlotDay(practiceSlotDay, selectedTeam, disabled)
          }
        >
          Finish
        </Button>
      </DialogActions>
    </Dialog>
  );
};
