import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeDivision } from '../../../types';

export const usePostDivision = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (division: TypeDivision) =>
      axios.post(`api/division`, division).then((res) => res.data),
    {
      onMutate: (_division: TypeDivision) => {
        queryClient.cancelQueries('division');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('division');
        queryClient.invalidateQueries('division/league');
        // queryClient.invalidateQueries(['division', variables.id]).then(_ => {})
      },
    },
  );
};
