import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostMatchScheduler = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (leagueId: number) =>
      axios
        .post(`api/match-scheduler/league/${leagueId}`, {})
        .then((res) => res.data),
    {
      onMutate: (leagueId: number) => {
        queryClient.cancelQueries('match-scheduler');

        const oldMatch = queryClient.getQueryData([
          'match-scheduler',
          leagueId,
        ]);

        return () =>
          queryClient.setQueryData(['match-scheduler', leagueId], oldMatch);
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('match-scheduler');
        queryClient.invalidateQueries('match-scheduler/validation');
        queryClient.invalidateQueries('match/division');
        queryClient.invalidateQueries('league');
        // queryClient.invalidateQueries(['match', variables.id]).then(_ => {})
      },
    },
  );
};
