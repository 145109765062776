import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeCanvaLink } from '../../../types';

const fetchCanvaLinkSubscriptionContent = async (
  membershipId: number,
): Promise<TypeCanvaLink[]> => {
  const res = await axios.get(`api/membership-dto/content/${membershipId}`);
  return res.data;
};

export const PrefetchCanvaLinkSubscriptionContent = (membershipId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['membership-dto/content', membershipId],
    () => fetchCanvaLinkSubscriptionContent(membershipId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetCanvaLinkSubscriptionContent = (
  membershipId: number,
  options?: UseQueryOptions<TypeCanvaLink[], AxiosError, TypeCanvaLink[], any>,
) => {
  return useQuery(
    ['membership-dto/content', membershipId],
    () => fetchCanvaLinkSubscriptionContent(membershipId),
    options,
  );
};
