import React, { useContext } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {
  PracticeAvailabilityList,
  PracticeSelectionOpen,
  PracticeSlotDayList,
} from '../Practice';
import { Loader } from '../../_common';
import { Link } from 'react-router-dom';
import { Launch } from '@mui/icons-material';
import { SportsCoachContext } from '../../../providers';

export const CoachTeams = () => {
  const {
    mapImageLink,
    selectedCoach,
    handleCoachChange,
    selectedTeam,
    setSelectedTeam,
    coachList,
    coachTeams,
    isCoachReady,
  } = useContext(SportsCoachContext);

  if (!isCoachReady) {
    return <Loader />;
  }

  if (coachList?.length === 0) {
    return (
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h6'} mt={2}>
              No Teams
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              You are not assigned to any teams. Please contact your league
              coordinator.
            </Alert>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant={'h6'} mt={2}>
            A message from your coordinator
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} />
        <Grid
          item
          xs={12}
          style={{
            whiteSpace: 'pre-line',
          }}
          mb={4}
        >
          <Typography variant={'body1'}>
            {selectedTeam?.division?.league?.message}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={selectedCoach}
            id="selectedCoach"
            options={coachList}
            getOptionLabel={(option) =>
              `${option.league?.name} - ${option.name}`
            }
            filterSelectedOptions
            disableClearable={selectedCoach !== null}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => handleCoachChange(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Coach" placeholder="Coach" />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Autocomplete
            value={selectedTeam}
            id="selectedTeam"
            options={coachTeams || []}
            getOptionLabel={(option) =>
              `${option.division?.name} - ${option.name}`
            }
            filterSelectedOptions
            disableClearable={selectedTeam !== null}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => setSelectedTeam(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Team" placeholder="Team" />
            )}
          />
        </Grid>
      </Grid>
      <PracticeSlotDayList />
      <Grid container spacing={2} mt={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant={'h6'}>
            {selectedTeam?.division?.name} Division Schedule
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {mapImageLink ? (
            <Link to={mapImageLink} target={'_blank'}>
              <Button
                variant="outlined"
                endIcon={<Launch />}
                disabled={!mapImageLink}
              >
                View Field Map
              </Button>
            </Link>
          ) : (
            <Button
              variant="outlined"
              disabled={!mapImageLink}
              endIcon={<Launch />}
            >
              No Field Map Available
            </Button>
          )}
        </Grid>
      </Grid>
      <Box mb={2}>
        <PracticeSelectionOpen />
      </Box>
      <PracticeAvailabilityList />
    </Container>
  );
};
