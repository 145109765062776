import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLinkDTO } from '../../../types';

export const usePostGoogleCalendarCode = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (code: TypeLinkDTO) =>
      axios
        .post(`api/public/oauth2callback/gcal`, code)
        .then((res) => res.data),
    {
      onMutate: () => {},
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('appointment/oauth2');
      },
    },
  );
};
