import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeOrder } from '../../../types';

const fetchOrders = async (): Promise<TypeOrder[]> => {
  const res = await axios.get('api/order');
  return res.data;
};

export const PrefetchOrder = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['order'], fetchOrders, {
    staleTime: 5000,
  });
};

export const useGetOrders = (
  options?: UseQueryOptions<TypeOrder[], AxiosError, TypeOrder[], any>,
) => {
  return useQuery('order', fetchOrders, options);
};
