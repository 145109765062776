import React, { Fragment, useContext, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  TypePracticeAvailabilityDay,
  TypePracticeSlotDay,
} from '../../../types';
import { SwapPracticeSlotDayDialog } from '../../_common';
import { usePostPracticeAvailabilityDaySwap } from '../../../api';
import {
  AuthContext,
  DialogContext,
  ProjectContext,
  SportsCoachContext,
} from '../../../providers';
import { formatTimeToStringTZ } from '../../_common/Utils/formatTimeToStringTZ';

export const PracticeSlotDayList = () => {
  const { setSnackbar, isSmallScreen } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { profile } = useContext(AuthContext);
  const {
    selectedTeam,
    practiceDivisionGroup,
    practiceSlotDays,
    isSelectionOpen,
  } = useContext(SportsCoachContext);

  const [selectedPracticeSlotDay, setSelectedPracticeSlotDay] =
    useState<TypePracticeSlotDay>();

  const { mutate: postPracticeAvailabilityDaySwap } =
    usePostPracticeAvailabilityDaySwap();

  const handleSwapPracticeSlotDay = (practiceSlotDay: TypePracticeSlotDay) => {
    setSelectedPracticeSlotDay(practiceSlotDay);
    openDialog('swapPracticeSlotDayDialog');
  };

  const resetSwapPracticeSlotDay = (error = false) => {
    setSelectedPracticeSlotDay(undefined);
    closeDialog('swapPracticeSlotDayDialog');
    if (error) {
      setSnackbar('Error swapping practice slot', 'error');
    } else {
      setSnackbar('Practice slot swapped');
    }
  };

  const completeSwapPracticeSlotDay = (
    practiceAvailabilityDay: TypePracticeAvailabilityDay | null,
  ) => {
    if (selectedPracticeSlotDay && practiceAvailabilityDay && selectedTeam) {
      practiceAvailabilityDay.claimWithTeam = selectedTeam;
      postPracticeAvailabilityDaySwap(
        {
          practiceSlotDayId: selectedPracticeSlotDay?.id!!,
          practiceAvailabilityDay: practiceAvailabilityDay,
        },
        {
          onSuccess: () => resetSwapPracticeSlotDay(),
          onError: () => resetSwapPracticeSlotDay(true),
        },
      );
    }
  };

  const handleCloseSwapDialog = () => {
    setSelectedPracticeSlotDay(undefined);
    closeDialog('swapPracticeSlotDayDialog');
  };

  const teamPracticeSlotDaysLarge = practiceSlotDays.length > 0 && (
    <TableContainer>
      <Table style={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell>Day</TableCell>
            <TableCell>Time</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {practiceSlotDays?.map((practiceSlotDay, index) => (
            <TableRow key={index}>
              <TableCell>{practiceSlotDay.leagueField?.name}</TableCell>
              <TableCell>{practiceSlotDay.leagueDay?.day}</TableCell>
              <TableCell>
                {formatTimeToStringTZ(
                  practiceSlotDay?.leagueTime?.time,
                  profile?.timeZone,
                )}
              </TableCell>
              <TableCell>
                <Button
                  sx={{ marginRight: 2 }}
                  variant={'contained'}
                  color={'info'}
                  disabled={!isSelectionOpen}
                  onClick={() => handleSwapPracticeSlotDay(practiceSlotDay)}
                >
                  Swap
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const teamPracticeSlotDaysSmall = practiceSlotDays.length > 0 && (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        Practice
      </Grid>
      <Grid item xs={3}>
        Actions
      </Grid>
      {practiceSlotDays?.map((practiceSlotDay, index) => (
        <Fragment key={index}>
          <Grid item xs={9}>
            {'Field '}
            {practiceSlotDay.leagueField?.name} -{' '}
            {practiceSlotDay.leagueDay?.day} -{' '}
            {formatTimeToStringTZ(
              practiceSlotDay?.leagueTime?.time,
              profile?.timeZone,
            )}
          </Grid>
          <Grid item xs={3}>
            <Button
              sx={{ marginRight: 2 }}
              variant={'contained'}
              color={'info'}
              disabled={!isSelectionOpen}
              onClick={() => handleSwapPracticeSlotDay(practiceSlotDay)}
            >
              Swap
            </Button>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );

  if (!practiceSlotDays || !selectedTeam) {
    return <></>;
  }

  return (
    <>
      <Box mt={2} mb={2}>
        <Typography variant={'h6'}>
          My Team Practices ({selectedTeam?.name})
        </Typography>
        <Typography variant={'body1'}>
          Used {practiceSlotDays?.length} of{' '}
          {practiceDivisionGroup?.slotsPerTeam} practice slots
        </Typography>
      </Box>
      {isSmallScreen ? teamPracticeSlotDaysSmall : teamPracticeSlotDaysLarge}
      <SwapPracticeSlotDayDialog
        practiceSlotDay={selectedPracticeSlotDay}
        swapPracticeSlotDay={completeSwapPracticeSlotDay}
        outerHandleCloseDialog={handleCloseSwapDialog}
      />
    </>
  );
};
