import React, { useContext } from 'react';

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
} from '@mui/material';

import { Link as LinkIcon, LinkOff as LinkOffIcon } from '@mui/icons-material';

import { authProviders } from '../../data/auth-providers';

import { authentication } from '../../services/authentication';
import { AuthContext, ProjectContext } from '../../providers';

export const LinksTab = () => {
  const { linkAuthProvider, unlinkAuthProvider } = useContext(AuthContext);
  const { performingAction } = useContext(ProjectContext);

  return (
    <List disablePadding>
      {authProviders.map((authProvider) => {
        const authProviderData = authentication.authProviderData(
          authProvider.id,
        );
        let identifier: string | null = null;

        if (authProviderData) {
          const displayName = authProviderData.displayName;
          const emailAddress = authProviderData.email;
          const phoneNumber = authProviderData.phoneNumber;

          identifier = displayName || emailAddress || phoneNumber;
        }

        return (
          <ListItem key={authProvider.id}>
            <ListItemIcon>
              <Box color={authProvider.color}>{authProvider.icon}</Box>
            </ListItemIcon>

            {authProviderData && (
              <ListItemText
                primary={authProvider.name}
                secondary={identifier}
              />
            )}

            {!authProviderData && <ListItemText primary={authProvider.name} />}

            <ListItemSecondaryAction>
              {authProviderData && (
                <Tooltip title="Unlink">
                  <div>
                    <IconButton
                      disabled={performingAction}
                      onClick={() => unlinkAuthProvider(authProvider)}
                    >
                      <LinkOffIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}

              {!authProviderData && (
                <Tooltip title="Link">
                  <div>
                    <IconButton
                      disabled={performingAction}
                      onClick={() => linkAuthProvider(authProvider)}
                    >
                      <LinkIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
};
