import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeCourse } from '../../../types';

export const usePostCourse = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (course: TypeCourse) =>
      axios.post(`api/course`, course).then((res) => res.data),
    {
      onMutate: (_course: TypeCourse) => {
        queryClient.cancelQueries('course');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('course');
        // queryClient.invalidateQueries(['course', variables.id]).then(_ => {})
      },
    },
  );
};
