import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeOrgInvite } from '../../../types';

const fetchOrgInvites = async (): Promise<TypeOrgInvite[]> => {
  const res = await axios.get('api/org-invite');
  return res.data;
};

export const PrefetchOrgInvite = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['org-invite'], fetchOrgInvites, {
    staleTime: 5000,
  });
};

export const useGetOrgInvites = (
  options?: UseQueryOptions<TypeOrgInvite[], AxiosError, TypeOrgInvite[], any>,
) => {
  return useQuery('org-invite', fetchOrgInvites, options);
};
