import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTagEntityMap } from '../../../types';

const fetchImageTagEntityMap = async (): Promise<TypeTagEntityMap> => {
  const res = await axios.get('api/image/mapped');
  return res.data;
};

export const PrefetchImageTagEntityMap = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['image/mapped'], fetchImageTagEntityMap, {
    staleTime: 5000,
  });
};

export const useGetImageTagEntityMap = (
  options?: UseQueryOptions<
    TypeTagEntityMap,
    AxiosError,
    TypeTagEntityMap,
    any
  >,
) => {
  return useQuery('image/mapped', fetchImageTagEntityMap, options);
};
