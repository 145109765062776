import React, { createContext } from 'react';
import { TypeFeatureFlag } from '../types';
import { useGetFeatureFlags } from '../api';

type FeatureFlagNames = 'SEND_EMAILS_TO_REAL_USERS';

type FeatureFlagContextType = {
  featureFlags: TypeFeatureFlag[];
  featureFlagsAvailable: boolean;
  isFeatureEnabled: (_featureFlagName: FeatureFlagNames) => boolean;
};

export const FeatureFlagContext = createContext<FeatureFlagContextType>({
  featureFlags: [],
  featureFlagsAvailable: false,
  isFeatureEnabled: (_featureFlagName: FeatureFlagNames) => false,
});

export type FeatureFlagProviderProps = {
  children: React.ReactNode;
};

export const FeatureFlagProvider = ({ children }: FeatureFlagProviderProps) => {
  const {
    data: featureFlagsData,
    isLoading: featureFlagsLoading,
    error: featureFlagsError,
  } = useGetFeatureFlags();

  const featureFlagsAvailable: boolean =
    !featureFlagsLoading &&
    !featureFlagsError &&
    featureFlagsData !== undefined;

  const isFeatureEnabled = (featureFlagName: FeatureFlagNames): boolean =>
    (featureFlagsAvailable &&
      featureFlagsData
        ?.filter((featureFlag) => featureFlag.name === featureFlagName)
        .some((featureFlag) => featureFlag.isEnabled)) ||
    false;

  return (
    <FeatureFlagContext.Provider
      value={{
        featureFlags: featureFlagsData || [],
        featureFlagsAvailable,
        isFeatureEnabled,
      }}
    >
      {children}
    </FeatureFlagContext.Provider>
  );
};
