import React, { ReactNode, useEffect, useState } from 'react';
import { EmptyState } from '../index';
import { ReactComponent as ErrorIllustration } from '../../illustrations/error.svg';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
};

export const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [errorState, setErrorState] = useState<ErrorBoundaryState>({
    hasError: false,
    error: null,
  });

  useEffect(() => {
    const errorHandler = (error: ErrorEvent) => {
      // You can log the error here or send it to an error reporting service
      console.error('Error caught by error boundary:', error);

      // Update state to indicate an error has occurred
      setErrorState({
        hasError: true,
        error: error.error,
      });
    };

    // Set up the error handler
    window.addEventListener('error', errorHandler);

    return () => {
      // Clean up the error handler when the component unmounts
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  if (errorState.hasError) {
    // You can customize the error message or UI here
    return (
      <div>
        {errorState.error && <p>{errorState.error.toString()}</p>}
        <EmptyState
          image={<ErrorIllustration />}
          title="Something went wrong"
          description="The app failed to load"
        />
      </div>
    );
  }

  return <>{children}</>;
};
