import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { TypeCanvaLink } from '../../../types';
import { useParams } from 'react-router-dom';
import {
  useGetCanvaLinkMembership,
  useGetCanvaLinkSubscriptionContent,
} from '../../../api/probodysports/CanvaLinkMembership';
import { flexCenterCenter } from '../../../data/styles';
import { AppearanceContext } from '../../../providers/AppearanceProvider';

export const CanvaLinkContent = () => {
  const { selectedTheme } = useContext(AppearanceContext);
  const { membershipId } = useParams();
  const [selectedCanvaLink, setSelectedCanvaLink] =
    useState<TypeCanvaLink | null>(null);
  const { data: canvaLinks } = useGetCanvaLinkSubscriptionContent(
    Number(membershipId),
    { enabled: !!membershipId },
  );
  const { data: membership } = useGetCanvaLinkMembership(Number(membershipId), {
    enabled: !!membershipId,
  });

  useEffect(() => {
    if (canvaLinks?.length) {
      setSelectedCanvaLink(canvaLinks[0]);
    }
  }, [canvaLinks]);

  if (!selectedCanvaLink) return <></>;

  const iframeStyles = {
    border: `2px solid ${selectedTheme === 'light' ? '#000000' : '#ffffff'}`,
    borderRadius: '8px',
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} style={flexCenterCenter}>
            <Typography variant="h6">{selectedCanvaLink.title}</Typography>
          </Grid>
          <Grid item xs={12} style={flexCenterCenter}>
            <iframe
              src={selectedCanvaLink.url}
              title="Canva Design"
              width="800"
              height="600"
              allowFullScreen
              style={iframeStyles}
            ></iframe>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h6">{membership?.name}</Typography>
              </CardContent>
            </Card>
          </Grid>
          {canvaLinks?.map((canvaLink, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Card>
                <CardContent>
                  {/* Card content here */}
                  <Typography variant="h6">{canvaLink.title}</Typography>
                  <Button
                    variant={'outlined'}
                    onClick={() => setSelectedCanvaLink(canvaLink)}
                    disabled={selectedCanvaLink.id === canvaLink.id}
                  >
                    View
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
