import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypePreparation } from '../../../types';

export const useDeletePreparation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (preparation: TypePreparation) =>
      axios.delete(`api/preparation/${preparation.id}`),
    {
      onMutate: (preparation: TypePreparation) => {
        queryClient.cancelQueries('preparation');

        const previousPreparation = queryClient.getQueryData([
          'preparation',
          preparation.id,
        ]);

        queryClient.setQueryData(['preparation', preparation.id], preparation);

        return { previousPreparation };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('preparation');
        queryClient.invalidateQueries('preparation-group/preparation');
      },
    },
  );
};
