import React from 'react';

import { Box, Button, ButtonGroup } from '@mui/material';

import { authProviders } from '../../data/auth-providers';

type AuthProviderListProps = {
  gutterBottom: boolean;
  performingAction: boolean;
  onAuthProviderClick: (_authProvider: any) => void;
};

export const AuthProviderList = ({
  gutterBottom,
  onAuthProviderClick,
  performingAction,
}: AuthProviderListProps) => {
  return (
    <Box mb={gutterBottom ? 3 : 0}>
      <ButtonGroup
        disabled={performingAction}
        fullWidth
        orientation="vertical"
        variant="outlined"
      >
        {authProviders.map((authProvider) => {
          return (
            <Button
              sx={{ color: authProvider.color }}
              key={authProvider.id}
              startIcon={authProvider.icon}
              onClick={() => onAuthProviderClick(authProvider)}
            >
              {authProvider.name}
            </Button>
          );
        })}
      </ButtonGroup>
    </Box>
  );
};
