import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { absoluteCenter } from '../../data/styles';

export const Loader = () => {
  return (
    <Box sx={{ ...absoluteCenter }}>
      <CircularProgress />
    </Box>
  );
};
