import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeQuestionnaireQuestion } from '../../../types';

export const usePostQuestionnaireQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (questionnaireQuestion: TypeQuestionnaireQuestion) =>
      axios
        .post(`api/questionnaire-question`, questionnaireQuestion)
        .then((res) => res.data),
    {
      onMutate: (_questionnaireQuestion: TypeQuestionnaireQuestion) => {
        queryClient.cancelQueries('questionnaire-question');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('questionnaire-question');
        // queryClient.invalidateQueries(['questionnaire-question', variables.id]).then(_ => {})
      },
    },
  );
};
