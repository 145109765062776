import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostProfileHelpdesk = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (helpdesk: boolean) =>
      axios
        .post(`api/profile/helpdesk/${helpdesk}`, {})
        .then((res) => res.data),
    {
      onMutate: (_helpdesk: boolean) => {
        queryClient.cancelQueries('profile/helpdesk');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
      },
    },
  );
};
