import React, { useContext, useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useGetLeagues, usePostCopyLeague } from '../../../api';
import { AuthContext, LeagueContext, ProjectContext } from '../../../providers';
import Autocomplete from '@mui/material/Autocomplete';
import { TypeCopyLeagueDTO, TypeLeague } from '../../../types';

type BooleanKeys = {
  [K in keyof TypeCopyLeagueDTO]: TypeCopyLeagueDTO[K] extends boolean
    ? K
    : never;
}[keyof TypeCopyLeagueDTO];

export const CopyLeague = () => {
  const { setSnackbar, performingAction, setPerformingAction } =
    useContext(ProjectContext);
  const { league, refreshLeagueData } = useContext(LeagueContext);
  const { profile } = useContext(AuthContext);
  const { data: leaguesData } = useGetLeagues({ enabled: !!profile });
  const { mutate: postCopyLeague } = usePostCopyLeague();
  const availableLeagues = useMemo(
    () => leaguesData?.filter((l) => l.id !== league?.id) || [],
    [leaguesData],
  );
  const [selectedLeague, setSelectedLeague] = useState<TypeLeague | null>(null);
  const [options, setOptions] = useState<Record<BooleanKeys, boolean>>({
    coaches: false,
    fields: false,
    dates: false,
    times: false,
    divisions: false,
    teams: false,
    practiceDays: false,
  });

  const handleCopyLeague = () => {
    setPerformingAction(true);
    postCopyLeague(
      {
        sourceLeagueId: selectedLeague?.id || 0,
        targetLeagueId: league?.id || 0,
        ...options,
      },
      {
        onSuccess: () => {
          refreshLeagueData();
          setSnackbar('League copied successfully');
        },
        onError: () => {
          setSnackbar('Error copying league');
        },
        onSettled: () => setPerformingAction(false),
      },
    );
  };

  const formatLabel = (key) => {
    // Split words on capital letters and convert to array
    const words = key.split(/(?=[A-Z])/).map(
      (word) =>
        // Capitalize first letter of each word
        word.charAt(0).toUpperCase() + word.slice(1),
    );

    return words.join(' ');
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            value={selectedLeague}
            id="editLeagueField"
            options={availableLeagues}
            getOptionLabel={(option) => option.name || ''}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            onChange={(_event, newValue) => setSelectedLeague(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="League" placeholder="League" />
            )}
          />
        </Grid>
        {selectedLeague && (
          <>
            <Grid item xs={12}>
              <Alert severity={'warning'}>
                This action will first remove all settings for {league?.name}{' '}
                and then copy selected settings from {selectedLeague.name}.
              </Alert>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={performingAction}
                variant={'contained'}
                onClick={() =>
                  setOptions({
                    ...options,
                    coaches: true,
                    fields: true,
                    dates: true,
                    times: true,
                    divisions: true,
                    teams: true,
                    practiceDays: true,
                  })
                }
              >
                Select all
              </Button>
              <Button
                disabled={performingAction}
                variant={'outlined'}
                onClick={() =>
                  setOptions({
                    ...options,
                    coaches: false,
                    fields: false,
                    dates: false,
                    times: false,
                    divisions: false,
                    teams: false,
                    practiceDays: false,
                  })
                }
                sx={{ ml: 2 }}
              >
                Select none
              </Button>
            </Grid>
            {Object.keys(options)?.map((key, index) => (
              <Grid item xs={12} key={`options-${index}`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={performingAction}
                      checked={options[key]}
                      onChange={(_event, checked) =>
                        setOptions({
                          ...options,
                          [key]: checked,
                          ...(key === 'teams' && checked
                            ? {
                                divisions: checked,
                              }
                            : {}),
                          ...(key === 'divisions' && !checked && options.teams
                            ? {
                                divisions: true,
                              }
                            : {}),
                        })
                      }
                    />
                  }
                  label={formatLabel(key)}
                  sx={{ width: '100%' }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                variant={'contained'}
                onClick={() => handleCopyLeague()}
                disabled={
                  performingAction ||
                  !selectedLeague ||
                  !Object.values(options).some(Boolean)
                }
              >
                Finish
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};
