// swap function for any property within type T
// works for: strings, numbers, and Dates (and is typed to accept only properties which are those types)
// could be extended for other types but would need some custom comparison function here
export const genericSwap = <T,>(object: T[], index: number, up: boolean) => {
  const result = (): T[] => {
    let index2 = up ? index - 1 : index + 1;
    if ((up && index > 0) || (!up && index < object.length - 1)) {
      let tmpIngredient = object[index];
      object[index] = object[index2];
      object[index2] = tmpIngredient;
    }
    return object;
  };

  return result();
};
