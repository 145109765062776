import React, { useContext } from 'react';

import { Button } from '@mui/material';
import { DialogContext, ProjectContext } from '../../../providers';
import { Replay, Save } from '@mui/icons-material';
import { BaseDialog } from './BaseDialog';

type TaskDialogProps = {
  title?: string;
  saveFn: () => void;
  resetFn: () => void;
  children: React.ReactNode;
};

export const TaskDialog = ({
  title,
  saveFn,
  resetFn,
  children,
}: TaskDialogProps) => {
  const { performingAction } = useContext(ProjectContext);
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { taskDialog } = dialogs;

  return (
    <BaseDialog
      title={title}
      dialogProps={{ open: taskDialog, fullScreen: true }}
      handleClose={() => closeDialog('taskDialog')}
      dismissiveAction={
        <Button
          color="primary"
          variant="outlined"
          onClick={() => closeDialog('taskDialog')}
        >
          Close
        </Button>
      }
      secondaryAction={
        <Button
          color="primary"
          variant="outlined"
          onClick={resetFn}
          endIcon={<Replay />}
        >
          Reset
        </Button>
      }
      confirmingAction={
        <Button
          color="primary"
          disabled={performingAction}
          variant="contained"
          onClick={saveFn}
          endIcon={<Save />}
        >
          Save & Close
        </Button>
      }
    >
      {children}
    </BaseDialog>
  );
};
