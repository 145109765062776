import React, { useContext, useMemo, useState } from 'react';
import { Alert, Box, Button, Grid, TextField } from '@mui/material';
import { CoachList } from './index';
import {
  useGetCoaches,
  usePostAllLeagueCoachInviteEmails,
  usePostCoach,
} from '../../../api';
import { TypeCoach } from '../../../types';
import { genericSearch, genericSort } from '../../_common';
import { LeagueContext } from '../../../providers';

export const Coaches = () => {
  const { league } = useContext(LeagueContext);
  const [coachName, setCoachName] = useState<string>('');
  const [coachEmail, setCoachEmail] = useState<string>('');
  const { data: coachesData, isLoading: coachesLoading } = useGetCoaches(
    Number(league?.id),
    { enabled: !!league?.id },
  );
  const { mutate: coachMutation } = usePostCoach();
  const { mutate: sendAllLeagueCoachInvitesMutation } =
    usePostAllLeagueCoachInviteEmails();

  const createCoach = () => {
    if (coachName === '' || coachEmail === '') return;
    coachMutation({
      name: coachName,
      email: coachEmail,
      league: league,
    });
    setCoachName('');
    setCoachEmail('');
  };

  const filteredCoaches = useMemo(() => {
    if (!coachesData) return [];
    const queryObject: object = {};
    if (coachName) queryObject['name'] = coachName;
    if (coachEmail) queryObject['email'] = coachEmail;
    return coachesData
      .filter((coach) => genericSearch<TypeCoach>(coach, queryObject))
      .sort((a, b) =>
        genericSort<TypeCoach>(a, b, {
          property: 'name',
          isDescending: false,
        }),
      );
  }, [coachName, coachEmail, coachesData]);

  const sendAllCoachInvites = () =>
    sendAllLeagueCoachInvitesMutation(league?.id!!);

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            variant="outlined"
            type={'text'}
            id="coachName"
            value={coachName}
            label="Search or Add Coach"
            placeholder="Coach Name"
            onChange={(event) => setCoachName(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            variant="outlined"
            type={'email'}
            id="coachEmail"
            value={coachEmail}
            label="Coach Email"
            placeholder="Coach Email"
            onChange={(event) => setCoachEmail(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="outlined"
            disabled={coachName === ''}
            onClick={createCoach}
          >
            Create Coach
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Alert
            severity="warning"
            action={<Button onClick={sendAllCoachInvites}>Send</Button>}
          >
            Click the button on the right to send invitations to all new
            coaches.
          </Alert>
        </Grid>
      </Grid>
      <CoachList coaches={filteredCoaches} coachesAvailable={!coachesLoading} />
    </Box>
  );
};
