import React, { useContext } from 'react';

import validate from 'validate.js';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

import { AuthProviderList } from '../AuthProviderList';

import { constraints } from '../../../data/constraints';
import { AuthContext, DialogContext } from '../../../providers';
import { AppearanceContext } from '../../../providers/AppearanceProvider';

type CustomError = {
  emailAddress: string;
  password: string;
  emailAddressConfirmation: string;
  passwordConfirmation: string;
};

type StateType = {
  performingAction: boolean;
  emailAddress: string;
  emailAddressConfirmation: string;
  password: string;
  passwordConfirmation: string;
  errors: CustomError | null;
};

const initialState: StateType = {
  performingAction: false,
  emailAddress: '',
  emailAddressConfirmation: '',
  password: '',
  passwordConfirmation: '',
  errors: null,
};

export const SignUpDialog = () => {
  const { signInWithAuthProvider, signUpEmailAddressAndPassword } =
    useContext(AuthContext);
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { defaultTheme } = useContext(AppearanceContext);
  const { signUpDialog } = dialogs;
  const [state, setState] = React.useState(initialState);
  const styles = {
    closeButton: {
      position: 'absolute',
      right: defaultTheme.spacing(1),
      top: defaultTheme.spacing(1),
    },

    icon: {
      marginRight: defaultTheme.spacing(0.5),
    },

    divider: {
      margin: 'auto',
    },

    grid: {
      marginBottom: defaultTheme.spacing(2),
    },
  };

  const signUp = () => {
    const {
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation,
    } = state;

    const errors = validate(
      {
        emailAddress: emailAddress,
        emailAddressConfirmation: emailAddressConfirmation,
        password: password,
        passwordConfirmation: passwordConfirmation,
      },
      {
        emailAddress: constraints.emailAddress,
        emailAddressConfirmation: constraints.emailAddressConfirmation,
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation,
      },
    );

    if (errors) {
      setState({
        ...state,
        errors: errors,
      });
    } else {
      setState({
        ...state,
        performingAction: true,
        errors: null,
      });
      signUpEmailAddressAndPassword(emailAddress, password);
    }
  };

  const handleKeyPress = (event) => {
    const {
      emailAddress,
      emailAddressConfirmation,
      password,
      passwordConfirmation,
    } = state;

    if (
      !emailAddress ||
      !emailAddressConfirmation ||
      !password ||
      !passwordConfirmation
    ) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      signUp();
    }
  };

  const handleExited = () => {
    setState(initialState);
  };

  const handleEmailAddressChange = (event) => {
    const emailAddress = event.target.value;

    setState({
      ...state,
      emailAddress: emailAddress,
    });
  };

  const handleEmailAddressConfirmationChange = (event) => {
    const emailAddressConfirmation = event.target.value;

    setState({
      ...state,
      emailAddressConfirmation: emailAddressConfirmation,
    });
  };

  const handlePasswordChange = (event) => {
    const password = event.target.value;

    setState({
      ...state,
      password: password,
    });
  };

  const handlePasswordConfirmationChange = (event) => {
    const passwordConfirmation = event.target.value;

    setState({
      ...state,
      passwordConfirmation: passwordConfirmation,
    });
  };

  const {
    performingAction,
    emailAddress,
    emailAddressConfirmation,
    password,
    passwordConfirmation,
    errors,
  } = state;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onKeyPress: handleKeyPress,
        onExited: handleExited,
      }}
      open={signUpDialog}
    >
      <DialogTitle>
        <Typography variant="h6" component="span">
          Sign up for an account
        </Typography>

        <Tooltip title="Close">
          <IconButton
            sx={{ ...styles.closeButton }}
            disabled={performingAction}
            onClick={() => closeDialog('signUpDialog')}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <Hidden xsDown>
        <DialogContent>
          <Grid container direction="row">
            <Grid item xs={12} sm={4}>
              <>
                <AuthProviderList
                  gutterBottom={false}
                  performingAction={performingAction}
                  onAuthProviderClick={signInWithAuthProvider}
                />
                <Typography
                  color="textSecondary"
                  variant="body2"
                  align="center"
                >
                  {`OR -->`}
                </Typography>
              </>
            </Grid>

            <Grid item xs={1}>
              <Divider sx={{ ...styles.divider }} orientation="vertical" />
            </Grid>

            <Grid item xs={7}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <TextField
                    autoComplete="email"
                    disabled={performingAction}
                    error={!!(errors && errors.emailAddress)}
                    fullWidth
                    helperText={
                      errors && errors.emailAddress ? errors.emailAddress : ''
                    }
                    label="E-mail address"
                    placeholder="john@doe.com"
                    required
                    type="email"
                    value={emailAddress}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={handleEmailAddressChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    autoComplete="email"
                    disabled={performingAction}
                    error={!!(errors && errors.emailAddressConfirmation)}
                    fullWidth
                    helperText={
                      errors && errors.emailAddressConfirmation
                        ? errors.emailAddressConfirmation
                        : ''
                    }
                    label="E-mail address confirmation"
                    placeholder="john@doe.com"
                    required
                    type="email"
                    value={emailAddressConfirmation}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={handleEmailAddressConfirmationChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    autoComplete="new-password"
                    disabled={performingAction}
                    error={!!(errors && errors.password)}
                    fullWidth
                    helperText={
                      errors && errors.password ? errors.password : ''
                    }
                    label="Password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    type="password"
                    value={password}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={handlePasswordChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    autoComplete="password"
                    disabled={performingAction}
                    error={!!(errors && errors.passwordConfirmation)}
                    fullWidth
                    helperText={
                      errors && errors.passwordConfirmation
                        ? errors.passwordConfirmation
                        : ''
                    }
                    label="Password confirmation"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    type="password"
                    value={passwordConfirmation}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={handlePasswordConfirmationChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Hidden>

      <Hidden smUp>
        <DialogContent>
          <AuthProviderList
            gutterBottom
            performingAction={performingAction}
            onAuthProviderClick={signInWithAuthProvider}
          />

          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <TextField
                autoComplete="email"
                disabled={performingAction}
                error={!!(errors && errors.emailAddress)}
                fullWidth
                helperText={
                  errors && errors.emailAddress ? errors.emailAddress : ''
                }
                label="E-mail address"
                placeholder="john@doe.com"
                required
                type="email"
                value={emailAddress}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handleEmailAddressChange}
              />
            </Grid>

            <Grid item xs>
              <TextField
                autoComplete="email"
                disabled={performingAction}
                error={!!(errors && errors.emailAddressConfirmation)}
                fullWidth
                helperText={
                  errors && errors.emailAddressConfirmation
                    ? errors.emailAddressConfirmation
                    : ''
                }
                label="E-mail address confirmation"
                placeholder="john@doe.com"
                required
                type="email"
                value={emailAddressConfirmation}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handleEmailAddressConfirmationChange}
              />
            </Grid>

            <Grid item xs>
              <TextField
                autoComplete="new-password"
                disabled={performingAction}
                error={!!(errors && errors.password)}
                fullWidth
                helperText={errors && errors.password ? errors.password : ''}
                label="Password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type="password"
                value={password}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handlePasswordChange}
              />
            </Grid>

            <Grid item xs>
              <TextField
                autoComplete="password"
                disabled={performingAction}
                error={!!(errors && errors.passwordConfirmation)}
                fullWidth
                helperText={
                  errors && errors.passwordConfirmation
                    ? errors.passwordConfirmation
                    : ''
                }
                label="Password confirmation"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type="password"
                value={passwordConfirmation}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handlePasswordConfirmationChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Hidden>

      <DialogActions>
        <Button
          color="primary"
          disabled={
            !emailAddress ||
            !emailAddressConfirmation ||
            !password ||
            !passwordConfirmation ||
            performingAction
          }
          variant="contained"
          onClick={signUp}
        >
          Sign up
        </Button>
      </DialogActions>
    </Dialog>
  );
};
