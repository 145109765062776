import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeCoach } from '../../../types';

export const useDeleteCoach = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (coach: TypeCoach) => axios.delete(`api/coach/${coach.id}`),
    {
      onMutate: (coach: TypeCoach) => {
        queryClient.cancelQueries('coach');

        const previousCoach = queryClient.getQueryData(['coach', coach.id]);

        queryClient.setQueryData(['coach', coach.id], coach);

        return { previousCoach };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('coach');
        queryClient.invalidateQueries('coach/league');
      },
    },
  );
};
