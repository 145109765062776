export const flexCenterStart = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
};

export const flexCenterCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const absoluteCenter = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
};
