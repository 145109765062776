import React, { useContext, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { LeagueDateList } from './index';
import { useGetLeagueDates, usePostLeagueDate } from '../../../api';
import { AuthContext, LeagueContext } from '../../../providers';
import { DatePicker } from '@mui/x-date-pickers';
import { formatDateToStringTZ } from '../../_common/Utils/formatDateToStringTZ';

export const LeagueDates = () => {
  const { league } = useContext(LeagueContext);
  const { profile } = useContext(AuthContext);
  const [leagueDate, setLeagueDate] = useState<Date | null>(null);
  const { data: leagueDatesData, isLoading: leagueDatesLoading } =
    useGetLeagueDates(Number(league?.id), { enabled: !!league?.id });
  const leagueDateMutation = usePostLeagueDate();

  const createLeagueDate = () => {
    if (leagueDate === null) return;
    leagueDateMutation.mutate({
      date: formatDateToStringTZ(leagueDate, profile?.timeZone, 'dateOnly'),
      league: league,
    });
    setLeagueDate(null);
  };

  const handleChange = (newValue: Date | null) => {
    if (newValue === null) return;
    setLeagueDate(newValue);
  };

  const shouldDisableDate = (date: Date) => {
    if (leagueDatesData) {
      const leagueDates = leagueDatesData.map((ld) => ld.date);
      return leagueDates.includes(date.toISOString().split('T')[0]);
    }
    return false;
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <DatePicker
            label="Search or Add Date"
            value={leagueDate}
            onChange={handleChange}
            shouldDisableDate={shouldDisableDate}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            disabled={leagueDate === null}
            onClick={createLeagueDate}
          >
            Create Date
          </Button>
        </Grid>
      </Grid>
      <LeagueDateList
        leagueDates={leagueDatesData || []}
        leagueDatesAvailable={!leagueDatesLoading}
      />
    </Box>
  );
};
