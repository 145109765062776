import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeOrgInvite } from '../../../types';

export const usePostOrgInvite = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (orgInvite: TypeOrgInvite) =>
      axios.post(`api/org-invite`, orgInvite).then((res) => res.data),
    {
      onMutate: (orgInvite: TypeOrgInvite) => {
        queryClient.cancelQueries('org-invite');

        const oldOrgInvite = queryClient.getQueryData([
          'orgInvite',
          orgInvite.id,
        ]);

        queryClient.setQueryData(['org-invite', orgInvite.id], orgInvite);

        return () =>
          queryClient.setQueryData(['org-invite', orgInvite.id], oldOrgInvite);
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('org-invite');
      },
    },
  );
};
