import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipe } from '../../../types';

const fetchRecipesByMaterial = async (
  materialId: number,
): Promise<TypeRecipe[]> => {
  const res = await axios.get(`api/recipe/material/${materialId}`);
  return res.data;
};

export const PrefetchRecipeByMaterial = (materialId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['recipe/material', materialId],
    () => fetchRecipesByMaterial(materialId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetRecipesByMaterial = (
  materialId: number,
  options?: UseQueryOptions<TypeRecipe[], AxiosError, TypeRecipe[], any>,
) => {
  return useQuery(
    ['recipe/material', materialId],
    () => fetchRecipesByMaterial(materialId),
    options,
  );
};
