import React, { useContext } from 'react';

import PbsLogo from '../../illustrations/pbs_logo.jpeg';
import { Box, Container, Divider, Grid, Typography } from '@mui/material';
import { blue, grey, lightGreen } from '@mui/material/colors';
import { AuthContext, DialogContext, ProjectContext } from '../../providers';
import { CloudinaryPhoto, Loader } from '../_common';
import { useGetImagesByCategory } from '../../api';
import { Link } from 'react-router-dom';
import { flexCenterCenter } from '../../data/styles';

export const PBSHome = () => {
  const { cldCloudName } = useContext(ProjectContext);
  const { openDialog } = useContext(DialogContext);
  const { user } = useContext(AuthContext);

  const { data: homeTopImagesData, isLoading: homeTopImagesLoading } =
    useGetImagesByCategory('home-top');
  const { data: homeBottomImagesData } = useGetImagesByCategory('home-bottom');

  const homeButton = {
    padding: '10px',
    borderRadius: '40px',
    color: '#050505',
    border: '2px solid #2EC1DC',
    width: '90%',
  };

  const boxStyles = {
    display: 'flex',
    padding: '40px 0 !important',
  };

  const inverted = {
    bgcolor: grey['900'],
    color: 'white',
  };

  const Header = () => (
    <Box sx={boxStyles}>
      <Container>
        <Grid container>
          <Grid item container xs={12} md={6}>
            <Grid item xs={4} md={4}>
              <img
                src={PbsLogo}
                alt="ProBody Sports"
                style={{ maxHeight: '120px', height: '100%' }}
              />
            </Grid>
            <Grid item xs={8} md={8}>
              <Box style={{ flexDirection: 'column', flex: 4 }}>
                <Typography
                  mt={2}
                  mb={2}
                  variant={'h4'}
                  style={{ fontFamily: 'Kaushan Script' }}
                  color={blue['900']}
                  // display={'inline'}
                >
                  ProBody Sports
                </Typography>
                <Typography
                  mt={2}
                  mb={2}
                  variant="h6"
                  style={{ fontFamily: 'Kaushan Script' }}
                  color={lightGreen['800']}
                  // display={'inline'}
                >
                  Challenging The Norm
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6}>
            <Grid item xs={4} sx={flexCenterCenter}>
              <Link
                style={{
                  ...homeButton,
                  backgroundColor: '#DBDF8D',
                  textAlign: 'center',
                }}
                to={
                  'https://www.canva.com/design/DAFxVWTViyA/YadugJ9YxtrkIqGWzS3cBg/view'
                }
                target={'_blank'}
              >
                Magazine
              </Link>
            </Grid>
            <Grid item xs={4} sx={flexCenterCenter}>
              <Link
                style={{
                  ...homeButton,
                  backgroundColor: '#53C9F2',
                  textAlign: 'center',
                }}
                to={'https://probodysports.as.me/'}
                target={'_blank'}
              >
                Schedule Now
              </Link>
            </Grid>
            <Grid item xs={4} sx={flexCenterCenter}>
              {user ? (
                <Link
                  style={{
                    ...homeButton,
                    backgroundColor: '#53C9F2',
                    textAlign: 'center',
                  }}
                  to={'/dashboard'}
                >
                  My Account
                </Link>
              ) : (
                <Box
                  style={{
                    ...homeButton,
                    backgroundColor: '#DBDF8D',
                    cursor: 'pointer',
                    textDecoration: 'underline',
                    textAlign: 'center',
                  }}
                  onClick={() => openDialog('signInDialog')}
                >
                  Log In
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              mt={2}
              mb={2}
              variant="h3"
              style={{ fontFamily: 'Kaushan Script', textAlign: 'center' }}
              color={lightGreen['800']}
            >
              Design Your Own <span style={{ color: '#0C447C' }}>Future</span>
            </Typography>
          </Grid>
          {!homeTopImagesLoading ? (
            homeTopImagesData?.map((image, index) => (
              <Grid item xs={6} md={3} style={flexCenterCenter} key={index}>
                <CloudinaryPhoto
                  cloudName={cldCloudName}
                  publicId={image.imageUrl}
                  width={250}
                  height={400}
                />
              </Grid>
            ))
          ) : (
            <Box sx={{ textAlign: 'center' }}>
              <Loader />
            </Box>
          )}
        </Grid>
      </Container>
    </Box>
  );

  const Section1 = () => (
    <Box sx={{ ...boxStyles, ...inverted }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Typography
              mt={2}
              mb={2}
              variant="h2"
              style={{ fontFamily: 'Libre Baskerville' }}
            >
              About ProBody Sports
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ ...flexCenterCenter, width: '100%' }}
          >
            <CloudinaryPhoto
              cloudName={cldCloudName}
              publicId={'aloi1jhuwxo5k1ofze3u'}
              width={600}
              height={400}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              mb={2}
              variant="body1"
              style={{ fontFamily: 'Open Sans' }}
            >
              Since 2010 ProBody Sports has been making waves in the sports
              industry by challenging traditional methods as well as trends that
              are not serving the health of the people participating in sports.
              While that gets us into toe to toe battles with some, it keeps YOU
              out of trouble.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              mb={2}
              variant="body1"
              style={{ fontFamily: 'Open Sans' }}
            >
              We are of maybe a couple of dozen people in the US that actually
              keep athletes safe, sane and on the field. We listen, we see and
              we help solve issues that are important. We also drill down to the
              why and how of the issues to truly fix the issues at hand.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const whatsTheFix = "What's the Fix?";

  const scene1Act1 =
    "You're at the gym. Need to max rep front or back squat. You load" +
    'your bar. Squat down then pull and HOLY CELESTIAL HEAVEN the' +
    "pain shoots through you like nothing you've experienced before." +
    "WTF (What's the Fix)? Because that was the THAT moment..";

  const scene1Act2 =
    "You are on the floor. Unable to move. You saw your life pass in front of you. Alas, you are still alive and you remember this crazy logo. You click on it and follow the YouTube instructions and get immediate relief. Over time, you will be perfect. PLUS - you will learn how to properly squat so it doesn't happen again. BECAUSE, you are a subscriber.";

  const Section2 = (
    <Box sx={boxStyles}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Grid item xs={12}>
              <Typography
                mt={2}
                mb={2}
                variant="h3"
                style={{ fontFamily: 'Kaushan Script', color: '#40A7E0' }}
              >
                {whatsTheFix}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                mb={2}
                variant="body1"
                style={{ fontFamily: 'Kaushan Script' }}
              >
                Scene 1 Act 1
              </Typography>
              <Typography
                mb={2}
                variant="body1"
                style={{ fontFamily: 'Open Sans' }}
              >
                {scene1Act1}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                mb={2}
                variant="body1"
                style={{ fontFamily: 'Kaushan Script' }}
              >
                Scene 1 Act 2
              </Typography>
              <Typography
                mb={2}
                variant="body1"
                style={{ fontFamily: 'Open Sans' }}
              >
                {scene1Act2}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <CloudinaryPhoto
              cloudName={cldCloudName}
              publicId={'orcwuqwr4dfjk4n2msx0'}
              width={600}
              height={328}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const moments =
    'The moments in between our reality and our dreams, goals and desires is where we decide our outcome. \n' +
    "It's in those moments where we either choose to use the tools we have to be better or we don't. \n" +
    'Either way our efforts prove our outcome.\n';
  const Section3 = (
    <Box sx={boxStyles}>
      <Container>
        <Grid container>
          {homeBottomImagesData?.map((image, index) => (
            <Grid item xs={4} style={{}} key={index}>
              <CloudinaryPhoto
                cloudName={cldCloudName}
                publicId={image.imageUrl}
                width={370}
                height={580}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Typography
              style={{
                fontFamily: 'Open Sans',
                textAlign: 'center',
                whiteSpace: 'pre-line',
              }}
            >
              {moments}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const Sponsors = (
    <Box sx={{ ...boxStyles }}>
      <Container>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography
              mt={2}
              mb={2}
              variant="h2"
              style={{ fontFamily: 'Libre Baskerville' }}
            >
              Our <span style={{ fontStyle: 'italic' }}>Partners</span>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ ...flexCenterCenter, width: '100%' }}>
            <CloudinaryPhoto
              cloudName={cldCloudName}
              publicId={'gjsftps8tb4phtsw1ihw'}
              width={1440}
              height={810}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  const Footer = (
    <Box sx={{ ...boxStyles }}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Typography mt={2} mb={2} variant="h6">
              <Link to={'/privacy-policy'}>Privacy Policy</Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );

  return (
    <Box>
      {Header()}
      <Divider />
      {Section1()}
      <Divider />
      {Section2}
      <Divider />
      {Section3}
      <Divider />
      {Sponsors}
      <Divider />
      {Footer}
    </Box>
  );
};
