import React from 'react';
import { Grid, Typography } from '@mui/material';
import { TypeInstruction } from '../../../types';

type ViewInstructionProps = {
  instruction: TypeInstruction;
  instructionIndex: number;
};

export const ViewInstruction = ({
  instruction,
  instructionIndex,
}: ViewInstructionProps) => {
  return (
    <Grid item xs={12}>
      <Typography variant={'body1'}>
        {instructionIndex + 1}. {instruction.instructionText}
      </Typography>
    </Grid>
  );
};
