import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React, { useContext, useState } from 'react';
import {
  ApplePay,
  CreditCard,
  GooglePay,
  PaymentForm,
} from 'react-square-web-payments-sdk';
import { AppointmentContext } from '../../../../providers';
import { Apple, CreditCardOff, Google } from '@mui/icons-material';

export const ConfirmPayment = () => {
  const {
    squareApplicationId,
    squareLocationId,
    completeSquareOrder,
    selectedCatalogItem,
  } = useContext(AppointmentContext);
  const [paymentMode, setPaymentMode] = useState('CARD');

  const actionsElement = (
    <ToggleButtonGroup
      value={paymentMode}
      exclusive
      onChange={(event, newPaymentMode) => setPaymentMode(newPaymentMode)}
      sx={{ mb: 2 }}
    >
      <ToggleButton value={'CARD'}>
        <CreditCardOff />
      </ToggleButton>
      <ToggleButton value={'GOOGLE'}>
        <Google />
      </ToggleButton>
      <ToggleButton value={'APPLE'}>
        <Apple />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  console.log('selectedCatalogItem', selectedCatalogItem);
  return (
    <Box mt={2}>
      {actionsElement}
      <PaymentForm
        applicationId={squareApplicationId}
        locationId={squareLocationId}
        cardTokenizeResponseReceived={({ token }) => completeSquareOrder(token)}
        createPaymentRequest={() => ({
          countryCode: 'US',
          currencyCode: 'USD',
          total: {
            amount: (
              selectedCatalogItem?.defaultSquareCatalogItemVariation
                ?.unitAmountDecimal ?? 0
            ).toString(),
            label: 'Total',
          },
        })}
      >
        {paymentMode === 'CARD' && <CreditCard />}
        {paymentMode === 'GOOGLE' && <GooglePay />}
        {paymentMode === 'APPLE' && <ApplePay />}
      </PaymentForm>
    </Box>
  );
};
