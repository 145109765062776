import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeagueWeekReschedule } from '../../../types';

export const usePostLeagueWeekReschedule = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (leagueWeekReschedule: TypeLeagueWeekReschedule) =>
      axios
        .post(`api/league-week-reschedule`, leagueWeekReschedule)
        .then((res) => res.data),
    {
      onMutate: (_leagueWeekReschedule: TypeLeagueWeekReschedule) => {
        queryClient.cancelQueries('league-week-reschedule');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-week-reschedule');
        queryClient.invalidateQueries('league-week-reschedule/league-week');
        queryClient.invalidateQueries('league-date/league');
      },
    },
  );
};
