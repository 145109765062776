import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMaterial } from '../../../types';

const fetchMaterials = async (): Promise<TypeMaterial[]> => {
  const res = await axios.get('api/material');
  return res.data;
};

export const PrefetchMaterial = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['material'], fetchMaterials, {
    staleTime: 5000,
  });
};

export const useGetMaterials = (
  options?: UseQueryOptions<TypeMaterial[], AxiosError, TypeMaterial[], any>,
) => {
  return useQuery('material', fetchMaterials, options);
};
