import React, { useContext, useState } from 'react';

import validate from 'validate.js';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Hidden,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { Close as CloseIcon } from '@mui/icons-material';

import { AuthProviderList } from '../AuthProviderList';

import { constraints } from '../../../data/constraints';
import { AuthContext, DialogContext, ProjectContext } from '../../../providers';
import { AppearanceContext } from '../../../providers/AppearanceProvider';

type CustomError = {
  emailAddress: string;
  password: string;
};

export const SignInDialog = () => {
  const {
    signInWithAuthProvider,
    signInEmailAndPassword,
    resetPassword,
    sendSignInLink,
  } = useContext(AuthContext);
  const { defaultTheme } = useContext(AppearanceContext);
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { signInDialog } = dialogs;
  const { performingAction, setPerformingAction } = useContext(ProjectContext);
  const [errors, setErrors] = useState<CustomError | null>(null);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');

  const styles = {
    closeButton: {
      position: 'absolute',
      right: defaultTheme.spacing(1),
      top: defaultTheme.spacing(1),
    },

    divider: {
      margin: 'auto',
    },
  };

  const getSignInButton = () => {
    if (emailAddress && !password) {
      return (
        <Button
          color="primary"
          disabled={!emailAddress || performingAction}
          variant="contained"
          onClick={() => sendSignInLinkToEmail()}
        >
          Send sign-in link
        </Button>
      );
    }

    return (
      <Button
        color="primary"
        disabled={!emailAddress || performingAction}
        variant="contained"
        onClick={() => signIn()}
      >
        Sign in
      </Button>
    );
  };

  const resetPasswordLocal = () => {
    const errors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      },
    );

    if (errors) {
      setErrors(errors);
    } else {
      setPerformingAction(true);
      resetPassword(emailAddress);
    }
  };

  const signIn = () => {
    const errors = validate(
      {
        emailAddress: emailAddress,
        password: password,
      },
      {
        emailAddress: constraints.emailAddress,
        password: constraints.password,
      },
    );

    if (errors) {
      setErrors(errors);
    } else {
      setErrors(null);
      setPerformingAction(true);
      signInEmailAndPassword(emailAddress, password);
    }
  };

  const sendSignInLinkToEmail = () => {
    const errors = validate(
      {
        emailAddress: emailAddress,
      },
      {
        emailAddress: constraints.emailAddress,
      },
    );

    if (errors) {
      setErrors(errors);
      return;
    }

    setErrors(null);
    sendSignInLink(emailAddress);
  };

  const handleKeyPress = (event) => {
    if (!emailAddress && !password) {
      return;
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      if (emailAddress && !password) {
        sendSignInLinkToEmail();
      } else {
        signIn();
      }
    }
  };

  const handleExited = () => {
    setEmailAddress('');
    setPassword('');
    setErrors(null);
  };

  const handleEmailAddressChange = (event) => {
    setEmailAddress(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      TransitionProps={{
        onKeyPress: handleKeyPress,
        onExited: handleExited,
      }}
      open={signInDialog}
    >
      <DialogTitle>
        <Typography variant="h6" component="span">
          Sign in to your account
        </Typography>

        <Tooltip title="Close">
          <IconButton
            sx={{ ...styles.closeButton }}
            disabled={performingAction}
            onClick={() => closeDialog('signInDialog')}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <Hidden smDown>
          <Grid container direction="row">
            <Grid item xs={12} sm={4}>
              <AuthProviderList
                gutterBottom={false}
                performingAction={performingAction}
                onAuthProviderClick={signInWithAuthProvider}
              />
            </Grid>

            <Grid item xs={1}>
              <Divider sx={{ ...styles.divider }} orientation="vertical" />
            </Grid>

            <Grid item xs={7}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs>
                  <TextField
                    autoComplete="email"
                    disabled={performingAction}
                    error={!!(errors && errors.emailAddress)}
                    fullWidth
                    helperText={
                      errors && errors.emailAddress ? errors.emailAddress : ''
                    }
                    label="E-mail address"
                    placeholder="john@doe.com"
                    required
                    type="email"
                    value={emailAddress}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={handleEmailAddressChange}
                  />
                </Grid>

                <Grid item xs>
                  <TextField
                    autoComplete="current-password"
                    disabled={performingAction}
                    error={!!(errors && errors.password)}
                    fullWidth
                    helperText={
                      errors && errors.password ? errors.password : ''
                    }
                    label="Password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    type="password"
                    value={password}
                    variant="outlined"
                    InputLabelProps={{ required: false }}
                    onChange={handlePasswordChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Hidden>

        <Hidden smUp>
          <AuthProviderList
            gutterBottom
            performingAction={performingAction}
            onAuthProviderClick={signInWithAuthProvider}
          />

          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <TextField
                autoComplete="email"
                disabled={performingAction}
                error={!!(errors && errors.emailAddress)}
                fullWidth
                helperText={
                  errors && errors.emailAddress ? errors.emailAddress[0] : ''
                }
                label="E-mail address"
                placeholder="john@doe.com"
                required
                type="email"
                value={emailAddress}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handleEmailAddressChange}
              />
            </Grid>

            <Grid item xs>
              <TextField
                autoComplete="current-password"
                disabled={performingAction}
                error={!!(errors && errors.password)}
                fullWidth
                helperText={errors && errors.password ? errors.password[0] : ''}
                label="Password"
                placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                required
                type="password"
                value={password}
                variant="outlined"
                InputLabelProps={{ required: false }}
                onChange={handlePasswordChange}
              />
            </Grid>
          </Grid>
        </Hidden>
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          disabled={!emailAddress || performingAction}
          variant="outlined"
          onClick={resetPasswordLocal}
        >
          Reset password
        </Button>

        {getSignInButton()}
      </DialogActions>
    </Dialog>
  );
};
