import React, { useContext, useState } from 'react';
import { TypeProfileTag, TypeTag, TypeTagEntityMap } from '../../../types';
import {
  useGetProfileTags,
  useGetTagsByType,
  useGetTaskTagEntityMap,
  useGetWorkflowTagEntityMap,
  usePostProfileTagMap,
  usePostTag,
} from '../../../api';
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { AuthContext, ProjectContext } from '../../../providers';
import { CheckBox, CheckBoxOutlineBlank, Edit } from '@mui/icons-material';

export const TaskTags = () => {
  const { profile } = useContext(AuthContext);
  const { performingAction, setPerformingAction, setSnackbar } =
    useContext(ProjectContext);
  const [taskTagMap, setTaskTagMap] = useState<TypeTagEntityMap>({});
  const [workflowTagMap, setWorkflowTagMap] = useState<TypeTagEntityMap>({});
  const [tags, setTags] = useState<TypeTag[]>([]);
  const [profileTags, setProfileTags] = useState<TypeProfileTag[]>([]);
  const [editTag, setEditTag] = useState<TypeTag | null>(null);
  useGetTaskTagEntityMap({
    onSuccess: (data) => {
      setTaskTagMap(data);
    },
  });
  useGetWorkflowTagEntityMap({
    onSuccess: (data) => {
      setWorkflowTagMap(data);
    },
  });
  const [tagName, setTagName] = useState<string>('');
  const { isLoading: tagsLoading } = useGetTagsByType('task', {
    onSuccess: (data) => {
      setTags(data);
    },
  });
  useGetProfileTags({
    enabled: !!profile,
    onSuccess: (data) => {
      setProfileTags(data);
    },
  });
  const { mutate: tagMutation } = usePostTag();
  const { mutate: profileTagMutation } = usePostProfileTagMap();

  const createTag = () => {
    if (tagName === '') return;
    setPerformingAction(true);
    tagMutation(
      {
        ...editTag,
        name: tagName,
        type: 'task',
        organization: profile?.organizationContext,
      },
      {
        onSuccess: () => {
          setSnackbar(`Tag ${editTag ? 'Saved' : 'Created'}: ${tagName}`);
          setTagName('');
          setEditTag(null);
        },
        onSettled: () => {
          setPerformingAction(false);
        },
      },
    );
  };

  const handleToggleTag = (tag: TypeTag) => {
    setPerformingAction(true);
    const enabling = !profileTags.find((pt) => pt.tag?.id === tag.id)?.enabled;
    profileTagMutation(
      {
        [tag.id!!]: enabling,
      },
      {
        onSuccess: () => {
          setSnackbar(`Tag ${enabling ? 'Enabled' : 'Disabled'}: ${tag.name}`);
        },
        onSettled: () => {
          setPerformingAction(false);
        },
      },
    );
  };

  const handleSetEditTag = (tag: TypeTag) => {
    setEditTag(tag);
    setTagName(tag.name ?? '');
  };

  if (tagsLoading) {
    return <></>;
  }
  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="tagName"
              value={tagName}
              label="Search or Add Tag"
              placeholder="Tag Name"
              onChange={(e) => setTagName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={tagName === '' || performingAction}
              onClick={createTag}
            >
              {editTag ? `Save` : `Create`} Tag
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          <Table style={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Tag Name</TableCell>
                <TableCell>Tasks</TableCell>
                <TableCell>Workflows</TableCell>
                <TableCell>Enable/Disable</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag) => {
                const isEnabled =
                  profileTags.find((pt) => pt.tag?.id === tag.id)?.enabled ??
                  false;
                return (
                  <TableRow key={tag.id}>
                    <TableCell>
                      <IconButton onClick={() => handleSetEditTag(tag)}>
                        <Edit />
                      </IconButton>{' '}
                      {tag.name}
                    </TableCell>
                    <TableCell>{taskTagMap[tag.id!!] || 0}</TableCell>
                    <TableCell>{workflowTagMap[tag.id!!] || 0}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => handleToggleTag(tag)}
                        variant={isEnabled ? 'contained' : 'outlined'}
                        endIcon={
                          isEnabled ? <CheckBox /> : <CheckBoxOutlineBlank />
                        }
                      >
                        Enabled
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              {tags.length === 0 && (
                <TableRow>
                  <TableCell colSpan={4}>No Tags Yet</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};
