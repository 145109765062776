import React, { useContext } from 'react';
import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeCourse } from '../../../types';
import { DeleteForever } from '@mui/icons-material';
import { useDeleteCourse } from '../../../api';
import { DialogContext } from '../../../providers';
import { DeleteCourseDialog } from '../Dialogs';

export const CourseList = ({
  courses,
  coursesAvailable,
  flatList = false,
}: {
  courses: TypeCourse[];
  coursesAvailable: boolean;
  flatList?: boolean;
}) => {
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { mutate: deleteCourse } = useDeleteCourse();
  const [courseToDelete, setCourseToDelete] = React.useState<TypeCourse>();

  const handleDeleteCourseDialog = (course?: TypeCourse) => {
    if (course) {
      setCourseToDelete(course);
      openDialog('deleteCourseDialog');
    } else {
      setCourseToDelete(undefined);
      closeDialog('deleteCourseDialog');
    }
  };

  return (
    <>
      <TableContainer hidden={!coursesAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Course</TableCell>
              {!flatList && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {courses?.map((course) => (
              <TableRow key={course.id}>
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={`/course/${course.id}`}
                    underline="none"
                  >
                    {course.name}
                  </Link>
                </TableCell>
                {!flatList && (
                  <TableCell>
                    <Button
                      variant="outlined"
                      onClick={() => handleDeleteCourseDialog(course)}
                    >
                      <DeleteForever />
                    </Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
            {(!courses || courses.length === 0) && (
              <TableRow>
                <TableCell colSpan={4}>No Courses Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {courseToDelete && (
        <DeleteCourseDialog
          courseName={courseToDelete?.name!!}
          deleteFn={() =>
            deleteCourse(courseToDelete, {
              onSuccess: () => handleDeleteCourseDialog(),
            })
          }
        />
      )}
    </>
  );
};
