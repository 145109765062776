import React, { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { usePostFeatureFlag } from '../../../api';
import { Loader } from '../Loader';
import { TypeFeatureFlag } from '../../../types';
import { FeatureFlagList } from './FeatureFlagList';
import { ProjectContext } from '../../../providers';
import { FeatureFlagContext } from '../../../providers/FeatureFlagProvider';

export const FeatureFlags = () => {
  const { featureFlags, featureFlagsAvailable } =
    useContext(FeatureFlagContext);
  const { setSnackbar } = useContext(ProjectContext);
  const { mutate: featureFlagMutation } = usePostFeatureFlag();

  const updateFeatureFlag = (featureFlag: TypeFeatureFlag) => {
    featureFlagMutation(featureFlag, {
      onSuccess: () => setSnackbar(`${featureFlag.name} Updated Successfully`),
    });
  };

  if (!featureFlagsAvailable) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      <Box m={2}>
        <Typography variant="h6">Feature Flags</Typography>
      </Box>
      <FeatureFlagList
        featureFlags={featureFlags}
        featureFlagsAvailable={featureFlagsAvailable}
        updateFeatureFlag={updateFeatureFlag}
      />
    </Box>
  );
};
