import React from 'react';
import { Chip } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { OverridableStringUnion } from '@mui/types';
import { ChipPropsColorOverrides } from '@mui/material/Chip/Chip';

type DateViewProps = {
  title: string;
  date?: string;
  color?: OverridableStringUnion<
    | 'default'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning',
    ChipPropsColorOverrides
  >;
};
export const DateView = ({ title, date, color }: DateViewProps) => {
  const formattedDate = date
    ? format(parseISO(date), 'MMM do hh:mmaaa')
    : 'Not Set';
  return (
    <Chip label={`${title}: ${formattedDate}`} color={color ?? 'default'} />
  );
};
