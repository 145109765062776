import { Grid } from '@mui/material';
import React from 'react';

export const DayLimits = () => {
  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}></Grid>
    </Grid>
  );
};
