import React from 'react';
import {
  useGetQuestionnaireQuestions,
  usePostQuestionnaireQuestionList,
} from '../../../api';
import { TypeQuestionnaireQuestion } from '../../../types';
import { QuestionnaireQuestionList } from './QuestionnaireQuestionList';
import { Box, Container } from '@mui/material';

export const Questionnaire = () => {
  const [questionnaireQuestions, setQuestionnaireQuestions] = React.useState<
    TypeQuestionnaireQuestion[]
  >([]);
  const { refetch: refetchQuestionnaireQuestions } =
    useGetQuestionnaireQuestions({
      onSuccess: (data) => setQuestionnaireQuestions(data),
      refetchOnWindowFocus: false,
    });
  const questionListMutation = usePostQuestionnaireQuestionList();

  const handleSetQuestionnaireQuestionList = (newQuestionnaireQuestionList) => {
    questionListMutation.mutate(newQuestionnaireQuestionList, {
      onSuccess: () => {
        refetchQuestionnaireQuestions();
      },
    });
  };

  return (
    <Box m={2}>
      <Container>
        <QuestionnaireQuestionList
          questionnaireQuestionList={questionnaireQuestions}
          setQuestionnaireQuestionList={handleSetQuestionnaireQuestionList}
          refetch={refetchQuestionnaireQuestions}
        />
      </Container>
    </Box>
  );
};
