import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  TypePracticeAvailability,
  TypePracticeAvailabilityDay,
} from '../../../types';
import { usePostPracticeAvailabilityDay } from '../../../api';
import {
  AuthContext,
  DialogContext,
  ProjectContext,
  SportsCoachContext,
} from '../../../providers';
import { ClaimPracticeAvailabilityDialog } from '../../_common';
import { formatTimeToStringTZ } from '../../_common/Utils/formatTimeToStringTZ';

export const PracticeAvailabilityList = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { openDialog, closeDialog } = useContext(DialogContext);
  const { profile } = useContext(AuthContext);
  const {
    selectedTeam,
    leagueDays,
    practiceSlotAvailability,
    practiceSlotDays,
    practiceDivisionGroup,
    isSelectionOpen,
    hasPracticeOnSameDay,
    hasPracticeAtSameDayTime,
  } = useContext(SportsCoachContext);
  const [claimPracticeAvailabilityDay, setClaimPracticeAvailabilityDay] =
    useState<TypePracticeAvailabilityDay>();
  const { mutate: claimPractice } = usePostPracticeAvailabilityDay();

  const isAtCapacity =
    practiceSlotDays &&
    practiceDivisionGroup &&
    practiceSlotDays.length >= practiceDivisionGroup.slotsPerTeam!!;
  const allowSameDay = practiceDivisionGroup?.allowSameDayPractices;

  const handleClaimPracticeAvailability = (
    practiceAvailabilityDay?: TypePracticeAvailabilityDay,
  ) => {
    setClaimPracticeAvailabilityDay(practiceAvailabilityDay);
    openDialog('claimPracticeAvailabilityDialog');
  };

  const resetClaimPracticeAvailability = (error = false) => {
    setClaimPracticeAvailabilityDay(undefined);
    closeDialog('claimPracticeAvailabilityDialog');
    if (error) {
      setSnackbar('Error claiming practice slot', 'error');
    } else {
      setSnackbar('Practice slot claimed');
    }
  };

  const completeClaimPracticeAvailabilityDay = () => {
    if (claimPracticeAvailabilityDay && selectedTeam) {
      claimPracticeAvailabilityDay.claimWithTeam = selectedTeam;
      claimPractice(claimPracticeAvailabilityDay, {
        onSuccess: () => resetClaimPracticeAvailability(),
        onError: () => resetClaimPracticeAvailability(true),
      });
    }
  };

  const renderPracticeAvailabilityDay = (
    practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => {
    const isDayFull = practiceAvailabilityDay.isFull;
    const isClosed = !isSelectionOpen;
    const isClaimed = hasPracticeAtSameDayTime(practiceAvailabilityDay);
    const hasSameDay = hasPracticeOnSameDay(practiceAvailabilityDay);

    let text: string;
    let variant: 'contained' | 'outlined' = 'contained';
    let isButtonDisabled = true;

    if (isClaimed) {
      text = 'Claimed';
    } else if (isClosed) {
      text = 'Closed';
    } else if (isAtCapacity) {
      text = 'At Capacity';
    } else if (isDayFull) {
      text = 'Full';
    } else if (hasSameDay && !allowSameDay) {
      text = 'Has Same Day';
    } else {
      text = 'Claim';
      isButtonDisabled = false;
    }

    return (
      <Button
        variant={variant}
        onClick={() => handleClaimPracticeAvailability(practiceAvailabilityDay)}
        disabled={isButtonDisabled}
      >
        {text}
      </Button>
    );
  };

  const renderPracticeAvailability = (
    practiceAvailability: TypePracticeAvailability,
    index: number,
  ) => (
    <TableRow key={index}>
      <TableCell>
        {formatTimeToStringTZ(
          practiceAvailability?.leagueTime?.time,
          profile?.timeZone,
        )}
      </TableCell>
      {practiceAvailability?.practiceAvailabilityDayList?.map(
        (practiceAvailabilityDay, index) => (
          <TableCell key={index}>
            {renderPracticeAvailabilityDay(practiceAvailabilityDay)}
          </TableCell>
        ),
      )}
    </TableRow>
  );

  if (!practiceSlotAvailability.length || !leagueDays.length) {
    return <></>;
  }

  if (isAtCapacity) {
    return (
      <Box mb={2}>
        <Alert severity={'success'}>
          All practice times have been selected for this team. To make changes,
          use the swap actions above.
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <TableContainer>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Time</TableCell>
              {leagueDays
                ?.filter((day) => day.enabled)
                .map((day, index) => (
                  <TableCell key={`day-${index}`}>{day.day}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {practiceSlotAvailability?.map((practiceAvailability, index) =>
              renderPracticeAvailability(practiceAvailability, index),
            )}
            {Object.values(practiceSlotAvailability).length === 0 && (
              <TableRow>
                <TableCell colSpan={7}>No Practice Slots Yet</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {selectedTeam && claimPracticeAvailabilityDay && (
        <ClaimPracticeAvailabilityDialog
          practiceAvailabilityDay={claimPracticeAvailabilityDay}
          completeFn={completeClaimPracticeAvailabilityDay}
        />
      )}
    </>
  );
};
