import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import { AuthContext, LeagueContext, ProjectContext } from '../../../providers';
import { Save } from '@mui/icons-material';
import { usePostLeague } from '../../../api';
import { Loader } from '../../_common';
import { ClearIcon, DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { formatDateToStringTZ } from '../../_common/Utils/formatDateToStringTZ';
import { parseISOSafe } from '../../_common/Utils/parseISOSafe';

export const LeagueDetails = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { isLeagueReady, league, weeks } = useContext(LeagueContext);
  const [leagueName, setLeagueName] = useState<string | null>(null);
  const [leagueMessage, setLeagueMessage] = useState<string | null>(null);
  const [leagueWeekNumber, setLeagueWeekNumber] = useState<number>(0);
  const [selectionOpenDateTime, setSelectionOpenDateTime] =
    useState<Date | null>(null);
  const [selectionCloseDateTime, setSelectionCloseDateTime] =
    useState<Date | null>(null);
  const [leagueStartDate, setLeagueStartDate] = useState<Date | null>(null);
  const [leagueEndDate, setLeagueEndDate] = useState<Date | null>(null);
  const { mutate: leagueMutation } = usePostLeague();

  useEffect(() => {
    console.log('weeks', weeks);
    if (weeks.length > 0) {
      setLeagueWeekNumber(weeks.length);
    }
  }, [weeks]);

  const handleSaveLeague = () =>
    leagueMutation(
      {
        ...league,
        message: leagueMessage || '',
        selectionOpenDateTime: formatDateToStringTZ(
          selectionOpenDateTime,
          profile?.timeZone,
        ),
        selectionCloseDateTime: formatDateToStringTZ(
          selectionCloseDateTime,
          profile?.timeZone,
        ),
        startDate: formatDateToStringTZ(leagueStartDate, profile?.timeZone),
        endDate: formatDateToStringTZ(leagueEndDate, profile?.timeZone),
        weeks: leagueWeekNumber,
      },
      {
        onSuccess: () => setSnackbar('League Details Updated Successfully'),
      },
    );

  useEffect(() => {
    if (league) {
      setLeagueName(league.name || '');
      setLeagueMessage(league.message || '');
      setLeagueStartDate(parseISOSafe(league.startDate));
      setLeagueEndDate(parseISOSafe(league.endDate));
      setSelectionOpenDateTime(parseISOSafe(league.selectionOpenDateTime));
      setSelectionCloseDateTime(parseISOSafe(league.selectionCloseDateTime));
    }
  }, [league]);

  if (!isLeagueReady) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            id="name"
            value={leagueName || ''}
            label="League Name"
            placeholder="League Name"
            onChange={(event) => setLeagueName(event.target.value as string)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <DatePicker
              desktopModeMediaQuery={'@media (min-width: 800px)'}
              sx={{ width: '100%' }}
              label="Start Date"
              value={leagueStartDate}
              onChange={(newValue) => {
                setLeagueStartDate(newValue);
              }}
            />
            <IconButton onClick={() => setLeagueStartDate(null)}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <DatePicker
              desktopModeMediaQuery={'@media (min-width: 800px)'}
              sx={{ width: '100%' }}
              label="End Date"
              value={leagueEndDate}
              onChange={(newValue) => {
                setLeagueEndDate(newValue);
              }}
            />
            <IconButton onClick={() => setLeagueEndDate(null)}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <DateTimePicker
              desktopModeMediaQuery={'@media (min-width: 800px)'}
              sx={{ width: '100%' }}
              label="Practice Selection Open"
              value={selectionOpenDateTime}
              onChange={(newValue) => {
                setSelectionOpenDateTime(newValue);
              }}
            />
            <IconButton onClick={() => setSelectionOpenDateTime(null)}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <DateTimePicker
              desktopModeMediaQuery={'@media (min-width: 800px)'}
              sx={{ width: '100%' }}
              label="Practice Selection Close"
              value={selectionCloseDateTime}
              onChange={(newValue) => {
                setSelectionCloseDateTime(newValue);
              }}
            />
            <IconButton onClick={() => setSelectionCloseDateTime(null)}>
              <ClearIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            type={'number'}
            id="leagueWeekNumber"
            value={leagueWeekNumber}
            label="League Weeks"
            placeholder="Weeks"
            onChange={(event) =>
              setLeagueWeekNumber(Number(event.target.value))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="outlined"
            id={'message'}
            label="League Message"
            value={leagueMessage || ''}
            placeholder="League Message"
            inputProps={{ maxLength: 4000 }}
            multiline
            maxRows={20}
            onChange={(event) => setLeagueMessage(event.target.value as string)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={handleSaveLeague}
            endIcon={<Save />}
          >
            Save League Details
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
