import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeAnnouncement } from '../../../types';

export const useDeleteAnnouncement = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (announcement: TypeAnnouncement) =>
      axios.delete(`api/announcement/${announcement.id}`),
    {
      onMutate: (announcement: TypeAnnouncement) => {
        queryClient.cancelQueries('announcement');

        const previousAnnouncement = queryClient.getQueryData([
          'announcement',
          announcement.id,
        ]);

        queryClient.setQueryData(
          ['announcement', announcement.id],
          announcement,
        );

        return { previousAnnouncement };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('announcement');
      },
    },
  );
};
