import React, { useContext } from 'react';

import { Avatar, Typography } from '@mui/material';

import { AccountCircle as AccountCircleIcon } from '@mui/icons-material';

import { authentication } from '../../services/authentication';
import { AuthContext } from '../../providers';

const styles = {
  nameInitials: {
    cursor: 'default',
  },
};

export const UserAvatar = () => {
  const { user } = useContext(AuthContext);

  if (!user) {
    return <AccountCircleIcon />;
  }

  const photoUrl = user.photoURL;

  if (photoUrl) {
    return <Avatar alt="Avatar" src={photoUrl} />;
  }

  const nameInitials = authentication.getNameInitials({
    ...user,
  });

  if (nameInitials) {
    return (
      <Avatar alt="Avatar">
        <Typography sx={styles.nameInitials}>{nameInitials}</Typography>
      </Avatar>
    );
  }

  return <AccountCircleIcon />;
};
