import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeSwapPracticeSlotDay } from '../../../types';

export const usePostPracticeAvailabilityDaySwap = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (swap: TypeSwapPracticeSlotDay) =>
      axios
        .post(
          `api/practice-availability/swap/${swap.practiceSlotDayId}`,
          swap.practiceAvailabilityDay,
        )
        .then((res) => res.data),
    {
      onMutate: (_swap: TypeSwapPracticeSlotDay) => {
        queryClient.cancelQueries('practice-availability');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('practice-availability');
        queryClient.invalidateQueries('practice-availability/team');
        queryClient.invalidateQueries('practice-slot-day/team');
      },
    },
  );
};
