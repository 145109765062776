import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypePracticeDivisionGroup } from '../../../types';

const fetchPracticeDivisionGroups = async (
  leagueId: number,
): Promise<TypePracticeDivisionGroup[]> => {
  const res = await axios.get(`api/practice-division-group/league/${leagueId}`);
  return res.data;
};

export const PrefetchPracticeDivisionGroup = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['practice-division-group/league', leagueId],
    () => fetchPracticeDivisionGroups(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetPracticeDivisionGroups = (
  leagueId: number,
  options?: UseQueryOptions<
    TypePracticeDivisionGroup[],
    AxiosError,
    TypePracticeDivisionGroup[],
    any
  >,
) => {
  return useQuery(
    ['practice-division-group/league', leagueId],
    () => fetchPracticeDivisionGroups(leagueId),
    options,
  );
};
