import React, { useContext } from 'react';
import { BaseDialog } from './BaseDialog';
import { AuthContext, DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';
import { TypePracticeAvailabilityDay } from '../../../types';
import { formatTimeToStringTZ } from '../Utils/formatTimeToStringTZ';

type ClaimPracticeAvailabilityProps = {
  practiceAvailabilityDay?: TypePracticeAvailabilityDay;
  completeFn: () => void;
};

export const ClaimPracticeAvailabilityDialog = ({
  practiceAvailabilityDay,
  completeFn,
}: ClaimPracticeAvailabilityProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { profile } = useContext(AuthContext);
  const { claimPracticeAvailabilityDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const dayTimeField = `${
    practiceAvailabilityDay?.leagueDay?.day
  } at ${formatTimeToStringTZ(
    practiceAvailabilityDay?.leagueTime?.time,
    profile?.timeZone,
  )}`;
  const props = {
    title: `Claim ${dayTimeField}?`,
    contentText: `This will use one of your available practice slots.`,
    dismissiveAction: (
      <Button
        color="primary"
        onClick={() => closeDialog('claimPracticeAvailabilityDialog')}
      >
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={completeFn}
      >
        Claim
      </Button>
    ),
  };

  if (!practiceAvailabilityDay) return <></>;

  return (
    <BaseDialog
      dialogProps={{ open: claimPracticeAvailabilityDialog }}
      {...props}
    />
  );
};
