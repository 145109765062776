import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeOrganization } from '../../../types';

const fetchAllOrganizations = async (): Promise<TypeOrganization[]> => {
  const res = await axios.get('api/organization/all');
  return res.data;
};

export const PrefetchAllOrganization = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['organization/all'],
    fetchAllOrganizations,
    {
      staleTime: 5000,
    },
  );
};

export const useGetAllOrganizations = (
  options?: UseQueryOptions<
    TypeOrganization[],
    AxiosError,
    TypeOrganization[],
    any
  >,
) => {
  return useQuery('organization/all', fetchAllOrganizations, options);
};
