import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeagueField } from '../../../types';

export const usePostLeagueField = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (leagueField: TypeLeagueField) =>
      axios.post(`api/league-field`, leagueField).then((res) => res.data),
    {
      onMutate: (_leagueField: TypeLeagueField) => {
        queryClient.cancelQueries('league-field');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league-field');
        queryClient.invalidateQueries('league-field/league');
        // queryClient.invalidateQueries(['field', variables.id]).then(_ => {})
      },
    },
  );
};
