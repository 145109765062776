import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeOrder } from '../../../types';

export const useDeleteOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (order: TypeOrder) =>
      axios.delete(`api/order/${order.id}`).then((res) => res.data),
    {
      onMutate: (_order: TypeOrder) => {
        queryClient.cancelQueries('order');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('order');
        // queryClient.invalidateQueries(['order', variables.id]).then(_ => {})
      },
    },
  );
};
