import React, { useState } from 'react';
import { TypeQuestionnaireAnswer } from '../../../types';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  useGetQuestionnaireAnswer,
  usePostQuestionnaireAnswer,
  usePostQuestionnaireQuestionList,
} from '../../../api';
import { QuestionnaireQuestionList } from './QuestionnaireQuestionList';

export const QuestionnaireAnswer = () => {
  const { answerId } = useParams();
  const [answer, setQuestionnaireAnswer] = useState<TypeQuestionnaireAnswer>(
    {},
  );
  const { refetch: refetchAnswer } = useGetQuestionnaireAnswer(
    Number(answerId),
    {
      onSuccess: (data) => setQuestionnaireAnswer(data),
      refetchOnWindowFocus: false,
    },
  );
  const answerMutation = usePostQuestionnaireAnswer();
  const questionListMutation = usePostQuestionnaireQuestionList();

  const updateQuestionnaireAnswer = () => {
    answerMutation.mutate(answer);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setQuestionnaireAnswer(
      Object.assign({}, answer, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  const handleSetQuestionnaireQuestionList = (newQuestionnaireQuestionList) => {
    questionListMutation.mutate(newQuestionnaireQuestionList, {
      onSuccess: () => {
        refetchAnswer();
      },
    });
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="answerText"
              value={answer.answerText || ''}
              label="Enter Answer Text"
              placeholder="Answer Text"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateQuestionnaireAnswer}>
              Update Answer
            </Button>
          </Grid>
          <Grid item xs={12}>
            <QuestionnaireQuestionList
              questionnaireQuestionList={answer.questionnaireQuestionList}
              setQuestionnaireQuestionList={handleSetQuestionnaireQuestionList}
              questionnaireAnswerId={answer.id}
              refetch={refetchAnswer}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
