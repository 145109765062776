import React, { useContext, useState } from 'react';

import {
  Dialog,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  Close as CloseIcon,
  Link as LinkIcon,
  Security as SecurityIcon,
} from '@mui/icons-material';

import SwipeableViews from 'react-swipeable-views';

import { LinksTab, SecurityTab } from '../../index';
import { DialogContext } from '../../../providers';
import { AppearanceContext } from '../../../providers/AppearanceProvider';

const tabs = [
  {
    key: 'links',
    icon: <LinkIcon />,
    label: 'Links',
  },

  {
    key: 'security',
    icon: <SecurityIcon />,
    label: 'Security',
  },
];

export const SettingsDialog = () => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { defaultTheme } = useContext(AppearanceContext);
  const { settingsDialog } = dialogs;
  const [selectedTab, setSelectedTab] = useState(0);

  const styles = {
    closeButton: {
      position: 'absolute',
      right: defaultTheme.spacing(1),
      top: defaultTheme.spacing(1),
    },

    tabs: {
      display: 'initial',
    },
  };

  const handleExited = () => {
    setSelectedTab(0);
  };

  const handleTabChange = (event, value) => {
    setSelectedTab(value);
  };

  const handleIndexChange = (index: any) => {
    setSelectedTab(index);
  };

  return (
    <Dialog
      open={settingsDialog}
      TransitionProps={{
        onExited: handleExited,
      }}
    >
      <DialogTitle>
        <Typography variant="h6" component="span">
          Settings
        </Typography>

        <Tooltip title="Close">
          <IconButton
            sx={{ ...styles.closeButton }}
            onClick={() => closeDialog('settingsDialog')}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </DialogTitle>

      <Tabs
        sx={{ ...styles.tabs }}
        style={{ overflow: 'initial', minHeight: 'initial' }}
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        variant="fullWidth"
        onChange={handleTabChange}
      >
        {tabs.map((tab) => {
          return <Tab key={tab.key} icon={tab.icon} label={tab.label} />;
        })}
      </Tabs>

      <SwipeableViews index={selectedTab} onChangeIndex={handleIndexChange}>
        <LinksTab />

        <SecurityTab />
      </SwipeableViews>
    </Dialog>
  );
};
