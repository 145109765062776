import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeDiscussionTopic } from '../../../types';

export const usePostDiscussionTopic = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (discussionTopic: TypeDiscussionTopic) =>
      axios
        .post(`api/discussion-topic`, discussionTopic)
        .then((res) => res.data),
    {
      onMutate: (_discussionTopic: TypeDiscussionTopic) => {
        queryClient.cancelQueries('discussion-topic');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('discussion-topic');
        // queryClient.invalidateQueries(['discussion-topic', variables.id]).then(_ => {})
      },
    },
  );
};
