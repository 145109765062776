import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTask } from '../../../types';

export const useDeleteTask = () => {
  const queryClient = useQueryClient();

  return useMutation((task: TypeTask) => axios.delete(`api/task/${task.id}`), {
    onMutate: (task: TypeTask) => {
      queryClient.cancelQueries('task');

      const previousTask = queryClient.getQueryData(['task', task.id]);

      queryClient.setQueryData(['task', task.id], task);

      return { previousTask };
    },
    onError: () => {},
    onSuccess: () => {
      queryClient.invalidateQueries('task');
    },
  });
};
