import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMeetingRecap } from '../../../types';

const fetchMeetingRecaps = async (): Promise<TypeMeetingRecap[]> => {
  const res = await axios.get('api/meeting-recap');
  return res.data;
};

export const PrefetchMeetingRecap = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['meeting-recap'], fetchMeetingRecaps, {
    staleTime: 5000,
  });
};

export const useGetMeetingRecaps = (
  options?: UseQueryOptions<
    TypeMeetingRecap[],
    AxiosError,
    TypeMeetingRecap[],
    any
  >,
) => {
  return useQuery('meeting-recap', fetchMeetingRecaps, options);
};
