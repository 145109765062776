import React, { useContext, useMemo } from 'react';
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { AuthContext, ProjectContext } from '../../providers';
import { Link as RouterLink } from 'react-router-dom';

export const NavDrawer = () => {
  const { appName, mobileOpen, toggleMobileOpen } = useContext(ProjectContext);

  const { userRoutes } = useContext(AuthContext);

  const drawerRoutes = useMemo(
    () => userRoutes.filter((appRoute) => appRoute.showInDrawer),
    [userRoutes],
  );

  return (
    <Drawer
      variant="temporary"
      open={mobileOpen}
      onClose={toggleMobileOpen}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: 240,
        },
      }}
    >
      <Box onClick={toggleMobileOpen} sx={{ textAlign: 'center' }}>
        <Typography variant="h6" sx={{ my: 2, ml: 2, textAlign: 'left' }}>
          {appName}
        </Typography>
        <Divider />
        <List>
          {drawerRoutes.map((drawerRoute, index) => (
            <ListItem
              key={index}
              component={RouterLink}
              to={drawerRoute.path}
              disablePadding
            >
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    sx={{ textAlign: 'left' }}
                  >
                    {drawerRoute.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};
