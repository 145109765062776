import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeFeatureFlag } from '../../../types';

const fetchFeatureFlags = async (): Promise<TypeFeatureFlag[]> => {
  const res = await axios.get('api/feature-flag');
  return res.data;
};

export const PrefetchFeatureFlag = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['feature-flag'], fetchFeatureFlags, {
    staleTime: 5000,
  });
};

export const useGetFeatureFlags = (
  options?: UseQueryOptions<
    TypeFeatureFlag[],
    AxiosError,
    TypeFeatureFlag[],
    any
  >,
) => {
  return useQuery('feature-flag', fetchFeatureFlags, options);
};
