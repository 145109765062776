import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLinkDTO } from '../../../types';

const fetchGoogleCalendarOAuthLink = async (): Promise<TypeLinkDTO> => {
  const res = await axios.get('api/public/oauth2');
  return res.data;
};

export const PrefetchGoogleCalendarOAuthLink = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['appointment/oauth2'],
    fetchGoogleCalendarOAuthLink,
    {
      staleTime: 5000,
    },
  );
};

export const useGetGoogleCalendarOAuthLink = (
  options?: UseQueryOptions<TypeLinkDTO, AxiosError, TypeLinkDTO, any>,
) => {
  return useQuery('appointment/oauth2', fetchGoogleCalendarOAuthLink, options);
};
