import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { TypeAppointment, TypeProfile } from '../../../../types';
import { SelectClient } from './SelectClient';
import { SelectTherapist } from './SelectTherapist';
import { SelectDate } from './SelectDate';
import { AppointmentContext } from '../../../../providers';
import { EditAppointmentDetails } from './EditAppointmentDetails';
import { ChevronLeft } from '@mui/icons-material';
import { NewAppointmentDetails } from './NewAppointmentDetails';
import { AppointmentConfirmation } from './AppointmentConfirmation';
import { AppointmentList } from '../AppointmentList';
import { ConfirmPayment } from './ConfirmPayment';
import { SelectCatalogItem } from './SelectCatalogItem';
import { CatalogItemDetails } from './CatalogItemDetails';
import { SelectAppointmentTypeOrCatalogItem } from './SelectAppointmentTypeOrCatalogItem';

type ScheduleAppointmentProps = {
  profile?: TypeProfile;
  appointment?: TypeAppointment;
};
type StepsType = {
  backLabel: string;
  component: React.FC;
  backAction?: () => void;
};
export const ScheduleAppointment = ({ profile }: ScheduleAppointmentProps) => {
  const {
    appointmentBalance,
    selectedAppointmentType,
    setSelectedAppointmentType,
    selectedAppointmentCalendar,
    setSelectedAppointmentCalendar,
    selectedAppointmentCalendarGroup,
    setSelectedAppointmentCalendarGroup,
    selectedDate,
    setSelectedDate,
    selectedTimeSpan,
    setSelectedTimeSpan,
    selectedAppointmentClientProfile,
    setSelectedAppointmentClientProfile,
    selectedCatalogItem,
    setSelectedCatalogItem,
    editAppointment,
    createdAppointment,
    showCatalogItems,
    setShowCatalogItems,
    // showAppointmentTypes,
    setShowAppointmentTypes,
  } = useContext(AppointmentContext);

  useEffect(() => {
    setSelectedAppointmentClientProfile(profile || null);
  }, [profile]);

  const [activeStep, setActiveStep] = useState(0);

  const steps: StepsType[] = useMemo(() => {
    const stepsList: StepsType[] = [
      {
        backLabel: '',
        component: SelectClient,
      },
      {
        backLabel: 'Select Client',
        component: SelectAppointmentTypeOrCatalogItem,
        backAction: () => {
          setSelectedAppointmentClientProfile(null);
          setShowAppointmentTypes(false);
          setShowCatalogItems(false);
        },
      },
      {
        backLabel: 'Select Appointment Type',
        component: SelectTherapist,
        backAction: () => setSelectedAppointmentType(null),
      },
      {
        backLabel: 'Select Therapist',
        component: SelectDate,
        backAction: () => {
          setSelectedDate(null);
          setSelectedTimeSpan(null);
          setSelectedAppointmentCalendar(null);
          setSelectedAppointmentCalendarGroup(null);
        },
      },
      {
        backLabel: 'Select Date',
        component: SelectCatalogItem,
        backAction: () => {
          setSelectedCatalogItem(null);
          setShowCatalogItems(false);
        },
      },
      {
        backLabel: selectedAppointmentType
          ? 'Select Package'
          : 'Select Appointment Type or Package',
        component: ConfirmPayment,
        backAction: () => {
          setSelectedCatalogItem(null);
          setShowCatalogItems(!!selectedAppointmentType);
        },
      },
      {
        backLabel: '',
        component: () => <></>,
      },
    ];
    return stepsList;
  }, [profile, editAppointment, selectedAppointmentType]);

  useEffect(() => {
    if (createdAppointment) {
      setActiveStep(6);
    } else if (!selectedAppointmentClientProfile) {
      setActiveStep(0);
    } else if (selectedCatalogItem) {
      setActiveStep(5);
    } else if (!selectedAppointmentType) {
      setActiveStep(1);
    } else if (
      !selectedAppointmentCalendar &&
      !selectedAppointmentCalendarGroup
    ) {
      setActiveStep(2);
    } else if (showCatalogItems) {
      setActiveStep(4);
    } else {
      setActiveStep(3);
    }
  }, [
    createdAppointment,
    selectedAppointmentType,
    selectedAppointmentClientProfile,
    selectedAppointmentCalendar,
    selectedAppointmentCalendarGroup,
    selectedCatalogItem,
    selectedDate,
    selectedTimeSpan,
    showCatalogItems,
  ]);

  const activeStepObj = useMemo(() => steps[activeStep], [activeStep]);
  const handlePrev = () => {
    if (
      (activeStep > 0 || (activeStep === 1 && !profile)) &&
      activeStepObj.backAction
    ) {
      activeStepObj.backAction();
    }
  };

  return (
    <Box>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Schedule Appointment</Typography>
          <Typography variant="h6">
            Remaining Balance: {appointmentBalance?.appointmentHoursRemaining}{' '}
            hour(s)
          </Typography>
        </Grid>
        {!profile && selectedAppointmentClientProfile && (
          <Grid item xs={12}>
            <Typography variant="body1">
              For {selectedAppointmentClientProfile?.name} (
              {selectedAppointmentClientProfile?.email})
            </Typography>
          </Grid>
        )}
        {!!activeStepObj.backLabel && (!profile || activeStep > 1) && (
          <Grid item xs={12}>
            <Button
              variant="text"
              color="primary"
              startIcon={<ChevronLeft />}
              onClick={handlePrev}
            >
              {activeStepObj.backLabel}
            </Button>
          </Grid>
        )}
      </Grid>
      <EditAppointmentDetails profile={profile} />
      <NewAppointmentDetails />
      <CatalogItemDetails />
      <AppointmentConfirmation profile={profile} />
      <activeStepObj.component />
      <AppointmentList />
    </Box>
  );
};
