type TypeCheckMap = {
  string: string;
  number: number;
  date: Date;
  array: Array<any>;
};

type SupportedTypes = keyof TypeCheckMap;

const typeCheckers = {
  string: (value: unknown): value is string => typeof value === 'string',
  number: (value: unknown): value is number => typeof value === 'number',
  date: (value: unknown): value is Date => value instanceof Date,
  array: (value: unknown): value is Array<any> => Array.isArray(value),
} as const;

export const genericTypeCheck = <T extends SupportedTypes>(
  value: unknown,
  type: T,
): value is TypeCheckMap[T] => typeCheckers[type](value);
