import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLeagueWeek } from '../../../types';

const fetchLeagueWeeks = async (
  leagueId: number,
): Promise<TypeLeagueWeek[]> => {
  const res = await axios.get(`api/league-week/league/${leagueId}`);
  return res.data;
};

export const PrefetchLeagueWeek = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['league-week/league', leagueId],
    () => fetchLeagueWeeks(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLeagueWeeks = (
  leagueId: number,
  options?: UseQueryOptions<
    TypeLeagueWeek[],
    AxiosError,
    TypeLeagueWeek[],
    any
  >,
) => {
  return useQuery(
    ['league-week/league', leagueId],
    () => fetchLeagueWeeks(leagueId),
    options,
  );
};
