import React from 'react';
import { Typography } from '@mui/material';

type FormattedCurrencyProps = {
  title?: string;
  amount: number;
  currency?: string;
};
export const FormattedCurrency = ({
  title,
  amount,
  currency = 'USD',
}: FormattedCurrencyProps) => {
  const formatted = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  }).format(amount);
  return (
    <Typography variant={'body1'}>
      {title} - {formatted}
    </Typography>
  );
};
