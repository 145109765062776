import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { PreparationList } from './PreparationList';
import { usePostPreparation } from '../../../api';
import { genericSearch, genericSort, Loader } from '../../_common';
import { TypePreparation } from '../../../types';
import { ChefContext, ProjectContext } from '../../../providers';

export const Preparations = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { preparations, isChefReady, refetchAll } = useContext(ChefContext);
  const [preparationName, setPreparationName] = useState<string>('');
  const { mutate: preparationMutation } = usePostPreparation();

  const createPreparation = () => {
    if (preparationName === '') return;
    preparationMutation(
      {
        preparationName: preparationName,
      },
      {
        onSuccess: () => {
          setSnackbar(`Preparation Created: ${preparationName}`);
          setPreparationName('');
          refetchAll();
        },
        onError: () => setSnackbar('Error Creating Preparation', 'error'),
      },
    );
  };

  const filteredPreparations = useMemo(
    () =>
      preparations
        ?.filter((preparation) =>
          genericSearch<TypePreparation>(preparation, {
            preparationName: preparationName,
          }),
        )
        .sort((a, b) =>
          genericSort<TypePreparation>(a, b, {
            property: 'preparationName',
            isDescending: false,
          }),
        ),
    [preparations, preparationName],
  );

  if (!isChefReady) return <Loader />;

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="preparationName"
              value={preparationName}
              label="Search or Add Preparation"
              placeholder="Preparations Title"
              onChange={(event) => setPreparationName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={preparationName === ''}
              onClick={createPreparation}
            >
              Create Preparation
            </Button>
          </Grid>
        </Grid>
        <PreparationList
          preparations={filteredPreparations}
          preparationsAvailable={!!filteredPreparations?.length}
        />
      </Container>
    </Box>
  );
};
