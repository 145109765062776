import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import { TypeOrgInvite } from '../../../types';
import { usePostAcceptOrgInvite } from '../../../api';

export const ProfileOrgInviteList = ({
  orgInvites,
  orgInvitesAvailable,
}: {
  orgInvites: TypeOrgInvite[] | undefined;
  orgInvitesAvailable: boolean;
}) => {
  const { mutate: postAcceptOrgInvite } = usePostAcceptOrgInvite();

  const acceptOrgInvite = (orgInvite: TypeOrgInvite | undefined) => {
    if (orgInvite !== undefined) {
      postAcceptOrgInvite(orgInvite.id!!);
    }
  };
  if (!orgInvitesAvailable) {
    return null;
  }

  return (
    <>
      {orgInvites?.map((orgInvite, index) => (
        <Box m={2} key={index}>
          <Card>
            <CardHeader
              title={`You're invited: Join ${orgInvite.organization?.name}`}
              subheader={orgInvite.authorities
                ?.map((role) => role.authority)
                .join(', ')}
            />
            <CardContent>
              <Typography variant={'body1'}>
                Please click the Accept Invite button to join the{' '}
                {orgInvite.organization?.name} organization.
              </Typography>
              {orgInvite.authorities
                ?.map((role) => role.authority)
                ?.includes('COACH') && (
                <Typography variant={'body1'}>
                  Then visit the Teams page via the menu to manage your teams.
                </Typography>
              )}
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={() => acceptOrgInvite(orgInvite)}
              >
                Accept Invite
              </Button>
            </CardActions>
          </Card>
        </Box>
      ))}
    </>
  );
};
