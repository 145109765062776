import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeRecipe } from '../../../types';

export const usePostRecipe = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (recipe: TypeRecipe) =>
      axios.post(`api/recipe`, recipe).then((res) => res.data),
    {
      onMutate: (_recipe: TypeRecipe) => {
        queryClient.cancelQueries('recipe');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('recipe');
        queryClient.invalidateQueries('recipe-group/recipe');
        // queryClient.invalidateQueries(['recipe', variables.id]).then(_ => {})
      },
    },
  );
};
