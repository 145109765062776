import { Box, Button, Grid, IconButton, TextField } from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import React, { useContext } from 'react';
import { PlannerContext, ProjectContext } from '../../../providers';

export const PlannerSearch = () => {
  const { performingAction } = useContext(ProjectContext);
  const {
    selectedTags,
    searchValue,
    setSearchValue,
    handleSetEditTask,
    handleSetEditWorkflow,
    handleSetEditDocument,
    selectedTab,
    setShowFilters,
  } = useContext(PlannerContext);

  const handleCreate = () => {
    if (selectedTab === 'task') {
      handleSetEditTask({ tags: selectedTags, name: searchValue });
    } else if (selectedTab === 'workflow') {
      handleSetEditWorkflow({ tags: selectedTags, name: searchValue });
    } else if (selectedTab === 'document') {
      handleSetEditDocument({ tags: selectedTags, name: searchValue });
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleCreate();
    }
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder={'Search'}
            value={searchValue}
            onChange={(event) => {
              setSearchValue(event.target.value);
            }}
            onFocus={() => setShowFilters(false)}
            onBlur={() => setShowFilters(true)}
            onKeyDown={handleKeyPress}
            disabled={performingAction}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setSearchValue('');
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCreate}
            disabled={performingAction || !searchValue.trim()}
          >
            Create {selectedTab}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
