import React from 'react';
import { Box } from '@mui/material';
import { DivisionList } from './DivisionList';
import { EditDivision } from './EditDivision';

export const Divisions = () => {
  return (
    <Box mt={2}>
      <EditDivision />
      <DivisionList />
    </Box>
  );
};
