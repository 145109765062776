import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeDiscussionTopic } from '../../../types';

const fetchDiscussionTopic = async (
  discussionTopicId: number,
): Promise<TypeDiscussionTopic> => {
  const res = await axios.get(`api/discussion-topic/${discussionTopicId}`);
  return res.data;
};

export const PrefetchDiscussionTopic = (discussionTopicId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['discussion-topic', discussionTopicId],
    () => fetchDiscussionTopic(discussionTopicId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetDiscussionTopic = (
  discussionTopicId: number,
  options?: UseQueryOptions<
    TypeDiscussionTopic,
    AxiosError,
    TypeDiscussionTopic,
    any
  >,
) => {
  return useQuery(
    ['discussion-topic', discussionTopicId],
    () => fetchDiscussionTopic(discussionTopicId),
    options,
  );
};
