import {
  TypeCoach,
  TypeLeagueDay,
  TypePracticeAvailability,
  TypePracticeAvailabilityDay,
  TypePracticeDivisionGroup,
  TypePracticeSlotDay,
  TypeTeam,
} from '../../types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from '../AuthProvider';
import { ProjectContext } from '../ProjectProvider';
import { useSubscription } from 'react-stomp-hooks';
import {
  useGetCoachesByProfile,
  useGetCoachTeams,
  useGetLeagueDays,
  useGetPracticeAvailabilityByTeam,
  useGetPracticeSlotDaysByTeam,
} from '../../api';
import { parseISOSafe } from '../../components/_common/Utils/parseISOSafe';
import { isAfter, isBefore } from 'date-fns';

type SportsCoachContextType = {
  selectedCoach: TypeCoach | null;
  handleCoachChange: (_coach: TypeCoach | null) => void;
  coachList: TypeCoach[];
  selectedTeam: TypeTeam | null;
  setSelectedTeam: (_team: TypeTeam | null) => void;
  coachTeams: TypeTeam[];
  practiceDivisionGroup: TypePracticeDivisionGroup | null;
  setPracticeDivisionGroup: (
    _practiceDivisionGroup: TypePracticeDivisionGroup | null,
  ) => void;
  mapImageLink: string | null;
  leagueDays: TypeLeagueDay[];
  practiceSlotDays: TypePracticeSlotDay[];
  practiceSlotAvailability: TypePracticeAvailability[];
  isCoachReady: boolean;
  selectionOpenTime?: string;
  selectionCloseTime?: string;
  isSelectionOpen: boolean;
  hasPracticeOnSameDay: (
    _practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => boolean;
  hasPracticeAtSameDayTime: (
    _practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => boolean;
};

export const SportsCoachContext = createContext<SportsCoachContextType>({
  selectedCoach: null,
  handleCoachChange: (_coach: TypeCoach | null) => {},
  coachList: [],
  selectedTeam: null,
  setSelectedTeam: (_team: TypeTeam | null) => {},
  coachTeams: [],
  practiceDivisionGroup: null,
  setPracticeDivisionGroup: (
    _practiceDivisionGroup: TypePracticeDivisionGroup | null,
  ) => {},
  mapImageLink: null,
  leagueDays: [],
  practiceSlotDays: [],
  practiceSlotAvailability: [],
  isCoachReady: false,
  selectionOpenTime: undefined,
  selectionCloseTime: undefined,
  isSelectionOpen: false,
  hasPracticeOnSameDay: (
    _practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => false,
  hasPracticeAtSameDayTime: (
    _practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ) => false,
});

export type SportsCoachProviderProps = {
  children: React.ReactNode;
};

export const SportsCoachProvider = ({ children }: SportsCoachProviderProps) => {
  const { cld } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const [selectedCoach, setSelectedCoach] = useState<TypeCoach | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<TypeTeam | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [practiceDivisionGroup, setPracticeDivisionGroup] =
    useState<TypePracticeDivisionGroup | null>(null);
  const [mapImageLink, setMapImageLink] = useState<string | null>(null);
  // const [isAllCapacityFilled, setIsAllCapacityFilled] = useState(false);
  const [dayTimesMap, setDayTimesMap] = useState({});
  const [daysMap, setDaysMap] = useState({});
  const league = selectedCoach?.league;

  useSubscription('/topic/coach/practice', (message) =>
    setMessage(message.body),
  );

  const { data: coachData, isLoading: coachLoading } = useGetCoachesByProfile(
    profile?.id!!,
    { enabled: !!profile?.id },
  );
  const { data: coachTeams, isLoading: coachTeamsLoading } = useGetCoachTeams(
    selectedCoach?.id!!,
    { enabled: !!coachData },
  );
  const { data: leagueDaysData, isLoading: leagueDaysLoading } =
    useGetLeagueDays(league?.id!!, {
      enabled: !!league?.id,
    });
  const {
    data: practiceSlotDays,
    isLoading: practiceSlotDaysLoading,
    refetch: practiceSlotDaysRefetch,
  } = useGetPracticeSlotDaysByTeam(selectedTeam?.id!!, {
    enabled: !!selectedTeam,
  });
  const {
    data: practiceSlotAvailabilityData,
    isLoading: practiceSlotAvailabilityLoading,
    refetch: practiceSlotAvailabilityRefetch,
  } = useGetPracticeAvailabilityByTeam(selectedTeam?.id!!, {
    enabled: !!selectedTeam,
  });

  const selectionOpenTime = parseISOSafe(
    selectedCoach?.league?.selectionOpenDateTime,
  );
  const selectionCloseTime = parseISOSafe(
    selectedCoach?.league?.selectionCloseDateTime,
  );
  const isAfterOpenTime =
    !!selectionOpenTime && isAfter(new Date(), selectionOpenTime);
  const isBeforeCloseTime =
    !!selectionCloseTime && isBefore(new Date(), selectionCloseTime);
  const isSelectionOpen =
    (!selectionOpenTime || isAfterOpenTime) &&
    (!selectionCloseTime || isBeforeCloseTime);

  useEffect(() => {
    if (practiceSlotAvailabilityData) {
      setPracticeDivisionGroup(
        practiceSlotAvailabilityData[0]?.practiceDivisionGroup || null,
      );
    }
  }, [practiceSlotAvailabilityData]);

  useEffect(() => {
    if (!selectedCoach && coachData?.length) {
      setSelectedCoach(coachData[0]);
    }
  }, [coachData]);

  const defaultSelectedTeam = coachTeams?.length ? coachTeams[0] : null;
  const handleCoachChange = (value: TypeCoach | null) => {
    setSelectedCoach(value);
    setSelectedTeam(defaultSelectedTeam);
  };

  useEffect(() => {
    setSelectedTeam(defaultSelectedTeam);
  }, [coachTeams, selectedCoach]);

  useEffect(() => {
    if (message === 'update') {
      console.log('update');
      practiceSlotAvailabilityRefetch();
      practiceSlotDaysRefetch();
      setMessage(null);
    }
  }, [message]);

  useEffect(() => {
    if (!cld || !selectedTeam?.division?.mapImage?.imageUrl) return;
    setMapImageLink(
      cld.image(selectedTeam?.division?.mapImage?.imageUrl).toURL(),
    );
  }, [cld, selectedTeam]);

  useEffect(() => {
    if (!practiceSlotDays) return;
    setDayTimesMap(
      practiceSlotDays.reduce((map, practiceSlotDay) => {
        const key = `${practiceSlotDay.leagueDay?.id!!}-${practiceSlotDay
          .leagueTime?.id!!}`;
        map[key] = true;
        return map;
      }, {}),
    );
    setDaysMap(
      practiceSlotDays.reduce((map, practiceSlotDay) => {
        const key = practiceSlotDay.leagueDay?.id!!;
        map[key] = true;
        return map;
      }, {}),
    );
  }, [practiceSlotDays]);

  const hasPracticeAtSameDayTime = (
    practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ): boolean => {
    return (
      `${practiceAvailabilityDay.leagueDay!!.id}-${
        practiceAvailabilityDay.leagueTime!!.id
      }` in dayTimesMap
    );
  };

  const hasPracticeOnSameDay = (
    practiceAvailabilityDay: TypePracticeAvailabilityDay,
  ): boolean => {
    return practiceAvailabilityDay.leagueDay?.id!! in daysMap;
  };

  return (
    <SportsCoachContext.Provider
      value={{
        selectedCoach,
        handleCoachChange,
        coachList: coachData ?? [],
        selectedTeam,
        setSelectedTeam,
        coachTeams: coachTeams ?? [],
        practiceDivisionGroup,
        setPracticeDivisionGroup,
        mapImageLink,
        leagueDays: leagueDaysData ?? [],
        practiceSlotDays: practiceSlotDays ?? [],
        practiceSlotAvailability: practiceSlotAvailabilityData ?? [],
        isCoachReady:
          !coachLoading &&
          !coachTeamsLoading &&
          !leagueDaysLoading &&
          !practiceSlotDaysLoading &&
          !practiceSlotAvailabilityLoading,
        isSelectionOpen,
        selectionOpenTime: undefined,
        selectionCloseTime: undefined,
        hasPracticeOnSameDay,
        hasPracticeAtSameDayTime,
      }}
    >
      {children}
    </SportsCoachContext.Provider>
  );
};
