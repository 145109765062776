import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypePracticeAvailability } from '../../../types';

const fetchPracticeAvailabilityByTeam = async (
  teamId: number,
): Promise<TypePracticeAvailability[]> => {
  const res = await axios.get(`api/practice-availability/team/${teamId}`);
  return res.data;
};

export const PrefetchPracticeAvailabilityByTeam = (teamId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['practice-availability/team', teamId],
    () => fetchPracticeAvailabilityByTeam(teamId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetPracticeAvailabilityByTeam = (
  teamId: number,
  options?: UseQueryOptions<
    TypePracticeAvailability[],
    AxiosError,
    TypePracticeAvailability[],
    any
  >,
) => {
  return useQuery(
    ['practice-availability/team', teamId],
    () => fetchPracticeAvailabilityByTeam(teamId),
    options,
  );
};
