import React from 'react';
import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { TypeTag, TypeTagEntityMap } from '../../../types';

export type TagListProps = {
  tags?: TypeTag[];
  tagsAvailable: boolean;
  type: string;
  mapFieldName: string;
  map: TypeTagEntityMap;
};
export const TagList = ({
  tags,
  tagsAvailable,
  type,
  mapFieldName,
  map,
}: TagListProps) => (
  <TableContainer hidden={!tagsAvailable}>
    <Table style={{ minWidth: 650 }}>
      <TableHead>
        <TableRow>
          <TableCell>Tag Name</TableCell>
          <TableCell>Display Order</TableCell>
          <TableCell>{mapFieldName}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tags?.map((tag) => (
          <TableRow key={tag.id}>
            <TableCell>
              <Link
                component={RouterLink}
                to={`/${type}-tag/${tag.id}`}
                underline="none"
              >
                {tag.name}
              </Link>
            </TableCell>
            <TableCell>{tag.position}</TableCell>
            <TableCell>{map[tag.id!!] || 0}</TableCell>
          </TableRow>
        ))}
        {tags?.length === 0 && (
          <TableRow>
            <TableCell colSpan={3}>No Tags Yet</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
