import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeImage } from '../../../types';

const fetchImages = async (): Promise<TypeImage[]> => {
  const res = await axios.get('api/image');
  return res.data;
};

export const PrefetchImage = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['image'], fetchImages, {
    staleTime: 5000,
  });
};

export const useGetImages = (
  options?: UseQueryOptions<TypeImage[], AxiosError, TypeImage[], any>,
) => {
  return useQuery('image', fetchImages, options);
};
