import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypePracticeSlotDay } from '../../../types';

export const usePostPracticeSlotDay = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (practiceSlotDay: TypePracticeSlotDay) =>
      axios
        .post(`api/practice-slot-day`, practiceSlotDay)
        .then((res) => res.data),
    {
      onMutate: (_practiceSlotDay: TypePracticeSlotDay) => {
        queryClient.cancelQueries('practice-slot-day');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('practice-slot-day');
        queryClient.invalidateQueries('practice-slot-day/team');
        queryClient.invalidateQueries('practice-division-group/league');
        // queryClient.invalidateQueries(['practice-slot', variables.id]).then(_ => {})
      },
    },
  );
};
