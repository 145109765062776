import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTagEntityMap } from '../../../types';

const fetchRecipeTagEntityMap = async (): Promise<TypeTagEntityMap> => {
  const res = await axios.get('api/recipe/mapped');
  return res.data;
};

export const PrefetchRecipeTagEntityMap = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['recipe/mapped'], fetchRecipeTagEntityMap, {
    staleTime: 5000,
  });
};

export const useGetRecipeTagEntityMap = (
  options?: UseQueryOptions<
    TypeTagEntityMap,
    AxiosError,
    TypeTagEntityMap,
    any
  >,
) => {
  return useQuery('recipe/mapped', fetchRecipeTagEntityMap, options);
};
