import React, { useContext, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { ImageList } from './ImageList';
import {
  useGetImages,
  useGetTagsByType,
  usePostImage,
  usePostRehydrateImages,
} from '../../../api';
import { CloudinaryUpload } from '../Cloudinary';
import Autocomplete from '@mui/material/Autocomplete';
import { TypeTag } from '../../../types';
import { flexCenterStart } from '../../../data/styles';
import { ProjectContext } from '../../../providers';

export const Images = () => {
  const { setSnackbar, performingAction, setPerformingAction } =
    useContext(ProjectContext);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageCategory, setImageCategory] = useState<TypeTag | null>(null);
  const [imageCategories, setImageCategories] = useState<TypeTag[]>([]);
  const { refetch: refetchImages, ...queryInfo } = useGetImages();
  const imageMutation = usePostImage();
  const { mutate: postRehydrateImages } = usePostRehydrateImages();
  useGetTagsByType('image', {
    onSuccess: (data) => setImageCategories(data),
  });

  const createImage = () => {
    if (imageUrl === '') return;
    imageMutation.mutate({
      imageUrl: imageUrl,
    });
    refetchImages();
    setImageUrl('');
  };

  const handleRehydrateImages = () => {
    setPerformingAction(true);
    postRehydrateImages(undefined, {
      onSuccess: () => setSnackbar('Images Rehydrated'),
      onError: () => setSnackbar('Failed to get Cloudinary images'),
      onSettled: () => setPerformingAction(false),
    });
  };

  const handleUpload = (imageUrl: string) => {
    setImageUrl(imageUrl);
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <Autocomplete
              id="image-category"
              value={imageCategory}
              options={imageCategories}
              getOptionLabel={(option) => option.name || ''}
              onChange={(event, newValue) => setImageCategory(newValue)}
              isOptionEqualToValue={(option, value) =>
                option.name === value?.name
              }
              renderInput={(params) => (
                <TextField {...params} label="Category" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={3} sx={flexCenterStart}>
            {imageUrl ? imageUrl : 'No Image Selected'}
          </Grid>
          <Grid item xs={6} sm={3} sx={flexCenterStart}>
            <CloudinaryUpload onImageUpload={handleUpload} />
          </Grid>
          <Grid item xs={6} sm={3} sx={flexCenterStart}>
            <Button
              variant="outlined"
              disabled={imageUrl === ''}
              onClick={createImage}
            >
              Save Image
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant={'contained'}
              disabled={performingAction}
              onClick={handleRehydrateImages}
            >
              Rehydrate Images
            </Button>
          </Grid>
        </Grid>
        <ImageList
          images={queryInfo.data}
          imagesAvailable={!queryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
