import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostProfileSysAdmin = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (profileId: number) =>
      axios
        .post(`api/profile/sysadmin/${profileId}`, {})
        .then((res) => res.data),
    {
      onMutate: (_profileId: number) => {
        queryClient.cancelQueries('profile/sysadmin');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('profile');
      },
    },
  );
};
