import React, { useEffect } from 'react';
import { TypeQuestionnaireQuestion } from '../../../types';
import {
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { KeyboardArrowDown, KeyboardArrowUp, Save } from '@mui/icons-material';
import { usePostQuestionnaireQuestion } from '../../../api';
import { genericSwap } from '../Utils/genericSwap';
import produce from 'immer';

type QuestionnaireQuestionListProps = {
  questionnaireQuestionList?: TypeQuestionnaireQuestion[];
  setQuestionnaireQuestionList: (
    _newQuestionnaireQuestionList: TypeQuestionnaireQuestion[],
  ) => void;
  questionnaireAnswerId?: number;
  refetch: () => void;
};

export const QuestionnaireQuestionList = ({
  questionnaireQuestionList,
  setQuestionnaireQuestionList,
  questionnaireAnswerId,
  refetch,
}: QuestionnaireQuestionListProps) => {
  const questionMutation = usePostQuestionnaireQuestion();
  const [newQuestionText, setNewQuestionText] = React.useState('');
  const [newQuestionPosition, setNewQuestionPosition] = React.useState(0);

  const handleSetNewQuestionText = (event) => {
    setNewQuestionText(event.target.value);
  };

  const handleMoveQuestion = (index: number, up: boolean) => {
    setQuestionnaireQuestionList(
      produce(questionnaireQuestionList!, (draft) => {
        genericSwap<TypeQuestionnaireQuestion>(draft, index, up);
      }),
    );
  };

  const handleNewQuestionnaireQuestion = () => {
    questionMutation.mutate(
      {
        questionText: newQuestionText,
        position: newQuestionPosition,
        questionnaireAnswer: questionnaireAnswerId,
      },
      {
        onSuccess: () => {
          refetch();
          setNewQuestionText('');
        },
      },
    );
  };

  useEffect(() => {
    if (questionnaireQuestionList === undefined) return;
    setNewQuestionPosition(questionnaireQuestionList.length + 1);
  }, [questionnaireQuestionList]);

  return (
    <TableContainer>
      <Table style={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell>Question</TableCell>
            <TableCell>Display Order</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {questionnaireQuestionList?.map((questionnaireQuestion, index) => (
            <TableRow key={questionnaireQuestion.id}>
              <TableCell>
                <Link
                  component={RouterLink}
                  to={`/question/${questionnaireQuestion.id}`}
                  underline="none"
                >
                  {questionnaireQuestion.questionText}
                </Link>
              </TableCell>
              <TableCell>{questionnaireQuestion.position}</TableCell>
              <TableCell>
                <IconButton
                  color="primary"
                  disabled={
                    questionnaireQuestion.position ===
                    questionnaireQuestionList?.length
                  }
                  onClick={() => handleMoveQuestion(index, false)}
                >
                  <KeyboardArrowDown />
                </IconButton>
                <IconButton
                  color="primary"
                  disabled={questionnaireQuestion.position === 1}
                  onClick={() => handleMoveQuestion(index, true)}
                >
                  <KeyboardArrowUp />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          {questionnaireQuestionList?.length === 0 && (
            <TableRow>
              <TableCell colSpan={3}>No Questions Yet</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>
              <TextField
                fullWidth
                variant="outlined"
                id="newQuestionText"
                value={newQuestionText}
                label="New Question Text"
                placeholder="Enter Question Here"
                onChange={handleSetNewQuestionText}
              ></TextField>
            </TableCell>
            <TableCell>{newQuestionPosition}</TableCell>
            <TableCell>
              <IconButton
                color="primary"
                disabled={newQuestionText === ''}
                onClick={handleNewQuestionnaireQuestion}
              >
                <Save />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
