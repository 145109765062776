import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostOrgInviteEmail = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (orgInviteId: number) =>
      axios
        .post(`api/org-invite/email/${orgInviteId}`, {})
        .then((res) => res.data),
    {
      onMutate: (orgInviteId: number) => {
        queryClient.cancelQueries('org-invite');

        const oldOrgInvite = queryClient.getQueryData([
          'org-invite',
          orgInviteId,
        ]);

        return () => {
          queryClient.setQueryData(
            ['org-invite/email', orgInviteId],
            oldOrgInvite,
          );
        };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('org-invite');
        queryClient.invalidateQueries('profile');
      },
    },
  );
};
