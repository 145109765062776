import React from 'react';
import * as MuiIcons from '@mui/icons-material';

export const getFilterIcon = (index: number) => {
  if (index < 9) {
    // MuiIcons will have Filter1, Filter2, etc. as properties
    const IconComponent = MuiIcons[`Filter${index + 1}`];
    return IconComponent ? <IconComponent /> : <MuiIcons.Filter9Plus />;
  }
  return <MuiIcons.Filter9Plus />;
};
