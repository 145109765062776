import React, { useContext } from 'react';
import { usePostVersion } from '../../api';
import { Button } from '@mui/material';
import { Upgrade } from '@mui/icons-material';
import { ProjectContext } from '../../providers';

export const Version = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { mutate: postVersion } = usePostVersion();
  const handleUpgradeVersion = () => {
    console.log('Upgrade Version');
    postVersion(undefined, {
      onSuccess: () => setSnackbar('Version Upgraded Successfully'),
      onError: () => setSnackbar('Error Upgrading Version'),
    });
  };
  return (
    <Button
      variant={'outlined'}
      endIcon={<Upgrade />}
      onClick={handleUpgradeVersion}
    >
      Upgrade Version
    </Button>
  );
};
