import React, { useEffect, useMemo, useState } from 'react';
import { TypeMapboxAddress, TypeOrder, TypeProfile } from '../../../types';
import {
  useGetAddressesByProfile,
  useGetAllProfiles,
  useGetOrders,
  usePostOrder,
} from '../../../api';
import { genericSearch, genericSort, Loader } from '../../_common';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { OrderList } from './OrderList';
import { AddressMinimap, SearchBox } from '@mapbox/search-js-react';
import { Feature, FeatureCollection, GeoJsonProperties, Point } from 'geojson';
import Autocomplete from '@mui/material/Autocomplete';
// import {Point} from "mapbox-gl";

export const Orders = () => {
  const [selectedAddress, setSelectedAddress] =
    useState<TypeMapboxAddress | null>(null);
  const [orders, setOrders] = useState<TypeOrder[]>([]);
  const [nonAdminProfiles, setNonAdminProfiles] = useState<TypeProfile[]>([]);
  const [selectedFeature, setSelectedFeature] = useState<
    Feature<Point, GeoJsonProperties> | undefined
  >(undefined);
  const [selectedProfile, setSelectedProfile] = useState<TypeProfile | null>(
    null,
  );
  const { ...orderQueryInfo } = useGetOrders({
    onSuccess: (data) => setOrders(data),
  });
  const orderMutation = usePostOrder();

  const { data: profileAddresses } = useGetAddressesByProfile(
    selectedProfile?.id || 0,
    {
      enabled: !!selectedProfile,
    },
  );

  const {
    data: allProfilesData,
    isLoading: allProfilesLoading,
    error: allProfilesError,
  } = useGetAllProfiles();

  useEffect(() => {
    if (allProfilesData === undefined) return;
    const nonAdminProfiles = allProfilesData.filter((profile) => {
      return !profile.authorities?.some(
        (authority) => authority.authority === 'SYSADMIN',
      );
    });
    setNonAdminProfiles(nonAdminProfiles);
  }, [allProfilesData]);

  const createOrder = () => {
    if (!selectedProfile || (!selectedAddress && !selectedFeature)) return;
    let addressToUse = selectedAddress;
    if (!addressToUse) {
      const properties = selectedFeature?.properties!!;
      addressToUse = {
        name: properties['name'] || '',
        mapboxId: properties['mapbox_id'] || '',
        fullAddress: properties['full_address'] || '',
        latitude: properties['coordinates']?.latitude || 0,
        longitude: properties['coordinates']?.longitude || 0,
      };
    }
    orderMutation.mutate(
      {
        mapboxAddress: addressToUse,
        profile: selectedProfile,
      },
      {
        onSuccess: () => {
          setSelectedProfile(null);
        },
      },
    );
  };

  const filteredOrders = useMemo(() => {
    const queryObject: object = {};
    if (selectedProfile) queryObject['email'] = selectedProfile?.email;
    return orders
      .filter((order) => genericSearch<TypeOrder>(order, queryObject))
      .sort((a, b) =>
        genericSort<TypeOrder>(a, b, {
          property: 'email',
          isDescending: false,
        }),
      );
  }, [selectedProfile, orders]);

  const handleRetrieve = (featureCollection: FeatureCollection) => {
    console.log('Feature Collection', featureCollection);
    // const testFC: Feature<Geometry, GeoJsonProperties> = featureCollection.features[0];
    // const testFC2: Feature<Point, GeoJsonProperties> = featureCollection.features[0] as Feature<Point, GeoJsonProperties>;
    // setSelectedFeature(featureCollection.features[0]);
    setSelectedFeature(
      featureCollection.features[0] as Feature<Point, GeoJsonProperties>,
    );
  };

  if (orderQueryInfo.isLoading || orderQueryInfo.error) return <Loader />;

  if (allProfilesLoading || allProfilesError || allProfilesData === undefined) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={nonAdminProfiles}
              getOptionLabel={(option) =>
                `${option.name} (${option.email})` || ''
              }
              value={selectedProfile}
              fullWidth
              onChange={(_event, newValue) => setSelectedProfile(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Profile" placeholder="Profile" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={selectedAddress}
              options={profileAddresses || []}
              getOptionLabel={(option) => `${option.name}`}
              fullWidth
              onChange={(_event, newValue) => setSelectedAddress(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Address" placeholder="Address" />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchBox
              accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
              onChange={(e) => console.log('Change', e)}
              onRetrieve={handleRetrieve}
              onSuggest={(e) => console.log('Suggest', e)}
              value={''}
            />
            {/*<AddressAutofill accessToken="my-access-token-here">*/}
            {/*  <TextField*/}
            {/*    name="address"*/}
            {/*    placeholder="Address"*/}
            {/*    type="text"*/}
            {/*    autoComplete="address-line1"*/}
            {/*  />*/}
            {/*</AddressAutofill>*/}
          </Grid>
          <Grid item xs={12} sm={6} sx={{ height: '500px', width: '500px' }}>
            {selectedFeature && (
              <AddressMinimap
                accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || ''}
                defaultMapStyle={['mapbox', 'outdoors-v11']}
                onSaveMarkerLocation={(coordinate) => console.log(coordinate)}
                show={!!selectedFeature}
                feature={selectedFeature}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              variant="outlined"
              disabled={
                !selectedProfile || (!selectedAddress && !selectedFeature)
              }
              onClick={createOrder}
            >
              Create Order
            </Button>
          </Grid>
        </Grid>
        <OrderList
          orders={filteredOrders}
          ordersAvailable={!orderQueryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
