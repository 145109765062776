import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import { Loader } from '../../_common/Loader';
import { Cached, Edit, Launch } from '@mui/icons-material';
import { PracticeDivisionGroupDetails } from './PracticeDivisionGroupDetails';
import {
  useGetPracticeSlots,
  useGetTeamsByPracticeDivisionGroup,
  usePostPracticeDivisionGroupPDF,
} from '../../../api';
import { TypePracticeDivisionGroup } from '../../../types';
import { PracticeSlotList } from './PracticeSlotList';
import { ProjectContext } from '../../../providers';
import { Link } from 'react-router-dom';

type PracticeDivisionGroupProps = {
  practiceDivisionGroup: TypePracticeDivisionGroup;
};
export const PracticeDivisionGroup = ({
  practiceDivisionGroup,
}: PracticeDivisionGroupProps) => {
  const { cld } = useContext(ProjectContext);
  const { data: practiceSlotsData, isLoading: practiceSlotsLoading } =
    useGetPracticeSlots(practiceDivisionGroup.id!!, {
      refetchOnWindowFocus: false,
    });
  const { data: teamsData, isLoading: teamsLoading } =
    useGetTeamsByPracticeDivisionGroup(practiceDivisionGroup.id!!, {
      refetchOnWindowFocus: false,
    });
  const { mutate: postPracticeDivisionGroupPDF } =
    usePostPracticeDivisionGroupPDF();
  const [showEditPracticeDivisionGroup, setShowEditPracticeDivisionGroup] =
    useState(false);
  const [scheduleImageLink, setScheduleImageLink] = useState('');

  const handlePrintPracticeSchedule = () =>
    postPracticeDivisionGroupPDF(practiceDivisionGroup.id!!);

  useEffect(() => {
    if (!cld || !practiceDivisionGroup?.scheduleImage?.imageUrl) return;
    setScheduleImageLink(
      cld.image(practiceDivisionGroup?.scheduleImage?.imageUrl).toURL(),
    );
  }, [cld, practiceDivisionGroup]);

  if (
    practiceSlotsLoading ||
    !practiceSlotsData ||
    teamsLoading ||
    !teamsData
  ) {
    return <Loader />;
  }

  return (
    <>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              endIcon={<Edit />}
              onClick={() =>
                setShowEditPracticeDivisionGroup(!showEditPracticeDivisionGroup)
              }
            >
              Edit {practiceDivisionGroup.name}
            </Button>
            {!practiceDivisionGroup.scheduleImageReady && (
              <Button
                variant="contained"
                endIcon={<Cached />}
                onClick={handlePrintPracticeSchedule}
              >
                Generate Practice Schedule
              </Button>
            )}
            {practiceDivisionGroup.scheduleImageReady &&
              practiceDivisionGroup.scheduleImage &&
              scheduleImageLink && (
                <Link to={scheduleImageLink} target={'_blank'}>
                  <Button
                    variant="outlined"
                    endIcon={<Launch />}
                    disabled={!scheduleImageLink}
                  >
                    View Practice Schedule
                  </Button>
                </Link>
              )}
          </Grid>
        </Grid>
      </Box>
      {showEditPracticeDivisionGroup && (
        <PracticeDivisionGroupDetails
          practiceDivisionGroup={practiceDivisionGroup}
        />
      )}
      <PracticeSlotList practiceSlots={practiceSlotsData} teams={teamsData} />
    </>
  );
};
