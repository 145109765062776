import React, { useContext } from 'react';
import { TypeAppointment } from '../../../types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { CancelAppointmentDialog } from '../../_common';
import { DeleteForever, SwapHoriz } from '@mui/icons-material';
import {
  AppointmentContext,
  DialogContext,
  ProjectContext,
} from '../../../providers';

export const AppointmentList = () => {
  const { performingAction } = useContext(ProjectContext);
  const {
    appointments,
    appointmentTimeframe,
    toggleAppointmentTimeframe,
    handleCancelAppointment,
    appointmentHeadlineDetails,
    setEditAppointment,
    cancelAppointment,
    setCancelAppointment,
    resetAppointmentScheduler,
  } = useContext(AppointmentContext);
  const { openDialog } = useContext(DialogContext);

  const handleSwapAppointment = (appointment: TypeAppointment) => {
    resetAppointmentScheduler();
    setEditAppointment(appointment);
  };

  const handleCancelAppointmentDialog = (appointment: TypeAppointment) => {
    setCancelAppointment(appointment);
    openDialog('cancelAppointmentDialog');
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h6'}>
            {appointmentTimeframe === 'upcoming'
              ? 'Upcoming Appointments'
              : 'Past Appointments'}
            <Button
              variant={'outlined'}
              size={'small'}
              onClick={toggleAppointmentTimeframe}
              sx={{ ml: 2 }}
            >
              {appointmentTimeframe === 'upcoming'
                ? 'Show Past'
                : 'Show Upcoming'}
            </Button>
          </Typography>
        </Grid>
        {appointments.length === 0 ? (
          <Grid item xs={12}>
            <Typography variant={'body1'}>No appointments yet</Typography>
          </Grid>
        ) : (
          appointments.map((appointment) => (
            <Grid item xs={12} key={appointment.id}>
              <Card>
                <CardContent>
                  {appointmentHeadlineDetails(appointment)}
                </CardContent>
                {appointmentTimeframe === 'upcoming' && (
                  <CardActions>
                    <Button
                      onClick={() => handleSwapAppointment(appointment)}
                      disabled={performingAction}
                      endIcon={<SwapHoriz />}
                    >
                      Reschedule
                    </Button>
                    <Button
                      onClick={() => handleCancelAppointmentDialog(appointment)}
                      disabled={performingAction}
                      endIcon={<DeleteForever />}
                    >
                      Cancel
                    </Button>
                  </CardActions>
                )}
              </Card>
            </Grid>
          ))
        )}
      </Grid>
      {cancelAppointment && (
        <CancelAppointmentDialog
          cancelTitle={'this appointment'}
          contentText={appointmentHeadlineDetails(cancelAppointment)}
          cancelFn={() => handleCancelAppointment(cancelAppointment)}
        />
      )}
    </Box>
  );
};
