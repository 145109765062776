import React, { useContext } from 'react';
import { Box, List } from '@mui/material';
import { TypeTask } from '../../../types';
import { usePostTaskPriorities } from '../../../api';
import { PlannerContext } from '../../../providers';
import { ViewTaskCard } from './ViewTaskCard';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { genericMoveItem } from '../../_common';

export const TaskList = () => {
  const {
    allFilters,
    showChips,
    tasks,
    setTasks,
    handleSetEditTask,
    handleSaveTask,
    isPlannerReady,
  } = useContext(PlannerContext);

  const { mutate: updateTaskPriorities } = usePostTaskPriorities();

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  // Function to update priorityValue and return the affected items
  const updatePriorityValues = (
    tasks: TypeTask[],
    fromIndex: number,
    toIndex: number,
  ) => {
    // Move the task in the list
    const updatedTasks = genericMoveItem<TypeTask>(
      [...tasks],
      { property: 'priorityValue' },
      fromIndex,
      toIndex,
    );
    setTasks(updatedTasks); // Update the state with the new order

    // List to hold affected tasks
    // get from tasks from fromIndex to toIndex
    const affectedTasks: TypeTask[] = updatedTasks.slice(
      Math.min(fromIndex, toIndex),
      Math.max(fromIndex, toIndex) + 1,
    );

    // Return only the affected tasks where priorityValue has changed
    console.log('affected tasks', affectedTasks);
    updateTaskPriorities(affectedTasks);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = tasks.findIndex((task) => task.id === active.id);
      const newIndex = tasks.findIndex((task) => task.id === over.id);
      console.log('drag end', active.id, oldIndex, over.id, newIndex);
      // assume we have moved the item to the new index
      // update each item's priorityValue to reflect the new order
      // then save only the affected items

      updatePriorityValues(tasks, oldIndex, newIndex);
    }
  };

  if (!isPlannerReady) {
    return <></>;
  }

  return (
    <Box>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        // onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={tasks.map((task) => task.id as UniqueIdentifier)}
          strategy={verticalListSortingStrategy}
        >
          <List>
            {tasks.map((item) => (
              <ViewTaskCard
                key={`task-${item.id}`}
                sortBy={allFilters['task'].selectedField}
                showChips={showChips}
                task={item}
                onSetEditTask={handleSetEditTask}
                onSave={handleSaveTask}
              />
            ))}
          </List>
        </SortableContext>
        {/*<DragOverlay>*/}
        {/*  {activeId && overlayTask ? (*/}
        {/*    <ViewTaskCard*/}
        {/*      key={`task-${activeId}`}*/}
        {/*      sortingBy={orderBy}*/}
        {/*      task={overlayTask}*/}
        {/*      activeTask={activeTask}*/}
        {/*      onSetEditTask={handleSetEditTask}*/}
        {/*      onSave={handleSave}*/}
        {/*    />*/}
        {/*  ) : null}*/}
        {/*</DragOverlay>*/}
      </DndContext>
    </Box>
  );
};
