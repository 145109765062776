import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeCoach } from '../../../types';

const fetchCoaches = async (leagueId: number): Promise<TypeCoach[]> => {
  const res = await axios.get(`api/coach/league/${leagueId}`);
  return res.data;
};

export const PrefetchCoach = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['coach/league', leagueId],
    () => fetchCoaches(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetCoaches = (
  leagueId: number,
  options?: UseQueryOptions<TypeCoach[], AxiosError, TypeCoach[], any>,
) => {
  return useQuery(
    ['coach/league', leagueId],
    () => fetchCoaches(leagueId),
    options,
  );
};
