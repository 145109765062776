import Autocomplete from '@mui/material/Autocomplete';
import { Alert, Button, Grid, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import {
  useDeleteMatchScheduler,
  useGetMatchesByCoach,
  useGetMatchesByDivision,
  useGetMatchesByWeek,
  usePostMatchScheduler,
} from '../../../api';
import { LeagueContext, ProjectContext } from '../../../providers';
import { AlertColor } from '@mui/material/Alert/Alert';
import { useSubscription } from 'react-stomp-hooks';
import { MatchList } from './MatchList';
import {
  TypeCoach,
  TypeDivision,
  TypeLeagueWeek,
  TypeMatch,
  TypeMatchSchedule,
} from '../../../types';

export const MatchSchedule = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { league, refetchLeague, divisions, coaches, weeks } =
    useContext(LeagueContext);
  // const { data: validationOutput, refetch: refetchMatchSchedulerValidation } =
  //   useGetMatchSchedulerValidation(league?.id!!, { enabled: !!league?.id });
  const [matchScheduleView, setMatchScheduleView] =
    React.useState<TypeMatchSchedule>('Coach');
  const [matchList, setMatchList] = useState<TypeMatch[]>([]);
  const [division, setDivision] = useState<TypeDivision | null>(null);
  const [coach, setCoach] = useState<TypeCoach | null>(null);
  const [week, setWeek] = useState<TypeLeagueWeek | null>(null);
  const options: TypeMatchSchedule[] = ['Coach', 'Week', 'Division'];
  const [matchMessage, setMatchMessage] = useState<string>(
    'Matches Not Generated',
  );
  const [matchMessageSeverity, setMatchMessageSeverity] =
    useState<AlertColor>('info');

  const { mutate: matchSchedulerMutation } = usePostMatchScheduler();
  const { mutate: matchSchedulerDeleteMutation } = useDeleteMatchScheduler();
  const { data: divisionMatchesData, refetch: refetchMatchesByDivision } =
    useGetMatchesByDivision(division?.id!!, {
      enabled: !!division,
    });
  const { data: coachMatchesData, refetch: refetchMatchesByCoach } =
    useGetMatchesByCoach(coach?.id!!, {
      enabled: !!coach,
    });
  const { data: weekMatchesData, refetch: refetchMatchesByWeek } =
    useGetMatchesByWeek(week?.id!!, {
      enabled: !!week,
    });

  useEffect(() => {
    if (league?.matchesGenerated) {
      setMatchMessage('Matches Generated Successfully');
      setMatchMessageSeverity('success');
    }
  }, [league]);

  useEffect(() => {
    if (divisions.length > 0) setDivision(divisions[0]);
    if (coaches.length > 0) setCoach(coaches[0]);
    if (weeks.length > 0) setWeek(weeks[0]);
  }, [divisions, coaches, weeks]);

  const generateMatches = () => {
    if (!league) return;
    matchSchedulerMutation(league.id!!, {
      onSuccess: () => setSnackbar('Started Match Generation'),
      onError: () => setSnackbar('Error Starting Match Generation', 'error'),
      onSettled: () => {
        setMatchMessage(
          'Matches are being generated. This may take a few minutes',
        );
        setMatchMessageSeverity('warning');
      },
    });
  };

  const deleteMatches = () => {
    if (!league) return;
    matchSchedulerDeleteMutation(league.id!!, {
      onSuccess: () => setSnackbar('Matches Deleted'),
      onError: () => setSnackbar('Error Deleting Matches', 'error'),
      onSettled: () => {
        setMatchMessage('Matches Not Generated');
        setMatchMessageSeverity('info');
        refetchLeague();
        refetchMatchesByDivision();
        refetchMatchesByCoach();
        refetchMatchesByWeek();
      },
    });
  };

  useEffect(() => {
    if (matchScheduleView === 'Division') {
      setMatchList(divisionMatchesData || []);
    } else if (matchScheduleView === 'Coach') {
      setMatchList(coachMatchesData || []);
    } else {
      setMatchList(weekMatchesData || []);
    }
  }, [
    matchScheduleView,
    divisionMatchesData,
    coachMatchesData,
    weekMatchesData,
  ]);

  useSubscription('/topic/coach/matches', (message) => {
    if (message.body === 'success') {
      setSnackbar('Matches Generated Successfully');
      setMatchMessage('Matches Generated Successfully');
      setMatchMessageSeverity('success');
    } else {
      setSnackbar('Error Generating Matches', 'error');
      setMatchMessage('Error Generating Matches');
      setMatchMessageSeverity('error');
    }
    refetchLeague();
    refetchMatchesByDivision();
    refetchMatchesByCoach();
    refetchMatchesByWeek();
    // refetchMatchSchedulerValidation();
  });

  const getCoachTeamsLabel = (coach: TypeCoach) =>
    `${coach.name} (${coach.teams?.length})` || '';
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={generateMatches}
            sx={{ marginRight: 2 }}
          >
            Generate Matches
          </Button>
          <Button
            variant={'outlined'}
            color={'secondary'}
            onClick={deleteMatches}
            sx={{ marginRight: 2 }}
            disabled={!league?.matchesGenerated}
          >
            Delete All Matches
          </Button>
        </Grid>
        <Grid item xs={12}>
          {matchMessage && (
            <Alert severity={matchMessageSeverity}>
              <Typography variant={'body1'}>{matchMessage}</Typography>
            </Alert>
          )}
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={matchScheduleView}
            onChange={(event, newValue) =>
              newValue && setMatchScheduleView(newValue)
            }
            filterSelectedOptions
            clearOnBlur
            options={options}
            getOptionLabel={(option) => option || ''}
            isOptionEqualToValue={(option, value) => option === value}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            renderInput={(params) => (
              <TextField {...params} label="Schedule View" variant="outlined" />
            )}
          />
        </Grid>
        {matchScheduleView === 'Coach' && (
          <Grid item xs={12}>
            <Autocomplete
              value={coach}
              onChange={(event, newValue) => newValue && setCoach(newValue)}
              filterSelectedOptions
              clearOnBlur
              options={coaches}
              getOptionLabel={(option) => getCoachTeamsLabel(option)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => (
                <li {...props}>{getCoachTeamsLabel(option)}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Coach" variant="outlined" />
              )}
            />
          </Grid>
        )}
        {matchScheduleView === 'Week' && (
          <Grid item xs={12}>
            <Autocomplete
              value={week}
              onChange={(event, newValue) => newValue && setWeek(newValue)}
              filterSelectedOptions
              clearOnBlur
              options={weeks}
              getOptionLabel={(option) => `${option.week}` || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => (
                <li {...props}>{option.week}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Week" variant="outlined" />
              )}
            />
          </Grid>
        )}
        {matchScheduleView === 'Division' && (
          <Grid item xs={12}>
            <Autocomplete
              value={division}
              onChange={(event, newValue) => newValue && setDivision(newValue)}
              filterSelectedOptions
              clearOnBlur
              options={divisions}
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Division" variant="outlined" />
              )}
            />
          </Grid>
        )}
        <MatchList matches={matchList} hideColumn={matchScheduleView} />
      </Grid>
    </>
  );
};
