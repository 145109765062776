import React, { useContext } from 'react';
import { Button, Grid, ListItem, Paper, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DeleteForever } from '@mui/icons-material';
import {
  TypeIngredient,
  TypeMaterialOption,
  TypeMeasurement,
  TypePreparation,
} from '../../../types';
import { ChefContext, RecipeContext } from '../../../providers';
import { flexCenterStart } from '../../../data/styles';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useSortable } from '@dnd-kit/sortable';
import { UniqueIdentifier } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

type IngredientProps = {
  ingredient: TypeIngredient;
  ingredientIndex: number;
};

export const Ingredient = ({
  ingredient,
  ingredientIndex,
}: IngredientProps) => {
  const { measurements, preparations } = useContext(ChefContext);
  const { recipe, setRecipe, materialOptionsForRecipe } =
    useContext(RecipeContext);

  const parseInputs = (input: string, regex: RegExp) => {
    const matches: number[] = [];
    const matchGroupsCount = (regex.toString().match(/\(/g) || []).length;
    const match = input.match(regex);
    if (!match) {
      return matches;
    }

    for (let i = 1; i <= matchGroupsCount; i++) {
      matches.push(parseInt(match[i]));
    }

    return matches;
  };

  const isWholeNumber = (input: string) => parseInputs(input, /^(\d+)$/);
  const isMixedFraction = (input: string) =>
    parseInputs(input, /^(\d+)\s+(\d+)\/(\d+)$/);
  const isOnlyFraction = (input: string) =>
    parseInputs(input, /^(\d+)\/(\d+)$/);

  const processIngredient = (
    ingredient: TypeIngredient,
    input: string,
  ): TypeIngredient => {
    ingredient.stringValue = input;
    // if (ingredient.stringValue === undefined) return ingredient;
    if (input === '') {
      ingredient.amount = 0;
      ingredient.fractionalNumerator = 0;
      ingredient.fractionalDenominator = 0;
      ingredient.valid = true;
      return ingredient;
    }

    const wholeNumberResult = isWholeNumber(input);
    const onlyFractionResult = isOnlyFraction(input);
    const mixedFractionResult = isMixedFraction(input);

    if (wholeNumberResult.length) {
      console.log('wholeNumberResult', wholeNumberResult);
      ingredient.amount = wholeNumberResult[0];
      ingredient.fractionalNumerator = 0;
      ingredient.fractionalDenominator = 0;
      ingredient.valid = true;
    } else if (onlyFractionResult.length) {
      console.log('onlyFractionResult', onlyFractionResult);
      ingredient.amount = 0;
      ingredient.fractionalNumerator = onlyFractionResult[0];
      ingredient.fractionalDenominator = onlyFractionResult[1];
      ingredient.valid = true;
    } else if (mixedFractionResult.length) {
      console.log('mixedFractionResult', mixedFractionResult);
      ingredient.amount = mixedFractionResult[0];
      ingredient.fractionalNumerator = mixedFractionResult[1];
      ingredient.fractionalDenominator = mixedFractionResult[2];
      ingredient.valid = true;
    } else {
      console.log('no result good');
      ingredient.valid = false;
    }
    return ingredient;
  };

  // const ingredientProcessed = useProcessIngredientNumbers(1, ingredient);

  const handleIngredientAmountChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ingredientIndex: number,
  ) => {
    const eventValue = event.target.value;

    setRecipe({
      ...recipe,
      ingredientList: recipe?.ingredientList?.map((ingredient, index) => {
        if (index === ingredientIndex) {
          ingredient = processIngredient(ingredient, eventValue);
        }
        return ingredient;
      }),
    });
  };

  const handleIngredientMaterialOptionChange = (
    newValue: TypeMaterialOption | null,
    ingredientIndex: number,
  ) => {
    let changeValue: TypeMaterialOption | null = null;
    if (newValue) {
      changeValue =
        materialOptionsForRecipe.find(
          (materialOption) =>
            newValue.recipe?.id === materialOption.recipe?.id &&
            newValue.material?.id === materialOption.material?.id,
        ) || null;
    }
    setRecipe({
      ...recipe,
      ingredientList: recipe?.ingredientList?.map((ingredient, index) => {
        if (index === ingredientIndex) {
          ingredient.materialOption = changeValue;
        }
        return ingredient;
      }),
    });
  };

  const handleIngredientMeasurementChange = (
    event: React.SyntheticEvent,
    newValue: TypeMeasurement | string | null,
    ingredientIndex: number,
  ) => {
    let changeValue: TypeMeasurement | null = null;
    if (newValue && typeof newValue !== 'string') {
      changeValue =
        measurements.find(
          (measurement) =>
            newValue.measurementName === measurement.measurementName,
        ) || null;
    }
    setRecipe({
      ...recipe,
      ingredientList: recipe?.ingredientList?.map((ingredient, index) => {
        if (index === ingredientIndex) {
          ingredient.measurement = changeValue;
        }
        return ingredient;
      }),
    });
  };

  const handleIngredientPreparationChange = (
    event: React.SyntheticEvent,
    newValue: TypePreparation | string | null,
    ingredientIndex: number,
  ) => {
    let changeValue: TypePreparation | null = null;
    if (newValue && typeof newValue !== 'string') {
      changeValue =
        preparations.find(
          (preparation) =>
            newValue.preparationName === preparation.preparationName,
        ) || null;
    }
    setRecipe({
      ...recipe,
      ingredientList: recipe?.ingredientList?.map((ingredient, index) => {
        if (index === ingredientIndex) {
          ingredient.preparation = changeValue;
        }
        return ingredient;
      }),
    });
  };

  const removeRowIngredientList = (ingredientIndex: number) => {
    setRecipe({
      ...recipe,
      ingredientList: recipe?.ingredientList?.filter(
        (item, index) => index !== ingredientIndex,
      ),
    });
  };

  const initialAmount = () => {
    if (ingredient.amount && ingredient.fractionalNumerator) {
      return `${ingredient.amount} ${ingredient.fractionalNumerator}/${ingredient.fractionalDenominator}`;
    } else if (ingredient.amount && !ingredient.fractionalNumerator) {
      return `${ingredient.amount}`;
    } else if (ingredient.fractionalDenominator) {
      return `${ingredient.fractionalNumerator}/${ingredient.fractionalDenominator}`;
    } else {
      return '';
    }
  };

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: ingredient.sortableId as UniqueIdentifier,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Paper
      elevation={3}
      sx={{ width: '100%', marginBottom: 1 }}
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
      <ListItem
        sx={{
          pb: 0,
          pt: 0,
        }}
      >
        <Grid container spacing={2} mb={1} mt={1}>
          <Grid item xs={1} style={flexCenterStart}>
            <DragIndicatorIcon {...listeners} style={{ cursor: 'grab' }} />
          </Grid>
          <Grid item xs={5} md={2}>
            <TextField
              fullWidth
              variant="outlined"
              label="Amount"
              value={ingredient.stringValue || initialAmount() || ''}
              placeholder="Amount"
              error={ingredient.valid === false}
              onChange={(event) =>
                handleIngredientAmountChange(event, ingredientIndex)
              }
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Autocomplete
              value={ingredient.measurement || null}
              onChange={(event, newValue) =>
                handleIngredientMeasurementChange(
                  event,
                  newValue,
                  ingredientIndex,
                )
              }
              options={measurements}
              getOptionLabel={(option) => option.measurementName || ''}
              isOptionEqualToValue={(option, value) =>
                value !== null && option.id === value.id
              }
              renderOption={(props, option) => (
                <li {...props}>{option.measurementName}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Measurement" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              value={ingredient.materialOption || null}
              onChange={(_event, newValue) =>
                handleIngredientMaterialOptionChange(newValue, ingredientIndex)
              }
              groupBy={(option) => (option.recipe ? 'Recipes' : 'Materials')}
              options={materialOptionsForRecipe}
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option, value) =>
                value !== null &&
                option.recipe?.id === value.recipe?.id &&
                option.material?.id === value.material?.id
              }
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    ingredient.materialOption?.material?.id
                      ? 'Material'
                      : 'Material (Recipe)'
                  }
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Autocomplete
              value={ingredient.preparation || null}
              onChange={(event, newValue) =>
                handleIngredientPreparationChange(
                  event,
                  newValue,
                  ingredientIndex,
                )
              }
              options={preparations}
              getOptionLabel={(option) => option.preparationName || ''}
              isOptionEqualToValue={(option, value) =>
                value !== null && option.id === value.id
              }
              renderOption={(props, option) => (
                <li {...props}>{option.preparationName}</li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Preparation" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={2} style={flexCenterStart}>
            <Button
              variant="outlined"
              onClick={() => removeRowIngredientList(ingredientIndex)}
            >
              <DeleteForever />
            </Button>
          </Grid>
        </Grid>
      </ListItem>
    </Paper>
  );
};
