import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostMatchSchedulePDF = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (leagueId: number) =>
      axios
        .post(`api/match-schedule-pdf/league/${leagueId}`, {})
        .then((res) => res.data),
    {
      onMutate: (_leagueId: number) => {
        queryClient.cancelQueries('match-schedule-pdf/league');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('match-schedule-pdf/league');
        queryClient.invalidateQueries('division/league');
      },
    },
  );
};
