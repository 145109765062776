import { ChefHome, Directions, Orders } from '../../components';
import { TypeAppRoute } from '../../types';
import { TypeProjectConfig } from '../../types/_common/ProjectConfig';
import { commonAppRoutes, commonSysAdminAppRoutes } from './CommonRouteConfig';

const floristAdminRoutes = (): TypeAppRoute[] => [
  {
    name: 'Home',
    path: '/',
    component: ChefHome,
    showInDrawer: true,
  },
  {
    name: 'Directions',
    path: '/directions',
    component: Directions,
    showInDrawer: true,
  },
  {
    name: 'Orders',
    path: '/orders',
    component: Orders,
    showInDrawer: true,
  },
];

export const FloristRouteConfig = (): TypeProjectConfig => ({
  cldCloudName: 'hkgss7uwm',
  cldUploadPreset: 'tvlwmfw3',
  roleProjectRoutes: {
    ADMIN: {
      homeRoute: '/directions',
      appRoutes: [...floristAdminRoutes(), ...commonAppRoutes()],
    },
    SYSADMIN: {
      homeRoute: '/directions',
      appRoutes: [
        ...floristAdminRoutes(),
        ...commonAppRoutes(),
        ...commonSysAdminAppRoutes(),
      ],
    },
  },
});
