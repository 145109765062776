import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeSquareOrder } from '../../../types';

export const usePostSquareOrder = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (order: TypeSquareOrder) =>
      axios.post(`api/square-order`, order).then((res) => res.data),
    {
      onMutate: (_order: TypeSquareOrder) => {
        queryClient.cancelQueries('square-order');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('square-order');
      },
    },
  );
};
