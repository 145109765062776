import React, { useContext } from 'react';
import { BaseDialog } from './BaseDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type ConfirmRescheduleDialogProps = {
  weekNumber: number;
  confirmFn: () => void;
};

export const ConfirmRescheduleDialog = ({
  weekNumber,
  confirmFn,
}: ConfirmRescheduleDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { confirmRescheduleDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Confirm Reschedule of Week ${weekNumber}?`,
    contentText:
      'This will use the new time slots and regenerate matches for the week.',
    dismissiveAction: (
      <Button
        color="primary"
        onClick={() => closeDialog('confirmRescheduleDialog')}
      >
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={confirmFn}
      >
        Confirm Reschedule
      </Button>
    ),
  };

  return (
    <BaseDialog dialogProps={{ open: confirmRescheduleDialog }} {...props} />
  );
};
