import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeStripeProduct } from '../../../types';

export const usePostStripeProduct = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (stripeProduct: TypeStripeProduct) =>
      axios.post(`api/stripe-product`, stripeProduct).then((res) => res.data),
    {
      onMutate: (_stripeProduct: TypeStripeProduct) => {
        queryClient.cancelQueries('stripe-product');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('stripe-product');
      },
    },
  );
};
