import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTeam } from '../../../types';

const fetchTeamsByPracticeDivisionGroup = async (
  divisionGroupId: number,
): Promise<TypeTeam[]> => {
  const res = await axios.get(`api/team/division-group/${divisionGroupId}`);
  return res.data;
};

export const PrefetchTeamsByPracticeDivisionGroup = (
  divisionGroupId: number,
) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['team/division-group', divisionGroupId],
    () => fetchTeamsByPracticeDivisionGroup(divisionGroupId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetTeamsByPracticeDivisionGroup = (
  divisionGroupId: number,
  options?: UseQueryOptions<TypeTeam[], AxiosError, TypeTeam[], any>,
) => {
  return useQuery(
    ['team/division-group', divisionGroupId],
    () => fetchTeamsByPracticeDivisionGroup(divisionGroupId),
    options,
  );
};
