import React, { useContext } from 'react';
import { BaseDialog } from './BaseDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type DeleteMaterialDialogProps = {
  materialName: string;
  usages: number;
  deleteFn: () => void;
};

export const DeleteMaterialDialog = ({
  materialName,
  usages,
  deleteFn,
}: DeleteMaterialDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteMaterialDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Delete ${materialName}?`,
    contentText: `${materialName} is used in ${usages} recipes.`,
    dismissiveAction: (
      <Button
        color="primary"
        onClick={() => closeDialog('deleteMaterialDialog')}
      >
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={deleteFn}
      >
        Delete {materialName}
      </Button>
    ),
  };

  return <BaseDialog dialogProps={{ open: deleteMaterialDialog }} {...props} />;
};
