import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeAvailabilityDTO } from '../../../types';

export const usePostFindAvailability = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (availability: TypeAvailabilityDTO) =>
      axios
        .post(`api/appointment/availability`, availability)
        .then((res) => res.data),
    {
      onMutate: (_availability: TypeAvailabilityDTO) => {
        queryClient.cancelQueries('appointment/availability');
      },
      onError: (err) => {
        console.log(err);
      },
      onSuccess: () => {
        queryClient.invalidateQueries('appointment/availability');
      },
    },
  );
};
