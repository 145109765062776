import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { MaterialList } from './MaterialList';
import { usePostMaterial } from '../../../api';
import { genericSearch, genericSort, Loader } from '../../_common';
import { TypeMaterial } from '../../../types';
import { ChefContext, ProjectContext } from '../../../providers';

export const Materials = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { materials, isChefReady, refetchAll } = useContext(ChefContext);
  const [materialName, setMaterialName] = useState<string>('');
  const materialMutation = usePostMaterial();

  const createMaterial = () => {
    if (materialName === '') return;
    materialMutation.mutate(
      { materialName: materialName },
      {
        onSuccess: () => {
          setSnackbar(`Material Created: ${materialName}`);
          setMaterialName('');
          refetchAll();
        },
        onError: () => setSnackbar('Error Creating Material', 'error'),
      },
    );
  };

  const filteredMaterials = useMemo(
    () =>
      materials
        ?.filter((material) =>
          genericSearch<TypeMaterial>(material, { materialName: materialName }),
        )
        .sort((a, b) =>
          genericSort<TypeMaterial>(a, b, {
            property: 'materialName',
            isDescending: false,
          }),
        ),
    [materials, materialName],
  );

  if (!isChefReady) return <Loader />;

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="materialName"
              value={materialName}
              label="Search or Add Material"
              placeholder="Materials Title"
              onChange={(event) => setMaterialName(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button
              variant="outlined"
              disabled={materialName === ''}
              onClick={createMaterial}
            >
              Create Material
            </Button>
          </Grid>
        </Grid>
        <MaterialList
          materials={filteredMaterials}
          materialsAvailable={!!filteredMaterials?.length}
        />
      </Container>
    </Box>
  );
};
