import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { TypePreparation } from '../../../types';
import { useParams } from 'react-router-dom';
import {
  useGetPreparation,
  useGetRecipesByPreparation,
  usePostPreparation,
} from '../../../api';
import { RecipeList } from '../Recipe';

export const Preparation = () => {
  const { preparationId } = useParams();
  const [preparation, setPreparation] = useState<TypePreparation>({});
  useGetPreparation(Number(preparationId), {
    onSuccess: (data) => setPreparation(data),
    refetchOnWindowFocus: false,
  });
  const { data: recipeList, isLoading: recipeListLoading } =
    useGetRecipesByPreparation(Number(preparationId));
  const preparationMutation = usePostPreparation();

  const updatePreparation = () => {
    preparationMutation.mutate(preparation);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setPreparation(
      Object.assign({}, preparation, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="preparationName"
              value={preparation?.preparationName || ''}
              label="Enter Preparation Name"
              placeholder="Preparation Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updatePreparation}>
              Update Preparation
            </Button>
          </Grid>
        </Grid>

        <RecipeList
          recipes={recipeList ?? []}
          recipesAvailable={!recipeListLoading}
          flatList={true}
        />
      </Container>
    </Box>
  );
};
