import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TabView, WorkflowDialog } from '../../_common';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import {
  AuthContext,
  PlannerContext,
  ProjectContext,
} from '../../../providers';
import { useGetTagsByType } from '../../../api';
import {
  TypeTag,
  TypeWorkflow,
  TypeWorkflowStep,
  TypeWorkflowTask,
} from '../../../types';
import { ClearIcon } from '@mui/x-date-pickers';
import Autocomplete from '@mui/material/Autocomplete';
import { Add, Restore } from '@mui/icons-material';
import { parseISO } from 'date-fns';
import { EditWorkflowStep } from './EditWorkflowStep';
import { WorkflowHistory } from './WorkflowHistory';
import { getFilterIcon } from './getFilterIcon';

export const EditWorkflowCard = () => {
  const { performingAction } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { activeWorkflow, handleSaveWorkflow, handleRefreshActiveWorkflow } =
    useContext(PlannerContext);

  const [editWorkflowId, setEditWorkflowId] = useState(activeWorkflow?.id || 0);
  const [editWorkflowName, setEditWorkflowName] = useState(
    activeWorkflow?.name || '',
  );
  const [editWorkflowTags, setEditWorkflowTags] = useState<TypeTag | null>(
    null,
  );
  const [editWorkflowSteps, setEditWorkflowSteps] = useState<
    TypeWorkflowStep[]
  >([]);
  const [editWorkflowAutoRestart, setEditWorkflowAutoRestart] = useState(false);
  const [workflowRunStartAt, setWorkflowRunStartAt] = useState<Date | null>(
    null,
  );
  const [selectedTab, setSelectedTab] = useState(0);

  const { data: workflowTags } = useGetTagsByType('task');
  const editWorkflowNameRef = useRef<HTMLInputElement | null>(null);

  const handleEdit = (workflow: TypeWorkflow) => {
    if (workflow.id != editWorkflowId) {
      setSelectedTab(0);
    }
    setEditWorkflowId(workflow.id ?? 0);
    setEditWorkflowName(workflow.name ?? '');
    setEditWorkflowSteps(workflow.workflowStepList ?? []);
    setEditWorkflowTags(workflow.tags?.length ? workflow.tags[0] : null);
    setEditWorkflowAutoRestart(workflow.autoRestart ?? false);
    setWorkflowRunStartAt(
      workflow.workflowRunStartAt
        ? parseISO(workflow.workflowRunStartAt)
        : null,
    );
  };

  const handleReset = () => {
    setSelectedTab(0);
    handleRefreshActiveWorkflow();
  };
  const handleSetEditWorkflowTasks = (
    stepIndex: number,
    tasks: TypeWorkflowTask[],
  ) => {
    const newSteps = editWorkflowSteps.map((step, i) => {
      if (i === stepIndex) {
        return { ...step, workflowTaskList: tasks };
      }
      return step;
    });
    setEditWorkflowSteps(newSteps);
  };

  const ensureTags = (tag: TypeTag | null) => {
    if (!tag) {
      return [];
    }
    return [
      {
        ...tag,
        type: 'task',
        position: 1,
        organization: profile?.organizationContext,
      },
    ];
  };

  const onHandleSave = () => {
    handleSaveWorkflow({
      ...activeWorkflow,
      name: editWorkflowName,
      workflowStepList: editWorkflowSteps,
      tags: ensureTags(editWorkflowTags),
      profile: profile ?? undefined,
      organization: profile?.organizationContext,
      autoRestart: editWorkflowAutoRestart,
    });
  };

  useEffect(() => {
    if (!activeWorkflow) {
      return;
    }
    handleEdit(activeWorkflow);
  }, [activeWorkflow]);

  const handleGetOptionLabel = (option: string | TypeTag) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.name || '';
  };

  const handleTagOptionEqualToValue = (
    option: TypeTag,
    value: TypeTag | string,
  ) => (typeof value === 'string' ? option.name === value : option === value);

  const handleTagsChange = (
    _event: React.SyntheticEvent,
    newValue: TypeTag | string | null,
  ) => {
    if (!newValue) {
      return;
    }
    setEditWorkflowTags(
      typeof newValue === 'string'
        ? { name: newValue, type: 'task' }
        : newValue,
    );
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onHandleSave();
    }
  };

  const handleAddStep = () => {
    console.log('addd step');
    setEditWorkflowSteps([
      ...editWorkflowSteps,
      { position: editWorkflowSteps.length + 1 },
    ]);
  };

  const handleRemoveStep = (index: number) => {
    console.log('remove step', index, selectedTab, tabs.length);
    setEditWorkflowSteps(editWorkflowSteps.filter((_step, i) => i !== index));
    setSelectedTab(selectedTab - 1 > 0 ? selectedTab - 1 : 0);
  };

  const tabs = useMemo(
    () => [
      ...(activeWorkflow?.id
        ? [
            {
              name: 'History',
              icon: <Restore />,
              component: <WorkflowHistory />,
            },
          ]
        : []),
      ...editWorkflowSteps.map((workflowStep, stepIndex) => ({
        name: `Step ${stepIndex + 1}`,
        icon: getFilterIcon(stepIndex),
        component: (
          <EditWorkflowStep
            key={`edit-workflow-steps-${stepIndex}`}
            workflowStep={workflowStep}
            stepIndex={stepIndex}
            handleRemoveStep={handleRemoveStep}
            handleSetEditWorkflowTasks={handleSetEditWorkflowTasks}
          />
        ),
      })),
      {
        name: 'Add Step',
        icon: <Add />,
        component: <Fragment />,
        onClick: handleAddStep,
      },
    ],
    [activeWorkflow, editWorkflowSteps],
  );

  const mainFields = () => (
    <>
      <Grid item xs={6}>
        <Autocomplete
          freeSolo
          clearOnBlur
          value={editWorkflowTags}
          options={workflowTags || []}
          getOptionLabel={handleGetOptionLabel}
          filterSelectedOptions
          isOptionEqualToValue={handleTagOptionEqualToValue}
          onChange={handleTagsChange}
          renderInput={(params) => (
            <TextField {...params} label="Project" placeholder="Project" />
          )}
          disabled={performingAction}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={editWorkflowAutoRestart}
              onChange={(_event, checked) =>
                setEditWorkflowAutoRestart(checked)
              }
            />
          }
          label={'Auto-Restart'}
        />
      </Grid>
    </>
  );

  return (
    <WorkflowDialog
      title={'Edit Workflow'}
      isWarning={!!workflowRunStartAt}
      saveFn={onHandleSave}
      resetFn={handleReset}
    >
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder={'Workflow Name'}
            value={editWorkflowName}
            onChange={(event) => setEditWorkflowName(event.target.value)}
            onKeyDown={handleKeyPress}
            disabled={performingAction}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={() => {
                    setEditWorkflowName('');
                    editWorkflowNameRef.current?.focus();
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ),
            }}
          />
        </Grid>
        {mainFields()}
        <Grid item xs={12}>
          <TabView
            tabs={tabs}
            inputSelectedTab={selectedTab}
            onSelectTab={setSelectedTab}
          />
        </Grid>
      </Grid>
    </WorkflowDialog>
  );
};
