import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import { LeagueContext, ProjectContext } from '../../../providers';
import {
  TypeDivision,
  TypeEmailRecipient,
  TypeEmailSent,
} from '../../../types';
import Autocomplete from '@mui/material/Autocomplete';
import {
  useGetEmailRecipientsByDivision,
  useGetEmailRecipientsByLeague,
  usePostLeagueEmailSent,
} from '../../../api';
import { Emails } from '../../_common';

export const LeagueEmails = () => {
  const { setSnackbar } = useContext(ProjectContext);
  const { isLeagueReady, league, divisions } = useContext(LeagueContext);
  const [sendToDivision, setSendToDivision] = useState<boolean>(false);
  const [selectedDivision, setSelectedDivision] =
    useState<TypeDivision | null>();
  const [emailRecipients, setEmailRecipients] = useState<TypeEmailRecipient[]>(
    [],
  );
  const { data: leagueEmailRecipients } = useGetEmailRecipientsByLeague(
    league?.id!!,
  );
  const { data: divisionEmailRecipients } = useGetEmailRecipientsByDivision(
    selectedDivision?.id!!,
    { enabled: sendToDivision && !!selectedDivision },
  );

  const { mutate: leagueEmailSentMutation } = usePostLeagueEmailSent();
  const handleSendEmail = (emailSent: TypeEmailSent) =>
    leagueEmailSentMutation(
      {
        league: league,
        emailSent: emailSent,
      },
      {
        onSuccess: () => setSnackbar('Email Sent Successfully'),
      },
    );

  useEffect(() => {
    if (selectedDivision && sendToDivision && divisionEmailRecipients) {
      setEmailRecipients(divisionEmailRecipients || []);
    } else if (league && leagueEmailRecipients) {
      setEmailRecipients(leagueEmailRecipients || []);
    }
  }, [
    selectedDivision,
    sendToDivision,
    league,
    divisionEmailRecipients,
    leagueEmailRecipients,
  ]);

  if (!isLeagueReady) {
    return <div>Loading...</div>;
  }

  return (
    <Box m={2}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                checked={sendToDivision}
                onChange={(_event, checked) => setSendToDivision(checked)}
              />
            }
            label="Send to Division"
            labelPlacement="start"
          />
        </Grid>
        {sendToDivision && (
          <Grid item xs={12}>
            <Autocomplete
              value={selectedDivision}
              id="selectedDivision"
              options={divisions || []}
              getOptionLabel={(option) => option.name || ''}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(_event, newValue) => setSelectedDivision(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Division"
                  placeholder="Division"
                />
              )}
            />
          </Grid>
        )}
        <Emails recipientList={emailRecipients} sendEmail={handleSendEmail} />
      </Grid>
    </Box>
  );
};
