import React, { useContext, useState } from 'react';
import { Box, Button, Grid, TextField } from '@mui/material';
import { LeagueFieldList } from './index';
import { useGetLeagueFields, usePostLeagueField } from '../../../api';
import { LeagueContext } from '../../../providers';

export const LeagueFields = () => {
  const { league } = useContext(LeagueContext);
  const [leagueFieldName, setFieldName] = useState<string>('');
  const { data: leagueFieldsData, isLoading: leagueFieldsLoading } =
    useGetLeagueFields(Number(league?.id), { enabled: !!league?.id });
  const leagueFieldMutation = usePostLeagueField();

  const createField = () => {
    if (leagueFieldName === '') return;
    leagueFieldMutation.mutate({
      name: leagueFieldName,
      league: league,
    });
    setFieldName('');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldName(event.target.value);
  };

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <TextField
            fullWidth
            variant="outlined"
            id="leagueFieldName"
            value={leagueFieldName}
            label="Search or Add Field"
            placeholder="Field Name"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Button
            variant="contained"
            disabled={leagueFieldName === ''}
            onClick={createField}
          >
            Create Field
          </Button>
        </Grid>
      </Grid>
      <LeagueFieldList
        leagueFields={leagueFieldsData || []}
        leagueFieldsAvailable={!leagueFieldsLoading}
      />
    </Box>
  );
};
