import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeLeague } from '../../../types';

export const usePostLeague = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (league: TypeLeague) =>
      axios.post(`api/league`, league).then((res) => res.data),
    {
      onMutate: (league: TypeLeague) => {
        queryClient.cancelQueries('league');

        const oldLeague = queryClient.getQueryData(['league', league.id]);

        queryClient.setQueryData(['league', league.id], league);

        return () => queryClient.setQueryData(['league', league.id], oldLeague);
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('league');
        queryClient.invalidateQueries('league-week/league');
      },
    },
  );
};
