import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeAppointment, TypeFetchAppointmentsDTO } from '../../../types';

const fetchClientAppointments = async (
  fetch: TypeFetchAppointmentsDTO,
): Promise<TypeAppointment[]> => {
  const res = await axios.get(
    `api/appointment/client/${fetch.timeframe}/${fetch.profileId}`,
  );
  return res.data;
};

export const PrefetchClientAppointment = (fetch: TypeFetchAppointmentsDTO) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['appointment/client', fetch.profileId],
    () => fetchClientAppointments(fetch),
    {
      staleTime: 5000,
    },
  );
};

export const useGetClientAppointments = (
  fetch: TypeFetchAppointmentsDTO,
  options?: UseQueryOptions<
    TypeAppointment[],
    AxiosError,
    TypeAppointment[],
    any
  >,
) => {
  return useQuery(
    'appointment/client',
    () => fetchClientAppointments(fetch),
    options,
  );
};
