import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeProfileTag } from '../../../types';

const fetchProfileTag = async (): Promise<TypeProfileTag[]> => {
  const res = await axios.get(`api/profile-tag`);
  return res.data;
};

export const PrefetchProfileTag = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['profile-tag'], () => fetchProfileTag(), {
    staleTime: 5000,
  });
};

export const useGetProfileTags = (
  options?: UseQueryOptions<
    TypeProfileTag[],
    AxiosError,
    TypeProfileTag[],
    any
  >,
) => {
  return useQuery(['profile-tag'], () => fetchProfileTag(), options);
};
