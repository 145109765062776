import {
  DateTimeFormatKey,
  formatDateToStringTZ,
} from './formatDateToStringTZ';

export const formatTimeToStringTZ = (
  time: Date | string | null | undefined,
  tz?: string,
  format: DateTimeFormatKey = 'timeOrdinal',
  fallback?: string,
): string | undefined =>
  time
    ? typeof time === 'string'
      ? formatDateToStringTZ('2024-01-01T' + time, tz ? tz : 'UTC', format)
      : formatDateToStringTZ(time, tz ? tz : 'UTC', format)
    : fallback;
