import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  useGetStripePricesByStripeProductId,
  useGetStripeProduct,
  usePostStripePrice,
  usePostStripeProduct,
} from '../../../../api';
import { useParams } from 'react-router-dom';
import { ProjectContext } from '../../../../providers';
import { TypeStripePrice } from '../../../../types';
import Autocomplete from '@mui/material/Autocomplete';

export const StripePrices = () => {
  const { stripeProductId } = useParams();
  const { setSnackbar, performingAction, setPerformingAction } =
    useContext(ProjectContext);
  const [addPriceNickname, setAddPriceNickname] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('recurring');
  const [selectedRecurrence, setSelectedRecurrence] = useState<string>('month');
  const [addProductPrice, setAddProductPrice] = useState<number>(0);
  const { data: stripeProductData } = useGetStripeProduct(
    Number(stripeProductId),
    {
      enabled: !!stripeProductId,
    },
  );
  const { data: stripePricesData } = useGetStripePricesByStripeProductId(
    Number(stripeProductId),
    {
      enabled: !!stripeProductId,
    },
  );
  const { mutate: postStripeProduct } = usePostStripeProduct();
  const { mutate: postStripePrice } = usePostStripePrice();

  const handleSetDefaultPrice = (stripePrice: TypeStripePrice) => {
    if (!stripeProductData) return;
    setPerformingAction(true);
    stripeProductData.defaultPrice = stripePrice;
    postStripeProduct(stripeProductData, {
      onSuccess: () => setSnackbar('Set default price'),
      onError: () => setSnackbar('Error setting default price', 'error'),
      onSettled: () => setPerformingAction(false),
    });
  };

  const disableCreateStripePrice =
    !stripeProductData ||
    !addProductPrice ||
    (selectedType === 'recurring' && !selectedRecurrence);

  const handleCreateStripePriceSuccess = () => {
    setSnackbar('Stripe Price Created');
    setAddPriceNickname('');
    setAddProductPrice(0);
  };

  const createStripePrice = () => {
    if (!disableCreateStripePrice && stripeProductData) {
      const createStripePriceRecurring =
        selectedType === 'recurring'
          ? {
              interval: selectedRecurrence,
              intervalCount: 1,
              usageType: 'licensed',
            }
          : undefined;
      setPerformingAction(true);
      postStripePrice(
        {
          nickname: addPriceNickname,
          unitAmountDecimal: addProductPrice,
          stripePriceRecurring: createStripePriceRecurring,
          stripeProduct: stripeProductData?.stripeProduct,
        },
        {
          onSuccess: () => handleCreateStripePriceSuccess(),
          onError: () => setSnackbar('Error creating price', 'error'),
          onSettled: () => setPerformingAction(false),
        },
      );
    }
  };

  const handleDecimalInputChange = (e) => {
    console.log('decimal', e);
    const inputValue = e.target.value;
    // Use parseFloat to parse the input value
    const parsedValue = parseFloat(inputValue);

    // Check if the parsedValue is a valid number (not NaN)
    if (!isNaN(parsedValue)) {
      setAddProductPrice(parsedValue);
    } else {
      // Handle invalid input, e.g., display an error message
    }
  };

  if (!stripeProductData || !stripePricesData) {
    return <></>;
  }

  return (
    <Box m={2}>
      <Container>
        <Box mt={2}>
          <Typography variant="h4">
            Stripe Product: {stripeProductData?.name}
          </Typography>
          <Typography variant="h6">
            {stripeProductData?.stripeProduct}
          </Typography>
        </Box>
        <TableContainer>
          <Table style={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Interval</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stripePricesData?.map((sp) => {
                const isDefaultPrice =
                  stripeProductData?.defaultPrice?.id === sp.id;
                return (
                  <TableRow key={sp.id}>
                    <TableCell>{sp.stripePrice}</TableCell>
                    <TableCell>{sp.nickname}</TableCell>
                    <TableCell>{sp.type}</TableCell>
                    <TableCell>
                      {sp.stripePriceRecurring
                        ? `Every ${sp.stripePriceRecurring?.intervalCount} ${sp.stripePriceRecurring?.interval}`
                        : ''}
                    </TableCell>
                    <TableCell>
                      {`$${sp.unitAmountDecimal?.toFixed(2)}`}
                    </TableCell>
                    <TableCell>
                      {isDefaultPrice ? (
                        <Button
                          variant={'contained'}
                          disabled={true}
                          color={'secondary'}
                        >
                          Default
                        </Button>
                      ) : (
                        <Button
                          variant={'contained'}
                          onClick={() => handleSetDefaultPrice(sp)}
                          disabled={performingAction}
                        >
                          Set Default
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="addPriceNickname"
                    value={addPriceNickname}
                    label="Price Nickname"
                    placeholder="Price Nickname"
                    onChange={(event) =>
                      setAddPriceNickname(event.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Autocomplete
                    value={selectedType}
                    id="selectedType"
                    options={['recurring', 'one_time']}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    disableClearable
                    isOptionEqualToValue={(option, value) => option === value}
                    onChange={(_event, newValue) => setSelectedType(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{ minWidth: 200 }}
                        label="Select Type"
                        placeholder="Select Type"
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  {selectedType === 'recurring' && (
                    <Autocomplete
                      value={selectedRecurrence}
                      id="selectedRecurrence"
                      options={['month', 'year']}
                      getOptionLabel={(option) => option}
                      filterSelectedOptions
                      disableClearable
                      isOptionEqualToValue={(option, value) => option === value}
                      onChange={(_event, newValue) =>
                        setSelectedRecurrence(newValue)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ minWidth: 200 }}
                          label="Select Interval"
                          placeholder="Select Interval"
                        />
                      )}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    type={'number'}
                    id="addProductPrice"
                    value={addProductPrice}
                    label="Price Amount"
                    placeholder="Price Amount"
                    onChange={(event) => handleDecimalInputChange(event)}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    disabled={disableCreateStripePrice || performingAction}
                    onClick={createStripePrice}
                  >
                    Create Price
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};
