import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTeam } from '../../../types';

export const useDeleteTeam = () => {
  const queryClient = useQueryClient();

  return useMutation((team: TypeTeam) => axios.delete(`api/team/${team.id}`), {
    onMutate: (team: TypeTeam) => {
      queryClient.cancelQueries('team');

      const previousTeam = queryClient.getQueryData(['team', team.id]);

      queryClient.setQueryData(['team', team.id], team);

      return { previousTeam };
    },
    onError: () => {},
    onSuccess: () => {
      queryClient.invalidateQueries('team');
      queryClient.invalidateQueries('team/division');
    },
  });
};
