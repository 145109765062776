import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeCopyLeagueDTO } from '../../../types';

export const usePostCopyLeague = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (copyLeagueDTO: TypeCopyLeagueDTO) =>
      axios.post(`api/league/copy`, copyLeagueDTO).then((res) => res.data),
    {
      onMutate: (copyLeagueDTO: TypeCopyLeagueDTO) => {
        queryClient.cancelQueries('league');

        const oldLeague = queryClient.getQueryData([
          'league/copy',
          copyLeagueDTO.targetLeagueId,
        ]);

        queryClient.setQueryData(
          ['league/copy', copyLeagueDTO.targetLeagueId],
          copyLeagueDTO,
        );

        return () =>
          queryClient.setQueryData(
            ['league', copyLeagueDTO.targetLeagueId],
            oldLeague,
          );
      },
      onError: () => {},
      onSuccess: () => {},
    },
  );
};
