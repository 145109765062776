import React, { useContext } from 'react';
import {
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { Restore, Save } from '@mui/icons-material';
import { ChefContext, RecipeContext } from '../../../providers';
import { flexCenterStart } from '../../../data/styles';
import { TypeTag } from '../../../types';

export const RecipeHeader = () => {
  const { allRecipeTags } = useContext(ChefContext);
  const { recipe, setRecipe, updateRecipe, resetRecipe, isRecipeValid } =
    useContext(RecipeContext);

  const handleRecipeTagsChange = (
    event: React.SyntheticEvent,
    newValue: TypeTag[],
  ) =>
    setRecipe({
      ...recipe,
      tags: newValue,
    });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const [eventId, eventValue] = [event.target.id, event.target.value];
    setRecipe({
      ...recipe,
      recipeTitle: eventId === 'recipeTitle' ? eventValue : recipe?.recipeTitle,
      recipeDescription:
        eventId === 'recipeDescription'
          ? eventValue
          : recipe?.recipeDescription,
      youtubeEmbedCode:
        eventId === 'youtubeEmbedCode' ? eventValue : recipe?.youtubeEmbedCode,
    });
  };

  return (
    <>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          variant="outlined"
          id="recipeTitle"
          label="Recipe Name"
          value={recipe.recipeTitle || ''}
          placeholder="Recipe Title"
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <Autocomplete
          multiple
          value={recipe?.tags || []}
          onChange={(event, newValue) =>
            handleRecipeTagsChange(event, newValue)
          }
          filterSelectedOptions
          clearOnBlur
          options={allRecipeTags || []}
          getOptionLabel={(option) => option.name || ''}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          renderInput={(params) => (
            <TextField {...params} label="Recipe Tags" variant="outlined" />
          )}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <FormControlLabel
          value="start"
          control={
            <Switch
              color="primary"
              checked={recipe.isMaterial || false}
              onChange={(_event, checked) =>
                setRecipe({ ...recipe, isMaterial: checked })
              }
            />
          }
          label="Is Material?"
          labelPlacement="start"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextField
          fullWidth
          variant="outlined"
          id="youtubeEmbedCode"
          label="YouTube Embed Code"
          value={recipe.youtubeEmbedCode || ''}
          placeholder="The text after https://www.youtube.com/watch?v="
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} md={8} sx={flexCenterStart}>
        <Button
          variant="contained"
          onClick={updateRecipe}
          startIcon={<Save />}
          disabled={!isRecipeValid}
          sx={{ marginRight: 2 }}
        >
          Update Recipe
        </Button>
        <Button
          variant="outlined"
          onClick={resetRecipe}
          startIcon={<Restore />}
        >
          Reset Recipe
        </Button>
      </Grid>
    </>
  );
};
