import React, { useContext } from 'react';
import { BaseDialog } from './BaseDialog';
import { DialogContext, ProjectContext } from '../../../providers';
import { Button } from '@mui/material';

type DeleteRecipeDialogProps = {
  recipeName: string;
  deleteFn: () => void;
};

export const DeleteRecipeDialog = ({
  recipeName,
  deleteFn,
}: DeleteRecipeDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteRecipeDialog } = dialogs;
  const { performingAction } = useContext(ProjectContext);

  const props = {
    title: `Delete ${recipeName}?`,
    contentText:
      "This will delete all ingredients and instructions you've provided.",
    dismissiveAction: (
      <Button color="primary" onClick={() => closeDialog('deleteRecipeDialog')}>
        Cancel
      </Button>
    ),
    confirmingAction: (
      <Button
        color="primary"
        disabled={performingAction}
        variant="contained"
        onClick={deleteFn}
      >
        Delete {recipeName}
      </Button>
    ),
  };

  return <BaseDialog dialogProps={{ open: deleteRecipeDialog }} {...props} />;
};
