import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeEmailInviteDTO } from '../../../types';

const fetchLeagueInvitedCoachEmails = async (
  leagueId: number,
): Promise<TypeEmailInviteDTO[]> => {
  const res = await axios.get(
    `api/league-email/invite-coaches/league/${leagueId}`,
  );
  return res.data;
};

export const PrefetchLeagueInvitedCoachEmail = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['league-email/invite-coaches/league', leagueId],
    () => fetchLeagueInvitedCoachEmails(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLeagueInvitedCoachEmails = (
  leagueId: number,
  options?: UseQueryOptions<
    TypeEmailInviteDTO[],
    AxiosError,
    TypeEmailInviteDTO[],
    any
  >,
) => {
  return useQuery(
    ['league-email/invite-coaches/league', leagueId],
    () => fetchLeagueInvitedCoachEmails(leagueId),
    options,
  );
};
