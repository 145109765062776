import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypePreparation } from '../../../types';

const fetchPreparations = async (): Promise<TypePreparation[]> => {
  const res = await axios.get('api/preparation');
  return res.data;
};

export const PrefetchPreparation = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(['preparation'], fetchPreparations, {
    staleTime: 5000,
  });
};

export const useGetPreparations = (
  options?: UseQueryOptions<
    TypePreparation[],
    AxiosError,
    TypePreparation[],
    any
  >,
) => {
  return useQuery('preparation', fetchPreparations, options);
};
