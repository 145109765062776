import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostStartWorkflow = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (workflowId: number) =>
      axios.post(`api/workflow-run/${workflowId}`).then((res) => res.data),
    {
      onMutate: (_workflowId: number) => {
        queryClient.cancelQueries('workflow');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('workflow');
        // queryClient.invalidateQueries(['workflow', variables.id]).then(_ => {})
      },
    },
  );
};
