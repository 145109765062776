import React, { useContext, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { TypeBodyGroup } from '../../../types';
import { useGetBodyGroup, usePostBodyGroup } from '../../../api';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../../../providers';

export const BodyGroup = () => {
  const { bodyGroupId } = useParams();
  const { profile } = useContext(AuthContext);
  const [bodyGroup, setBodyGroup] = useState<TypeBodyGroup>({});
  useGetBodyGroup(Number(bodyGroupId), {
    onSuccess: (data) => setBodyGroup(data),
    enabled: !!profile,
    refetchOnWindowFocus: false,
  });
  const bodyGroupMutation = usePostBodyGroup();

  const updateBodyGroup = () => {
    bodyGroupMutation.mutate(bodyGroup);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setBodyGroup(
      Object.assign({}, bodyGroup, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="bodyGroupName"
              value={bodyGroup?.bodyGroupName || ''}
              label="Enter Body Group Name"
              placeholder="Body Group Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateBodyGroup}>
              Update Body Group
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
