import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeMeetingRecap } from '../../../types';

export const usePostMeetingRecap = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (meetingRecap: TypeMeetingRecap) =>
      axios.post(`api/meeting-recap`, meetingRecap).then((res) => res.data),
    {
      onMutate: (_meetingRecap: TypeMeetingRecap) => {
        queryClient.cancelQueries('meeting-recap');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('meeting-recap');
        // queryClient.invalidateQueries(['meetingRecap', variables.id]).then(_ => {})
      },
    },
  );
};
