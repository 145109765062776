import React, { useContext, useMemo } from 'react';
import { Box, Container, Grid, IconButton, TextField } from '@mui/material';
import { TypeAnnouncement, TypeRole, TypeRoleOptions } from '../../../types';
import { useGetRoles, usePostAnnouncement } from '../../../api';
import { ClearIcon, DateTimePicker } from '@mui/x-date-pickers';
import { AuthContext, DialogContext, ProjectContext } from '../../../providers';
import { AnnouncementDialog } from '../Dialogs';
import Autocomplete from '@mui/material/Autocomplete';
import { Loader } from '../Loader';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import { formatDateToStringTZ } from '../Utils/formatDateToStringTZ';
import { parseISOSafe } from '../Utils/parseISOSafe';

export type AnnouncementProps = {
  announcement: TypeAnnouncement | null;
  setAnnouncement: (_announcement: TypeAnnouncement) => void;
  selectableRoles?: TypeRoleOptions[];
};
export const Announcement = ({
  announcement,
  setAnnouncement,
  selectableRoles,
}: AnnouncementProps) => {
  const { setPerformingAction, setSnackbar } = useContext(ProjectContext);
  const { profile } = useContext(AuthContext);
  const { closeDialog } = useContext(DialogContext);

  const {
    data: rolesData,
    isLoading: rolesLoading,
    error: rolesError,
  } = useGetRoles();
  const { mutate: announcementMutation } = usePostAnnouncement();

  const roles: TypeRole[] = useMemo(() => {
    if (selectableRoles) {
      return (
        rolesData?.filter((role) =>
          selectableRoles.includes(role.authority as TypeRoleOptions),
        ) ?? []
      );
    }
    return rolesData ?? [];
  }, [rolesData, selectableRoles]);
  const handleSave = (announcement: TypeAnnouncement | null) => {
    if (!announcement) return;
    setPerformingAction(true);
    announcementMutation(announcement, {
      onSuccess: () => {
        setSnackbar('Announcement saved');
        closeDialog('announcementDialog');
      },
      onSettled: () => {
        setPerformingAction(false);
      },
    });
  };

  if (rolesLoading || rolesError) {
    return <Loader />;
  }
  return (
    <AnnouncementDialog
      title={announcement?.id ? 'Edit Announcement' : 'Create Announcement'}
      saveFn={() => handleSave(announcement)}
      resetFn={() => {}}
    >
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="announcementTitle"
              value={announcement?.announcementTitle || ''}
              label="Enter Announcement Title"
              placeholder="Announcement Title"
              onChange={(event) =>
                setAnnouncement({
                  ...announcement,
                  announcementTitle: event.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <DateTimePicker
                desktopModeMediaQuery={'@media (min-width: 800px)'}
                sx={{ width: '100%' }}
                label="Start Date"
                value={parseISOSafe(announcement?.startAt)}
                onChange={(newValue) => {
                  setAnnouncement({
                    ...announcement,
                    startAt: formatDateToStringTZ(
                      newValue,
                      profile?.timeZone || '',
                    ),
                  });
                }}
              />
              <IconButton
                onClick={() =>
                  setAnnouncement({
                    ...announcement,
                    startAt: undefined,
                  })
                }
              >
                <ClearIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <DateTimePicker
                desktopModeMediaQuery={'@media (min-width: 800px)'}
                sx={{ width: '100%' }}
                label="Start Date"
                value={parseISOSafe(announcement?.endAt)}
                onChange={(newValue) => {
                  setAnnouncement({
                    ...announcement,
                    endAt: formatDateToStringTZ(
                      newValue,
                      profile?.timeZone || '',
                    ),
                  });
                }}
              />
              <IconButton
                onClick={() =>
                  setAnnouncement({
                    ...announcement,
                    endAt: undefined,
                  })
                }
              >
                <ClearIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              value={announcement?.roles}
              id="roles"
              options={roles}
              getOptionLabel={(option) => option.authority || ''}
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(_event, newValue) =>
                setAnnouncement({
                  ...announcement,
                  roles: newValue ?? undefined,
                })
              }
              renderInput={(params) => (
                <TextField {...params} label="Roles" placeholder="Roles" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MDEditor
              value={announcement?.announcementNotes || ''}
              onChange={(value) =>
                setAnnouncement({
                  ...announcement,
                  announcementNotes: value,
                })
              }
              height={'100%'}
              preview={'edit'}
              previewOptions={{
                rehypePlugins: [[rehypeSanitize]],
              }}
              textareaProps={{
                maxLength: 4000,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </AnnouncementDialog>
  );
};
