import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeEmailRecipient } from '../../../types';

const fetchEmailRecipientsByDivision = async (
  divisionId: number,
): Promise<TypeEmailRecipient[]> => {
  const res = await axios.get(`api/email-recipient/division/${divisionId}`);
  return res.data;
};

export const PrefetchEmailRecipientByDivision = (divisionId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['email-recipient/division', divisionId],
    () => fetchEmailRecipientsByDivision(divisionId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetEmailRecipientsByDivision = (
  divisionId: number,
  options?: UseQueryOptions<
    TypeEmailRecipient[],
    AxiosError,
    TypeEmailRecipient[],
    any
  >,
) => {
  return useQuery(
    ['email-recipient/division', divisionId],
    () => fetchEmailRecipientsByDivision(divisionId),
    options,
  );
};
