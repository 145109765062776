import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeCourse } from '../../../types';

export const useDeleteCourse = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (course: TypeCourse) =>
      axios.delete(`api/course/${course.id}`).then((res) => res.data),
    {
      onMutate: (_course: TypeCourse) => {
        queryClient.cancelQueries('course');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('course');
        // queryClient.invalidateQueries(['course', variables.id]).then(_ => {})
      },
    },
  );
};
