import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeTimeSlot } from '../../../types';

const fetchTimeSlotsByLeagueWeek = async (
  leagueWeekId: number,
): Promise<TypeTimeSlot[]> => {
  const res = await axios.get(`api/time-slot/league-week/${leagueWeekId}`);
  return res.data;
};

export const PrefetchTimeSlot = (leagueWeekId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['time-slot/league-week', leagueWeekId],
    () => fetchTimeSlotsByLeagueWeek(leagueWeekId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetTimeSlotsByLeagueWeek = (
  leagueWeekId: number,
  options?: UseQueryOptions<TypeTimeSlot[], AxiosError, TypeTimeSlot[], any>,
) => {
  return useQuery(
    ['time-slot/league-week', leagueWeekId],
    () => fetchTimeSlotsByLeagueWeek(leagueWeekId),
    options,
  );
};
