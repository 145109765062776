import React, { useContext, useState } from 'react';

import validate from 'validate.js';

import {
  DialogContent,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Tooltip,
} from '@mui/material';

import { Edit as EditIcon, Lock as LockIcon } from '@mui/icons-material';

import { constraints } from '../../data/constraints';
import { AuthContext, ProjectContext } from '../../providers';

type CustomError = {
  emailAddress: string;
  password: string;
  passwordConfirmation: string;
};

export const SecurityTab = () => {
  const { changePassword: authChangePassword } = useContext(AuthContext);
  const { performingAction } = useContext(ProjectContext);
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState<CustomError | null>(null);
  const [showingField, setShowingField] = useState('');

  const showField = (fieldId) => {
    if (!fieldId) {
      return;
    }

    setShowingField(fieldId);
  };

  const hideFields = (
    _: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement> | null = null,
  ) => {
    setShowingField('');
    setPassword('');
    setPasswordConfirmation('');
    setErrors(null);
  };

  const changeField = (fieldId) => {
    const errors = validate(
      {
        password: password,
      },
      {
        password: constraints.password,
      },
    );
    switch (fieldId) {
      case 'password':
        if (errors) {
          setErrors(errors);
          return;
        }

        setErrors(null);
        showField('password-confirmation');
        return;

      case 'password-confirmation':
        changePassword();
        return;

      default:
        return;
    }
  };

  const changePassword = () => {
    const errors = validate(
      {
        password: password,
        passwordConfirmation: passwordConfirmation,
      },
      {
        password: constraints.password,
        passwordConfirmation: constraints.passwordConfirmation,
      },
    );

    if (errors) {
      setErrors(errors);
      return;
    }

    setErrors(null);
    authChangePassword(password);
    hideFields();
  };

  const handleKeyDown = (event, fieldId) => {
    if (!event || !fieldId) {
      return;
    }

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    const key = event.key;

    if (!key) {
      return;
    }

    if (key === 'Escape') {
      hideFields();
    } else if (key === 'Enter') {
      changeField(fieldId);
    }
  };

  const handlePasswordChange = (event) => {
    if (!event) {
      return;
    }

    setPassword(event.target.value);
  };

  const handlePasswordConfirmationChange = (event) => {
    if (!event) {
      return;
    }

    setPasswordConfirmation(event.target.value);
  };

  return (
    <DialogContent>
      <List disablePadding>
        <ListItem>
          <Hidden xsDown>
            <ListItemIcon>
              <LockIcon />
            </ListItemIcon>
          </Hidden>

          {showingField === 'password' && (
            <TextField
              autoComplete="new-password"
              autoFocus
              disabled={performingAction}
              error={!!(errors && errors.password)}
              fullWidth
              helperText={
                errors && errors.password
                  ? errors.password
                  : 'Press Enter to change your password'
              }
              label="Password"
              required
              type="password"
              value={password}
              variant="filled"
              InputLabelProps={{ required: false }}
              onBlur={hideFields}
              onKeyDown={(event) => handleKeyDown(event, 'password')}
              onChange={handlePasswordChange}
            />
          )}

          {showingField === 'password-confirmation' && (
            <TextField
              autoComplete="new-password"
              autoFocus
              disabled={performingAction}
              error={!!(errors && errors.passwordConfirmation)}
              fullWidth
              helperText={
                errors && errors.passwordConfirmation
                  ? errors.passwordConfirmation
                  : 'Press Enter to change your password'
              }
              label="Password confirmation"
              required
              type="password"
              value={passwordConfirmation}
              variant="filled"
              InputLabelProps={{ required: false }}
              onBlur={hideFields}
              onKeyDown={(event) =>
                handleKeyDown(event, 'password-confirmation')
              }
              onChange={handlePasswordConfirmationChange}
            />
          )}

          {showingField !== 'password' &&
            showingField !== 'password-confirmation' && (
              <>
                <Hidden xsDown>
                  <ListItemText primary="Password" />
                </Hidden>

                <Hidden smUp>
                  <ListItemText primary="Password" />
                </Hidden>

                <ListItemSecondaryAction>
                  <Tooltip title="Change">
                    <div>
                      <IconButton
                        disabled={performingAction}
                        onClick={() => showField('password')}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                  </Tooltip>
                </ListItemSecondaryAction>
              </>
            )}
        </ListItem>
      </List>
    </DialogContent>
  );
};
