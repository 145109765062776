import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

export const usePostAcceptOrgInvite = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (orgInviteId: number) =>
      axios.post(`api/org-invite/${orgInviteId}`, {}).then((res) => res.data),
    {
      onMutate: (orgInviteId: number) => {
        queryClient.cancelQueries('org-invite');

        const oldOrgInvite = queryClient.getQueryData([
          'org-invite',
          orgInviteId,
        ]);

        return () => {
          queryClient.setQueryData(['org-invite', orgInviteId], oldOrgInvite);
        };
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('org-invite');
        queryClient.invalidateQueries('profile');
      },
    },
  );
};
