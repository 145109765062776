import React, { ReactElement, useState } from 'react';
import { Alert, Box, Button, Container, Grid, TextField } from '@mui/material';
import { TypeTag } from '../../../types';
import { useParams } from 'react-router-dom';
import { useGetTag, usePostTag } from '../../../api';

export type TagProps = {
  children?: ReactElement;
};

export const Tag = ({ children }: TagProps) => {
  const { tagId } = useParams();
  const [tag, setTag] = useState<TypeTag>({});
  const { isSuccess } = useGetTag(Number(tagId), {
    onSuccess: (data) => setTag(data),
    refetchOnWindowFocus: false,
  });
  const tagMutation = usePostTag();

  const updateTag = () => {
    tagMutation.mutate(tag);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setTag(
      Object.assign({}, tag, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  if (!isSuccess) {
    return <Alert color={'error'}>This Tag Does Not Exist</Alert>;
  }

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="name"
              value={tag?.name || ''}
              label="Enter Tag Name"
              placeholder="Tag Name"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateTag}>
              Update Tag
            </Button>
          </Grid>
        </Grid>

        {children || <Alert color={'info'}>No attached data</Alert>}
      </Container>
    </Box>
  );
};
