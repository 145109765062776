import React from 'react';
import { Tag } from '../Tag';
import { useGetImagesByTag } from '../../../api';
import { ImageList } from '../Image';
import { useParams } from 'react-router-dom';

export const ImageTag = () => {
  const { tagId } = useParams();
  const { data: imageList, isSuccess } = useGetImagesByTag(Number(tagId));

  return (
    <Tag>
      <ImageList images={imageList || []} imagesAvailable={isSuccess} />
    </Tag>
  );
};
