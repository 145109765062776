import React, { useContext } from 'react';
import { DialogContext } from '../../../providers';
import { DeleteDialog } from './DeleteDialog';

type DeleteCoachDialogProps = {
  deleteTitle: string;
  contentText: string;
  deleteFn: () => void;
};

export const DeleteCoachDialog = ({
  deleteTitle,
  contentText,
  deleteFn,
}: DeleteCoachDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteCoachDialog } = dialogs;

  return (
    <DeleteDialog
      deleteTitle={deleteTitle}
      contentText={contentText}
      deleteFn={deleteFn}
      closeFn={() => closeDialog('deleteCoachDialog')}
      open={deleteCoachDialog}
    />
  );
};
