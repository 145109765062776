import React, { useContext } from 'react';
import { LeagueContext } from '../../../providers';
import { Announcements } from '../../_common';
import { TypeRoleOptions } from '../../../types';

export const LeagueAnnouncements = () => {
  const { league } = useContext(LeagueContext);

  return (
    <Announcements
      league={league}
      selectableRoles={['COACH', 'PARENT'] as TypeRoleOptions[]}
    />
  );
};
