import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeImage } from '../../../types';

export const usePostImage = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (image: TypeImage) =>
      axios.post(`api/image`, image).then((res) => res.data),
    {
      onMutate: (image: TypeImage) => {
        queryClient.cancelQueries('image');

        const oldQuestionnaireQuestion = queryClient.getQueryData([
          'image',
          image.id,
        ]);

        queryClient.setQueryData(['image', image.id], image);

        return () =>
          queryClient.setQueryData(
            ['image', image.id],
            oldQuestionnaireQuestion,
          );
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('image');
        // queryClient.invalidateQueries(['image', variables.id]).then(_ => {})
      },
    },
  );
};
