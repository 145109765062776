import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeAppointmentCalendar } from '../../../types';

const fetchAppointmentCalendars = async (): Promise<
  TypeAppointmentCalendar[]
> => {
  const res = await axios.get('api/appointment-calendar');
  return res.data;
};

export const PrefetchAppointmentCalendar = () => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['appointment-calendar'],
    fetchAppointmentCalendars,
    {
      staleTime: 5000,
    },
  );
};

export const useGetAppointmentCalendars = (
  options?: UseQueryOptions<
    TypeAppointmentCalendar[],
    AxiosError,
    TypeAppointmentCalendar[],
    any
  >,
) => {
  return useQuery('appointment-calendar', fetchAppointmentCalendars, options);
};
