import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { ToggleChips } from './ToggleChips';
import { Info, InfoOutlined } from '@mui/icons-material';
import { PlannerContext } from '../../../providers';
import { PlannerToggleChips, PlannerFilterChips } from './index';

export const PlannerFilterAndSort = () => {
  const { showChips, setShowChips } = useContext(PlannerContext);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PlannerFilterChips />
        </Grid>
        <Grid item xs={12} md={6}>
          <PlannerToggleChips />
          <ToggleChips
            isActive={showChips}
            onToggle={setShowChips}
            label={'Show Chips'}
            activeIcon={<Info />}
            inactiveIcon={<InfoOutlined />}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
