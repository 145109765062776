import React, { useContext, useMemo, useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { RecipeList } from './RecipeList';
import { useGetRecipes, usePostRecipe } from '../../../api';
import { TypeRecipe, TypeTag } from '../../../types';
import { genericSearch, genericSort, Loader } from '../../_common';
import Autocomplete from '@mui/material/Autocomplete';
import { ChefContext } from '../../../providers';

export const Recipes = () => {
  const { allRecipeTags } = useContext(ChefContext);
  const [recipeTitle, setRecipeTitle] = useState<string>('');
  const [tags, setTags] = useState<TypeTag[]>([]);
  const [recipes, setRecipes] = useState<TypeRecipe[]>([]);
  const { ...recipeQueryInfo } = useGetRecipes({
    onSuccess: (data) => setRecipes(data),
  });
  const recipeMutation = usePostRecipe();

  const createRecipe = () => {
    if (recipeTitle === '') return;
    recipeMutation.mutate(
      {
        recipeTitle: recipeTitle,
        tags: tags,
      },
      {
        onSuccess: () => {
          setRecipeTitle('');
          setTags([]);
        },
      },
    );
  };

  const filteredRecipes = useMemo(() => {
    const queryObject: object = {};
    if (recipeTitle) queryObject['recipeTitle'] = recipeTitle;
    if (tags.length) queryObject['tags'] = tags;
    return recipes
      .filter((recipe) => genericSearch<TypeRecipe>(recipe, queryObject))
      .sort((a, b) =>
        genericSort<TypeRecipe>(a, b, {
          property: 'recipeTitle',
          isDescending: false,
        }),
      );
  }, [recipeTitle, tags, recipes]);

  if (recipeQueryInfo.isLoading || recipeQueryInfo.error) return <Loader />;

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="recipeTitle"
              value={recipeTitle}
              label="Search or Add Recipe"
              placeholder="Recipes Title"
              onChange={(event) => setRecipeTitle(event.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Autocomplete
              disablePortal
              multiple
              options={allRecipeTags}
              getOptionLabel={(option) => option.name || ''}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              value={tags}
              renderInput={(params) => (
                <TextField {...params} label="Recipe Tags" variant="outlined" />
              )}
              onChange={(_event, newValue) => setTags(newValue)}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Button
              variant="outlined"
              disabled={recipeTitle === ''}
              onClick={createRecipe}
            >
              Create Recipe
            </Button>
          </Grid>
        </Grid>
        <RecipeList
          recipes={filteredRecipes}
          recipesAvailable={!recipeQueryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
