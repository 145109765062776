import { Box, Container, Grid } from '@mui/material';
import React from 'react';

export const Limits = () => {
  return (
    <Box mt={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};
