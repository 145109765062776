import { useMutation, useQueryClient } from 'react-query';
import { TypeElasticSearchDTO } from '../../../types';
import axios from 'axios';

export const useSearchTask = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (task: TypeElasticSearchDTO) =>
      axios.post(`api/task/search`, task).then((res) => res.data),
    {
      onMutate: (_task: TypeElasticSearchDTO) => {
        queryClient.cancelQueries('task/search');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('task/search');
      },
    },
  );
};
