import React, { useState } from 'react';
import { Box, Button, Container, Grid, TextField } from '@mui/material';
import { TypeDiscussionTopic } from '../../../types';
import { useParams } from 'react-router-dom';
import { useGetDiscussionTopic, usePostDiscussionTopic } from '../../../api';

export const DiscussionTopic = () => {
  const { discussionTopicId } = useParams();
  const [discussionTopic, setDiscussionTopic] = useState<TypeDiscussionTopic>(
    {},
  );
  useGetDiscussionTopic(Number(discussionTopicId), {
    onSuccess: (data) => setDiscussionTopic(data),
    refetchOnWindowFocus: false,
  });
  const discussionTopicMutation = usePostDiscussionTopic();

  const updateDiscussionTopic = () => {
    discussionTopicMutation.mutate(discussionTopic);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setDiscussionTopic(
      Object.assign({}, discussionTopic, {
        [event.target.id]: event.target.value as string,
      }),
    );
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8}>
            <TextField
              fullWidth
              variant="outlined"
              id="discussionTopicTitle"
              value={discussionTopic?.discussionTopicTitle || ''}
              label="Enter Discussion Topic Title"
              placeholder="Discussion Topic Title"
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="outlined" onClick={updateDiscussionTopic}>
              Update Discussion Topic
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              maxRows={10}
              variant="outlined"
              id="discussionTopicDescription"
              value={discussionTopic?.discussionTopicDescription || ''}
              label="Enter Discussion Topic Notes"
              placeholder="Discussion Topic Notes"
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
