import React from 'react';
import { Box, Button, Container, Grid } from '@mui/material';
import { MeetingRecapList } from './MeetingRecapList';
import { useGetMeetingRecaps, usePostMeetingRecap } from '../../../api';

export const MeetingRecaps = () => {
  const { ...queryInfo } = useGetMeetingRecaps();
  const meetingRecapMutation = usePostMeetingRecap();

  const createMeetingRecap = () => {
    meetingRecapMutation.mutate({});
  };

  return (
    <Box m={2}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" onClick={createMeetingRecap}>
              Create New Meeting Recap
            </Button>
          </Grid>
        </Grid>
        <MeetingRecapList
          meetingRecaps={queryInfo.data}
          meetingRecapsAvailable={!queryInfo.isLoading}
        />
      </Container>
    </Box>
  );
};
