import React from 'react';
import { Tag } from '../../_common/Tag';
import { useGetRecipesByTag } from '../../../api';
import { RecipeList } from '../Recipe';
import { useParams } from 'react-router-dom';

export const RecipeTag = () => {
  const { tagId } = useParams();
  const { data: recipeList, isSuccess } = useGetRecipesByTag(Number(tagId));

  return (
    <Tag>
      <RecipeList recipes={recipeList || []} recipesAvailable={isSuccess} />
    </Tag>
  );
};
