import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMembershipDTO } from '../../../types';

const fetchCanvaLinkMembership = async (
  membershipId: number,
): Promise<TypeMembershipDTO> => {
  const res = await axios.get(`api/membership/${membershipId}`);
  return res.data;
};

export const PrefetchCanvaLinkMembership = (membershipId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['membership', membershipId],
    () => fetchCanvaLinkMembership(membershipId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetCanvaLinkMembership = (
  membershipId: number,
  options?: UseQueryOptions<
    TypeMembershipDTO,
    AxiosError,
    TypeMembershipDTO,
    any
  >,
) => {
  return useQuery(
    ['membership', membershipId],
    () => fetchCanvaLinkMembership(membershipId),
    options,
  );
};
