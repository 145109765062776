import React, { useEffect, useState } from 'react';
import { Chip, Grid } from '@mui/material';
import { useGetProfileTagsEnabled } from '../../../api';
import { TypeTag } from '../../../types';
import { stableSort } from '../../_common';

export type TagsHeaderProps = {
  selectedTags: TypeTag[];
  onSelectTag: (_tag: TypeTag) => void;
};

export const TagsHeader = ({ selectedTags, onSelectTag }: TagsHeaderProps) => {
  const { data } = useGetProfileTagsEnabled();
  const [sortedTags, setSortedTags] = useState<TypeTag[]>([]);

  useEffect(() => {
    if (!data) return;
    setSortedTags(
      stableSort<TypeTag>(data, {
        property: 'name',
        isDescending: false,
      }),
    );
  }, [data]);

  return (
    <Grid item xs={12}>
      {sortedTags.map((tag) => (
        <Chip
          sx={{ mr: 1, mb: 1 }}
          key={tag.id}
          label={tag.name}
          color={
            selectedTags.some((s) => s.id === tag.id) ? 'primary' : 'default'
          }
          onClick={() => onSelectTag(tag)}
        />
      ))}
    </Grid>
  );
};
