import React, { useContext } from 'react';
import { AnnouncementsView } from '../_common';
import { CoachTeams } from './Coach';
import { SportsCoachContext, SportsCoachProvider } from '../../providers';

const SportsCoachHomeWrapped = () => {
  const { selectedCoach } = useContext(SportsCoachContext);
  return (
    <>
      <AnnouncementsView role={'COACH'} leagueId={selectedCoach?.league?.id} />
      <CoachTeams />
    </>
  );
};

export const SportsCoachHome = () => {
  return (
    <SportsCoachProvider>
      <SportsCoachHomeWrapped />
    </SportsCoachProvider>
  );
};
