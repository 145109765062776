import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeCoach } from '../../../types';

const fetchCoachesByProfile = async (
  profileId: number,
): Promise<TypeCoach[]> => {
  const res = await axios.get(`api/coach/profile/${profileId}`);
  return res.data;
};

export const PrefetchCoachesByProfile = (profileId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['coach/profile', profileId],
    () => fetchCoachesByProfile(profileId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetCoachesByProfile = (
  profileId: number,
  options?: UseQueryOptions<TypeCoach[], AxiosError, TypeCoach[], any>,
) => {
  return useQuery(
    ['coach/profile', profileId],
    () => fetchCoachesByProfile(profileId),
    options,
  );
};
