import React, { useContext } from 'react';
import { DialogContext } from '../../../providers';
import { CancelDialog } from './CancelDialog';

type CancelAppointmentDialogProps = {
  cancelTitle: string;
  contentText: string;
  cancelFn: () => void;
};

export const CancelAppointmentDialog = ({
  cancelTitle,
  contentText,
  cancelFn,
}: CancelAppointmentDialogProps) => {
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { cancelAppointmentDialog } = dialogs;

  return (
    <CancelDialog
      cancelTitle={cancelTitle}
      contentText={contentText}
      cancelFn={cancelFn}
      closeFn={() => closeDialog('cancelAppointmentDialog')}
      open={cancelAppointmentDialog}
    />
  );
};
