import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeBodyGroup } from '../../../types';

export const usePostBodyGroup = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (bodyGroup: TypeBodyGroup) =>
      axios.post(`api/body-group`, bodyGroup).then((res) => res.data),
    {
      onMutate: (_bodyGroup: TypeBodyGroup) => {
        queryClient.cancelQueries('bodyGroup');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('bodyGroup');
        // queryClient.invalidateQueries(['bodyGroup', variables.id]).then(_ => {})
      },
    },
  );
};
