import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLeague } from '../../../types';

const fetchLeague = async (leagueId: number): Promise<TypeLeague> => {
  const res = await axios.get(`api/league/${leagueId}`);
  return res.data;
};

export const PrefetchLeague = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['league', leagueId],
    () => fetchLeague(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLeague = (
  leagueId: number,
  options?: UseQueryOptions<TypeLeague, AxiosError, TypeLeague, any>,
) => {
  return useQuery(['league', leagueId], () => fetchLeague(leagueId), options);
};
