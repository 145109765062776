import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLeagueTime } from '../../../types';

const fetchLeagueTimes = async (
  leagueId: number,
): Promise<TypeLeagueTime[]> => {
  const res = await axios.get(`api/league-time/league/${leagueId}`);
  return res.data;
};

export const PrefetchLeagueTime = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['league-time/league', leagueId],
    () => fetchLeagueTimes(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLeagueTimes = (
  leagueId: number,
  options?: UseQueryOptions<
    TypeLeagueTime[],
    AxiosError,
    TypeLeagueTime[],
    any
  >,
) => {
  return useQuery(
    ['league-time/league', leagueId],
    () => fetchLeagueTimes(leagueId),
    options,
  );
};
