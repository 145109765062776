// case insensitive search of n-number properties of type T
// returns true if at least one of the property values includes the query value

type ObjectMap<T> = {
  [key in keyof T]: T[key];
};

const isSimpleType = (value: any): boolean =>
  typeof value === 'string' || typeof value === 'number';
const isArrayType = (value: any): boolean =>
  typeof value === 'object' && value instanceof Array;
const isObjectType = (value: any): boolean =>
  typeof value === 'object' && !(value instanceof Array);
export const genericSearch = <T extends object>(
  object: T,
  properties: ObjectMap<T>,
): boolean => {
  const searchKeys = Object.keys(properties);
  const objectKeys = Object.keys(object);
  return searchKeys.every((searchKey) => {
    const searchValue = properties[searchKey];
    if (!objectKeys.includes(searchKey)) return false;
    const objectValue = object[searchKey];
    if (isSimpleType(objectValue) && isSimpleType(searchValue)) {
      return objectValue
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    } else if (isArrayType(objectValue) && isArrayType(searchValue)) {
      return searchValue.every((searchValueElement) =>
        objectValue.some(
          (objectValueElement) =>
            objectValueElement &&
            searchValueElement &&
            objectValueElement?.id === searchValueElement?.id,
        ),
      );
    } else if (isObjectType(objectValue) && isObjectType(searchValue)) {
      return objectValue && searchValue && objectValue?.id === searchValue?.id;
    }
    return false;
  });
};
