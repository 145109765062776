import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLeagueField } from '../../../types';

const fetchLeagueFields = async (
  leagueId: number,
): Promise<TypeLeagueField[]> => {
  const res = await axios.get(`api/league-field/league/${leagueId}`);
  return res.data;
};

export const PrefetchLeagueField = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['league-field/league', leagueId],
    () => fetchLeagueFields(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLeagueFields = (
  leagueId: number,
  options?: UseQueryOptions<
    TypeLeagueField[],
    AxiosError,
    TypeLeagueField[],
    any
  >,
) => {
  return useQuery(
    ['league-field/league', leagueId],
    () => fetchLeagueFields(leagueId),
    options,
  );
};
