import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeRecipe } from '../../../types';

const fetchRecipe = async (recipeId: number): Promise<TypeRecipe> => {
  const res = await axios.get(`api/recipe/${recipeId}`);
  return res.data;
};

export const PrefetchRecipe = (recipeId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['recipe', recipeId],
    () => fetchRecipe(recipeId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetRecipe = (
  recipeId: number,
  options?: UseQueryOptions<TypeRecipe, AxiosError, TypeRecipe, any>,
) => {
  return useQuery(['recipe', recipeId], () => fetchRecipe(recipeId), options);
};
