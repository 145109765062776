import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeLeagueDay } from '../../../types';

const fetchLeagueDays = async (leagueId: number): Promise<TypeLeagueDay[]> => {
  const res = await axios.get(`api/league-day/league/${leagueId}`);
  return res.data;
};

export const PrefetchLeagueDay = (leagueId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['league-day/league', leagueId],
    () => fetchLeagueDays(leagueId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetLeagueDays = (
  leagueId: number,
  options?: UseQueryOptions<TypeLeagueDay[], AxiosError, TypeLeagueDay[], any>,
) => {
  return useQuery(
    ['league-day/league', leagueId],
    () => fetchLeagueDays(leagueId),
    options,
  );
};
