import React, { useEffect, useState } from 'react';
import { Tags } from '../Tag';
import { TypeTagEntityMap } from '../../../types';
import { useGetImageTagEntityMap } from '../../../api';

export const ImageTags = () => {
  const [map, setMap] = useState<TypeTagEntityMap>({});
  const { data: imageTagEntityMap } = useGetImageTagEntityMap();

  useEffect(() => {
    if (!imageTagEntityMap) return;
    setMap(imageTagEntityMap);
  }, [imageTagEntityMap]);

  return <Tags type={'image'} mapFieldName={'Images'} map={map} />;
};
