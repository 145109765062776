import React, { useContext, useMemo, useState } from 'react';
import { Billing, Organizations, OrgInvites, Profile } from './index';
import {
  AccountCircle as AccountCircleIcon,
  CorporateFare as CorporateFareIcon,
  CreditCard as CreditCardIcon,
  Flag as FlagIcon,
  PersonAdd as PersonAddIcon,
  Upgrade,
} from '@mui/icons-material';
import { Container, Tab, Tabs } from '@mui/material';
import { AuthContext } from '../../../providers';
import { FeatureFlags } from '../FeatureFlag';
import { Version } from '../Version';

export const Account = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { hasRole, roles } = useContext(AuthContext);

  const tabs = useMemo(() => {
    const tabsList = [
      {
        key: 'account',
        icon: <AccountCircleIcon />,
        label: 'Profile',
        component: Profile,
      },
    ];
    if (hasRole(['SYSADMIN', 'ADMIN'])) {
      tabsList.push({
        key: 'billing',
        icon: <CreditCardIcon />,
        label: 'Billing',
        component: Billing,
      });
      tabsList.push({
        key: 'org-invites',
        icon: <PersonAddIcon />,
        label: 'Organization',
        component: OrgInvites,
      });
    }
    if (hasRole(['SYSADMIN'])) {
      tabsList.push({
        key: 'organizations',
        icon: <CorporateFareIcon />,
        label: 'All Organizations',
        component: Organizations,
      });
      tabsList.push({
        key: 'feature-flags',
        icon: <FlagIcon />,
        label: 'Feature Flags',
        component: FeatureFlags,
      });
      tabsList.push({
        key: 'version',
        icon: <Upgrade />,
        label: 'Version',
        component: Version,
      });
    }
    return tabsList;
  }, [roles]);

  return (
    <Container>
      <Tabs
        style={{ overflow: 'initial', minHeight: 'initial' }}
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        variant="scrollable"
        onChange={(event, value) => setSelectedTab(value)}
      >
        {tabs.map((tab) => {
          return <Tab key={tab.key} icon={tab.icon} label={tab.label} />;
        })}
      </Tabs>

      {tabs.map(
        (tab, index) => index === selectedTab && <tab.component key={index} />,
      )}
    </Container>
  );
};
