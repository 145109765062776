import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypePracticeSlot } from '../../../types';

const fetchPracticeSlots = async (
  divisionGroupId: number,
): Promise<TypePracticeSlot[]> => {
  const res = await axios.get(
    `api/practice-slot/division-group/${divisionGroupId}`,
  );
  return res.data;
};

export const PrefetchPracticeSlot = (divisionGroupId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['practice-slot/division-group', divisionGroupId],
    () => fetchPracticeSlots(divisionGroupId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetPracticeSlots = (
  divisionGroupId: number,
  options?: UseQueryOptions<
    TypePracticeSlot[],
    AxiosError,
    TypePracticeSlot[],
    any
  >,
) => {
  return useQuery(
    ['practice-slot/division-group', divisionGroupId],
    () => fetchPracticeSlots(divisionGroupId),
    options,
  );
};
