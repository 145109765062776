import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeTimeSlot } from '../../../types';

export const usePostTimeSlot = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (timeSlot: TypeTimeSlot) =>
      axios.post(`api/time-slot`, timeSlot).then((res) => res.data),
    {
      onMutate: (_timeSlot: TypeTimeSlot) => {
        queryClient.cancelQueries('time-slot');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('time-slot');
        queryClient.invalidateQueries('time-slot/division');
        queryClient.invalidateQueries('time-slot/league-week');
        // queryClient.invalidateQueries(['time-slot', variables.id]).then(_ => {})
      },
    },
  );
};
