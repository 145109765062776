import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeQuestionnaireQuestion } from '../../../types';

const fetchQuestionnaireQuestion = async (
  questionnaireQuestionId: number,
): Promise<TypeQuestionnaireQuestion> => {
  const res = await axios.get(
    `api/questionnaire-question/${questionnaireQuestionId}`,
  );
  return res.data;
};

export const PrefetchQuestionnaireQuestion = (
  questionnaireQuestionId: number,
) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['questionnaire-question', questionnaireQuestionId],
    () => fetchQuestionnaireQuestion(questionnaireQuestionId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetQuestionnaireQuestion = (
  questionnaireQuestionId: number,
  options?: UseQueryOptions<
    TypeQuestionnaireQuestion,
    AxiosError,
    TypeQuestionnaireQuestion,
    any
  >,
) => {
  return useQuery(
    ['questionnaire-question', questionnaireQuestionId],
    () => fetchQuestionnaireQuestion(questionnaireQuestionId),
    options,
  );
};
