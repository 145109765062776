import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMatch } from '../../../types';

const fetchMatches = async (divisionId: number): Promise<TypeMatch[]> => {
  const res = await axios.get(`api/match/division/${divisionId}`);
  return res.data;
};

export const PrefetchMatch = (divisionId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['match/division', divisionId],
    () => fetchMatches(divisionId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetMatchesByDivision = (
  divisionId: number,
  options?: UseQueryOptions<TypeMatch[], AxiosError, TypeMatch[], any>,
) => {
  return useQuery(
    ['match/division', divisionId],
    () => fetchMatches(divisionId),
    options,
  );
};
