import axios, { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TypeMeetingRecap } from '../../../types';

const fetchMeetingRecap = async (
  meetingRecapId: number,
): Promise<TypeMeetingRecap> => {
  const res = await axios.get(`api/meeting-recap/${meetingRecapId}`);
  return res.data;
};

export const PrefetchMeetingRecap = (meetingRecapId: number) => {
  const queryClient = useQueryClient();
  return queryClient.prefetchQuery(
    ['meeting-recap', meetingRecapId],
    () => fetchMeetingRecap(meetingRecapId),
    {
      staleTime: 5000,
    },
  );
};

export const useGetMeetingRecap = (
  meetingRecapId: number,
  options?: UseQueryOptions<
    TypeMeetingRecap,
    AxiosError,
    TypeMeetingRecap,
    any
  >,
) => {
  return useQuery(
    ['meeting-recap', meetingRecapId],
    () => fetchMeetingRecap(meetingRecapId),
    options,
  );
};
