import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useGetProfileProducts } from '../../../api/_common/Profile/useGetProfileProducts';
import { MembershipElement } from '../Store/MembershipElement';

export const Billing = () => {
  // const navigate = useNavigate();
  const { data: profileProductsData } = useGetProfileProducts();

  return (
    <Box m={2}>
      <Container>
        <Box mt={2}>
          <Typography variant="h4">Subscriptions</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {profileProductsData?.memberships.length ? (
              profileProductsData?.memberships.map((membership) => (
                <MembershipElement
                  key={membership.id}
                  membership={membership}
                />
              ))
            ) : (
              <Box m={2}>
                <Typography variant={'body1'}>
                  No subscriptions found.
                </Typography>
                {/*<Button*/}
                {/*  variant={'contained'}*/}
                {/*  onClick={() => navigate('/store')}*/}
                {/*>*/}
                {/*  Go to Store*/}
                {/*</Button>*/}
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
