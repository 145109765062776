import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeOrganization } from '../../../types';

export const OrganizationList = ({
  organizations,
  organizationsAvailable,
}: {
  organizations: TypeOrganization[] | undefined;
  organizationsAvailable: boolean;
}) => (
  <TableContainer hidden={!organizationsAvailable}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Id</TableCell>
          <TableCell>Name</TableCell>
          {/*<TableCell>Time</TableCell>*/}
        </TableRow>
      </TableHead>
      <TableBody>
        {organizations?.map((organization) => (
          <TableRow key={organization.id}>
            <TableCell>{organization.id}</TableCell>
            <TableCell>{organization.name}</TableCell>
          </TableRow>
        ))}
        {organizations?.length === 0 && (
          <TableRow>
            <TableCell>No Organizations Yet</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
);
