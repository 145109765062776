import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import {
  useGetMemberships,
  useGetStripeProducts,
  useGetTagsByType,
  usePostMembership,
  usePostStripeProductRehydrate,
} from '../../../api';
import { Link as RouterLink } from 'react-router-dom';
import { Refresh } from '@mui/icons-material';
import { TypeStripeProduct, TypeTag } from '../../../types';
import Autocomplete from '@mui/material/Autocomplete';

export const Memberships = () => {
  const [addMembershipName, setAddMembershipName] = useState<string>('');
  const [newMembershipStripeProduct, setNewMembershipStripeProduct] =
    useState<TypeStripeProduct | null>(null);
  const [canvaLinkTags, setCanvaLinkTags] = useState<TypeTag[]>([]);
  const [stripeProducts, setStripeProducts] = useState<TypeStripeProduct[]>([]);
  const { mutate: postStripeProductsRehydrate } =
    usePostStripeProductRehydrate();
  const { mutate: postMembership } = usePostMembership();
  const { data: memberships } = useGetMemberships();
  const { data: allCanvaLinkTags } = useGetTagsByType('canva-link');
  const { data: stripeProductsData } = useGetStripeProducts();

  useEffect(() => {
    setStripeProducts(
      stripeProductsData?.filter((sp) => !!sp.defaultPrice) || [],
    );
  }, [stripeProductsData]);

  const disableCreateMembership =
    !addMembershipName || !newMembershipStripeProduct || !canvaLinkTags.length;

  const createMembership = () => {
    if (disableCreateMembership) return;
    postMembership(
      {
        name: addMembershipName,
        stripeProduct: newMembershipStripeProduct,
        canvaLinkTags: canvaLinkTags,
      },
      {
        onSuccess: () => {
          setAddMembershipName('');
          setNewMembershipStripeProduct(null);
          setCanvaLinkTags([]);
        },
      },
    );
  };

  return (
    <Box m={2}>
      <Container>
        <Box mt={2}>
          <Typography variant="h4">Memberships</Typography>
        </Box>
        <Box mt={2}>
          <Button
            variant={'contained'}
            endIcon={<Refresh />}
            onClick={() => postStripeProductsRehydrate()}
          >
            Sync Stripe Products
          </Button>
        </Box>
        <TableContainer>
          <Table style={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={4}>MembershipElement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {memberships?.map((membership) => (
                <TableRow key={membership.id}>
                  <TableCell>
                    <Link
                      component={RouterLink}
                      to={`/membership/${membership.id}`}
                      underline="none"
                    >
                      {membership.name}
                    </Link>
                  </TableCell>
                  <TableCell>{membership.stripeProduct?.name}</TableCell>
                  <TableCell>
                    {membership.canvaLinkTags?.map((tag) => (
                      <Link
                        key={`tag-${tag.id}`}
                        component={RouterLink}
                        to={`/canva-link-tag/${tag.id}`}
                        underline="none"
                      >
                        <Chip
                          clickable
                          sx={{ marginRight: 1 }}
                          label={tag.name}
                        />
                      </Link>
                    ))}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="membershipName"
                    value={addMembershipName}
                    label="Add MembershipElement"
                    placeholder="MembershipElement Name"
                    onChange={(event) =>
                      setAddMembershipName(event.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Autocomplete
                    value={newMembershipStripeProduct}
                    id="stripeProduct"
                    options={stripeProducts || []}
                    getOptionLabel={(option) =>
                      option.name ? `${option.id} - ${option.name}` : ''
                    }
                    filterSelectedOptions
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    onChange={(_event, newValue) =>
                      newValue && setNewMembershipStripeProduct(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stripe Product"
                        placeholder="Stripe Product"
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Autocomplete
                    disablePortal
                    multiple
                    options={allCanvaLinkTags || []}
                    getOptionLabel={(option) => option.name || ''}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value?.id
                    }
                    value={canvaLinkTags}
                    renderInput={(params) => (
                      <TextField {...params} label="Tags" variant="outlined" />
                    )}
                    onChange={(_event, newValue) => setCanvaLinkTags(newValue)}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    disabled={disableCreateMembership}
                    onClick={createMembership}
                  >
                    Create MembershipElement
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};
