import React from 'react';
import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { TypeFeatureFlag } from '../../../types';

export const FeatureFlagList = ({
  featureFlags,
  featureFlagsAvailable,
  updateFeatureFlag,
}: {
  featureFlags: TypeFeatureFlag[];
  featureFlagsAvailable: boolean;
  updateFeatureFlag: (_featureFlag: TypeFeatureFlag) => void;
}) => {
  return (
    <>
      <TableContainer hidden={!featureFlagsAvailable}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Feature Flag Name</TableCell>
              <TableCell>Enabled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {featureFlags?.map((featureFlag) => (
              <TableRow key={featureFlag.id}>
                <TableCell>{featureFlag.name}</TableCell>
                <TableCell>
                  <Switch
                    color="primary"
                    checked={featureFlag.isEnabled}
                    onChange={(_event, checked) =>
                      updateFeatureFlag({
                        ...featureFlag,
                        isEnabled: checked,
                      })
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
