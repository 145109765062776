import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypeAppointmentCalendar } from '../../../types';

export const usePostAppointmentCalendar = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (appointmentCalendar: TypeAppointmentCalendar) =>
      axios
        .post(`api/appointment-calendar`, appointmentCalendar)
        .then((res) => res.data),
    {
      onMutate: () => {
        queryClient.cancelQueries('appointment-calendar');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('appointment-calendar');
        queryClient.invalidateQueries('availability');
      },
    },
  );
};
