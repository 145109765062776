import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import React, { useContext } from 'react';
import { ProjectContext } from '../../providers';
import { Launch } from '@mui/icons-material';

export interface LinkViewProps {
  link?: string;
  title: string;
  isImage?: boolean;
}

export const LinkView = ({ link, title, isImage }: LinkViewProps) => {
  const { cld } = useContext(ProjectContext);
  const linkUrl = isImage && link && cld ? cld.image(link).toURL() : link;
  return linkUrl ? (
    <Link to={linkUrl} target={'_blank'}>
      <Button variant="outlined" endIcon={<Launch />}>
        {title}
      </Button>
    </Link>
  ) : (
    <Button variant="outlined" disabled>
      {title}
    </Button>
  );
};
