import React, { useContext, useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  TextField,
} from '@mui/material';
import { AuthContext, DialogContext, ProjectContext } from '../../../providers';

export const DeleteAccountDialog = () => {
  const { user, deleteAccount } = useContext(AuthContext);
  const { performingAction } = useContext(ProjectContext);
  const { dialogs, closeDialog } = useContext(DialogContext);
  const { deleteAccountDialog } = dialogs;

  const [displayName, setDisplayName] = useState(user?.displayName || '');

  const handleKeyPress = (event) => {
    if (user && user.displayName) {
      if (!displayName) {
        return;
      }

      if (displayName !== user.displayName) {
        return;
      }
    }

    const key = event.key;

    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    if (key === 'Enter') {
      deleteAccount();
    }
  };

  const handleExited = () => {
    setDisplayName('');
  };

  const handleUsernameChange = (event) => {
    const displayName = event.target.value;

    setDisplayName(displayName);
  };

  const hasUsername = (user && !!user.displayName) ?? false;

  return (
    <Dialog
      open={deleteAccountDialog}
      TransitionProps={{
        onKeyPress: handleKeyPress,
        onExited: handleExited,
      }}
    >
      <DialogTitle>Delete account?</DialogTitle>

      <DialogContent>
        <Box mb={hasUsername ? 2 : 0}>
          <DialogContentText>
            Deleted accounts can’t be recovered. All data associated with your
            account will be deleted.
          </DialogContentText>

          {hasUsername && (
            <DialogContentText>
              Type your displayName and <Hidden xsDown>click</Hidden>
              <Hidden smUp>tap</Hidden> Delete to delete your account. This
              action is irreversible.
            </DialogContentText>
          )}
        </Box>

        {hasUsername && (
          <TextField
            autoComplete="displayName"
            autoFocus
            color="secondary"
            disabled={performingAction}
            fullWidth
            label="Username"
            placeholder={user?.displayName || 'Display Name'}
            required
            type="text"
            value={displayName}
            variant="outlined"
            onChange={handleUsernameChange}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          disabled={performingAction}
          onClick={() => closeDialog('deleteAccountDialog')}
        >
          Cancel
        </Button>
        <Button
          color="secondary"
          disabled={
            performingAction ||
            (hasUsername && displayName !== user?.displayName)
          }
          variant="contained"
          onClick={deleteAccount}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
