import React, { useContext } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import { ChefContext } from '../../../providers';
import { TypeIngredient } from '../../../types';
import { Link as RouterLink } from 'react-router-dom';

type ViewIngredientProps = {
  recipeId: number;
  ingredient: TypeIngredient;
};

export const ViewIngredient = ({
  recipeId,
  ingredient,
}: ViewIngredientProps) => {
  const { getRecipeMultiplier } = useContext(ChefContext);

  const findGCD = (a: number, b: number) => {
    // Euclidean algorithm to find the GCD
    if (b === 0) {
      return a;
    } else {
      return findGCD(b, a % b);
    }
  };

  const processNumeratorDenominator = (
    numerator: number,
    denominator: number,
  ) => {
    if (numerator % denominator === 0) {
      return `${numerator / denominator}`;
    } else {
      const newWholeNumber = Math.floor(numerator / denominator);
      const newNumerator = numerator % denominator;
      const gcd = findGCD(newNumerator, denominator);
      const reducedNumerator = newNumerator / gcd;
      const reducedDenominator = denominator / gcd;
      if (newWholeNumber > 0) {
        return `${newWholeNumber} ${reducedNumerator}/${reducedDenominator}`;
      } else {
        return `${reducedNumerator}/${reducedDenominator}`;
      }
    }
  };

  const calculatedAmount = () => {
    const multiplier = getRecipeMultiplier(recipeId);
    if (
      ingredient.amount &&
      ingredient.fractionalNumerator &&
      ingredient.fractionalDenominator
    ) {
      const multipliedNumerator =
        multiplier *
        (ingredient.amount * ingredient.fractionalDenominator +
          ingredient.fractionalNumerator);
      if (multipliedNumerator.toString().includes('.')) {
        const newNumerator = multipliedNumerator * 2;
        const newDenominator = ingredient.fractionalDenominator * 2;
        return processNumeratorDenominator(newNumerator, newDenominator);
      } else {
        return processNumeratorDenominator(
          multipliedNumerator,
          ingredient.fractionalDenominator,
        );
      }
    } else if (ingredient.amount) {
      const multipliedNumerator = multiplier * ingredient.amount;
      if (multipliedNumerator.toString().includes('.')) {
        const newNumerator = multipliedNumerator * 2;
        const newDenominator = 2;
        return processNumeratorDenominator(newNumerator, newDenominator);
      } else {
        return `${multipliedNumerator}`;
      }
    } else if (
      ingredient.fractionalNumerator &&
      ingredient.fractionalDenominator
    ) {
      const multipliedNumerator = multiplier * ingredient.fractionalNumerator;
      if (multipliedNumerator.toString().includes('.')) {
        const newNumerator = multipliedNumerator * 2;
        const newDenominator = ingredient.fractionalDenominator * 2;
        return processNumeratorDenominator(newNumerator, newDenominator);
      }
      return processNumeratorDenominator(
        multipliedNumerator,
        ingredient.fractionalDenominator,
      );
    } else {
      return '';
    }
  };

  return (
    <Grid item xs={12}>
      <Typography variant={'body1'}>
        {calculatedAmount()} {ingredient.measurement?.measurementName}{' '}
        {ingredient.materialOption?.recipe ? (
          <Link
            key={`recipe-${ingredient.materialOption?.recipe.id}`}
            component={RouterLink}
            to={`/recipe/${ingredient.materialOption?.recipe.id}`}
          >
            {ingredient.materialOption?.recipe.recipeTitle}
          </Link>
        ) : (
          ingredient.materialOption?.material?.materialName
        )}
      </Typography>
    </Grid>
  );
};
