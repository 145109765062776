import React, { useContext, useMemo } from 'react';
import { AdvancedImage, lazyload } from '@cloudinary/react';
import { quality } from '@cloudinary/url-gen/actions/delivery';
import { fill, thumbnail } from '@cloudinary/url-gen/actions/resize';
import { ProjectContext } from '../../../providers';
import { Typography } from '@mui/material';

type CloudinaryPhotoProps = {
  cloudName?: string;
  publicId?: string;
  width?: number;
  height?: number;
  isThumbnail?: boolean;
  format?: string;
};
export const CloudinaryPhoto = ({
  publicId,
  width,
  height,
  isThumbnail,
  format,
}: CloudinaryPhotoProps) => {
  const { cld } = useContext(ProjectContext);

  const myImage = useMemo(() => {
    if (!cld || !publicId) return null;
    const cldImage = cld.image(publicId);
    if (isThumbnail) {
      return cldImage.resize(
        thumbnail()
          .width(width || 150)
          .height(height || 150),
      );
    } else {
      return cldImage
        .resize(
          fill()
            .width(width || 250)
            .height(height || 250),
        )
        .delivery(quality(60));
    }
  }, [cld, publicId, width, height]);

  const styles = useMemo(() => {
    if (isThumbnail) {
      return {
        padding: '10px',
      };
    } else {
      return {
        width: '100%',
        padding: '10px',
      };
    }
  }, [isThumbnail]);

  if (!myImage) return <></>;
  if (format === 'pdf') return <Typography variant={'body1'}>PDF</Typography>;

  return (
    <AdvancedImage style={styles} cldImg={myImage} plugins={[lazyload()]} />
  );
};
