import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { TypePracticeAvailabilityDay } from '../../../types';

export const usePostPracticeAvailabilityDay = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (practiceAvailabilityDay: TypePracticeAvailabilityDay) =>
      axios
        .post(`api/practice-availability`, practiceAvailabilityDay)
        .then((res) => res.data),
    {
      onMutate: (_practiceAvailabilityDay: TypePracticeAvailabilityDay) => {
        queryClient.cancelQueries('practice-availability');
      },
      onError: () => {},
      onSuccess: () => {
        queryClient.invalidateQueries('practice-availability');
        queryClient.invalidateQueries('practice-availability/team');
        queryClient.invalidateQueries('practice-slot-day/team');
      },
    },
  );
};
