import { analytics, auth, storage } from '../firebaseapp';

export const authentication = {
  authProviderData: (providerId) => {
    if (!providerId) {
      return;
    }

    const currentUser = auth.currentUser;

    if (!currentUser) {
      return;
    }

    const providerData = currentUser.providerData;

    if (!providerData) {
      return;
    }

    return providerData.find(
      (authProvider) => authProvider.providerId === providerId,
    );
  },
  changeAvatar: (avatar) => {
    return new Promise((resolve, reject) => {
      if (!avatar) {
        reject(new Error('No avatar'));

        return;
      }

      const avatarFileTypes = [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/webp',
        'image/svg+xml',
      ];

      if (!avatarFileTypes.includes(avatar.type)) {
        reject(new Error('Invalid file type'));

        return;
      }

      if (avatar.size > 20 * 1024 * 1024) {
        reject(new Error('Invalid size'));

        return;
      }

      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error('No current user'));

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject(new Error('No UID'));

        return;
      }

      const avatarReference = storage
        .ref()
        .child('images')
        .child('avatars')
        .child(uid);

      avatarReference
        .put(avatar)
        .then(() => {
          avatarReference
            .getDownloadURL()
            .then((value) => {
              currentUser
                .updateProfile({
                  photoURL: value,
                })
                .then((value) => {
                  analytics.logEvent('change_avatar');

                  resolve(value);
                })
                .catch((reason) => {
                  reject(reason);
                });
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  removeAvatar: () => {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject(new Error('No current user'));

        return;
      }

      const uid = currentUser.uid;

      if (!uid) {
        reject(new Error('No UID'));

        return;
      }

      currentUser
        .updateProfile({
          photoURL: null,
        })
        .then(() => {
          const avatarReference = storage
            .ref()
            .child('images')
            .child('avatars')
            .child(uid);

          avatarReference
            .delete()
            .then((value) => {
              analytics.logEvent('remove_avatar');

              resolve(value);
            })
            .catch((reason) => {
              reject(reason);
            });
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
  getNameInitials: (fields) => {
    if (!fields) {
      return null;
    }

    const firstName = fields.firstName;
    const lastName = fields.lastName;
    const username = fields.username;
    const displayName = fields.displayName;

    if (firstName && lastName) {
      return firstName.charAt(0) + lastName.charAt(0);
    }

    if (firstName) {
      return firstName.charAt(0);
    }

    if (username) {
      return username.charAt(0);
    }

    if (lastName) {
      return lastName.charAt(0);
    }

    if (displayName) {
      return displayName.charAt(0);
    }

    return null;
  },
};
