import React, { useContext, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { LeagueContext } from '../../../providers';
import { Loader } from '../../_common';
import { Add } from '@mui/icons-material';
import { PracticeDivisionGroupDetails } from './PracticeDivisionGroupDetails';
import { PracticeDivisionGroup } from './PracticeDivisionGroup';

export const PracticeSchedule = () => {
  const { isLeagueReady, practiceDivisionGroups } = useContext(LeagueContext);

  const [selectedTab, setSelectedTab] = useState(0);

  if (!isLeagueReady) {
    return <Loader />;
  }

  return (
    <>
      <Tabs
        style={{ overflow: 'initial', minHeight: 'initial' }}
        indicatorColor="primary"
        textColor="primary"
        value={selectedTab}
        variant="scrollable"
        onChange={(event, value) => setSelectedTab(value)}
      >
        {practiceDivisionGroups.map((practiceDivisionGroupId, index) => {
          return (
            <Tab
              key={index}
              icon={<GroupIcon />}
              label={practiceDivisionGroupId.name}
            />
          );
        })}
        <Tab key={practiceDivisionGroups.length} icon={<Add />} label={'Add'} />
      </Tabs>
      <Box>
        {practiceDivisionGroups.map(
          (practiceDivisionGroup, index) =>
            index === selectedTab && (
              <PracticeDivisionGroup
                practiceDivisionGroup={practiceDivisionGroup}
                key={index}
              />
            ),
        )}
        {practiceDivisionGroups.length === selectedTab && (
          <PracticeDivisionGroupDetails />
        )}
      </Box>
    </>
  );
};
