import React, { useContext, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  Switch,
  Typography,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Add, Remove } from '@mui/icons-material';
import {
  Ingredient,
  Instruction,
  RecipeEventList,
  RecipeHeader,
  RecipeList,
  ViewIngredient,
  ViewInstruction,
  ViewRecipeHeader,
} from '.';
import { genericMoveItem, Loader } from '../../_common';
import {
  ChefContext,
  ProjectContext,
  RecipeContext,
  RecipeProvider,
} from '../../../providers';
import {
  DefaultIngredient,
  DefaultInstruction,
} from '../../../types/_common/defaults';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TypeIngredient, TypeInstruction } from '../../../types';
import { getTime } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers';

const RecipeWrapper = () => {
  const { performingAction } = useContext(ProjectContext);
  const { editMode, setEditMode, getRecipeMultiplier, setRecipeMultiplier } =
    useContext(ChefContext);
  const {
    recipe,
    setRecipe,
    handleAddRecipeEvent,
    recipeEvents,
    linkedMaterialRecipes,
    isRecipeReady,
  } = useContext(RecipeContext);
  const [recipeEventMadeAt, setRecipeEventMadeAt] = useState<Date>(new Date());

  const addEmptyRowToIngredientList = () => {
    const oldIngredientList = recipe?.ingredientList || [];
    setRecipe({
      ...recipe,
      ingredientList: [
        ...oldIngredientList,
        {
          ...DefaultIngredient,
          position: oldIngredientList.length,
          sortableId: `temp-ingredient-${getTime(new Date())}`,
        },
      ],
    });
  };

  const addEmptyRowToInstructionList = () => {
    const oldInstructionList = recipe?.instructionList || [];
    console.log(oldInstructionList);
    setRecipe({
      ...recipe,
      instructionList: [
        ...oldInstructionList,
        {
          ...DefaultInstruction,
          position: oldInstructionList.length,
          sortableId: `temp-instruction-${getTime(new Date())}`,
        },
      ],
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 250,
        tolerance: 5,
      },
    }),
  );

  const handleIngredientDragEnd = (event) => {
    const { active, over } = event;

    const ingredients = recipe.ingredientList;
    console.log('drag end', active, over, ingredients);
    if (active.id !== over.id && ingredients !== undefined) {
      const oldIndex = ingredients.findIndex(
        (ingredient) => ingredient.sortableId === active.id,
      );
      const newIndex = ingredients.findIndex(
        (ingredient) => ingredient.sortableId === over.id,
      );
      console.log('drag end', active.id, oldIndex, over.id, newIndex);
      // assume we have moved the item to the new index
      // update each item's priorityValue to reflect the new order
      // then save only the affected items

      if (
        oldIndex !== undefined &&
        oldIndex > -1 &&
        newIndex !== undefined &&
        newIndex > -1
      ) {
        const ingredientList = genericMoveItem<TypeIngredient>(
          ingredients,
          { property: 'position' },
          oldIndex,
          newIndex,
        );
        console.log('ingredientList', ingredientList);
        setRecipe({
          ...recipe,
          ingredientList,
        });
      }
    }
  };

  const handleInstructionDragEnd = (event) => {
    const { active, over } = event;

    const instructions = recipe.instructionList;
    console.log('drag end', active, over, instructions);
    if (active.id !== over.id && instructions !== undefined) {
      const oldIndex = instructions.findIndex(
        (instruction) => instruction.sortableId === active.id,
      );
      const newIndex = instructions.findIndex(
        (instruction) => instruction.sortableId === over.id,
      );
      console.log('drag end', active.id, oldIndex, over.id, newIndex);
      // assume we have moved the item to the new index
      // update each item's priorityValue to reflect the new order
      // then save only the affected items

      if (
        oldIndex !== undefined &&
        oldIndex > -1 &&
        newIndex !== undefined &&
        newIndex > -1
      ) {
        const instructionList = genericMoveItem<TypeInstruction>(
          instructions,
          { property: 'position' },
          oldIndex,
          newIndex,
        );
        console.log('instructionList', instructionList);
        setRecipe({
          ...recipe,
          instructionList,
        });
      }
    }
  };

  if (!isRecipeReady) {
    return <Loader />;
  }

  return (
    <Box m={2}>
      {recipe && (
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                value="start"
                control={
                  <Switch
                    color="primary"
                    checked={editMode}
                    onChange={(_event, checked) => setEditMode(checked)}
                  />
                }
                label="Edit Mode"
                labelPlacement="start"
              />
            </Grid>
            {editMode ? <RecipeHeader /> : <ViewRecipeHeader />}
            <Grid item xs={12}>
              <Typography variant="h5">Ingredients</Typography>
            </Grid>
            <Grid item xs={12}>
              {editMode ? (
                <Alert severity="info">
                  Use fractions or whole numbers for quantity.
                </Alert>
              ) : (
                <>
                  <IconButton
                    onClick={() => setRecipeMultiplier(recipe.id!!, false)}
                  >
                    <Remove />
                  </IconButton>
                  <Button>{`${getRecipeMultiplier(recipe.id!!)}x`}</Button>
                  <IconButton
                    onClick={() => setRecipeMultiplier(recipe.id!!, true)}
                  >
                    <Add />
                  </IconButton>
                </>
              )}
            </Grid>
            <Grid item xs={12}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                // onDragStart={handleDragStart}
                onDragEnd={handleIngredientDragEnd}
              >
                <SortableContext
                  items={(recipe.ingredientList ?? []).map(
                    (ingredient) => ingredient.sortableId as UniqueIdentifier,
                  )}
                  strategy={verticalListSortingStrategy}
                >
                  <List>
                    {(recipe.ingredientList ?? []).map(
                      (ingredient, ingredientIndex) =>
                        editMode ? (
                          <Ingredient
                            key={`ingredient-${ingredient.sortableId}`}
                            ingredient={ingredient}
                            ingredientIndex={ingredientIndex}
                          />
                        ) : (
                          <ViewIngredient
                            key={`ingredient-${ingredient.sortableId}`}
                            recipeId={recipe.id!!}
                            ingredient={ingredient}
                          />
                        ),
                    )}
                  </List>
                </SortableContext>
              </DndContext>
            </Grid>
            {editMode && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={addEmptyRowToIngredientList}
                  startIcon={<Add />}
                >
                  Add Ingredient
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h5">Instructions</Typography>
            </Grid>
            <Grid item xs={12}>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                // onDragStart={handleDragStart}
                onDragEnd={handleInstructionDragEnd}
              >
                <SortableContext
                  items={(recipe.instructionList ?? []).map(
                    (instruction) => instruction.sortableId as UniqueIdentifier,
                  )}
                  strategy={verticalListSortingStrategy}
                >
                  <List>
                    {recipe.instructionList?.map(
                      (instruction, instructionIndex) =>
                        editMode ? (
                          <Instruction
                            key={`instruction-${instruction.sortableId}`}
                            instruction={instruction}
                            instructionIndex={instructionIndex}
                          />
                        ) : (
                          <ViewInstruction
                            key={`instruction-${instruction.sortableId}`}
                            instruction={instruction}
                            instructionIndex={instructionIndex}
                          />
                        ),
                    )}
                  </List>
                </SortableContext>
              </DndContext>
            </Grid>
            {editMode && (
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={addEmptyRowToInstructionList}
                  startIcon={<Add />}
                >
                  Add Instruction
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              {recipe?.isMaterial && (
                <RecipeList
                  recipes={linkedMaterialRecipes}
                  recipesAvailable={!!linkedMaterialRecipes}
                  flatList
                />
              )}
            </Grid>
            {!editMode && (
              <>
                <Grid item xs={8}>
                  <DateTimePicker
                    sx={{ width: '100%' }}
                    value={recipeEventMadeAt}
                    label="Made At"
                    onChange={(value) => value && setRecipeEventMadeAt(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    disabled={performingAction}
                    onClick={() =>
                      recipeEventMadeAt &&
                      handleAddRecipeEvent(recipe, recipeEventMadeAt)
                    }
                    startIcon={<Add />}
                  >
                    Add Recipe Event
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <RecipeEventList recipeEvents={recipeEvents} />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      )}
    </Box>
  );
};

export const Recipe = () => {
  const { recipeId } = useParams();
  const recipeIdNum = Number(recipeId);
  if (!recipeIdNum) return <></>;

  return (
    <RecipeProvider recipeId={recipeIdNum}>
      <RecipeWrapper />
    </RecipeProvider>
  );
};
