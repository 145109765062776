import React, { useContext } from 'react';
import { SelectCatalogItem } from './SelectCatalogItem';
import { SelectAppointmentType } from './SelectAppointmentType';
import { AppointmentContext, AuthContext } from '../../../../providers';

export const SelectAppointmentTypeOrCatalogItem = () => {
  const { profile } = useContext(AuthContext);
  const { selectedAppointmentClientProfile } = useContext(AppointmentContext);
  return (
    <>
      <SelectAppointmentType />
      {profile?.id === selectedAppointmentClientProfile?.id && (
        <SelectCatalogItem />
      )}
    </>
  );
};
