import { formatInTimeZone } from 'date-fns-tz';

export const DATE_TIME_FORMATS = {
  dateTimeTZ: `yyyy-MM-dd'T'HH:mm:ssXXX`,
  timeTZ: 'HH:mmXXX',
  dateOnly: `yyyy-MM-dd`,
  dateTimeOrdinal: 'MMM do, yyyy - hh:mmaaa',
  timeOrdinal: 'hh:mm a',
  zuluTimeOnly: `HH:mm'Z'`,
} as const;
export type DateTimeFormatKey = keyof typeof DATE_TIME_FORMATS;

export const formatDateToStringTZ = (
  date: Date | string | null | undefined,
  tz?: string,
  format: DateTimeFormatKey = 'dateTimeTZ',
  fallback?: string,
): string | undefined =>
  date
    ? formatInTimeZone(date, tz ? tz : 'UTC', DATE_TIME_FORMATS[format])
    : fallback;
