interface ISortBy<T> {
  property: {
    [K in keyof T]: T[K] extends number | undefined ? K : never;
  }[keyof T];
}

export const genericMoveItem = <T,>(
  array: T[],
  sortProperty: ISortBy<T>,
  fromIndex: number,
  toIndex: number,
): T[] => {
  let lastPriority = array[toIndex][sortProperty.property]; // Save the original priorityValue

  // If moving to the right
  if (fromIndex < toIndex) {
    for (let i = toIndex; i > fromIndex; i--) {
      array[i][sortProperty.property] = array[i - 1][sortProperty.property]; // Shift right items' priorityValue
    }
  }
  // If moving to the left
  else if (fromIndex > toIndex) {
    for (let i = toIndex; i < fromIndex; i++) {
      array[i][sortProperty.property] = array[i + 1][sortProperty.property]; // Shift left items' priorityValue
    }
  }

  // Set the task that was moved to the new priorityValue
  array[fromIndex][sortProperty.property] = lastPriority;

  // Move the task itself to the new position in the array
  const [item] = array.splice(fromIndex, 1);
  array.splice(toIndex, 0, item);

  return array;
};
